import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

async function getApi(data) {
    const apiUrl = API.baseURL + API.endPoints.addBookMark + data.id + "/bookmark";
    try {
        const response = axios.delete(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* deleteBookamark(action) {
    try {
        const bookmark = yield call(getApi, action.payload);
        yield put({ type: 'DELETE_BOOKMARK_SUCCESS', popData: action.payload });
    } catch (e) {
        yield put({ type: 'DELETE_BOOKMARK_FAILED', message: e.message });
    }
}

function* deleteBookmarkSaga() {
    yield takeEvery('DELETE_BOOKMARK_REQUESTED', deleteBookamark);
}

export default deleteBookmarkSaga;