import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { IonButton, IonIcon, IonContent } from '@ionic/react';
import { Link } from "react-router-dom";
import StatsLoader from "./statsSkeleton";
import { IonButton, IonDatetime } from '@ionic/react';
// import { getNotificationList } from "../../redux/actions/notificationList";
import { getNotificationCount } from "../../redux/actions/notificationCount";
import DasboardNotification from "./dashboardNotification";
import Alert from "../../components/dashboard/alert";
import { Swiper, SwiperSlide } from 'swiper/react';
import PrimaryNotificationLoader from '../../components/dashboard/primaryNotificationLoader'

const Stats = (props) => {
  const { loading, provider_stats, earned, project_completed, project_interactions, saved, notifications, primaryNotifications, profile_score } = props;
  console.log(earned, "earned in the stats page")

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotificationCount());
    // dispatch(getNotificationList());
  }, []);
  // notificationCount
  const notificationCount = useSelector(state => state.notificationCount.notification_count);
  const firstFiveNotifications = useSelector(state => state.firstFiveNotifications.data);
  const firstFiveNotificationsLoading = useSelector(state => state.firstFiveNotifications.loading);
  const firstFiveNotificationsError = useSelector(state => state.firstFiveNotifications.error);
  const firstTwoNotifications = firstFiveNotifications && firstFiveNotifications.length != 0 && firstFiveNotifications.slice(0, 2);
  const convertAmount = (amount) => {
    if (isNaN(amount))
      return amount

    if (amount >= 1000 && amount < 1000000)
      return Math.ceil(amount / 1000) + "K"
    else if (amount >= 1000000 && amount < 1000000000)
      return Math.ceil(amount / 1000000) + "M"
    else if (amount >= 1000000000)
      return Math.ceil(amount / 1000000000) + "B"
    else
      return amount
  }

  return (
    <section className="ebw-section ebw-section--achievements">
      <div className="ebw-achievements__inwrap container">
        <div className="ebw-achivements__wrap d-flex flex-wrap align-items-center justify-content-between">
          <div className={`ebw-achievements__items ebw-card ebw-card__others d-flex flex-wrap align-items-start mb-3 mb-md-0 ${primaryNotifications.length ? "ebw-achievements__items-withMsg" : ""}`}>
            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  // width={150}
                  // height={100}
                  />
                </div>
                :
                (

                  <>
                    <div className="ebw-achievements__item-icon ebw-achievements__item-icon--saved  mb-2">
                      <img className="img-fluid" src="./images/dashboard/stats/saved.svg" alt="Saved" />
                    </div>
                    <div className="mb-2">
                      <div className="ebw-achievements__item-count">$<span>{convertAmount(saved)}</span></div>
                      <div className="ebw-achievements__item-title">Saved</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">{saved == "0" ? "Bid on projects, get hired and start saving while earning." : "You have saved astounding stakes using your Pro membership."}</div>
                  </>

                )
              }
            </div>

            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  // width={150}
                  // height={100}
                  />
                </div> :
                (

                  <>
                    <div className="ebw-achievements__item-icon ebw-achievements__item-icon--project-interactions  mb-2">
                      <img className="img-fluid" src="./images/dashboard/stats/project-interactions.svg" alt="Bidded" />
                    </div>
                    <div className="mb-2">
                      <div className="ebw-achievements__item-count"><span>{project_interactions}</span></div>
                      <div className="ebw-achievements__item-title">Project Interactions</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">Keep bidding on more projects of your choice to increase the number of interactions.</div>
                  </>

                )
              }
            </div>

            {/* <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                    <StatsLoader
                      width={150}
                      height={100}
                    />
                </div>  :
                (

                <>
                  <div className="ebw-achievements__item-icon  mb-2">
                    <img className="img-fluid" src="./images/dashboard/earned.svg" alt="Earned" />
                  </div>
                  <div className="d-md-flex flex-wrap align-items-end mb-1">
                    <div className="ebw-achievements__item-count">$<span>{provider_stats && provider_stats.earned}</span></div>
                    <div className="ebw-achievements__item-title">Earned</div>
                  </div>
                  <div className="ebw-achievements__item-para d-none d-md-block">Earn high value projects with easybids</div>
                </>

                )
              }
            </div> */}

            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  // width={150}
                  // height={100}
                  />
                </div> :
                (

                  <>
                    <div className="ebw-achievements__item-icon ebw-achievements__item-icon--earned mb-2">
                      <img className="img-fluid" src="./images/dashboard/stats/earned.svg" alt="Completed" />
                      {/* <IonButton color="primary">Primary</IonButton> */}
                    </div>
                    <div className="mb-2">
                      <div className="ebw-achievements__item-count"><span>${convertAmount(earned)}</span></div>
                      <div className="ebw-achievements__item-title">Earned</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">{earned == 0 ? " Bid on projects, complete them & start growing your business" : `You have earned this amount by completing your projects.`}</div>
                  </>

                )
              }
            </div>
            <div className="ebw-achievements__item">
              {loading ?
                <div className="my-auto">
                  <StatsLoader
                  // width={150}
                  // height={100}
                  />
                </div> :
                (

                  <>
                    <div className="ebw-achievements__item-icon ebw-achievements__item-icon--projects-completed mb-2">
                      <img className="img-fluid" src="./images/dashboard/stats/projects-completed.svg" alt="Completed" />
                    </div>
                    <div className="mb-2">
                      <div className="ebw-achievements__item-count"><span>{project_completed}</span></div>
                      <div className="ebw-achievements__item-title">Projects Completed</div>
                    </div>
                    <div className="ebw-achievements__item-para d-none d-md-block">{project_completed == 0 ? "Place bids on available projects & get hired now!" : `You have been completed ${project_completed} projects.`}</div>
                  </>

                )
              }
            </div>
          </div>
          {primaryNotifications.length ?
            <div className="ebw-achievements__msg-box px-md-3">
              <div className="ebw-card ebw-card__others py-5">
                <div className='d-flex align-items-center justify-content-between'>
                  <h6 className='edbw-title__secondary'>Quick Actions</h6>
                  <Link to="" className='ebw-button--plain ebw-button__plain--blue font-medium'>Show all</Link>
                </div>
                {/* <Swiper
                  className="ebw-swiper__container pb-0 w-100 h-100"
                  loop={true}
                  autoplay={{ delay: 3000 }}
                  spaceBetween={20}
                  slidesPerView="auto"
                  // pagination={{
                  //   el: 'swiper-pagination',
                  //   clickable: true
                  // }}
                  navigation={{
                    prevEl: '.prev',
                    nextEl: '.next',
                  }}
                  breakpoints={{
                    // when window width is >= 640px
                    640: {
                      spaceBetween: '0'
                    },
                    // when window width is >= 768px
                    768: {
                      spaceBetween: '20'
                    },
                  }}
                > */}
                {/* <SwiperSlide className='ebw-notification__slide--swiper'> */}
                {/* <div className="ebw-achievements__notification-list"> */}
                {loading ? <PrimaryNotificationLoader /> : <DasboardNotification
                  notificationLoading={firstFiveNotificationsLoading}
                  primaryNotifications={primaryNotifications}
                  toWhere={"inDashboard"}
                />}
                {/* </div> */}
                {/* </SwiperSlide> */}
                {/* </Swiper> */}
                <div className="ebw-slider__navigation mt-0">
                  <div className="ebw-slider__navigation-arrow ebw-slider__navigation-arrow--prev prev">
                    <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon "></i>
                  </div>
                  <div className="ebw-slider__navigation-arrow next ebw-slider__navigation-arrow--active">
                    <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon"></i>
                  </div>
                </div>
              </div>
            </div> :
            ""
          }
        </div>
      </div>
      {/* <Alert value={profile_score} /> */}
    </section>
  );
}

export default Stats;