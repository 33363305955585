import * as type from '../actions/types';

const initialState = {
    selectedCategories: [],
    loading: false,
    error: null,
}

export default function selectedCategoryData(state = initialState, action) {
    switch (action.type) {
        case type.STORE_SELECTED_CATEGORY_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.STORE_SELECTED_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedCategories: action.data,
            }
        case type.STORE_SELECTED_CATEGORY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}