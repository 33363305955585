import { call, put, takeEvery } from 'redux-saga/effects'
import storeBusiness from '../reducers/storeBusiness';




function* storeData(action) {
    if (action.data) {
        // localStorage.setItem("street_address",action.data.street_address);
        // localStorage.setItem("zipcode",action.data.zipcode);
        // localStorage.setItem("company_name",action.data.company_name);
        // localStorage.setItem("company_type",action.data.company_type);
        // localStorage.setItem("provider_locations_attributes",action.data.provider_locations_attributes));
        // localStorage.setItem("years_of_business",action.data.years_of_business);
        // street_address: this.state.street_address,
        // 			zipcode: this.state.zipcode,
        // 			company_name: this.state.company_name,
        // 			company_type: this.state.company_type,
        // 			project_type_ids: this.state.project_type_ids.selected_service_id,
        // 			project_sub_type_ids: this.state.project_type_ids.selected_sub_service_id,
        // 			provider_locations_attributes: selected_cities,
        // 			years_of_business: this.state.years_of_business,

        yield put({ type: 'REGISTER_STORE_BUSINESS_SUCCESS', business: action.data });
        yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 4 });
    } else {
        yield put({ type: 'REGISTER_STORE_BUSINESS_FAILED', message: "no data" });
    }
}

function* storeBusinessSaga() {
    yield takeEvery('REGISTER_STORE_BUSINESS_REQUESTED', storeData);
}

export default storeBusinessSaga;