import React from 'react';
import { IonButton } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';

const GotHired = ({ project_id, provider_project_id, project_status, is_commission_paid, amount_payable_to_easybids_after_discount, bid_amount }) => {
    const history = useHistory();
    const redirectData = {
        pathname: `/commission-payment/${project_id}/${provider_project_id}`,
    };
    return (
        <>{bid_amount !== "$0.00" &&
            <div className="ebw-project__hired-wrap mb-4">
                {
                    (project_status == 3 || (project_status == 4 && is_commission_paid == false && amount_payable_to_easybids_after_discount != 0)) &&
                    <div className="ebw-project__hired-top d-flex align-items-center mt-2">
                        <div className="ebw-project__hired-icon">
                            <img src="/images/projects/congradulations.svg" alt="" />
                        </div>
                        <div className="ebw-project__hired-content">
                            <div className="ebw-project__hired-title">Congratulations!</div>
                            <div className="ebw-project__hired-subtitle">You have been hired by the customer.</div>
                        </div>
                    </div>
                }

                {amount_payable_to_easybids_after_discount != 0 &&
                    <div className="ebw-project__hired-bottom px-0">
                        <div className="ebw-project__hired-para mb-2">Please pay the service fees to Easy Bids</div>
                        <Link to={redirectData} className='w-100 '>
                            <button class="ebw-button ebw-button__primary btn btn-secondary w-100" >Pay Now!</button>
                        </Link>
                    </div>
                }
            </div>
        }</>
    );
}

export default GotHired;