import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import history from '../../common/history';
import axios from 'axios';

import { toastr } from 'react-redux-toastr';

const apiUrl = API.baseURL + `/web_login_with_email`;
async function postApi(data) {
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* login(action) {
    try {
        const auth = yield call(postApi, action.data);
        if (auth.data.code === 200 || auth.data.code === 201) {
            // toastr.success('Success', 'OTP sent to your email and mobile successfully');
        } else {
            // toastr.error('Sorry', 'We are unable to send OTP 1');
        }
        history.push('/');
        yield put({ type: 'GET_OTP_LOGIN_SUCCESS', data: auth.data.data });
    } catch (e) {
        // toastr.error('Sorry', 'We are unable to send OTP');
        yield put({ type: 'GET_OTP_LOGIN_FAILED', message: e.response.data.data.errors[0] });
    }
}

function* loginOtpFirstStepSaga() {
    yield takeEvery('GET_OTP_LOGIN_REQUESTED', login);
}

export default loginOtpFirstStepSaga;