import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { setAuth } from '../../utils';
import API from "../../API";
// import ReactDOM from 'react-dom';
import { storeSocialRegIncomplete } from "../../redux/actions/socialIncompleteReg";
import { logout } from "../../redux/actions/logout";
import CONSTANTS from "../../common/constants";

const FacebookSignup = () => {
    const dispatch = useDispatch();
    const [floginError, setFloginError] = useState("");
    const [socialData, setSocialData] = useState({});
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const toggle = (e) => {
        setModal(!modal);
    }
    const setLocalSession = (data) => {
        setAuth(data.data, data.headers);
        window.location.href = '/';
    }
    const responseFacebook = (response) => {
        dispatch(logout());
        if (response && response != undefined && response.name) {
            const data = {
                social_id: response.id,
                name: response.name,
                email: response.email,
                social_type: "facebook",
                user_type: 2,
                socialLogin: true,
            };
            const EBresponse = axios.post(API.baseURL + `/social_login/sign_in `, data, API.options);
            EBresponse.then(res => {
                if ("is_user_type" in res.data && res.data.is_user_type === false) {
                    setFloginError("This email is registered as a Customer. Try logging into Customer App")
                    // toastr.error('Failed', "This email is registered as a Customer. Try logging into Customer App")
                } else if (res.data.user_exist === false) {
                    setSocialData(data);
                    toggle();
                } else if (res.data.signup_status != "completed") {
                    dispatch(storeSocialRegIncomplete(res));
                } else {
                    setLocalSession(res);
                }
            })
        }
    }
    const continueToSignup = (event) => {
        event.preventDefault();
        dispatch(logout());
        history.push('/register', { data: socialData });
        setModal(false);
    }
    return (
        <>
            <FacebookLogin
                appId={CONSTANTS.FB_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="ebw-entry__other-option ebw-entry__other-option--facebook my-facebook-button-class"
                textButton=""
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 46">
                    <path id="icons8-facebook-f" d="M32,11h5a1,1,0,0,0,1-1V3.263a1,1,0,0,0-.925-1C35.484,2.153,32.376,2,30.141,2,24,2,20,5.68,20,12.368V19H13a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7V47a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V28h7.222a1,1,0,0,0,.994-.89l.778-7A1,1,0,0,0,37,19H29V14A3,3,0,0,1,32,11Z" transform="translate(-12 -2)" fill="#264eef" />
                </svg>}
            />
            {floginError &&
                <div className="ebw-error ebw-error-social">{floginError}</div>
            }
            <Modal isOpen={modal} toggle={toggle} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--success">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">You have not created an account yet. Please sign up to begin.</h3>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setModal(false)} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--success" color="success" type="submit" onClick={(e) => continueToSignup(e)} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default FacebookSignup;