import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
// import {toastr} from 'react-redux-toastr'
import axios from 'axios';


async function getApi() {
    const apiUrl = API.baseURL + `/customer_provider_reviewes`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* customerReviews() {
    try {
        const response = yield call(getApi);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        yield put({ type: 'GET_CUSTOMER_REVIEWS_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_CUSTOMER_REVIEWS_FAILED', message: e.message });
    }
}

function* customerReviewSaga() {
    yield takeEvery('GET_CUSTOMER_REVIEWS_REQUESTED', customerReviews);
}

export default customerReviewSaga;