import React, { useState, useEffect } from "react";
import { Col, Row, Form } from 'reactstrap';
import { InputText } from "../inputs/text-input";
import Buttons from "../buttons/button-primary";
import InputEmail from "../inputs/email-input";
// import InputPassword from "../inputs/password-input";
import validate from "./registerPersonalSocialValidation";
import Textarea from "../inputs/textarea";
import InputNumber from "../inputs/number-input";
import { SelectCompany, SelectCity } from "../inputs/select";

//- Personal Form ==> Register Page 
const RgisterPersonalSocial = (props) => {
    const [state, setState] = useState({
        name: props.socialData.name,
        email: props.socialData.email,
        phone: "",
        nameError: "",
        emailError: "",
        phoneError: "",
        valid: false
    });
    const handleInputChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value,
            nameError: "",
            emailError: "",
            phoneError: "",
        }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validate(state);
        let data = JSON.stringify({
            name: state.name,
            email: state.email,
            mobile_number: state.phone,
            social_id: props.socialData.social_id,
            social_type: props.socialData.social_type,
            user_type: "2"
        });
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        errors.valid && props.handleRegisterPersonalSocial(data)
    }

    return (
        <Form className="ebw-entry__register-form text-center" onSubmit={handleSubmit}>
            <Row form>
                <Col sm="12">
                    <InputText
                        label="Full Name"
                        name="name"
                        id="register_name"
                        value={state.name}
                        error={state.nameError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12">
                    <InputEmail
                        label="Email"
                        name="email"
                        id="register_email"
                        value={state.email}
                        error={state.emailError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12">
                    <InputNumber
                        label="Phone"
                        name="phone"
                        id="phone"
                        value={state.phone}
                        error={state.phoneError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12" md="4" xl="3" className="mx-auto text-center mt-4">
                    <Buttons
                        text="Next"
                    />
                </Col>
            </Row>
        </Form>
    );
}
export default RgisterPersonalSocial;