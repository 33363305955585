import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form } from 'reactstrap';
import Cookies from 'js-cookie';
import { InputText } from "../inputs/text-input";
import Buttons from "../buttons/button-primary";
import InputEmail from "../inputs/email-input";
import InputPassword from "../inputs/password-input";
import validate from "./registrer-personal-validation";
import Textarea from "../inputs/textarea";
import InputNumber from "../inputs/number-input";
import { SelectCompany, SelectCity } from "../inputs/select";
import { registerPersonal } from "../../redux/actions/registerpersonal";


//- Personal Form ==> Register Page 
const RgisterPersonal = (props) => {
    const dispatch = useDispatch();
    const registerPersonalLoading = useSelector(state => state.registerPersonal.loading);
    const registerPersonalError = useSelector(state => state.registerPersonal.error);
    const [state, setState] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        nameError: "",
        emailError: "",
        phoneError: "",
        passwordError: "",
        confirmPasswordError: "",
        valid: false
    });
    const handleInputChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value,
            nameError: "",
            emailError: "",
            phoneError: "",
            passwordError: "",
            confirmPasswordError: "",
        }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        errors.valid && props.handleRegisterPersonal(state)
        errors.valid && Cookies.set("mobileNumber", state.phone)
    }

    return (
        <Form className="ebw-entry__register-form text-center" onSubmit={handleSubmit}>
            <Row form>
                <Col sm="12">
                    <InputText
                        label="Full Name"
                        name="name"
                        id="register_name"
                        value={state.name}
                        error={state.nameError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12">
                    <InputEmail
                        label="Email"
                        name="email"
                        id="register_email"
                        value={state.email}
                        error={state.emailError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12">
                    <InputNumber
                        label="Phone"
                        name="phone"
                        id="phone"
                        value={state.phone}
                        error={state.phoneError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12">
                    <InputPassword
                        label="Password"
                        name="password"
                        id="register_password"
                        value={state.password}
                        error={state.passwordError}
                        changeEvent={handleInputChange}
                    />
                </Col>
                <Col sm="12">
                    <InputPassword
                        label="Confirm Password"
                        name="confirmPassword"
                        id="register_password"
                        value={state.confirmPassword}
                        error={state.confirmPasswordError}
                        changeEvent={handleInputChange}
                    />
                </Col>

                <Col sm="12" className="mx-auto text-center">
                    {registerPersonalError ? <div className="ebw-error">{registerPersonalError}</div> : ""}
                </Col>

                <Col sm="12" md="4" xl="3" className="mx-auto text-center mt-4">
                    <Buttons
                        text="Next"
                        disabled={registerPersonalLoading}
                    />
                </Col>
            </Row>
        </Form>
    );
}
export default RgisterPersonal;