
import React from "react"
import ContentLoader from "react-content-loader"
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Row, Col
} from 'reactstrap';

const ProfileLoader = (props) => (
    <Row className="align-items-start">
        <Col sm="4">
            {/* <ContentLoader
                speed={2}
                width={1200}
                height={500}
                viewBox="0 0 1200 500"
                backgroundColor="#f3f3f3"
                foregroundColor="#ababab"
                {...props}
            >
                <rect x="8" y="42" rx="10" ry="10" width="132" height="123" />
                <rect x="9" y="174" rx="0" ry="0" width="129" height="14" />
                <rect x="188" y="63" rx="0" ry="0" width="129" height="8" />
                <rect x="188" y="77" rx="0" ry="0" width="128" height="7" />
                <rect x="189" y="91" rx="0" ry="0" width="128" height="7" />
                <rect x="178" y="40" rx="0" ry="0" width="362" height="4" />
                <rect x="188" y="105" rx="0" ry="0" width="129" height="8" />
                <rect x="188" y="119" rx="0" ry="0" width="128" height="7" />
                <rect x="189" y="133" rx="0" ry="0" width="128" height="7" />
                <rect x="358" y="63" rx="0" ry="0" width="172" height="8" />
                <rect x="358" y="77" rx="0" ry="0" width="170" height="7" />
                <rect x="359" y="91" rx="0" ry="0" width="170" height="7" />
                <rect x="358" y="105" rx="0" ry="0" width="172" height="8" />
                <rect x="358" y="119" rx="0" ry="0" width="170" height="7" />
                <rect x="359" y="133" rx="0" ry="0" width="170" height="7" />
                <rect x="189" y="161" rx="0" ry="0" width="129" height="8" />
                <rect x="189" y="175" rx="0" ry="0" width="128" height="7" />
                <rect x="190" y="189" rx="0" ry="0" width="128" height="7" />
                <rect x="189" y="203" rx="0" ry="0" width="129" height="8" />
                <rect x="189" y="217" rx="0" ry="0" width="128" height="7" />
                <rect x="190" y="231" rx="0" ry="0" width="128" height="7" />
                <rect x="358" y="158" rx="0" ry="0" width="172" height="8" />
                <rect x="358" y="172" rx="0" ry="0" width="170" height="7" />
                <rect x="359" y="186" rx="0" ry="0" width="170" height="7" />
                <rect x="358" y="200" rx="0" ry="0" width="172" height="8" />
                <rect x="358" y="214" rx="0" ry="0" width="170" height="7" />
                <rect x="359" y="228" rx="0" ry="0" width="170" height="7" />
                <rect x="175" y="252" rx="0" ry="0" width="362" height="4" />
                <rect x="9" y="212" rx="0" ry="0" width="129" height="8" />
                <rect x="9" y="226" rx="0" ry="0" width="128" height="7" />
                <rect x="10" y="240" rx="0" ry="0" width="128" height="7" />
                <rect x="9" y="254" rx="0" ry="0" width="129" height="8" />
                <rect x="466" y="14" rx="0" ry="0" width="63" height="22" />
                <rect x="8" y="281" rx="0" ry="0" width="158" height="24" />
                <rect x="369" y="279" rx="0" ry="0" width="158" height="24" />
                <rect x="189" y="281" rx="0" ry="0" width="158" height="24" />
            </ContentLoader> */}
            <ContentLoader 
                speed={5}
                width="100%"
                height="100%"
                viewBox="0 0 400 700"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="1" y="80" rx="15" ry="15" width="400" height="400" /> 
                <rect x="0" y="507" rx="0" ry="0" width="400" height="31" /> 
                <rect x="0" y="560" rx="0" ry="0" width="329" height="31" /> 
                <rect x="0" y="0" rx="10" ry="10" width="276" height="45" />
            </ContentLoader>
            
        </Col>
        <Col sm="4">
        <ContentLoader 
            speed={5}
            width="100%"
            height="100%"
            viewBox="0 0 600 900"
            backgroundColor="#d5cdcd"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="120" rx="10" ry="10" width="600" height="130" /> 
            <rect x="0" y="270" rx="10" ry="10" width="361" height="35" /> 
            <circle cx="402" cy="290" r="20" /> 
            <rect x="5" y="335" rx="10" ry="10" width="598" height="5" />

            <rect x="0" y="370" rx="10" ry="10" width="600" height="130" /> 
            <rect x="0" y="520" rx="10" ry="10" width="361" height="35" /> 
            <circle cx="402" cy="540" r="20" /> 
            <rect x="5" y="585" rx="10" ry="10" width="598" height="5" />

            <rect x="0" y="620" rx="10" ry="10" width="600" height="130" /> 
            <rect x="0" y="770" rx="10" ry="10" width="361" height="35" /> 
            <circle cx="402" cy="790" r="20" /> 
            <rect x="5" y="585" rx="10" ry="10" width="598" height="5" />
        </ContentLoader>
            
        </Col>
        <Col sm="4">
            <ContentLoader 
                speed={5}
                width="100%"
                height="100%"
                viewBox="0 0 600 900"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="120" rx="10" ry="10" width="600" height="130" /> 
                <rect x="0" y="270" rx="10" ry="10" width="361" height="35" /> 
                <circle cx="402" cy="290" r="20" /> 
                <rect x="5" y="335" rx="10" ry="10" width="598" height="5" />

                <rect x="0" y="370" rx="10" ry="10" width="600" height="130" /> 
                <rect x="0" y="520" rx="10" ry="10" width="361" height="35" /> 
                <circle cx="402" cy="540" r="20" /> 
                <rect x="5" y="585" rx="10" ry="10" width="598" height="5" />

                <rect x="0" y="620" rx="10" ry="10" width="600" height="130" /> 
                <rect x="0" y="770" rx="10" ry="10" width="361" height="35" /> 
                <circle cx="402" cy="790" r="20" /> 
                <rect x="5" y="585" rx="10" ry="10" width="598" height="5" />
            </ContentLoader>
            
        </Col>
    </Row>
)

export default ProfileLoader
