import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AppleLogin from 'react-apple-login';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { setAuth } from '../../utils';
import { storeSocialRegIncomplete } from "../../redux/actions/socialIncompleteReg";
import { logout } from "../../redux/actions/logout";
import API from "../../API";
import CONSTANTS from "../../common/constants";

const AppleSignup = (props) => {
    const dispatch = useDispatch();
    const [appleLoginError, setAppleLoginError] = useState("");
    const [socialData, setSocialData] = useState({});
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const toggle = (e) => {
        setModal(!modal);
    }
    const setLocalSession = (data) => {
        setAuth(data.data, data.headers);
        // window.location.href = '/';
    }
    const getAppleData = (res) => {
        if (res.authorization) {
            const data = {
                name: "",
                email: "",
                social_id: res.authorization.id_token,
                authorizationCode: res.authorization.code,
                social_type: "apple",
                user_type: 2,
                socialLogin: true,
            }
            const EBresponse = axios.post(API.baseURL + `/social_login/sign_in `, data, API.options);
            EBresponse.then(res => {
                if ("is_user_type" in res.data && res.data.is_user_type === false) {
                    setAppleLoginError("This email is registered as a Customer. Try logging into Customer App")
                    // toastr.error('Failed', "This email is registered as a Customer. Try logging into Customer App")
                } else if (res.data.user_exist === false) {
                    setSocialData(data);
                    toggle();
                } else if (res.data.signup_status != "completed") {
                    dispatch(storeSocialRegIncomplete(res));
                } else {
                    setLocalSession(res);
                    window.location.href = '/';
                }
            })
        }
    }
    const continueToSignup = () => {
        // event.preventDefault();
        dispatch(logout());
        history.push('/register', { data: socialData });
        setModal(false)
    }
    return (
        <>
            <AppleLogin
                clientId="com.easybids.providerweb"
                redirectURI={`${CONSTANTS.APPLE_REDIRECT_URI}/apple/user/return`}
                usePopup={true}
                render={renderProps => (
                    <button onClick={renderProps.onClick} type="button" className="ebw-entry__other-option ebw-entry__other-option--apple my-apple-button-class metro">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="100px" height="24px"><path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z" /></svg>
                    </button>
                )}
                responseType={"code"}
                responseMode={"query"}
                callback={getAppleData}
            />
            {appleLoginError &&
                < div className="ebw-error ebw-error-social">{appleLoginError}</div>
            }
            <Modal isOpen={modal} toggle={toggle} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--success">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">You have not created an account yet. Please sign up to begin.</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setModal(false)} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--success" color="success" type="submit" onClick={(e) => continueToSignup(e)} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default AppleSignup;
