import React from 'react'
import { Link } from "react-router-dom";
import { Card, CardTitle, CardText } from 'reactstrap';
import { setAvailableProjects } from "../../redux/actions/availableProjects";
import { useDispatch, useSelector } from 'react-redux';
import history from '../../common/history';


const CardPrimary = ({ projectName, projectCount, projectImage, projectDate, projectBgImage }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state)
    const available = useSelector(state => state.allProjectsData.available);
    return (
        <Link to={`/projects/${projectName}/1`} className="ebw-card__wrap w-100">
            <Card body className="ebw-card ebw-card__primary">
                <div style={{ backgroundImage: `url(${projectBgImage})` }} className="ebw-card__primary-image"></div>
                <div className="d-flex flex-wrap align-items-center justify-content-end mb-7">
                    {/* <div className="ebw-card__primary-icon d-flex align-items-center justify-content-center">
                    <img src={projectImage} alt={projectName} />
                </div> */}
                    <div className="ebw-card__count-wrap">
                        <div className="ebw-card__count">{projectCount}</div>
                        <div className="ebw-card__count-text">Projects</div>
                    </div>
                </div>
                <div className="ebw-card__content ebw-card__primary-content">
                    <CardTitle className="ebw-card__primary-title mb-0 pb-2">{projectName}</CardTitle>
                    <div className="d-flex justify-content-between align-items-center pt-2">
                        <CardText className="mb-0 ebw-card__text--white ebw-text--M">Recently uploaded on</CardText>
                        <CardText className="ebw-card__date ebw-card__text--white ebw-text--M ml-4">{projectDate}</CardText>
                    </div>
                </div>
            </Card>
        </Link>
    )
}

export default CardPrimary