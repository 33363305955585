import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../API';
import { toastr } from 'react-redux-toastr';

async function getApi(data) {
    const apiUrl = API.baseURL + `/referral_list`;
    const requestOptions = {
        method: 'GET',
        headers: API.options.headers,
    };
    try {
        const response = await fetch(apiUrl, requestOptions);
        return await response.json();
    } catch (error) {
        throw error;
    }
}

function* getSrefarralList(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.code == 200 || response.code == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'GET_REFARRAL_LIST_SUCCESS', refarralList: response.data });
    } catch (e) {
        yield put({ type: 'GET_REFARRAL_LIST_FAILED', message: e.message });
    }
}

function* refarralListSaga() {
    yield takeEvery('GET_REFARRAL_LIST_REQUESTED', getSrefarralList);
}

export default refarralListSaga;