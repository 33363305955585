import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';

async function postApi(data) {
    const apiUrl = API.baseURL + `/providers/update_mobile_number_without_login`;
    try {
        const response = axios.put(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* editMobile(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Success', 'Mobile number updated Enter the new OTP');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to send message', response.errors[0]);
        }
        yield put({ type: 'PUT_REGISTRATION_EDIT_MOBILE_NUMBER_SUCCESS', data: response.data });
    } catch (e) {
        // toastr.error('Sorry', 'unable to update the mobile number');
        yield put({ type: 'PUT_REGISTRATION_EDIT_MOBILE_NUMBER_FAILED', message: e.message });
    }
}

function* regEditMobileSaga() {
    yield takeEvery('PUT_REGISTRATION_EDIT_MOBILE_NUMBER_REQUESTED', editMobile);
}

export default regEditMobileSaga;