const validateMobile = (inputs) => {

    let errors = {
        valid: true,
    };
    //Phone errors
    if (inputs.changedMobileNumber.length != 10) {
        errors.changedMobileNumberError = 'Enter valid phone number'
        errors.valid = false;
    }
    return errors;
}
export default validateMobile;