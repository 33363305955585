import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

async function getApi(data) {
    const apiUrl = API.baseURL + API.endPoints.addBookMark + data.id + "/bookmark";
    const postData = {}
    try {
        const response = axios.post(apiUrl, postData, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* addBookmark(action) {
    try {
        const bookmark = yield call(getApi, action.payload);
        yield put({ type: 'ADD_BOOKMARK_SUCCESS', popData: action.payload });
    } catch (e) {
        yield put({ type: 'ADD_BOOKMARK_FAILED', message: e.message });
    }
}

function* addBookmarkSaga() {
    yield takeEvery('ADD_BOOKMARK_REQUESTED', addBookmark);
}

export default addBookmarkSaga;