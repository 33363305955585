import { call, put, takeEvery } from 'redux-saga/effects'

function* storeData(action) {
    if (action.data) {
        yield put({ type: 'STORE_SET_AUTH_SUCCESS', auth: action.data });
    } else {
        yield put({ type: 'STORE_SET_AUTH_FAILED', message: "no data" });
    }
}

function* setAuthSaga() {
    yield takeEvery('STORE_SET_AUTH_REQUESTED', storeData);
}

export default setAuthSaga;