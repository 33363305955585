import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { setAuth } from '../../utils';
import { storeSocialRegIncomplete } from "../../redux/actions/socialIncompleteReg";
import { logout } from "../../redux/actions/logout";
import API from "../../API";
import CONSTANTS from "../../common/constants";

const GoogleSignup = (props) => {
    const dispatch = useDispatch();
    const [gloginError, setGloginError] = useState("");
    const [socialData, setSocialData] = useState({});
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const toggle = (e) => {
        setModal(!modal);
    }
    const setLocalSession = (data) => {
        setAuth(data.data, data.headers);
        // window.location.href = '/';
    }
    const responseGoogle = (response) => {
        const data = {
            name: response.profileObj.name,
            email: response.profileObj.email,
            image: response.profileObj.imageUrl,
            // mobile: response.profileObj.mobile,
            social_id: response.googleId,
            social_type: "google",
            user_type: 2,
            socialLogin: true,
        }

        const EBresponse = axios.post(API.baseURL + `/social_login/sign_in `, data, API.options);
        EBresponse.then(res => {
            if ("is_user_type" in res.data && res.data.is_user_type === false) {
                setGloginError("This email is registered as a Customer. Try logging into Customer App")
                // toastr.error('Failed', "This email is registered as a Customer. Try logging into Customer App")
            } else if (res.data.user_exist === false) {
                setSocialData(data);
                toggle();
            } else if (res.data.signup_status != "completed") {
                dispatch(storeSocialRegIncomplete(res));
            } else {
                setLocalSession(res);
                window.location.href = '/';
            }
        })
    }
    const continueToSignup = () => {
        dispatch(logout());
        history.push('/register', { data: socialData });
        setModal(false);
    }
    return (
        <>
            <GoogleLogin
                clientId={CONSTANTS.GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                    <button onClick={renderProps.onClick} type="button" className="ebw-entry__other-option ebw-entry__other-option--google my-google-button-class metro">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="22" viewBox="0 0 50.97 52">
                            <path id="icons8-google" d="M32.521,6a26,26,0,1,0,0,52C54.209,58,59.1,37.866,57.064,27.668H32.516v8.666H49.279a17.339,17.339,0,1,1-5.4-17.4l6.154-6.154A25.913,25.913,0,0,0,32.521,6Z" transform="translate(-6.516 -6)" fill="#e33030" />
                        </svg>
                    </button>
                )}
                buttonText="Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />
            {gloginError &&
                <div className="ebw-error ebw-error-social">{gloginError}</div>
            }
            <Modal isOpen={modal} toggle={toggle} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--success">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">You have not created an account yet. Please sign up to begin.</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setModal(false)} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--success" color="success" type="submit" onClick={(e) => continueToSignup(e)} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default GoogleSignup;
