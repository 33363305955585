export const GET_ACTIVITIES_ALL_REQUESTED = 'GET_ACTIVITIES_ALL_REQUESTED';
export const GET_ACTIVITIES_ALL_SUCCESS = 'GET_ACTIVITIES_ALL_SUCCESS';
export const GET_ACTIVITIES_ALL_FAILED = 'GET_ACTIVITIES_ALL_FAILED';

export const GET_ACTIVITIES_INBIDDING_REQUESTED = 'GET_ACTIVITIES_INBIDDING_REQUESTED';
export const GET_ACTIVITIES_INBIDDING_SUCCESS = 'GET_ACTIVITIES_INBIDDING_SUCCESS';
export const GET_ACTIVITIES_INBIDDING_FAILED = 'GET_ACTIVITIES_INBIDDING_FAILED';

export const GET_ACTIVITIES_INCONVERSATION_REQUESTED = 'GET_ACTIVITIES_INCONVERSATION_REQUESTED';
export const GET_ACTIVITIES_INCONVERSATION_SUCCESS = 'GET_ACTIVITIES_INCONVERSATION_SUCCESS';
export const GET_ACTIVITIES_INCONVERSATION_FAILED = 'GET_ACTIVITIES_INCONVERSATION_FAILED';

export const GET_ACTIVITIES_BOOKMARK_REQUESTED = 'GET_ACTIVITIES_BOOKMARK_REQUESTED';
export const GET_ACTIVITIES_BOOKMARK_SUCCESS = 'GET_ACTIVITIES_BOOKMARK_SUCCESS';
export const GET_ACTIVITIES_BOOKMARK_FAILED = 'GET_ACTIVITIES_BOOKMARK_FAILED';