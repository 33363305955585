import React from 'react';
import { Button } from 'reactstrap';

const ButtonYellow = (props) => {
    
    return (
        <Button
            type={props.type}
            className='ebw-button ebw-button__yellow'
            onClick={props.clickEvent}
        >
            {props.text}
        </Button>
    );
}

export default ButtonYellow;