import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import SwiperCore, { Autoplay, Navigation, Scrollbar } from 'swiper/core';
import 'swiper/swiper.scss';
import "swiper/components/scrollbar/scrollbar.min.css"

SwiperCore.use([Navigation, Autoplay, Scrollbar]);

const uploadedImgs = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <div>need to be deleted in the future</div>
    );

}

export default uploadedImgs;