import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from "../../../API";

async function getApi(data) {
    const apiUrl = API.baseURL + API.endPoints.activitiesBookmark;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* fetchBookmarkActivities(action) {
    try {
        const activities = yield call(getApi, action.payload);
        yield put({ type: 'GET_ACTIVITIES_BOOKMARK_SUCCESS', bookmarked: activities.data.data });
    } catch (e) {
        yield put({ type: 'GET_ACTIVITIES_BOOKMARK_FAILED', message: e.message });
    }
}
function* bookmarkActivitiesSaga() {
    yield takeEvery('GET_ACTIVITIES_BOOKMARK_REQUESTED', fetchBookmarkActivities);
}
export default bookmarkActivitiesSaga;