import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Main from '../../layout/main';
import { login } from '../../redux/actions/login';
import NavTabs from "../../components/tabs/tabs";
import { Container, Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { LoginRegsiterLeft, LoginMobileSlider } from "../../components/login/login-left";

class FirstPage extends Component {
    state = {
        status: true,
        email: "",
        password: "",
    }
    componentDidMount() {
    }
    changeHandler = (event) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        })
    }
    handleLogin = async () => {
        const { email, password } = this.state;
        const data = {
            email: email,
            password: password,
            user_type: "2"
        }
        await this.props.login(data);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Easy Bids Pro Login </title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="login">
                    <section className="ebw__section--login h-100">
                        <Container className="ebw__entry ebw-entry__container--login d-md-flex align-items-center">
                            <Row className="ebw-entry__row ebw-entry__row--login">
                                {/* <Col sm="12" md="5" lg="4" className="ebw-entry__col-lft order-2 order-md-1">
                                    <LoginRegsiterLeft
                                        title="Get service providers easier than ever"
                                        text="The go-to tool to find a variety of projects with the liberty to work with your choice of customers!"
                                        imgUrl="./images/login/login@2x.png"
                                    />
                                    <LoginMobileSlider />
                                </Col> */}
                                <Col sm="12" md="7" lg="7" className="ebw-entry__col-lft h-100 mx-auto pt-10 pt-md-0 pb-10 pb-md-0">
                                    <div className="ebw-entry__content-rgt">
                                        <h2 className="ebw-entry__title mb-8">
                                            <div className="ebw-entry__title-top">Sign in</div>
                                            <div className="ebw-entry__title-bottom">to your account to continue</div>
                                        </h2>
                                        <NavTabs />
                                    </div>
                                </Col>
                                <Col sm="12" md="5" lg="5" className="ebw-entry__col-rgt">
                                    <div className="ebw-entry__content-lft">
                                        <LoginMobileSlider />
                                        {/* <LoginRegsiterLeft
                                            title="Get service providers easier than ever"
                                            text="The go-to tool to find a variety of projects with the liberty to work with your choice of customers!"
                                            imgUrl="./images/login/login@2x.png"
                                        /> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Main>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.login
});
export default connect(mapStateToProps, {
    login
})(FirstPage);