import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Badge } from 'reactstrap';
import InputNumber from "../inputs/number-input";
import { useSelector, useDispatch } from 'react-redux';
import SearchSelect from "../inputs/search-select";
import { getServedCities } from '../../redux/actions/servedCItiesList';


const EditCityAndRadius = (props) => {
    const { locations } = props;
    const dispatch = useDispatch();
    const cities = useSelector(state => state.servedCities.cities);
    const [state, setState] = useState({
        company: "",
        companyType: "",
        businessAddress: "",
        servedCities: "",
        mileRadius: "",
        cityKey: "",
        currentChangingCityName: "",
        selectedCities: [...locations],
        yearsOfBusiness: "",

        companyError: "",
        companyTypeError: "",
        businessAddressError: "",
        servedCitiesError: "",
        mileRadiusError: "",
        yearsOfBusinessError: "",
        valid: false
    });


    const handleChangeRadius = (radius) => {
        state.selectedCities.forEach((data, index) => {
            if (state.currentChangingCityName == data.location_name) {
                data.radius = radius.target.value
            }
            props.handleChangeCityRadius(state.selectedCities)

        })
        setState((prevProps) => ({
            ...prevProps,
            mileRadius: radius.target.value
        }));
        props.handleChangeCityRadius(state.selectedCities)
    }
    const addThisToCity = () => {
        setState((prevProps) => ({
            ...prevProps,
            mileRadius: "",
            cityKey: "",
        }));
        props.handleChangeCityRadius(state.selectedCities)
    }
    const handleCityChange = (search) => {
        setState((prevProps) => ({
            ...prevProps,
            cityKey: search
        }));

        dispatch(getServedCities(search));
    }
    const handleSelectServedCity = (id, name, state_short, State) => {
        setState((prevProps) => ({
            ...prevProps,
            cityKey: name,
            mileRadius: 5,
            selectedCities: [...state.selectedCities.filter(x => x.location_name !== name), { location_name: name, city: name, radius: 5, state_short: state_short, state: State }],
            currentChangingCityName: name
        }));
        props.handleChangeCityRadius([...state.selectedCities.filter(x => x.location_name !== name), { location_name: name, city: name, radius: 5, state_short: state_short, state: State }])
    }
    const removeTheCity = (dele) => {
        setState((prevProps) => ({
            ...prevProps,
            selectedCities: prevProps.selectedCities.filter(x => x.id != dele),
        }));
        props.handleChangeCityRadius(state.selectedCities)
    }
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }

    useEffect(() => {
        props.handleChangeCityRadius(state.selectedCities)
    }, [])
    return (

        <Row form>
            <Col sm="12">
                <div className="w-100 d-flex align-items-start flex-wrap">
                    {state.selectedCities.map((data, index) => {
                        return (
                            <Badge key={index} pill variant="light" className="ebw-form__input-select--badge">
                                <span className="pr-2">{capitalize(data.location_name && data.location_name)}{capitalize(data.cityName && data.cityName)}&nbsp;-</span>
                                <span className="pr-2">{data.radius}&nbsp;mi</span>
                                <span className="ml-2" onClick={() => removeTheCity(data.id)} style={{ color: "#FF6347", cursor: "pointer" }}>X</span>
                            </Badge>
                        )
                    })}
                </div>
            </Col>

            <Col xs="5">
                <SearchSelect
                    label="Cities Served"
                    name="servedCities"
                    id="register_cities"
                    value={state.cityKey}
                    error={state.servedCitiesError}
                    changeEvent={handleCityChange}
                    handleSelectCity={handleSelectServedCity}
                    data={cities}

                />
            </Col>

            <Col xs="5">
                <InputNumber
                    label="Miles Radius"
                    name="mileRadius"
                    id="register_miles"
                    value={state.mileRadius}
                    error={state.mileRadiusError}
                    changeEvent={handleChangeRadius}
                />
            </Col>

            <Col xs="2" className="h-100">
                <a role="button" className="ebw-form__input-select--add my-auto d-block" onClick={addThisToCity}>
                    +
                </a>
            </Col>
        </Row>
    );
}

export default EditCityAndRadius;