import React from 'react';
const BidAmountDetailed = (props) => {
    const { bid_type, bid_amount, hours, pending_amount, down_payment } = props;
    const getTotalBidAmount = (a, b) => {
        return Number(a.substring(1)) * Number(b);
    }
    return (<>
        {bid_type == "hourly" ? (
            <>
                <div className="d-flex justify-content-between pt-6">
                    <div className="ebw-myproject__completed-hours">Hours</div>
                    <div className="ebw-myproject__completed-time">{hours}</div>
                </div>
                <div className="d-flex justify-content-between pt-1">
                    <div className="ebw-myproject__completed-hours">Per hour cost</div>
                    <div className="ebw-myproject__completed-time">{bid_amount}</div>
                </div>
                <div className="d-flex justify-content-between pt-2">
                    <div className="ebw-myproject__completed-hours">Total bid amount</div>
                    <div className="ebw-myproject__completed-time">${getTotalBidAmount(bid_amount, hours)}</div>
                </div>
                <div className="d-flex justify-content-between pt-4">
                    <div className="ebw-myproject__completed-initial">Initial Payment</div>
                    <div className="ebw-myproject__completed-hours">$ {down_payment}</div>
                </div>
                <div className="d-flex justify-content-between pt-1">
                    <div className="ebw-myproject__completed-total">Total amount due from customer</div>
                    <div className="ebw-myproject__completed-due">${pending_amount}</div>
                </div>
            </>
        ) : (
            <>
                <div className="d-flex justify-content-between pt-2">
                    <div className="ebw-myproject__completed-hours">Total bid amount</div>
                    <div className="ebw-myproject__completed-time">{bid_amount}</div>
                </div>
                <div className="d-flex justify-content-between pt-4">
                    <div className="ebw-myproject__completed-initial">Initial Payment</div>
                    <div className="ebw-myproject__completed-hours">$ {down_payment}</div>
                </div>
                <div className="d-flex justify-content-between pt-1">
                    <div className="ebw-myproject__completed-total">Total amount due from customer</div>
                    <div className="ebw-myproject__completed-due">${pending_amount}</div>
                </div>
            </>
        )}
    </>
    );
}

export default BidAmountDetailed;