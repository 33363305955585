import * as type from './types';
const initialState = {

    all: {},
    inProcess: {},
    cancelled: {},
    history: {},
    allPagination: {},
    inProcessPagination: {},
    cancelledPagination: {},
    historyPagination: {},

    allLoading: false,
    allError: "",
    inProcessLoading: false,
    inProcessError: "",
    cancelledLoading: false,
    cancelledError: "",
    historyLoading: false,
    historyError: "",
}

export default function myProjects(state = initialState, action) {
    switch (action.type) {
        //|||||||||||||||||||||||||  -------------------- ALL
        case type.GET_MYPROJECTS_ALL_REQUESTED:
            return {
                ...state,
                allLoading: true,
            }
        case type.GET_MYPROJECTS_ALL_SUCCESS:
            return {
                ...state,
                allLoading: false,
                all: action.all.projects,
                allPagination: action.all.pagination
            }
        case type.GET_MYPROJECTS_ALL_FAILED:
            return {
                ...state,
                allLoading: false,
                allError: action.message,
            }
        //|||||||||||||||||||||||||  -------------------- In process
        case type.GET_MYPROJECTS_IN_PROCESS_REQUESTED:
            return {
                ...state,
                inProcessLoading: true,
            }
        case type.GET_MYPROJECTS_IN_PROCESS_SUCCESS:
            return {
                ...state,
                inProcessLoading: false,
                inProcess: action.inProcess.projects,
                inProcessPagination: action.inProcess.pagination
            }
        case type.GET_MYPROJECTS_IN_PROCESS_FAILED:
            return {
                ...state,
                inProcessLoading: false,
                inProcessError: action.message,
            }
        //|||||||||||||||||||||||||  -------------------- Cancelled
        case type.GET_MYPROJECTS_CANCELLED_REQUESTED:
            return {
                ...state,
                cancelledLoading: true,
            }
        case type.GET_MYPROJECTS_CANCELLED_SUCCESS:
            return {
                ...state,
                cancelledLoading: false,
                cancelled: action.cancelled,
                cancelled: action.cancelled.projects,
                cancelledPagination: action.cancelled.pagination
            }
        case type.GET_MYPROJECTS_CANCELLED_FAILED:
            return {
                ...state,
                cancelledLoading: false,
                cancelledError: action.message,
            }
        //|||||||||||||||||||||||||  -------------------- history
        case type.GET_MYPROJECTS_HISTORY_REQUESTED:
            return {
                ...state,
                historyLoading: true,
            }
        case type.GET_MYPROJECTS_HISTORY_SUCCESS:
            return {
                ...state,
                historyLoading: false,
                history: action.history,
                history: action.history.projects,
                historyPagination: action.history.pagination
            }
        case type.GET_MYPROJECTS_HISTORY_FAILED:
            return {
                ...state,
                historyLoading: false,
                historyError: action.message,
            }
        default:
            return state
    }
}