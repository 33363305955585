import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import Main from '../../layout/main'
import ReviewsLoader from "../../components/reviews/reviewsSkeleton"

const Review = (props) => {
    console.log(props, "props in the reviews")

    const { average_rating, total_reviews, yelp_busines, yelp_reviews, reviews, google_business, google_reviews, customer_feedback, yelp_google_app_ratings, yelp_google_app_reviews } = props.customerReviews;
    const printStar = (star) => {
        let stars = [];
        for (let i = 0; i < star; i++) {
            stars.push(<span className="ebw-icon ebw-icon--lg icon-review ebw-review__profile-rating--icon pl-1"></span>)
        }
        return stars;
    }
    const combainReviews = (inapp, external) => {
        const ratingTogather = [...inapp, ...external];
        return ratingTogather.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reviews</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="profile pb-0">
                <section className="ebw-section__pages ebw-review">
                    <Container className="ebw-review__inwrap">
                        <Row>
                            <Col>
                                <div className="ebw-title__primary mb-4 mb-md-6">Reviews</div>
                            </Col>
                        </Row>
                        {reviews && reviews.length == 0 && customer_feedback && customer_feedback.length == 0 &&
                            <Row>
                                <Col sm="12 mt-8" >
                                    <div className="ebw-section__empty position-relative">
                                        <div className="ebw-section__empty-imgs w-100">
                                            <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                            {/* <div className="position-absolute ebw-section__empty-img"><img src="/images/projects/project-empty-img.png" alt="" /></div> */}
                                        </div>
                                        <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                            <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Gain great reviews from customers for completed jobs and increase your business credibility</div>
                                            <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {props.customerReviewsLoading ?
                            <ReviewsLoader />
                            :
                            <> {google_reviews && google_reviews.length > 0 && <Row>
                                <Col className="">
                                    <div className="ebw-review__ratings-wrap d-flex align-items-center">
                                        <div className="ebw-review__ratings">{google_business.rating}</div>
                                        <div className="ebw-review__ratings-platform pl-1"><img className="img-fluid" src="./images/review/google-image.png"></img></div>
                                        <div className="ebw-review__ratings-text pl-4">Based on <span className="ebw-review__ratings-count">{google_business.review_count}</span> Google rating</div>
                                    </div>
                                    <Row className="ebw-reviews mt-6 mb-4">
                                        {google_reviews.map((data, index) => {
                                            return (
                                                <Col md="6" lg="4" key={index} className="ebw-card__fancy">
                                                    <div className="ebw-card__fancy-inwrap">
                                                        <div className="ebw-card__fancy-wrap position-relative">
                                                            <div className="d-flex align-items-center">
                                                                <div className="ebw-card__fancy-profile">
                                                                    <img src={`${data.image_url}`} />
                                                                </div>
                                                                <div className="ebw-card__fancy-details position-relative">
                                                                    <div className="ebw-card__fancy-title">{data.name}</div>
                                                                    <div className="ebw-card__fancy-rating">{data.rating}
                                                                        {printStar(data.rating)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="ebw-card__fancy-content">
                                                                {data.text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>

                                </Col>
                            </Row>}
                                {yelp_reviews && yelp_reviews.length > 0 && <Row>
                                    <Col className="">
                                        <div className="ebw-review__ratings-wrap d-flex align-items-center">
                                            <div className="ebw-review__ratings">{yelp_busines.rating}</div>
                                            <div className="ebw-review__ratings-platform pl-1"><img className="img-fluid" src="./images/review/yelp-logo.png"></img></div>
                                            <div className="ebw-review__ratings-text pl-4">Based on <span className="ebw-review__ratings-count">{total_reviews}</span>Yelp rating</div>
                                        </div>
                                        <Row className="ebw-reviews mb-4">
                                            {yelp_reviews.map((data, index) => {
                                                return (
                                                    <Col md="6" lg="4" key={index} className="ebw-card__fancy">
                                                        <div className="ebw-card__fancy-inwrap">
                                                            <div className="ebw-card__fancy-wrap position-relative">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ebw-card__fancy-profile">
                                                                        <img src={`${data.image_url}`} />
                                                                    </div>
                                                                    <div className="ebw-card__fancy-details position-relative">
                                                                        <div className="ebw-card__fancy-title">{data.name}</div>
                                                                        <div className="ebw-card__fancy-rating">{data.rating}
                                                                            {printStar(data.rating)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="ebw-card__fancy-content">
                                                                    {data.text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>


                                    </Col>
                                </Row>}
                                {reviews && customer_feedback && reviews.length + customer_feedback.length > 0 && <Row>
                                    <Col className="">
                                        <div className="ebw-review__ratings-wrap d-flex align-items-center">
                                            <div className="ebw-review__ratings">{yelp_google_app_ratings}{printStar(1)}</div>
                                            <div className="ebw-review__ratings-text pl-4">Based on <span className="ebw-review__ratings-count">{yelp_google_app_reviews}</span> reviews</div>
                                        </div>
                                        <Row className="ebw-reviews">
                                            {
                                                combainReviews(reviews, customer_feedback).map((data, index) => {
                                                    return (
                                                        <Col md="6" lg="4" key={index} className="ebw-card__fancy">
                                                            <div className="ebw-card__fancy-inwrap">
                                                                <div className="ebw-card__fancy-wrap position-relative">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="ebw-card__fancy-profile">
                                                                            <img src={`${data.id ? data.consumer_avatar_url : data.profile_pic}`} />
                                                                        </div>
                                                                        <div className="ebw-card__fancy-details position-relative">
                                                                            <div className="ebw-card__fancy-title">{data.id ? data.consumer_name : data.name}</div>
                                                                            <div className="ebw-card__fancy-rating">{data.id ? data.ratings : data.rating}
                                                                                {printStar(data.id ? data.ratings : data.rating)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ebw-card__fancy-content">
                                                                        {data.id ? data.description : data.text}
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mt-3">
                                                                        <div className="ebw-card__fancy-text"><b>{data.project_name}</b></div>
                                                                        <div className="ebw-card__fancy-text ebw-card__fancy-text--label ">{data.project_type}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                        </Row>


                                    </Col>
                                </Row>}
                            </>
                        }

                    </Container>
                </section>
            </Main>
        </div>
    );
}

export default Review;
