import React from 'react';
const BidAmount = (props) => {
    const { bid_type, bid_amount, hours } = props;

    return (
        <div className="d-flex justify-content-between pt-6">
            {bid_type == "hourly" ? (
                <>
                    <div className="ebw-myproject__completed-amount">Bid amount<span className="ebw-myproject__completed-cost pl-2">{bid_amount}</span>
                        <span className="ebw-myproject__completed-hr">/hr</span></div>
                    <div className="ebw-myproject__completed-cost">{hours}<span className="ebw-myproject__completed-hr pl-1">hr</span></div></>) :
                (
                    <><div className="ebw-myproject__completed-amount">Bid amount<span className="ebw-myproject__completed-cost pl-2"></span>
                    </div>
                        <div className="ebw-myproject__completed-cost"><span className="ebw-myproject__completed-hr pl-1">{bid_amount}</span></div></>
                )
            }
        </div>
    );
}

export default BidAmount;