import React, { useEffect } from 'react';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { useSelector, useDispatch } from 'react-redux';
import Projectdetail from "./project-detail";
import { getCurrentProject } from "../../redux/reduxes/projectDetails/currentProjectAction";
import { getCommissionDetails } from "../../redux/actions/commissionDetails";
import { projectVideosData } from "../../redux/actions/projectVideos";
import { relatedProjects } from "../../redux/actions/relatedProjects";
import { getProjectFiles } from "../../redux/reduxes/availableProjects/projectFilesAction";


const ProjectDetailPage = (props) => {
    const { project_id, id, from } = props.match.params;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCurrentProject(project_id));
        dispatch(getCommissionDetails());
        dispatch(projectVideosData(project_id));
        dispatch(relatedProjects(project_id))
        dispatch(getProjectFiles(project_id));
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [project_id, id, from])
    const projectDataNotFound = useSelector(state => state.currentProjectData.notFound);
    const projectData = useSelector(state => state.currentProjectData.projectData);
    const projectDataLoading = useSelector(state => state.currentProjectData.loading);
    const projectDataError = useSelector(state => state.currentProjectData.error);
    const commissionDetails = useSelector(state => state.commissionDetails.commissionDetails);
    const projectVideos = useSelector(state => state.projectVideos.data);
    const projectFiles = useSelector(state => state.availableProjectsX.files);
    // const relatedProjects = useSelector(state => state.relatedProjects.data);
    console.log(projectData, "projectData of current project :::::::::");
    return (
        <>
            <Header />
            <Projectdetail
                projectDataNotFound={projectDataNotFound}
                projectData={projectData}
                projectDataLoading={projectDataLoading}
                projectDataError={projectDataError}
                commissionDetails={commissionDetails}
                projectVideos={projectVideos}
                projectFiles={projectFiles}
                from={from}
            />
            <Footer />
        </>
    );
}

export default ProjectDetailPage;