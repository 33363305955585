import React, { useState, useEffect } from "react";
import { Col, Row, Form, Badge } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import Buttons from "../buttons/button-primary";
import { InputText } from "../inputs/text-input";
import validate from "./register-business-validation";
import Textarea from "../inputs/textarea";
import InputNumber from "../inputs/number-input";
import Select from "../inputs/select";
import SearchSelect from "../inputs/search-select";
import { getServedCities } from '../../redux/actions/servedCItiesList';


//- Business Form ==> Register Page 
const RegisterBusiness = (props) => {
    const dispatch = useDispatch();
    const cities = useSelector(state => state.servedCities.cities);
    // const citiesLoading = useSelector(state => state.servedCities);
    // const citiesError = useSelector(state => state.servedCities);

    const [state, setState] = useState({
        company: "",
        companyType: "",
        businessAddress: "",
        servedCities: "",
        mileRadius: "",
        cityKey: "",
        currentChangingCityId: "",
        selectedCities: [],
        yearsOfBusiness: "",

        companyError: "",
        companyTypeError: "",
        businessAddressError: "",
        servedCitiesError: "",
        mileRadiusError: "",
        yearsOfBusinessError: "",
        valid: false
    });
    const handleInputChange = (event) => {
        if ([event.target.name] == "servedCities") {
        }
        setState((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value,
            cityKey: "",
            companyError: "",
            companyTypeError: "",
            companyTypeAddressError: "",
            servedCitiesError: "",
            mileRadiusError: "",
            businessAddressError: "",
            yearsOfBusinessError: "",
        }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = {
            street_address: state.businessAddress,
            zipcode: state.zipCode,
            company_name: state.company,
            company_type: state.companyType,
            provider_locations_attributes: state.selectedCities,
            years_of_business: state.yearsOfBusiness,
        }
        errors.valid && props.handleRegisterBusiness(data);
    }
    const companies = [
        { label: "Company Type", value: "0" },
        { label: "Sole Proprietor", value: "1" },
        { label: "LLC", value: "2" },
        { label: "Corp", value: "3" }
    ]
    const handleCityChange = (search) => {
        setState((prevProps) => ({
            ...prevProps,
            cityKey: search
        }));

        dispatch(getServedCities(search));

    }
    const handleSelectServedCity = (id, name, state_short) => {
        setState((prevProps) => ({
            ...prevProps,
            cityKey: name,
            selectedCities: [...state.selectedCities.filter(x => Number(x.id) !== Number(id)), { cityName: name, id: id, radius: 5, state_short: state_short }],
            currentChangingCityId: id
        }));
    }
    const handleChangeRadius = (radius) => {
        state.selectedCities.forEach((data, index) => {
            if (state.currentChangingCityId == data.id) {
                data.radius = radius.target.value
            }

        })
        setState((prevProps) => ({
            ...prevProps,
            mileRadius: radius.target.value
        }));
    }
    const removeTheCity = (dele) => {
        setState((prevProps) => ({
            ...prevProps,
            selectedCities: prevProps.selectedCities.filter(x => x.id != dele),
        }));
    }
    const addThisToCity = () => {
        setState((prevProps) => ({
            ...prevProps,
            mileRadius: "",
            cityKey: "",

        }));
    }

    return (
        <>
            <Form className="ebw-entry__register-form text-center" onSubmit={handleSubmit}>
                <Row form>
                    <Col sm="12">
                        <InputText
                            label="Company Name"
                            name="company"
                            id="register_company"
                            value={state.company}
                            error={state.companyError}
                            changeEvent={handleInputChange}
                        />
                    </Col>

                    <Col sm="12">
                        <Select
                            name="companyType"
                            id="register_address"
                            value={state.companyType}
                            error={state.companyTypeError}
                            changeEvent={handleInputChange}
                            data={companies}
                        />
                    </Col>

                    <Col sm="12">
                        <Textarea
                            label="Business Address"
                            name="businessAddress"
                            id="register_address"
                            value={state.businessAddress}
                            error={state.businessAddressError}
                            changeEvent={handleInputChange}
                        />
                    </Col>

                    <Col sm="12">
                        <InputNumber
                            label="Zip Code"
                            name="zipCode"
                            id="register_zipcode"
                            value={state.zipCode}
                            error={state.zipCodeError}
                            changeEvent={handleInputChange}
                        />
                    </Col>
                </Row>

                <Row form>
                    <Col xl="6">
                        <SearchSelect
                            label="Cities Served"
                            name="servedCities"
                            id="register_cities"
                            value={state.cityKey}
                            error={state.servedCitiesError}
                            changeEvent={handleCityChange}
                            handleSelectCity={handleSelectServedCity}
                            data={cities}
                        />
                    </Col>
                    <Col xs="10" xl="5">
                        <InputNumber
                            label="Miles Radius"
                            name="mileRadius"
                            id="register_miles"
                            value={state.mileRadius}
                            error={state.mileRadiusError}
                            changeEvent={handleChangeRadius}
                        />
                    </Col>

                    <Col xs="2" xl="1" className="h-100">
                        <a role="button" className="ebw-form__input-select--add my-auto d-block" onClick={addThisToCity}>
                            +
                        </a>
                    </Col>
                    <Col sm="12">
                        <div className="w-100 d-flex align-items-start flex-wrap">
                            {state.selectedCities.map((data, index) => {
                                return (
                                    <Badge key={index} pill variant="light" className="ebw-form__input-select--badge">
                                        <span className="pr-2">{data.cityName}</span>
                                        <span className="pr-2">{data.radius}</span>
                                        <span className="ml-2" onClick={() => removeTheCity(data.id)} style={{ color: "black", cursor: "pointer" }}>X</span>
                                    </Badge>
                                )
                            })}
                        </div>
                    </Col>
                </Row>

                <Row form>
                    <Col sm="12">
                        <InputNumber
                            label="Number of years in business"
                            name="yearsOfBusiness"
                            id="register_yobus"
                            value={state.yearsOfBusiness}
                            error={state.yearsOfBusinessError}
                            changeEvent={handleInputChange}
                        />
                    </Col>
                </Row>

                <Row className="mt-8">
                    <Col xs="12" md="4" lg="3" className="mx-auto">
                        <Buttons text="Next" type="submit" />
                    </Col>
                </Row>
            </Form>
        </>
    );
}
export default RegisterBusiness;