import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Profile from "./profile";
import { getProfileData } from "../../redux/actions/profileData";
import { getProfileEditData } from "../../redux/actions/profileEditData";
import { getServiceTypes } from "../../redux/actions/serviceList";



const ProfilePage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
    const dispatch = useDispatch();
    const auth = useSelector(state => state);

    useEffect(() => {
        dispatch(getProfileData());
        dispatch(getProfileEditData());
        dispatch(getServiceTypes());
    }, []);
    const ProfileData = useSelector(state => state.getProfileEditData.profileEdit);
    const profileDetails = useSelector(state => state.profileData.profile);
    const ProfileDataLoading = useSelector(state => state.getProfileEditData.loading);
    const ProfileDataError = useSelector(state => state.getProfileEditData.error);
    const getLatest = () => {
        dispatch(getProfileData());
        dispatch(getProfileEditData());
        dispatch(getServiceTypes());
    }
    console.log(profileDetails, "ProfileDataProfileDataProfileData")
    return (
        <>
            <Header />
            <Profile {...profileDetails} profileData={ProfileData} ProfileDataLoading={ProfileDataLoading} getLatest={getLatest} />
            <Footer />
        </>
    );
}

export default ProfilePage;