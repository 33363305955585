import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';



async function postApi(data) {
    const apiUrl = API.baseURL + API.endPoints.submitBid + `${data.project_id}/bid/${data.providr_bid_id}`;
    try {
        const response = axios.delete(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* deleteBid(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            yield put({ type: 'DELETE_BID_SUCCESS', registerPersonal: response.data });
            location.reload();
        } else {
        }
        // window.location.href = "/my-projects"
    } catch (e) {
        yield put({ type: 'DELETE_BID_FAILED', message: e.message });
    }
}

function* deleteBidSaga() {
    yield takeEvery('DELETE_BID_REQUESTED', deleteBid);
}

export default deleteBidSaga;