import React, { useState, useEffect } from "react";
import { Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from "react-router-dom";

import ButtonsSecondary from "../buttons/button-secondary";
import { activateChatBox } from "../../redux/actions/chatboxActive";
import Loader from "../../common/loader";

import MessageScrollArea from "../../components/chat/messageScrollArea";
import SendMessage from "../../components/chat/sendMessage"

import { InputTextNoBorder } from "../inputs/text-input";
import { sendMessage } from "../../redux/actions/sendMessage"


const ProjectInConversation = (props) => {
    const dispatch = useDispatch();
    const chatActive = useSelector(state => state.activateChatBox.chatbox);
    const chat = useSelector(state => state.chat.chat);
    const chatLoading = useSelector(state => state.chat.loading);
    const chatError = useSelector(state => state.chat.error);
    const chatSensitive = useSelector(state => state.sendMessage.data);
    const [sensitive, setSensitive] = useState(false);
    const [sensitiveContent, setSensitiveContent] = useState("");

    const { address,
        cust_avathar,
        pro_avathar,
        cust_name,
        pro_name,
        project_name,
        cust_id,
        chats,
        provider_id,
    } = chat;
    const [state, setState] = useState({
        message: "",
        files: [],
        valid: false,
    });

    useEffect(() => {
        if (chatSensitive && chatSensitive.length != 0 && chatSensitive.data && chatSensitive.data.sensitive_info) {
            setSensitive(true);
            setSensitiveContent(chatSensitive.data.message)
        }
    }, [chatSensitive]);

    const [hidePopup, setHidePopup] = useState(false);

    const handleHideChange = () => {
        const activateData = {
            provider_id: null,
            project_id: null,
            active: false
        };
        dispatch(activateChatBox(activateData));
    };
    const chatImage = [];
    const messageInputHandler = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            message: event.target.value,
        }));
    }
    const imageInputHandler = (data) => {
        const fileList = data.target.files;
        let file = []
        for (var i = 0; i < fileList.length; i++) {
            if (fileList[i].type.split('/')[0] == "video") {
                const objectURL = URL.createObjectURL(fileList[i])
                const upItem = {
                    blob: fileList[i],
                    url: objectURL,
                    type: fileList[i].type,
                    where: "local",
                };
                file.push(upItem);
            } else if (fileList[i].type.split('/')[0] == "image") {
                const objectURL = URL.createObjectURL(fileList[i])
                const upItem = {
                    blob: fileList[i],
                    url: objectURL,
                    type: fileList[i].type,
                    where: "local",
                };
                file.push(upItem);
            }
        }
        setState((prevProps) => ({
            ...prevProps,
            files: [...prevProps.files, ...file],
        }));
    }
    const cancelFromUpload = (i) => {
        let newState = []
        state.files.length > 0 && state.files.forEach((item, index) => {
            index !== i && newState.push(item);
        })
        setState((prevProps) => ({
            ...prevProps,
            files: newState,
        }));
    }
    const clearAllFile = () => {
        setState((prevProps) => ({
            ...prevProps,
            files: [],
        }));
    }

    const clearInputField = () => {
        setState((prevProps) => ({
            ...prevProps,
            message: ""
        }));
    }

    const style = !chatActive.active ? { display: 'none', transition: 'all 0.25s linear' } : {};
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessageHandle()
            return true
        }
        return
    }
    const sendMessageHandle = () => {
        if (state.message !== "") {
            var formData = new FormData();
            var data = JSON.stringify({
                project_id: chatActive.project_id,
                user_id: 526,
                provider_id: provider_id,
                message_type: "text",
                message_by: "provider",
                msg: state.message
            })
            formData.append(`message`, data);

            dispatch(sendMessage(formData));
            clearInputField()
        }
        if (state.files.length != 0) {
            for (let index = 0; index < state.files.length; index++) {
                var formData = new FormData();
                if (state.files[index].blob !== undefined) {
                    const type = state.files[index].type.split('/')[0];
                    var data = JSON.stringify({
                        project_id: chatActive.project_id,
                        user_id: 526,
                        provider_id: provider_id,
                        message_type: type,
                        message_by: "provider",
                        msg: ""
                    })
                    formData.append(`message`, data);
                    formData.append(`document`, state.files[index].blob);
                    dispatch(sendMessage(formData));
                }
            }
            clearAllFile();
        }
    }
    const toggle = (e) => {
        setSensitive(!sensitive);
        location.reload();
    }
    const path = window.location.pathname.split("/");
    return (

        <>
            <div className="ebw-project__chat-col position-fixed" id="ebw-project__chatbox" style={style}>
                <div className="ebw-project__chat ">
                    {chatLoading ?
                        <div className="ebw-data__loader">
                            <Loader loading={chatLoading} />
                        </div>

                        :
                        <div className="ebw-project__chat-inner d-flex flex-column justify-content-between">
                            <div className="ebw-project__chat-top">
                                <div className="d-flex align-items-center justify-content-between mb-4 mb-md-6">
                                    <Link to={`/project-detail/${chat.provider_id}/projects`}>
                                        <h2 className="ebw-project__chat-project mb-0">{project_name && project_name}</h2>
                                    </Link>
                                    <div className="ebw-project__chat-hide d-flex align-items-center" onClick={handleHideChange} >
                                        <span>Hide</span>
                                        <span className="ebw-icon ebw-icon--md icon-arrow-right ebw-project__chat-hide--arrow"></span>
                                    </div>
                                </div>

                                <div className="ebw-project__chat-info">
                                    <div className="d-flex align-items-center">
                                        <div className="ebw-project__chat-img">
                                            <img src={cust_avathar} alt="" className="img-fluid d-block" />
                                        </div>
                                        <div className="ebw-project__chat-profile ml-2">
                                            <h6 className="ebw-project__chat-profile--name m-0">{cust_name && cust_name}</h6>
                                            <p className="ebw-project__chat-profile--location m-0">{address && address}</p>
                                        </div>
                                    </div>

                                    <div className="ebw-project__chat-profile--desc">
                                        <span>Note:</span>
                                        <span> All of your responses are publicly visible. You can answer questions related to project details only. Sharing your contact details or contacting service providers directly before hiring them is not appreciated. Help us keep the customer experience safe.</span>
                                    </div>
                                </div>

                                <MessageScrollArea
                                    chats={chats}
                                    pro_avathar={pro_avathar}
                                    cust_avathar={cust_avathar}
                                    cust_id={cust_id}
                                    project_id={chatActive.project_id}
                                    provider_id={provider_id}
                                    files={state.files}
                                />
                            </div>

                            {/* <ChatBottom /> */}
                            <div className="ebw-project__chat-btm position-relative h-100">
                                <div className="ebw-project__documents-upload">
                                    <div className={`${state.files.length != 0 ? "ebw-project__documents" : "d-none"}  d-flex flex-row justify-content-start`}>

                                        {state.files.length != 0 && state.files.map((data, index) => {
                                            return (
                                                <div key={index} className="ebw-project__documents-img">
                                                    <img src={data.type.split('/')[0] == "image" ? data.url : "/images/projects/video-thumb.png"} alt="File" className="img-fluid d-block" />
                                                    <div className="ebw-project__documents-delete">
                                                        <span onClick={() => cancelFromUpload(index)} className="ebw-icon icon-close ebw-project__documents-delete--icon"></span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className="ebw-project__chat-formgroup">
                                        <div className="ebw-project__chat-form ">

                                            <InputGroup className="ebw-form__input-file--group d-flex align-items-center">
                                                <InputGroupAddon addonType="prepend">
                                                    <div className="position-relative">
                                                        <Input
                                                            type="file"
                                                            name="chatImage"
                                                            id="akhil"
                                                            multiple
                                                            onChange={(data) => imageInputHandler(data)}
                                                            className="ebw-form__input-file ebw-form__input-file--conversation position-absolute"
                                                        />
                                                        <div className="ebw-form__input-file--upload">
                                                            <span className="ebw-icon ebw-icon--2xl icon-attachment"></span>
                                                        </div>
                                                        {/* {props.error && <p className="ebw-input__error">{props.error}</p>} */}
                                                    </div>
                                                </InputGroupAddon>

                                                <InputTextNoBorder
                                                    id="chat_input"
                                                    name="message"
                                                    label="Type your message here"
                                                    value={state.message}
                                                    changeHandler={(e) => messageInputHandler(e)}
                                                    onKeyDown={handleKeyDown}
                                                    tabIndex="0"
                                                />

                                                <InputGroupAddon addonType="append" className="ml-1 mr-0.5">
                                                    <SendMessage
                                                        sendMessage={sendMessageHandle}
                                                        additionalClass="text-uppercase"
                                                    />
                                                </InputGroupAddon>
                                            </InputGroup>

                                            {/* {props.error && <p className="ebw-input__error">{props.error}</p>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Modal isOpen={sensitive} toggle={toggle} size="lg" className="ebw-global__modal">
                    <div className="ebw-modal__wrap ebw-modal__wrap--success">
                        <ModalBody className="ebw-global__modal-body text-center px-15">
                            <h3 className="text-4xl mb-6 text-center">Communication Policy</h3>

                            <div className="ebw-modal__para text-lg mb-1 text-justify">Congrats! You are almost hired! As a reminder please do not share any contact information with the customer until then.  All the important contact information will be provided at that time! Thank you!</div>

                            <div className="d-flex justify-content-center align-items-center mt-6">
                                {/* <div className="">
                                    <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={(e) => setSensitive(false)} >No</Button>
                                </div> */}
                                <div className="col-4">
                                    <Button className="ebw-button ebw-button__primary--success" color="success" type="submit" onClick={(e) => toggle()} >Okay</Button>
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </div>

        </>
    );
}

export default ProjectInConversation;