import React from "react"
import ContentLoader from "react-content-loader"

const StatsLoader = (props) => (
    <ContentLoader
        speed={7}
        width={476}
        height={168}
        viewBox="0 0 476 168"
        backgroundColor="#f5f5f5"
        foregroundColor="#c4c4c4"
        {...props}
    >
        <circle cx="103" cy="52" r="31" />
        <rect x="52" y="93" rx="0" ry="0" width="103" height="24" />
        <rect x="23" y="127" rx="0" ry="0" width="165" height="11" />
        <rect x="22" y="145" rx="0" ry="0" width="165" height="11" />
    </ContentLoader>
)

export default StatsLoader;