import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Messages from './messages';
import { getMessages } from "../../redux/reduxes/messages/messagesAction";

const MessageContainer = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMessages());
    }, []);

    const messages = useSelector(state => state.messages.messages);
    const messagesLoading = useSelector(state => state.messages.loading);
    console.log(messages, "messagesmessagesmessages");
    return (
        <>
            <Header />
            <Messages
                messages={messages}
                messagesLoading={messagesLoading} />
            <Footer />
        </>
    );
}

export default MessageContainer;