import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';

async function postApi(data) {
    const apiUrl = API.baseURL + `/update_profile_image`;
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* updateProfileImage(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Success', 'Updated profile picture successfully');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to update', response.errors[0]);
        }
        yield put({ type: 'POST_UPDATE_PROFILE_IMAGE_SUCCESS', data: response.data });
        yield put({ type: 'GET_PROFILE_DATA_REQUESTED', data: response.data });
    } catch (e) {
        yield put({ type: 'POST_UPDATE_PROFILE_IMAGE_FAILED', message: e.message });
    }
}

function* updateProfileImageSaga() {
    yield takeEvery('POST_UPDATE_PROFILE_IMAGE_REQUESTED', updateProfileImage);
}

export default updateProfileImageSaga;