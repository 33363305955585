import * as type from '../actions/types';

const initialState = {
  cities: [],
  loading: false,
  error: null,
}

export default function servedCities(state = initialState, action) {
  switch (action.type) {
    case type.GET_CITIES_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.GET_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: action.cities
      }
    case type.GET_CITIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}