import React, { useEffect } from 'react';
import ForgotPasswordIn from "./forgotPassword";
import Header from "../../layout/header";


const ForgotPassword = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
    return (
        <>
            <Header />
            <ForgotPasswordIn />
        </>
    );
}

export default ForgotPassword;