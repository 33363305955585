import React, { useState } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Main from "../../layout/main";
import axios from 'axios';
import API from '../../API';
// import Payment from "./checkout";
import LeadPaymentCard from "../../components/getContact/leadPaymentCard";
import LeadPaymentLoader from "../../components/getContact/leadPaymentSkeleton";

const GetContact = ({
    project_id, public_project_id, project_thumbnail, title, consumer_name, full_address, customer_avathar, project_type
}) => {
    const [show, setShow] = useState(false);
    const showNotification = () => {
        setShow(true);
        setTimeout(function () {
            setShow(false);
        }, 3000);
    }
    const copyToClipBoard = (data) => {
        showNotification();
        navigator.clipboard.writeText(data);
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />

                <meta name="description" content="Have questions about EasyBids pro ? We strive to constantly listen to the questions we receive and build out helpful resources to quickly address such inquiries." />
                <title>Get Contact</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="faqs pb-0">
                <section className="ebw-section ebw-section--getreview">
                    <div className="ebw-faqs__inner">
                        <Container>
                            <Row>
                                <Col lg="6">
                                    <div className="ebw-card ebw-card__others ebw-card__others--shadow mb-2">
                                        <div className="ebw-prodet__content d-flex align-items-start">
                                            <div>
                                                <img src="/images/icons/icon-information-circle-outline.svg" alt="" />
                                            </div>
                                            <div className='ml-2'>Easy Bids provides the customer's contact information to the first 3 PROs only.'</div>
                                        </div>
                                    </div>

                                    <div className="ebw-card ebw-card__others ebw-card__others--shadow mb-4">
                                        <div className="ebw-prodet__content">
                                            <div className="ebw-prodet__name text-capitalize">{title}</div>
                                            <div className="ebw-prodet__date">{project_type}</div>

                                            <div className="ebw-prodet__id-wrap d-flex align-items-center mt-5 mb-5">
                                                <div className="d-flex align-items-center mr-2">
                                                    <div><img src="/images/icons/icon-information-circle-outline.svg" alt="" /></div>
                                                    <div className="es-project__id-label ml-1">Project ID:</div>
                                                </div>
                                                <div className="ebw-prodet__id d-flex align-items-center w-60">
                                                    <input className="ebw-prodet__id-input" type="text" value={public_project_id} id="projectId" disabled="disabled" readonly />
                                                    <div onClick={() => copyToClipBoard(public_project_id)} className="icon-copy-url es-icon__size-sm ml-1"><img src="/images/icons/icon-copy.svg" /></div>
                                                </div>
                                                <div className={show ? "dblock es-project__id-notification" : "d-none"}>
                                                    Copied
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center ebw-prodet__profile pt-3" style={{ borderTop: '1px solid #f7f6f5;' }}>
                                            <img className="ebw-prodet__img ebw-header__profile-img" src={(customer_avathar && customer_avathar.length > 0) ? customer_avathar : "./assets/images/card/default-card-img-a.png"} alt="" />
                                            <div className="ebw-prodet__details pl-4">
                                                <div className="ebw-prodet__name text-capitalize">{consumer_name}</div>
                                                <div className="d-flex align-items-center">
                                                    <span className="ebw-icon ebw-icon--lg icon-location"></span>
                                                    <div>{full_address} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <LeadPaymentCard
                                        project_id={project_id}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default GetContact;