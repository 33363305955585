import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/projects/${data.project_id}/commission_details?id=${data.provider_project_id}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* fetchpaymentData(action) {
    try {
        const payment = yield call(getApi, action.payload);
        yield put({ type: 'GET_COMMISSION_PAYMENT_DATA_SUCCESS', paymentPageData: payment.data.data });
    } catch (e) {
        yield put({ type: 'GET_COMMISSION_PAYMENT_DATA_FAILED', message: e.message });
    }
}

function* commissionPaymentDataSaga() {
    yield takeEvery('GET_COMMISSION_PAYMENT_DATA_REQUESTED', fetchpaymentData);
}

export default commissionPaymentDataSaga;