import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from "../../../API";

async function getApi(data) {
    const apiUrl = `${API.baseURL}${API.endPoints.myProjectsAll}?page=${data.page}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* fetchMyPRojectsAll(action) {
    try {
        const myProjects = yield call(getApi, action.payload);
        console.log("myProjects myProjects all in the saga", myProjects);
        yield put({ type: 'GET_MYPROJECTS_ALL_SUCCESS', all: myProjects.data.data });
    } catch (e) {
        yield put({ type: 'GET_MYPROJECTS_ALL_FAILED', message: e.message });
    }
}
function* myProjectsAllSaga() {
    yield takeEvery('GET_MYPROJECTS_ALL_REQUESTED', fetchMyPRojectsAll);
}
export default myProjectsAllSaga;