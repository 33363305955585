import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../API';
import { toastr } from 'react-redux-toastr'
import axios from 'axios';


async function getApi(data) {
    console.log("Comes Inside the API", data)
    const apiUrl = API.baseURL + `/v2/providers/projects?page=${data.page}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* projectsData(action) {
    try {
        const response = yield call(getApi, action.data);
        console.log(response.data.data, "response in the available projects Saga")
        yield put({ type: 'GET_AVAILABLE_PROJECTS_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_AVAILABLE_PROJECTS_FAILED', message: e.message });
    }
}
function* availableProjectsSagaX() {
    yield takeEvery('GET_AVAILABLE_PROJECTS_REQUESTED', projectsData);
}

export default availableProjectsSagaX;