const validate = (inputs) => {
    let errors = {
        valid: true,
    };
    //company name errors
    if (!inputs.company) {
        errors.companyError = 'Enter Company Name'
        errors.valid = false;
    }
    //company type errors
    if (inputs.companyType === "") {
        errors.companyTypeError = 'Select Company Type'
        errors.valid = false;
    }
    //company Served cities errors
    // if (inputs.servedCities === "") {
    //     errors.servedCitiesError = 'Enter Served City'
    //     errors.valid = false;
    // }
    //company business address errors
    if (inputs.zipCode === "") {
        errors.zipCodeError = 'Enter Zip Code'
        errors.valid = false;
    }
    if (inputs.businessAddress === "") {
        errors.businessAddressError = 'Enter Business Address'
        errors.valid = false;
    }
    //company Served cities mile radius errors
    if (inputs.selectedCities.length == 0) {
        errors.mileRadiusError = 'Enter Mile Radius'
        errors.valid = false;
    }
    //company Served cities mile radius errors
    if (inputs.yearsOfBusiness === "") {
        errors.yearsOfBusinessError = 'Enter Years of Business'
        errors.valid = false;
    }


    return errors;
}
export default validate;