import React, { useState } from 'react';
import LeadCheckout from "./leadCheckout";

const LeadPaymentCard = (props) => {
    const [serviceAmount, setServiceAmount] = useState(0)
    const { project_id, additionalClass } = props;
    return (
        <div className={`ebw-entry__pro-card ${additionalClass}`}>
            <div className="ebw-paymentn__row d-lg-flex justify-content-between align-items-center mb-5">
                <div className="ebw-paymentn__title">Purchase contact number for only</div>
                <div className="ebw-paymentn__amount ebw-paymentn__amount--M mt-2">$ {serviceAmount}</div>
            </div>
            <div className="ebw-paymentn__row mb-6">
                <p className="ebw-paymentn__para ebw-paymentn__para--M">Easy Bids guarantees accuracy of the phone number with a money back guarantee. Easy Bids limits the number of service professionals who can contact the customer.</p>
            </div>
            <div className="ebw-entry__pro-payment">
                <h3 className="ebw-title__secondary mb-3">Payment information</h3>
                <div className="ebw-entry__pro-form d-flex flex-wrap">
                    <LeadCheckout setServiceAmount={setServiceAmount} project_id={project_id} />
                </div>
            </div>
        </div>
    );
}

export default LeadPaymentCard;