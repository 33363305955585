import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addBookamark } from "../../redux/reduxes/bookMark/addBookamrkAction";
import { deleteBookmark } from "../../redux/reduxes/bookMark/deleteBookmarkAction";
import { clearPopBookamark } from "../../redux/reduxes/bookMark/clearPopBookamrkAction";

const Bookmark = ({ bookmarked, project_id, title, project_type, project_thumbnail, additionalClass }) => {
    const dispatch = useDispatch();
    const [bookmark, setBookmark] = useState(bookmarked);
    const changeIcon = () => setBookmark(!bookmark)
    useEffect(() => {
        setBookmark(bookmarked);
    }, [bookmarked])
    const bookmarkThis = (bookmarked, id) => {
        const data = {
            id: id,
            projectName: title,
            project_type: project_type,
            project_img_url: project_thumbnail,
        }

        changeIcon();
        if (bookmarked) {
            dispatch(deleteBookmark(data));
            setTimeout(() => {
                dispatch(clearPopBookamark())
            }, 5000);
        } else {
            dispatch(addBookamark(data));
            setTimeout(() => {
                dispatch(clearPopBookamark())
            }, 5000);
        }
    }
    return (
        <div className={`ebw-card__bookmark mr-auto ml-auto mt-2 ${additionalClass}`} onClick={() => bookmarkThis(bookmarked, project_id)} >
            {/* {bookmark ? "bookmarked" : "bookmark"} */}
            <img src={`/images/icons/${bookmark ? "bookmark-icon-filled" : "bookmark-icon-outline"}.svg`} alt="" />
        </div>

    );
}

export default Bookmark;