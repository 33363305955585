import React, { useState, useEffect } from 'react';
import { Col, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import OtpInputNumber from "../inputs/otp-input";
import Buttons from "../buttons/button-primary";

import axios from "axios";
import API from "../../API";
import InputNumber from "../inputs/number-input";
const MobileNumberUpdate = (props) => {
    const [isOpen, openModal] = useState(false);
    const toggleModal = () => openModal(!isOpen);
    const [state, setState] = useState({
        mobile: "",
        currentStep: 1,
        otp: "",
        mobileError: "",
        otpError: "",
    });
    const validateOtp = (inputs) => {
        let errors = {
            valid: true,
        };
        if (inputs.otp == "" || inputs.otp.length < 6) {
            errors.otpError = 'Enter valid OTP';
            errors.valid = false;
        }
        return errors
    }
    const validateMobileNumber = (inputs) => {
        let errors = {
            valid: true,
        };
        if (inputs.mobile.length != 10 || isNaN(inputs.mobile)) {
            errors.mobileError = 'Invalid mobile number';
            errors.valid = false;
        }
        return errors
    }
    const handleMobileChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            mobile: event.target.value,
            mobileError: "",

        }));
    }
    const numberUpdate = (event) => {
        event.preventDefault();
        const errors = validateMobileNumber(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = JSON.stringify({
            mobile_number: state.mobile,
        });
        errors.valid && axios.post(API.baseURL + `/mobilenumber_update_request`, data, API.options)
            .then(result => {
                if (result.status === 200)
                    setState((prevProps) => ({
                        ...prevProps,
                        currentStep: 2
                    }));
            });
    }
    const handleChangeOtp = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            otp: data,
            otpError: "",
        }));
    }
    const sendOtp = (event) => {
        event.preventDefault();
        const errors = validateOtp(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        let data = JSON.stringify({
            mobile_number: state.mobile,
            otp: state.otp
        });
        errors.valid && axios.post(API.baseURL + `/mobilenumber_update`, data, API.options)
            .then(result => {
                if (result.status === 200) {
                    window.location.href = "/profile";
                }
            }).catch(error => {
                setState((prevProps) => ({
                    ...prevProps,
                    otpError: error.response.data.data.errors,
                }));
            });
    }

    return (
        <>
            <div className="ebw-profile__mobile-wrap mt-1">
                <span className="ebw-profile__mobile">
                    {props.mobile_number}
                </span>
                <span
                    onClick={() => openModal('mobile')}
                    className="ebw-profile__service-link ml-3"
                >
                    Edit
                </span>
            </div>
            <div className="ebw-profile__modal">

                <Modal isOpen={isOpen}>
                    {state.currentStep === 1 ?
                        <div className="px-5 py-5 ebw-modal__wrap">
                            <ModalHeader toggle={toggleModal} className="ebw-profile__modal-head mb-4" style={{ border: "none" }}>
                                <div className="ebw-modal__title mb-1">Edit mobile number</div>
                                <div className="ebw-modal__para">We will send you an access code via mobile for verification</div>
                            </ModalHeader>

                            <ModalBody className="ebw-modal__body">
                                <form className="ebw-modal__form">
                                    <div className="ebw-modal__input-wrap mb-2">
                                        <InputNumber
                                            changeEvent={(e) => handleMobileChange(e)}
                                            value={state.mobile}
                                            className="ebw-modal__input" type="number"
                                            placeholder="Enter new mobile number"
                                            error={state.mobileError}
                                            required
                                        />
                                    </div>
                                    <div className="text-right d-flex justify-content-end">
                                        <div className="ebw-modal__submit ebw-button__plain ebw-button__plain--grey mr-2" onClick={toggleModal}>cancel</div>
                                        <button
                                            onClick={(e) => numberUpdate(e)}
                                            className="ebw-modal__submit ebw-button__primary"
                                            type="submit">Verify</button>
                                    </div>
                                </form>
                            </ModalBody>
                        </div>

                        :

                        <div className="px-6 pb-6 ebw-modal__wrap">
                            <ModalHeader toggle={toggleModal} className="pb-0 px-0" style={{ border: 'none' }}>
                                <div className="ebw-modal__title mb-1">Verify OTP</div>
                            </ModalHeader>

                            <ModalBody className="p-0">

                                <OtpInputNumber
                                    value={state.otp}
                                    changeEvent={handleChangeOtp}
                                    otpError={state.otpError}
                                />

                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="mt-3 text-right">
                                        <a className="ebw-modal__submit ebw-button__plain ebw-button__plain--grey mr-2" style={{ color: "var(--color-grey-8)" }} role="button" href="#">Resend OTP</a>
                                    </div>

                                    <Col md="6" className="mt-3 px-0 ml-auto">
                                        <Buttons clickEvent={sendOtp} text="Verify OTP" />
                                    </Col>
                                </div>

                            </ModalBody>
                        </div>}
                </Modal>
            </div>
        </>
    );
}

export default MobileNumberUpdate;