import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../API';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';


async function getApi(data) {
    const apiUrl = API.baseURL + `/album`;
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* myWorksNewFolder(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.data.code == 200 || response.data.code == 201) {
            // toastr.success('Success', 'You are successfully completed the new album ');
        } else {
            // response.errors.length != 0 && toastr.error('Failed', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'CREATE_MY_WORKS_FOLDER_SUCCESS', data: response.data });
        yield put({ type: 'GET_MY_WORKS_REQUESTED', data: "" });
    } catch (e) {
        yield put({ type: 'CREATE_MY_WORKS_FOLDER_FAILED', message: e.message });
    }
}

function* myWorksNewFolderSaga() {
    yield takeEvery('CREATE_MY_WORKS_FOLDER_REQUESTED', myWorksNewFolder);
}

export default myWorksNewFolderSaga;