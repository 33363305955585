import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';



async function postApi(data) {
    const apiUrl = API.baseURL + `/messages/soft_delete?message_id=${data}&is_visible=${false}`;
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* deleteMessage(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // setAuth(response.data.data, response.headers);
            yield put({ type: 'POST_DELETE_MESSAGE_SUCCESS', registerPersonal: response.data });
            // toastr.success('Deleted', 'You are successfully deleted the message');
        } else {
            // response.errors.length != 0 && toastr.error('Deletion Failed', response.errors[0]);
        }
        // history.push('/');

    } catch (e) {
        yield put({ type: 'POST_DELETE_MESSAGE_FAILED', message: e.message });
    }
}

function* deleteMessageSaga() {
    yield takeEvery('POST_DELETE_MESSAGE_REQUESTED', deleteMessage);
}

export default deleteMessageSaga;