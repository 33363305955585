import React, {useState} from 'react';
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";

const InputPassword = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisiblity = () => {
        setShowPassword(showPassword ? false : true);
    };

    return (
        <div className="position-relative">
            <FloatingLabel
                id={props.name}
                name={props.name}
                placeholder={props.label}
                className="custom-class ebw-form__input position-relative"
                type={showPassword ? "text" : "password"}
                value={props.value}
                autoComplete="new-password"
                onChange={props.changeEvent}
            />
            {props.error && <p className="ebw-input__error">{props.error}</p>}

            <div className="ebw-form__input-show--password position-absolute">
                <span onClick={togglePasswordVisiblity} className="ebw-form__input-show--text" >{showPassword ? "Hide" : "Show"}</span>
            </div>
        </div>
    );
}
export default InputPassword;