import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import API from '../../API';
import { useDispatch } from 'react-redux';
import { getCurrentProject } from "../../redux/reduxes/projectDetails/currentProjectAction";

const EditBidShow = ({
    bid_amount,
    hours,
    bid_detail,
    bid_breakdown_document,
    bid_type,
    commission_calc,
    bid_id,
    project_id,
    refreshThePage,
    amount_receive_from_customer,
    easybids_commission,
    total_amount
}) => {
    const dispatch = useDispatch();
    const [amountReceive, setAmountReceive] = useState(0)
    const [deleteBidDocument, setDeleteBidDocument] = useState(false);

    const commissionCalc = () => {
        let amount = Number(parseInt(bid_amount.replace('$', '')));
        let bid_estimated_hours = Number(hours);
        var singleCommission
        for (singleCommission of commission_calc) {
            if (amount >= singleCommission.from_bid && amount <= singleCommission.to_bid) {
                const commission = singleCommission.commission
                let bal
                if (bid_type === "hourly") {
                    bal = (amount * bid_estimated_hours) - ((amount * bid_estimated_hours) * (commission / 100))
                } else {
                    bal = amount - (amount * (commission / 100))
                }
                setAmountReceive(bal);
                break;
            }
        }
    }
    useEffect(() => {
        commission_calc && commission_calc.length > 0 && commissionCalc()
    }, [commission_calc])
    const handleDeleteBreakdownDocument = () => {
        console.log("comes inside the delete bid", bid_id, "sdf", project_id)

        axios.delete(API.baseURL + `/v2/providers/projects/${project_id}/bid/delete_doc?id=${bid_id}`, API.options).then(response => response.data)
            .then((res) => {
                setDeleteBidDocument(false);
                dispatch(getCurrentProject(project_id));
            })
    }
    console.log(bid_detail != "null", bid_detail != "", "condition in console")
    return (
        <>
            <div className={`ebw-enteramount__breakdown ebw-project__dropdown toggled mt-2`} >
                <div className="ebw-project__dropdown-inwrap mt-4">
                    <div className="mb-5">
                        <div className="ebw-card__title ebw-card__title-medium mb-2"><b>Bid amount</b></div>
                        {bid_type === "hourly" ?
                            <>
                                <div className="mb-2 d-flex flex-wrap">
                                    <div className="ebw-card__text w-50 pr-2">Your Bid Amount</div>
                                    <div className="ebw-project__dropdown-text w-50 ebw-card__text-medium ">
                                        <span className="ebw-project__dropdown-text--blue font-medium"><b>{bid_amount}</b></span> /hr
                                    </div>
                                </div>
                                <div className="mb-2 d-flex flex-wrap">
                                    <div className="ebw-card__text w-50 pr-2">Approx. No. of Hours</div>
                                    <div className="ebw-project__dropdown-text w-50 ebw-card__text-medium ">
                                        <span className="ebw-project__dropdown-text--blue font-medium"><b>{hours}</b></span>
                                    </div>
                                </div>
                            </> :
                            <div className="mb-2 d-flex flex-wrap">
                                <div className="ebw-card__text w-50 pr-2">Your Bid Amount</div>
                                <div className="ebw-project__dropdown-text w-50 ebw-card__text-medium ">
                                    <span className="ebw-project__dropdown-text--blue font-medium"><b>{bid_amount}</b></span>
                                </div>
                            </div>}

                        <div className="ebw-project__dropdown-text ebw-project__dropdown-text--grey">You will get ${total_amount - easybids_commission} after you complete the job</div>
                    </div>
                    {bid_detail != "null" &&
                        <div className="mb-5">
                            <div className="ebw-card__title ebw-card__title-medium font-medium mb-2"><b>Bid Breakdown</b></div>
                            <div className="ebw-card__text font-normal">
                                {bid_detail != "" ? bid_detail : "---"}
                            </div>
                        </div>
                    }
                    {bid_breakdown_document &&
                        <div className="ebw-project__dropdown-file d-flex justify-content-between align-items-start">
                            <div className="d-flex align-items-center">
                                <div className="icon-attachment ebw-icon__size-md ebw-icon__color--blue"></div>
                                {/* <div onClick={async () => {
                                    await Browser.open({ url: bid_breakdown_document });
                                }} className="ml-2">Proposal document</div> */}
                                <a href={bid_breakdown_document} target="_blank">Proposal document</a>
                            </div>
                            <div className="icon-delete ebw-icon__size-md" onClick={() => setDeleteBidDocument(true)}></div>
                        </div>
                    }
                </div>
            </div>
            <Modal isOpen={deleteBidDocument} toggle={() => setDeleteBidDocument(false)} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">Confirm delete Bid Breakdown document?</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setDeleteBidDocument(false)} >Cancel</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => handleDeleteBreakdownDocument()} >Delete</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            {/* <IonModal css-class="ebw-modal ebw-modal--s ebw-modal__bid-delete" isOpen={deleteBidDocument} style={{ height: '200px' }} onDidDismiss={() => setDeleteBidDocument(false)}>
                <div className="ebw-modal__inwrap">
                    <div className="ebw-modal__header ebw-modal__header flex-wrap">
                        <span className="ebw-modal__title ebw-modal__title--blue ebw-modal__title--pb">Confirm delete Bid Breakdown document?</span>
                    </div>
                    <div className="ebw-modal__body" style={{ height: 'auto' }}>
                        <div className="ebw-modal__btn-options ebw-modal__btn-options--delete-bid d-flex justify-content-center align-items-center">
                            <div className="ebw-modal__btn-wrap">
                                <IonButton class="ebw-modal__btn--nobackground" onClick={() => setDeleteBidDocument(false)}>Cancel</IonButton>
                            </div>
                            <div className="ebw-modal__btn-wrap ml-5">
                                <IonButton class="ebw-modal__btn--success ebw-btn--primary" onClick={() => handleDeleteBreakdownDocument()}>Delete</IonButton>
                            </div>
                        </div>
                    </div>
                </div>
            </IonModal> */}
        </>
    );
}

export default EditBidShow;