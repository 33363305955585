import React from "react";
import { Link } from 'react-router-dom';

const ProfileProcess = ({ provider_id, profile_score, profile_percent, uploadProfilePic }) => {
    return (
        <>
            <div className="ebw-profile__process-title mt-4">Complete your Profile <span class="ebw-profile__process-title--green">{profile_score}%</span></div>
            <div className="ebw-profile__process-subtitle">Increase your chances of getting more projects quicker.</div>
            <div className="ebw-profile__process-steps mt-2">
                <div className={`ebw-profile__process-step mb-4 ebw-profile__process-step${profile_percent && profile_percent.user_avatar && '--active'} d-flex align-items-center justify-content-between`}>
                    <div className="d-flex flex-wrap align-items-center w-75 pr-2">
                        <div className="ebw-profile__process-icon">
                            <div className="icon ebw-icon__color d-flex align-items-center justify-content-center">
                                {profile_percent && profile_percent.user_avatar ?
                                    <img src="/images/icons/profile-step-1.svg" alt="Add a profile picture" /> :
                                    <img src="/images/icons/process-step-1.svg" alt="Add a profile picture" />}
                            </div>
                            <span className="ebw-profile__process-line"></span>
                        </div>
                        <div className="ebw-profile__process-label">Add a profile picture</div>
                    </div>
                    {profile_percent && !profile_percent.user_avatar && <div onClick={() => uploadProfilePic()} className="ebw-profile__process-link ebw-btn ebw-btn--plain ebw-btn--blue w-25 cursor-pointer">Add</div>}
                </div>
                <div className={`ebw-profile__process-step mb-4 ebw-profile__process-step${profile_percent && profile_percent.feedback && '--active'} d-flex align-items-center justify-content-between`}>
                    <div className="d-flex flex-wrap align-items-center w-75 pr-2">
                        <div className="ebw-profile__process-icon">
                            <div className="icon ebw-icon__color d-flex align-items-center justify-content-center">
                                {profile_percent && profile_percent.feedback ?
                                    <img src="/images/icons/profile-step-2.svg" alt="Get more than three five star reviews" /> :
                                    <img src="/images/icons/process-step-2.svg" alt="Get more than three five star reviews" />}
                            </div>
                            <span className="ebw-profile__process-line"></span>
                        </div>
                        <div className="ebw-profile__process-label">Get more than three five star reviews</div>
                    </div>
                    {profile_percent && !profile_percent.feedback && <Link to={`/get-reviews/${provider_id}`} className="ebw-profile__process-link ebw-btn ebw-btn--plain ebw-btn--blue w-25">Get reviewed</Link>}
                </div>
                <div className={`ebw-profile__process-step${profile_percent && profile_percent.my_works && '--active'} d-flex align-items-center justify-content-between`}>
                    <div className="d-flex flex-wrap align-items-center w-75 pr-2">
                        <div className="ebw-profile__process-icon">
                            <div className="icon ebw-icon__color d-flex align-items-center justify-content-center">
                                {profile_percent && profile_percent.my_works ?
                                    <img src="/images/icons/profile-step-3.svg" alt="Showcase your great works" /> :
                                    <img src="/images/icons/process-step-3.svg" alt="Showcase your great works" />}
                            </div>
                        </div>
                        <div className="ebw-profile__process-label">Showcase your great works</div>
                    </div>
                    {profile_percent && !profile_percent.my_works && <Link to="/my-works" className="ebw-profile__process-link ebw-btn ebw-btn--plain ebw-btn--blue w-25">Upload</Link>}
                </div>
            </div>
        </>
    )
}

export default ProfileProcess;