import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from "../../../API";

async function getApi(data) {

    const apiUrl = API.baseURL + API.endPoints.activitiesAll;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* fetchAllActivities(action) {
    console.log("comes inside the saga page")
    try {
        const activities = yield call(getApi, action.payload);
        console.log(activities, "allActicities allActicities allActicities in the saga page");
        yield put({ type: 'GET_ACTIVITIES_ALL_SUCCESS', all: activities.data.data });
    } catch (e) {
        yield put({ type: 'GET_ACTIVITIES_ALL_FAILED', message: e.message });
    }
}
function* allActivitiesSaga() {
    yield takeEvery('GET_ACTIVITIES_ALL_REQUESTED', fetchAllActivities);
}
export default allActivitiesSaga;