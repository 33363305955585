import { useState } from "react";
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: auto;
  border-color: var(--color-blue-2);
`;

const Loader=(props)=> {
  let loading =props.loading;
  let color ="#87c2ff";


  return (
    <div className="sweet-loading d-flex justify-content-center align-items-center m-auto w-100 h-100">
      <BounceLoader color={color} loading={loading} css={override} size={80} />
    </div>
  );
}
export default Loader;