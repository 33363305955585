import React, { Component } from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import consumer from "../../common/cable";
import DeleteMessage from "./deleteMessage";
import { Player } from 'video-react';
import options from "../../values/imageViewerOptions";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, UncontrolledCollapse } from 'reactstrap';

class MessageScrollArea extends Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = React.createRef();
        this.state = {
            chatData: [],
            deleteModal: false,
            deleteMessageId: null,
            images: [],
            videoModal: false,
            currentVideo: "",
            images: [],
            viewImage: false,
            clickedImagePosition: null,
            playingVideo: false,
            showingImage: false,
            currentImage: "",
        }
    }
    removeMessage = (msg_id) => {
        const deletedArray = this.state.chatData.filter(item => item.message_id !== msg_id)
        this.setState({ chatData: deletedArray })
    }
    componentDidMount() {
        this.props.chats && this.setState({
            chatData: this.props.chats
        })
        // const { chatData } = this.state;

        consumer.subscriptions.create({
            channel: 'ChatChannel',
            // channel: `chat_${this.props.project_id}_${this.props.provider_id}`,
            project_id: this.props.project_id,
            provider_id: this.props.provider_id
        }, {
            connected: () => console.log(''),
            disconnected: () => console.log(''),
            received: data => {
                if (data.project_id == this.props.project_id && data.provider_id == this.props.provider_id) {
                    const newChatdata = [...this.state.chatData, data];
                    this.setState({ chatData: newChatdata })
                }
                if (data.deleted_message_id) {
                    this.removeMessage(data.deleted_message_id);
                }
            },
        })
        setTimeout(() => {
            this.scrollToBottom()
        }, 2000);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const provider_chat = nextProps && nextProps.chats ? nextProps.chats : [];
        if (this.state.chatData !== provider_chat && this.state.chatData.length < provider_chat.length) {
            this.setState({ chatData: provider_chat });
        }
        this.scrollToBottom()
    }
    openPlayer = (msgId) => {
        const { chatData } = this.state;
        const clickedChat = chatData && chatData.length != 0 && chatData.filter(x => x.message_id == msgId);
        if (clickedChat[0].message_type == "image") {
            //image viewer
            this.setState({
                showingImage: true,
                currentImage: clickedChat[0].original_file_url
            });
        } else if (clickedChat[0].message_type == "video") {
            //video player
            this.setState({
                playingVideo: true,
                currentVideo: clickedChat[0].original_file_url,
            })

        } else {
            //error message
        }
    }

    //closeModal = () => this.setState({ isOpen: false });
    toggleModal = () => this.setState({ playingVideo: false });
    // Ended Modal Box Open Function 

    refreshChat = (id) => {
        const withoutDeleted = this.state.chatData.filter(chat => chat.message_id != id);
        this.setState({ chatData: withoutDeleted })
    }

    customerTextMesasge = (content, time, index) => {
        return (
            <div key={index} className="ebw-project__chat-conv">
                <div className="d-flex align-items-center justify-content-start">
                    <div className="ebw-project__chat-conv--img">
                        <img src={this.props.cust_avathar} alt="Profile" className="d-block img-fluid" />
                    </div>
                    <div className="ebw-project__chat-conv--text ml-4 position-relative">
                        <div className="ebw-project__chat-conv--msg position-relative">{content} </div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-1 ml-10">
                    <span className="ebw-project__chat-conv--timestamp text-xs">{time}</span>
                    {/* <span onClick={() => deleteMessage(message_id)} className="ebw-icon ebw-icon--md icon-delete ebw-project__chat-conv--delete ml-2"></span> */}
                </div>
            </div>
        )
    };
    customerInfoGraphicMessage = (url, file, time, message_id, message_type, index) => {
        return (
            <>
                <div key={index} className="ebw-project__chat-conv">
                    <div className="d-flex align-items-start justify-content-start">
                        <div className="ebw-project__chat-conv--img">
                            <img src={this.props.cust_avathar} alt="Profile" className="d-block img-fluid" />
                        </div>
                        <div onClick={() => this.openPlayer(message_id)} className="ebw-project__chat-conv--text ml-4 position-relative">
                            <div className="ebw-project__chat-conv--fileimgvid">
                                {message_type == "image" ?
                                    <SimpleReactLightbox>
                                        <SRLWrapper options={options}>
                                            <div className="ebw-project__main-slider--img">
                                                <img src={file} alt="File" className="img-fluid d-block" />
                                            </div>
                                        </SRLWrapper>
                                    </SimpleReactLightbox> :
                                    <img src={url} alt="File" className="img-fluid d-block" />}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-1 ml-10">
                        <span className="ebw-project__chat-conv--timestamp text-xs">{time}</span>
                        {/*<span onClick={() => deleteMessage(message_id)} className="ebw-icon ebw-icon--md icon-delete ebw-project__chat-conv--delete ml-2"></span> */}
                    </div>
                </div>
            </>
        )
    };
    providerTextMessage = (content, time, message_id, index) => {

        return (
            <div key={index} className="ebw-project__chat-conv">
                <div className="d-flex align-items-center justify-content-end">
                    <div className="ebw-project__chat-conv--text mr-4">
                        <div className="ebw-project__chat-conv--usermsg position-relative">{content}</div>
                    </div>
                    <div className="ebw-project__chat-conv--img">
                        <img src={this.props.pro_avathar} alt="Profile" className="d-block img-fluid" />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-1 mr-10">
                    <DeleteMessage refreshChat={this.refreshChat} message_id={message_id} project_id={this.props.project_id} provider_id={this.props.provider_id} />
                    <span className="ebw-project__chat-conv--timestamp text-xs">{time}</span>
                </div>
            </div>
        )
    };
    providerInfoGraphicMessage = (url, file, time, message_id, message_type, index) => {

        return (
            <>
                <div key={index} className="ebw-project__chat-conv">
                    <div className="d-flex align-items-start justify-content-end">
                        <div onClick={() => this.openPlayer(message_id)} className="ebw-project__chat-conv--text mr-4 position-relative">
                            <div className="ebw-project__chat-conv--fileimgvid">
                                {message_type == "image" ?
                                    <SimpleReactLightbox>
                                        <SRLWrapper options={options}>
                                            <div className="ebw-project__chat-conv--wrap">
                                                <img src={file} alt="File" className="img-fluid d-block" />
                                            </div>
                                        </SRLWrapper>
                                    </SimpleReactLightbox> :
                                    <img src={url} alt="File" className="img-fluid d-block" />}
                            </div>
                        </div>
                        <div className="ebw-project__chat-conv--img">
                            <img src={this.props.pro_avathar} alt="Profile" className="d-block img-fluid" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-1 mr-10">
                        <DeleteMessage refreshChat={this.refreshChat} message_id={message_id} project_id={this.props.project_id} provider_id={this.props.provider_id} />
                        <span className="ebw-project__chat-conv--timestamp text-xs">{time}</span>
                    </div>
                </div>
            </>
        )
    };
    scrollToBottom = () => {
        this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    render() {
        const { files } = this.props;
        return (
            <>
                <div ref={this.myRef} className={`${files.length != 0 ? "ebw-project__chat-convs--min-height" : "ebw-project__chat-convs--max-height"} ebw-project__chat-convs d-flex flex-column mb-auto`}>

                    {
                        this.state.chatData && this.state.chatData !== 0 && this.state.chatData.map((data, index) => {
                            if (data.message_by === "customer") {
                                if (data.message_type === "text") {
                                    return this.customerTextMesasge(data.message_text, data.message_time, index)
                                } else if (data.message_type === "image" || data.message_type === "video") {
                                    return this.customerInfoGraphicMessage(data.thumb_image_url, data.original_file_url, data.message_time, data.message_id, data.message_type, index)
                                }
                            } else if (data.message_by === "provider") {
                                if (data.message_type === "text") {
                                    return this.providerTextMessage(data.message_text, data.message_time, data.message_id, index)
                                } else if (data.message_type === "image" || data.message_type === "video") {
                                    return this.providerInfoGraphicMessage(data.thumb_image_url, data.original_file_url, data.message_time, data.message_id, data.message_type, index)
                                }
                            }
                        })
                    }
                    <Modal className="ebw-modal__videoplayer-wrap d-flex align-items-center" isOpen={this.state.playingVideo} toggle={this.toggleModal}>
                        <div className="ebw-modal__videoplayer-modal">
                            <div className="p-0">
                                <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head pb-0" style={{ border: "none", background: "none", color: "var(${--color-white})" }}></ModalHeader>
                                <ModalBody className="ebw-modal__body p-0">
                                    <div className="ebw-modal__videoplayer">
                                        <Player>
                                            <source src={this.state.currentVideo} />
                                        </Player>
                                    </div>
                                </ModalBody>
                            </div>
                        </div>
                    </Modal>
                    <div ref={this.messagesEndRef} />
                </div>
            </>
        );
    }
}

export default MessageScrollArea;