import * as type from './types';

const initialState = {
    projectData: {},
    notFound: false,
    loading: false,
    error: null,
}

export default function currentProjectData(state = initialState, action) {
    switch (action.type) {
        case type.GET_CURRENT_PROJECT_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_CURRENT_PROJECT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                projectData: action.data.project,
                notFound: false,
            }
        case type.GET_CURRENT_PROJECT_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.notFound ? "Project not found" : "",
                notFound: true,
            }
        default:
            return state
    }
}