import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { getMyWorksFiles } from "../../redux/actions/myWorksFile";
import { getMyworks } from "../../redux/actions/getMyworks";
import MyWorkUploads from "./my-work-uploads";

const MyWorksWiewer = (props) => {
    const { id } = props.match.params;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMyworks());
        dispatch(getMyWorksFiles(id));
    }, []);
    const myWorksFiles = useSelector(state => state.myWorksFiles.myWorksFiles);
    const myWorksFIlesLoading = useSelector(state => state.myWorksFiles.loading);
    const myWorksFilesError = useSelector(state => state.myWorksFiles.error);
    const myWorksData = useSelector(state => state.myWorks.myWorks);
    const myWorksLoading = useSelector(state => state.myWorks.loading);
    const myWorksError = useSelector(state => state.myWorks.error);

    return (
        <>
            <Header />
            <MyWorkUploads
                myWorksFiles={myWorksFiles}
                myWorksFIlesLoading={myWorksFIlesLoading}
                myWorksFilesError={myWorksFilesError}
                myWorksData={myWorksData}
                workId={id}
            // myWorksLoading={myWorksLoading}
            // myWorksError={myWorksError}
            />
            <Footer />
        </>
    );
}

export default MyWorksWiewer;