import React, { useState, useEffect } from 'react';
import '@ionic/react/css/core.css';
import './App.scss';
import AppRouter from "./routes/router";
// import ReactNotificationComponent from "./ReactNotificationComponent";
// import { onMessageListener } from "./firebaseInit";
// import Notifications from "./Notifications";
import ReactGA from 'react-ga';

//  Core CSS required for Ionic components to work properly 
// import '@ionic/react/css/core.css';

// //  Basic CSS for apps built with Ionic 

// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

// //  Optional CSS utils that can be commented out 
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

function App({ additionalClass_main }) {
  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });
  // console.log(onMessageListener()
  // )
  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));


  // GOOGLE ANALYTICS CODE
  useEffect(() => {
    ReactGA.initialize("UA-154734979-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <div className="ebw">
      <AppRouter />
      {/* {show && notification.body != "" ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )} */}
      {/* <Notifications /> */}
    </div>
  );
}
export default App;
