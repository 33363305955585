import * as type from '../actions/types';

const initialState = {
  completedRegsteps: false,
  business: {},
  loading: false,
  error: null,
}

export default function storeBusiness(state = initialState, action) {
  switch (action.type) {
    case type.REGISTER_STORE_BUSINESS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.REGISTER_STORE_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        business: action.business,
        completedRegsteps: true,
      }
    case type.REGISTER_STORE_BUSINESS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}