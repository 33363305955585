export const GET_MYPROJECTS_ALL_REQUESTED = 'GET_MYPROJECTS_ALL_REQUESTED';
export const GET_MYPROJECTS_ALL_SUCCESS = 'GET_MYPROJECTS_ALL_SUCCESS';
export const GET_MYPROJECTS_ALL_FAILED = 'GET_MYPROJECTS_ALL_FAILED';

export const GET_MYPROJECTS_IN_PROCESS_REQUESTED = 'GET_MYPROJECTS_IN_PROCESS_REQUESTED';
export const GET_MYPROJECTS_IN_PROCESS_SUCCESS = 'GET_MYPROJECTS_IN_PROCESS_SUCCESS';
export const GET_MYPROJECTS_IN_PROCESS_FAILED = 'GET_MYPROJECTS_IN_PROCESS_FAILED';

export const GET_MYPROJECTS_CANCELLED_REQUESTED = 'GET_MYPROJECTS_CANCELLED_REQUESTED';
export const GET_MYPROJECTS_CANCELLED_SUCCESS = 'GET_MYPROJECTS_CANCELLED_SUCCESS';
export const GET_MYPROJECTS_CANCELLED_FAILED = 'GET_MYPROJECTS_CANCELLED_FAILED';

export const GET_MYPROJECTS_HISTORY_REQUESTED = 'GET_MYPROJECTS_HISTORY_REQUESTED';
export const GET_MYPROJECTS_HISTORY_SUCCESS = 'GET_MYPROJECTS_HISTORY_SUCCESS';
export const GET_MYPROJECTS_HISTORY_FAILED = 'GET_MYPROJECTS_HISTORY_FAILED';
