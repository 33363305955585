import React from 'react';
import { Link } from "react-router-dom";

const GetFeedback = (props) => {
    return (
        <div className="ebw-card__fancy mt-5">
            <div className="ebw-card__fancy-inwrap ebw-card__fancy-inwrap--image p-4" style={{ backgroundImage: "url(./images/profile/fancy-card-background.svg)" }}>
                <div className="ebw-card__fancy-wrap position-relative">
                    <div className="d-flex align-items-center">
                        <div className="ebw-card__fancy-details position-relative pl-0 w-100">
                            <div className="ebw-card__fancy-title mb-1">Get customer reviews.</div>
                            <div className="ebw-card__fancy-text ebw-card__fancy-text--grey">Share the link to get genuine reviews to add it to your portfolio.</div>
                        </div>
                    </div>
                    <div>
                        <Link to={`/get-reviews/${props.provider_id}`} className="ebw-profile__google-btn ebw-profile__google-btn--radius align-items-center text-center  mt-2" >Get reviewed</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetFeedback;