import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileEditData } from "../../redux/actions/profileEditData";
import { inConversationData } from "../../redux/actions/inConversation";
import { getAvailableProjects } from "../../redux/reduxes/availableProjects/availableProjectsAction";
import Projects from "./projects";
import Header from '../../layout/header';
import Footer from '../../layout/footer';

const ProjectsPage = (props) => {
    const { category, tab } = props.match.params;

    const availableData = { page: 1 };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProfileEditData());
        dispatch(inConversationData());
        dispatch(getAvailableProjects(availableData));
    }, []);
    const available = useSelector(state => state.availableProjects.data);

    const availableLoading = useSelector(state => state.availableProjectsX.loading);
    const availableError = useSelector(state => state.availableProjects.error);
    const conversations = useSelector(state => state.conversations.conversations);
    const conversationsLoading = useSelector(state => state.conversations.loading);
    const availableProjects = useSelector(state => state.availableProjectsX.xAvailable);
    const pagination = useSelector(state => state.availableProjectsX.pagination);

    console.log(availableLoading, "availableLoading in the my projects ")

    const checkAvailData = (name) => {
        let pushData;
        if (name === "all") {
            pushData = available;
        } else {
            pushData = available.filter(x => x.project_type == name);
        }
        return pushData
    }
    const pageChanged = () => {

    }
    return (
        <>
            <Header />
            <Projects
                availableProjects={availableProjects}
                availableData={checkAvailData(category)}
                availableLoading={availableLoading}
                conversations={conversations}
                conversationsLoading={conversationsLoading}
                tab={tab}
                pagination={pagination}
            />
            <Footer />
        </>
    );
}

export default ProjectsPage;