import { call, put, takeEvery } from 'redux-saga/effects'
import { setIncompleteRegAuth } from '../../utils';
import history from '../../common/history';

function* storeData(action) {
    if (action.data) {
        // yield put({ type: 'SET_SOCIAL_INCOMPLETE_REG_SUCCESS', data:  });
        if (action.data.data.signup_status && action.data.data.signup_status === "verification_status") {
            setIncompleteRegAuth(action.data.data.data, action.data.headers);
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: 2 });
            yield put({ type: 'POST_REGISTER_PERSONAL_SUCCESS', registerPersonal: action.data });
            history.push('/register');
            window.location.reload();
        } else {
            setIncompleteRegAuth(action.data.data.data, action.data.headers);
            yield put({ type: 'STORE_ACTIVE_REG_STEP_SUCCESS', activeStep: action.data.data.signup_status == "business-info" ? 3 : 5 });
            history.push('/register');
            window.location.reload();
        }
    } else {
        yield put({ type: 'SET_SOCIAL_INCOMPLETE_REG_FAILED', message: "no data" });
    }
}

function* setSocialIncompleteSaga() {
    yield takeEvery('SET_SOCIAL_INCOMPLETE_REG_REQUESTED', storeData);
}

export default setSocialIncompleteSaga;