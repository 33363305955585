import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';



async function postApi(data) {
    const apiUrl = API.baseURL + `/messages/chat_creation`;
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* sendMessage(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Success', 'You are successfully sent message ');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to send message', response.errors[0]);
        }
        yield put({ type: 'POST_SEND_MESSAGE_SUCCESS', data: response.data });
    } catch (e) {
        yield put({ type: 'POST_SEND_MESSAGE_FAILED', message: e.message });
    }
}

function* sendMessageSaga() {
    yield takeEvery('POST_SEND_MESSAGE_REQUESTED', sendMessage);
}

export default sendMessageSaga;