import React, { Component } from 'react'
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Main from '../../layout/main'
import { Helmet } from "react-helmet";
import { Container, Row, Col} from 'reactstrap';

class Payment extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Payment</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="profile">
                <section className="ebw-payment mt-8 mb-8">
                    <Container>
                        <div className="ebw-title__primary">Complete payment</div>
                        <Row className="mt-6">
                            <Col sm={12} md={6}>
                                <div className="ebw-payment__wrap px-4 py-4 px-md-6 py-md-6">
                                    <div className="ebw-payment__summary">
                                        <div className="ebw-payment__title mb-3">Project summary</div>
                                        <div className="ebw-payment__card ebw-payment__card--border ebw-payment__project">
                                            <div className="d-md-flex justify-content-between align-items-center">
                                                <div className="ebw-payment__label-summary">Project Name</div>
                                                <div className="ebw-payment__answer-summary">Gutter cleaning</div>
                                            </div>
                                            <div className="d-md-flex justify-content-between align-items-center pt-5">
                                                <div className="ebw-payment__label-summary">Customer</div>
                                                <div className="ebw-payment__answer-summary">Merry Pink</div>
                                            </div>
                                            <div className="d-md-flex justify-content-between align-items-center pt-5">
                                                <div className="ebw-payment__label-summary">Customer Address</div>
                                                <div className="ebw-payment__answer-summary">Customers full address</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ebw-payment__cost mt-8">
                                        <div className="ebw-payment__title mb-3">Project Cost</div>
                                        <div className="ebw-payment__card">
                                            <div className="d-flex justify-content-between">
                                                <div className="ebw-payment__label-budjet">Project Budget</div>
                                                <div className="ebw-payment__answer-budjet">$50-$1000</div>
                                            </div>
                                            <div className="d-flex justify-content-between pt-2">
                                                <div className="ebw-payment__label-budjet ebw-payment__label-budjet--green ">Comission charge Percentage</div>
                                                <div className="ebw-payment__answer-budjet ebw-payment__answer-budjet--bold ">30%</div>
                                            </div>
                                        </div>
                                        <div className="ebw-payment__card ebw-payment__card--nobackground">
                                            <div className="d-flex justify-content-between pt-3 pb-5">
                                                    <div className="ebw-payment__final">Final project amount</div>
                                                    <div className="ebw-payment__final-amount">$ 200</div>
                                            </div>
                                            <div className="ebw-payment__final-description">
                                                This amount goes to easybids, please collect project closure amount from the customer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="ebw-payment__info px-4 py-4 px-md-6 py-md-6">
                                    <div className="ebw-payment__title">Payment information</div>
                                    <div className="pt-6 pb-4 d-md-flex justify-content-between">
                                        <div className="mr-lg-3 w-100 mb-2 mb-md-0">
                                            <div className="ebw-payment__card-label mb-1">Card Number</div>
                                            <Input className="ebw-payment__card-input"></Input>
                                        </div>
                                        <div className="">
                                            <div className="ebw-payment__card-label mb-1">Valid Thru</div>
                                            <Input className="ebw-payment__card-input"></Input>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-end"><div className="ebw-payment__button pt-2 pb-2 pl-4 pr-4">Proceed To Pay</div></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                </Main>
            </div>
        )
    }
}

export default Payment
