import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import API from "../../API";

const CheckoutForm = ({ project_id, setCommissionAmnt }) => {
    const history = useHistory();
    const [state, setState] = useState({
        serviceAmount: null,
        availableCards: [],
        addNewCard: false,
        provider_project_id: null,
        selectedCardId: null,
        client_payment_intent_key: null,
        preLoading: false,
        error: "",
        provider_name: "",
        loadingShow: false,
        provider_id: null,
        paymentSuccess: false,
        free: false,
        freeLoadingShow: false,

        amount_payable_to_easybids_after_discount: 0,
        project_number: "",
        inProcess: false,
    });
    const stripe = useStripe();
    const elements = useElements();
    const initiateCommissionPayment = (res) => {
        console.log(res, "comes inside initiate commission payment");
        setCommissionAmnt(res.data.amount_payable_to_easybids_after_discount)
        setState((prevProps) => ({
            ...prevProps,
            commissionAmount: res.data.easybids_commission,
            amount_payable_to_easybids_after_discount: res.data.amount_payable_to_easybids_after_discount,
            availableCards: res.data.stripe_card_details,
            project_number: res.data.project_number,
            addNewCard: res.data.stripe_card_details.length == 0,
            provider_project_id: res.data.provider_project_id,
            selectedCardId: res.data.stripe_card_details && res.data.stripe_card_details.length != 0 ? res.data.stripe_card_details[0].id : "",
        }));
        const data = JSON.stringify({
            project_number: res.data.project_number,
        });
        axios.post(API.baseURL + `/v2/providers/projects/${project_id}/initiate_commission_payment`, data, API.options)
            .then(response => response.data)
            .then(res => {
                console.log(res, "comes inside the initiate success");
                setState((prevProps) => ({
                    ...prevProps,
                    client_payment_intent_key: JSON.parse(res.payment_response).client_secret,
                    preLoading: false
                }));

                if (res.errors) {
                    setState((prevProps) => ({
                        ...prevProps,
                        error: res.errors.message, preLoading: false
                    }));
                    return
                }
            })
            .catch(error => {
                // this.setState({ error: error.error.message, loadingShow: false });
            })

    }
    const getCardDetails = () => {
        axios
            .get(
                API.baseURL +
                `/v2/providers/projects/${project_id}/commission_card_details`,
                API.options
            ).then(response => response.data)
            .then(res => {
                // console.log(res.data.amount_payable_to_easybids_after_discount, "res in the commition payment")
                initiateCommissionPayment(res);
                setCommissionAmnt(res.data.amount_payable_to_easybids_after_discount);
            })
            .catch(err => {
                // if (err.response.status == 404)
                //     this.props.history.push('/project-not-found')
                // else if (err.response.status == 403)
                //     this.props.history.push('/project-ignored')
            });
    }
    useEffect(() => {
        getCardDetails()
    }, []);
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };
    // const [stripeLoading, setStripeLoading] = useState(true);
    const [paymentErros, setPaymentErros] = useState("");
    // const [stripeLoading, setStripeLoading] = useState(true);
    const confirmCommissionPayment = (result) => {
        console.log(result, "Result in the confirm payment", state)
        if (result.error) {
            setState((prevProps) => ({
                ...prevProps, error: result.error.message, stripe: true, loadingShow: false
            }));
        } else {
            if (result.paymentIntent.status === "succeeded") {
                const data = JSON.stringify({
                    provider_token: Cookies.get('authentication_token'),
                    payment_intent_id: result.paymentIntent.id,
                    project_number: state.project_number
                });
                axios
                    .post(API.baseURL + `/v2/providers/projects/${project_id}/confirm_commission_payment`, data, API.options)
                    .then(response => response.data)
                    .then(res => {
                        setState((prevProps) => ({
                            ...prevProps,
                            loadingShow: false,
                            paymentSuccess: true,
                            inProcess: false
                        }));
                        // history.replace(`/project-detail/${state.provider_project_id}/${project_id}/lead_projects`)
                    })
                    .catch(error => {
                        setState((prevProps) => ({
                            ...prevProps,
                            error: "Unable to complete the payment.",
                            loadingShow: false
                        }));
                    })
            }
        }
    }

    const handleSubmit = async (event) => {
        setState((prevProps) => ({
            ...prevProps,
            inProcess: true
        }));
        event.preventDefault();
        if (!stripe || !elements) { return; }
        const cardElement = elements.getElement(CardElement);
        if (state.addNewCard) {
            if (elements.getElement(CardElement)._empty) {
                setState((prevProps) => ({
                    ...prevProps,
                    error: "Enter the card details"
                }));
                return
            };

            setState((prevProps) => ({
                ...prevProps,
                loadingShow: true, stripe: false
            }));

            const result = await stripe.confirmCardPayment(
                state.client_payment_intent_key,
                {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: localStorage.getItem("username")
                        }
                    },
                    setup_future_usage: 'off_session'
                }
            );
            confirmCommissionPayment(result);
        } else {
            setState((prevProps) => ({
                ...prevProps,
                loadingShow: true, stripe: false
            }));
            const result = await stripe.confirmCardPayment(
                state.client_payment_intent_key,
                {
                    payment_method: state.selectedCardId
                }
            );
            confirmCommissionPayment(result);
        }


    }
    // };
    const newCardAdd = () => {
        setState((prevProps) => ({
            ...prevProps,
            addNewCard: true,
            selectedCardId: "",
        }));
    }
    const checkRadio = (event) => {
        // event.preventDefault()
        setState((prevProps) => ({
            ...prevProps,
            selectedCardId: event.target.value,
            addNewCard: false,
        }));
    };
    const redirectToDetails = () => {
        history.replace(`/project-detail/${project_id}/my_projects`)
    }
    const getItForFree = () => {
        setState((prevProps) => ({
            ...prevProps,
            freeLoadingShow: true
        }));

        axios
            .post(API.baseURL + `/v2/providers/projects/${project_id}/lead_purchased_without_amount`, {}, API.options)
            .then(response => response.data)
            .then(res => {
                console.log(res, "without payment success");
                setState((prevProps) => ({
                    ...prevProps,
                    freeLoadingShow: false,
                    paymentSuccess: true
                }));

            })
            .catch(error => {
                setState((prevProps) => ({
                    ...prevProps,
                    freeLoadingShow: false
                }));

            })
    }
    return (
        <>{state.free ?
            <>
                <>
                    <div>
                        <div className="es-payment__row">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="es-payment__title">Congratulations! You have gained access to the customer's phone number for free</div>
                                <div className="es-payment__amount-paied text-right">
                                    <div className="es-payment__amount es-payment__amount--gray es-payment__amount-crossed">$ 31</div>
                                    <div className="es-payment__amount-type"><b>Free</b></div>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => getItForFree()} class="es-button es-button__primary w-100 d-flex align-items-center mt-3">
                            <div className="icon-phone-wave es-icon__size-md mr-1"></div>
                            CALL THE CUSTOMER
                        </button>
                    </div>
                </>
            </> :
            <>
                <form className="ebw-paymentn__form w-100" onSubmit={handleSubmit}>
                    {state.availableCards && state.availableCards.length != 0 &&
                        <div className="ebw-paymentn__row">
                            <div className="ebw-paymentn__label mb-2">Saved Payment Methods</div>
                            <div className="">
                                {state.availableCards && state.availableCards.length != 0 && state.availableCards.map((cards, index) => {
                                    return (
                                        <div key={index} className="ebw-paymentn__card mb-2">
                                            <input
                                                onChange={(e) => checkRadio(e)}
                                                value={cards.id}
                                                id={cards.id}
                                                className="ebw-paymentn__card-input d-none"
                                                type="radio"
                                                name="card"
                                                checked={state.selectedCardId == cards.id}
                                            />
                                            <label for={cards.id} className="ebw-paymentn__card-inwrap d-flex justify-content-between align-items-center mb-0">
                                                <div className="ebw-paymentn__card-left d-flex">
                                                    <div className="ebw-paymentn__checkbox">
                                                    </div>
                                                    <div className="ebw-paymentn__card-type d-flex align-items-center">
                                                        <div className="ebw-paymentn__card-type--img mr-2">
                                                            <img src={cards.card_icon} alt=""
                                                            />
                                                        </div>
                                                        <div className="ebw-paymentn__card-type--num d-flex m-l">XX<div>{cards.last4_digit.substring(2)}</div></div>
                                                    </div>
                                                </div>
                                                <div className="ebw-paymentn__card-right">
                                                    <div className="ebw-paymentn__card-mmyy">{`${cards.exp_month}/${cards.exp_year}`}</div>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    <span className="ebw-paymentn__label mb-3" onClick={newCardAdd}>
                        <div className="ebw-paymentn__add-card mb-2">
                            Add new card
                            <div className="ebw-button__measuring ebw-rform__measuring-btn"></div>
                        </div>
                    </span>
                    {state.addNewCard &&
                        <div className="ebw-paymentn__card-new mb-3">
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                        </div>
                    }
                    <div>{paymentErros}</div>
                    {state.error ? <div className="ebw-error mb-3">{paymentErros}{state.error}</div> : ""}
                    <button className="ebw-button ebw-button__primary btn btn-secondary w-100 border-radius" type="submit" disabled={!stripe}>
                        {state.inProcess ? "processing..." : "Pay Now"}
                    </button>
                </form>
            </>

        }

            <Modal isOpen={state.paymentSuccess} toggle={() => redirectToDetails()}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={() => redirectToDetails()}></ModalHeader>
                    <ModalBody className="pt-0">
                        <h3 className="text-4xl mb-6">Thank you for choosing Easy Bids for your business.</h3>
                        <div className="ebw-modal__para ebw-modal__para--bold">Keep Bidding to grow your business.</div>
                        <div className="text-right d-flex justify-content-end pt-3">
                            <Button
                                onClick={() => redirectToDetails()}
                                className="ebw-button__primary border-none pt-2 pb-2 pl-4 pr-4">
                                <span>Go to project summary</span>
                            </Button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default CheckoutForm;