import * as type from './types';

const initialState = {
    user_name: "",
    ads: [],
    deals_avilable: [],
    app_update_details: [],
    activities: [],
    available_projects: [],
    my_projects: [],
    provider_id: "",
    stats: [],
    video_url: "",
    profile_score: 0,
    reviews: [],
    stories: [],
    provider_avatar: "",
    primaryNotifications: [],
    loading: false,
    error: null,
    newService: false,
    primaryNotificationsLoading: false,
    primaryNotificationsError: null,
    closePrimaryNotificationsLoading: false,
    closePrimaryNotificationsError: null,
}

export default function dashoardData(state = initialState, action) {
    switch (action.type) {
        //||||||||||||||||||||||||||||DASH DATA ||||||||||||||||||||||||||||
        case type.GET_DASHBOARD_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                user_name: action.dashboard.user_name,
                ads: action.dashboard.ads,
                deals_avilable: action.dashboard.deals_avilable,
                app_update_details: action.dashboard.app_update_details,
                activities: action.dashboard.activities,
                reviews: action.dashboard.reviews,
                stories: action.dashboard.stories,
                available_projects: action.dashboard.available_projects,
                my_projects: action.dashboard.my_projects,
                provider_id: action.dashboard.provider_id,
                stats: action.dashboard.stats,
                video_url: action.dashboard.video_url,
                profile_score: action.dashboard.profile_score,
                newService: action.dashboard.new_services,
                provider_avatar: action.dashboard.provider_avatar,
            }
        case type.GET_DASHBOARD_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        //|||||||||||||||||||||||||||| PRIMARY NOTIFICATIONS ||||||||||||||||||||||||||||
        case type.GET_PRIMARY_NOTIFICATION_REQUESTED:
            return {
                ...state,
                primaryNotificationsLoading: true,
            }
        case type.GET_PRIMARY_NOTIFICATION_SUCCESS:
            return {
                ...state,
                primaryNotificationsLoading: false,
                primaryNotifications: action.primaryNotification
            }
        case type.GET_PRIMARY_NOTIFICATION_FAILED:
            return {
                ...state,
                primaryNotificationsLoading: false,
                primaryNotificationsError: action.message,
            }
        default:
            return state
    }
}