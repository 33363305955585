import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import MyProjects from "./my-projects";
import { allProjectsData } from "../../redux/actions/allProjects";
import { getProfileEditData } from "../../redux/actions/profileEditData";

import { getMyProjectsAll } from "../../redux/reduxes/myProjects/allAction";
import { getMyProjectsInProcess } from "../../redux/reduxes/myProjects/inProcessAction";
import { getMyProjectsCancelled } from "../../redux/reduxes/myProjects/cancelledAction";
import { getMyProjectsHistory } from "../../redux/reduxes/myProjects/historyAction";

const MyProjectsPage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state);

    useEffect(() => {
        dispatch(allProjectsData()); //-
        dispatch(getProfileEditData()); //-

        dispatch(getMyProjectsAll({ page: 1 }));
        dispatch(getMyProjectsInProcess({ page: 1 }));
        dispatch(getMyProjectsCancelled({ page: 1 }));
        dispatch(getMyProjectsHistory({ page: 1 }));
    }, [])

    const myProjectsAll = useSelector(state => state.myProjects.all);
    const myProjectsInProcess = useSelector(state => state.myProjects.inProcess);
    const myProjectsCancelled = useSelector(state => state.myProjects.cancelled);
    const myProjectsHistory = useSelector(state => state.myProjects.history);
    const paginationAll = useSelector(state => state.myProjects.allPagination);
    const paginationInProcess = useSelector(state => state.myProjects.inProcessPagination);
    const paginationCancelled = useSelector(state => state.myProjects.cancelledPagination);
    const paginationHistory = useSelector(state => state.myProjects.historyPagination);
    const allLoading = useSelector(state => state.myProjects.allLoading);
    const inProcessLoading = useSelector(state => state.myProjects.inProcessLoading);
    const cancelledLoading = useSelector(state => state.myProjects.cancelledLoading);
    const historyLoading = useSelector(state => state.myProjects.historyLoading);

    console.log(paginationAll, "paginationAll myProjectsAll in the container index");
    return (
        <>
            <Header />
            <MyProjects
                myProjectsAll={myProjectsAll}
                myProjectsInProcess={myProjectsInProcess}
                myProjectsCancelled={myProjectsCancelled}
                myProjectsHistory={myProjectsHistory}
                allLoading={allLoading}
                inProcessLoading={inProcessLoading}
                cancelledLoading={cancelledLoading}
                historyLoading={historyLoading}
                paginationAll={paginationAll}
                paginationInProcess={paginationInProcess}
                paginationCancelled={paginationCancelled}
                paginationHistory={paginationHistory}
            />
            <Footer />
        </>
    );
}

export default MyProjectsPage;