import * as type from '../actions/types';

const initialState = {
    available: [],
    // available_limited: [],
    // available_pagecount: 0,
    offset: 0,
    inbidding: [],
    awarded: [],
    loading: false,
    error: null,
}

export default function allProjectsData(state = initialState, action) {
    switch (action.type) {
        case type.GET_ALL_PROJECTS_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ALL_PROJECTS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                available: action.data.available,
                // available_limited: action.available.slice(0, 10),
                // available_pagecount: Math.ceil(action.available.length / 10),
                offset: 0,
                inbidding: action.data.inbidding,
                awarded: action.data.awarded
            }
        case type.GET_ALL_PROJECTS_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}