import { call, put, takeEvery } from 'redux-saga/effects'

function* storeData(action) {
    if (action.data) {
        yield put({ type: 'STORE_SELECTED_TYPES_ALL_SUCCESS', data: action.data });
    } else {
        yield put({ type: 'STORE_SELECTED_TYPES_ALL_FAILED', message: "no data" });
    }
}

function* selectedAllTypesSaga() {
    yield takeEvery('STORE_SELECTED_TYPES_ALL_REQUESTED', storeData);
}

export default selectedAllTypesSaga;