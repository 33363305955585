import React from "react"
import ContentLoader from "react-content-loader"
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Row, Col
} from 'reactstrap';

const ProPlusLoader = (props) => (
    <Row className="align-items-center mt-5 mt-md-0">
        <Col sm="6">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 207"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="12" y="0" rx="0" ry="0" width="356" height="47" /> 
                <rect x="385" y="0" rx="5" ry="5" width="129" height="48" /> 
                <rect x="14" y="68" rx="5" ry="5" width="340" height="12" /> 
                <rect x="16" y="103" rx="8" ry="8" width="313" height="93" />
            </ContentLoader>
        </Col>
        <Col sm="6">
            <ContentLoader 
                speed={23}
                width="100%"
                height="100%"
                viewBox="0 0 600 515"
                backgroundColor="#d5cdcd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="321" height="29" /> 
                <rect x="0" y="52" rx="5" ry="5" width="609" height="45" /> 
                <rect x="-1" y="108" rx="0" ry="0" width="378" height="8" /> 
                <rect x="0" y="131" rx="0" ry="0" width="600" height="45" /> 
                <rect x="0" y="187" rx="0" ry="0" width="379" height="9" /> 
                <rect x="0" y="275" rx="0" ry="0" width="380" height="8" /> 
                <rect x="0" y="220" rx="0" ry="0" width="608" height="45" /> 
                <rect x="6" y="309" rx="11" ry="11" width="600" height="128" /> 
                <rect x="125" y="456" rx="6" ry="6" width="341" height="70" />
            </ContentLoader>
        </Col>
    </Row>
)

export default ProPlusLoader
