import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";



const CommissionCheckout = (props) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm setCommissionAmnt={props.setCommissionAmnt} project_id={props.project_id} />
        </Elements>
    );
}

export default CommissionCheckout;