import React, { useState, useEffect } from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Container,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import CardSecondary from '../../components/card/card-secondary';
import Main from '../../layout/main'
import { CategoryDropdown } from "../../components/dropdown/dropdown";
import PaginationCmn from "../../components/common/pagination"
import Filter from "../../components/common/filter";
import ProjectsLoader from "../../components/projects/projectsSkeleton"
import { setAvailableCategories } from "../../redux/actions/setAvailableCategories";

import AllActivities from "../../components/Activities/allActivities";
import InBiddingActivities from "../../components/Activities/inBiddingActivities";
import InConversationActivities from "../../components/Activities/inConversationActivities";
import BookmarkedActivities from "../../components/Activities/bookmarksActivities";


const Activities = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setCategories();
    });
    const categoriesFromStore = useSelector(state => state.categoryFilter.selectedCategories);
    const { projectsData } = props
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const [dropdownOpen, setDropdownOpen] = useState(false);


    const dropdown = () => setDropdownOpen(prevState => !prevState);

    const [val, setVal] = useState([25, 50])
    const updateRange = (e, data) => {
        setVal(data)
    }

    const {
        inbiddingActivities,
        inbiddingLoading,
        allActivities,
        allLoading,
        inConversationActivities,
        inConversationLoading,
        bookmarkedActivities,
        bookmarkedLoading,
    } = props;
    console.log(allActivities, "allActivities in the container");
    const inbiddingData = props.projectsData.inbidding
    const hiredData = projectsData && projectsData.awarded && projectsData.awarded && projectsData.awarded.length != 0 && projectsData.awarded.filter(x => x.project_status !== 4);
    const completedData = projectsData && projectsData.awarded && projectsData.awarded && projectsData.awarded.length != 0 && projectsData.awarded.filter(x => x.project_status === 4);

    // const [availableProjectsData, setAvailableProjects] = useState([])

    const [state, setState] = useState({
        startDate: "",
        endDate: "",
        location: "",
        budgetRangeStart: 10,
        budgetRangeEnd: 80,

        hiredStartDate: "",
        hiredEndDate: "",
        hiredLocation: "",
        hiredBudgetRangeStart: 10,
        hiredBudgetRangeEnd: 80,

        completedStartDate: "",
        completedEndDate: "",
        completedLocation: "",
        completedBudgetRangeStart: 10,
        completedBudgetRangeEnd: 80,
    });

    const filterUpdated = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            startDate: data.startDate,
            endDate: data.endDate,
            location: data.location,
            budgetRangeStart: data.budgetRangeStart,
            budgetRangeEnd: data.budgetRangeEnd,
        }));
    };
    const hiredFilterUpdated = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            hiredStartDate: data.startDate,
            hiredEndDate: data.endDate,
            hiredLocation: data.location,
            hiredBudgetRangeStart: data.budgetRangeStart,
            hiredBudgetRangeEnd: data.budgetRangeEnd,
        }));
    };
    const completedFilterUpdated = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            completedStartDate: data.startDate,
            completedEndDate: data.endDate,
            completedLocation: data.location,
            completedBudgetRangeStart: data.budgetRangeStart,
            completedBudgetRangeEnd: data.budgetRangeEnd,
        }));
    };
    const [otherFilterActive, setOtherFilterActive] = useState(false)
    const [otherHiredFilterActive, setOtherHiredFilterActive] = useState(false)
    const [otherCompletedFilterActive, setOtherCompletedFilterActive] = useState(false)

    const [currentInbiddingData, setCurrentInbiddingData] = useState([]);
    const [currentHiredData, setCurrentHiredData] = useState([]);
    const [currentCompletedData, setCurrentCompletedData] = useState([]);

    const storeFilterdArray = (data) => {
        setCurrentInbiddingData(data);
        setOtherFilterActive(true);
    };
    const storeHiredFilterdArray = (data) => {
        setCurrentHiredData(data);
        setOtherHiredFilterActive(true)
    };
    const storeCompletedFilterdArray = (data) => {
        setCurrentCompletedData(data);
        setOtherCompletedFilterActive(true)
    };
    const setCategories = () => {
        const availableCategories = [];
        if (activeTab == "1") {
            inbiddingData && inbiddingData.length > 0 && inbiddingData.forEach(data => {
                !availableCategories.includes(data.project_type) && availableCategories.push(data.project_type);
            })
        } else if (activeTab == "2") {
            hiredData && hiredData.length > 0 && hiredData.forEach(data => {
                !availableCategories.includes(data.project_type) && availableCategories.push(data.project_type);
            })
        } else if (activeTab == "3") {
            completedData && completedData.length > 0 && completedData.forEach(data => {
                !availableCategories.includes(data.project_type) && availableCategories.push(data.project_type);
            })
        }
        dispatch(setAvailableCategories(availableCategories));
    }
    setCategories();
    const itemsPerPage = 9;
    const [activePageInbidding, setInbiddingActivePage] = useState(1);
    const [activePageHired, setHiredActivePage] = useState(1);
    const [activePageCompleted, setCompletedActivePage] = useState(1);
    const applyPaginationFilter = (currentData, activePage) => {
        const last = activePage * itemsPerPage;
        const first = (activePage - 1) * itemsPerPage;
        return currentData && currentData.length != 0 && currentData.slice(first, last);
    }
    const inbiddingProjectsDataPage = applyPaginationFilter(currentInbiddingData, activePageInbidding);
    const hiredProjectsDataPage = applyPaginationFilter(currentHiredData, activePageHired);
    const completedProjectsDataPage = applyPaginationFilter(currentCompletedData, activePageCompleted);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Check out all your projects at a single place.The projects you bidded,You bought and the bids approved.Check everything at a single place" />
                <title> - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="myprojects">
                <Nav tabs className="ebw-projects__navtab">
                    <Container>
                        <Row>
                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    All
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    In Bidding
                                </NavLink>
                            </NavItem>

                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    In Conversation
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { toggle('4'); }}
                                >
                                    Bookmarks
                                </NavLink>
                            </NavItem>

                            {/* <NavItem className="ebw-tab__item ml-auto">
                                <CategoryDropdown />
                            </NavItem> */}
                        </Row>
                    </Container>
                </Nav>
                <section className="ebw-section ebw-section__pages">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <AllActivities
                                allActivities={allActivities}
                                allLoading={allLoading}
                                categoriesFromStore={categoriesFromStore}
                            />
                        </TabPane>

                        <TabPane tabId="2">
                            <InBiddingActivities
                                inbiddingActivities={inbiddingActivities}
                                inbiddingLoading={inbiddingLoading}
                                categoriesFromStore={categoriesFromStore}
                            />
                        </TabPane>

                        <TabPane tabId="3">
                            <InConversationActivities
                                inConversationActivities={inConversationActivities}
                                inConversationLoading={inConversationLoading}
                                categoriesFromStore={categoriesFromStore}
                            />
                        </TabPane>
                        <TabPane tabId="4">
                            <BookmarkedActivities
                                bookmarkedActivities={bookmarkedActivities}
                                bookmarkedLoading={bookmarkedLoading}
                                categoriesFromStore={categoriesFromStore}
                            />
                        </TabPane>
                    </TabContent>
                </section>
            </Main>
        </div>
    );
}

const mapStateToProps = state => ({
    projectsData: state.allProjectsData,
});

export default connect(mapStateToProps, {})(Activities);