import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProCheckoutForm from "./proCheckoutForm";
import API from '../../API';

const ProPlusCheckout = (props) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    return (
        <Elements stripe={stripePromise}>
            <ProCheckoutForm provider_id={props.provider_id} user_id={props.user_id} />
        </Elements>
    );
}

export default ProPlusCheckout;