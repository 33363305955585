import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavbarBrand, NavItem, Badge, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { Link, NavLink } from "react-router-dom";

import Buttons from "../components/buttons/button-primary";
import { HeaderDropdown, NotificationDropdown } from '../components/dropdown/dropdown';
import { isLogin } from "../utils/index";
import history from "../common/history";
import { MobileHeader, MobileNavTabs } from '../components/mobile-header/mobile-header';
import { firstFiveNotifications } from "../redux/actions/firstFiveNotifications";
import { getNotificationCount } from "../redux/actions/notificationCount";
import { getProfileData } from "../redux/actions/profileData";
import { logout } from "../redux/actions/logout";
import NewService from "./newServices";
import DasboardNotification from "../components/dashboard/dashboardNotification";

import ProjectInConversation from '../components/projects/project-incoversation';
import ProjectBookmarked from "../components/card/project-bookmarked";


const Header = (props) => {
    const [playVideo, setPlayVideo] = useState(false);
    const [addNewService, setAddNewService] = useState(false);
    const [showPrimary, setShowPrimary] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isLogin() === true) {
            dispatch(firstFiveNotifications());
            dispatch(getNotificationCount());
            dispatch(getProfileData());
        }
    }, []);
    // notificationCount
    const notificationCount = useSelector(state => state.notificationCount.notification_count);
    // const notification = useSelector(state => state.notificationList.notification_list);
    // const notificationLoading = useSelector(state => state.notificationList.loading);
    // const notificationError = useSelector(state => state.notificationList.error);
    const firstFiveNotificationsData = useSelector(state => state.firstFiveNotifications.data);
    const bookmark = useSelector(state => state.bookmark);
    const firstFiveNotificationsLoading = useSelector(state => state.firstFiveNotifications.loading);
    const firstFiveNotificationsError = useSelector(state => state.firstFiveNotifications.error);

    const profile = useSelector(state => state.profileData.profile);
    const video_url = useSelector(state => state.dashoardData.video_url);
    const profileLoading = useSelector(state => state.profileData.loading);
    const profileError = useSelector(state => state.profileData.error);
    const auth = useSelector(state => state.auth.auth);
    const regStep = useSelector(state => state.registrationStep.activeRegistrationStep);
    const primaryNotifications = useSelector(state => state.dashoardData.primaryNotifications);
    const [regModal, setRegModal] = useState(false);
    const toggle = () => {
        setRegModal(!regModal);
    }
    useEffect(() => {
        if (primaryNotifications && primaryNotifications.length > 0 && location.pathname == "/dashboard") {
            setShowPrimary(true);
        } else {
            setShowPrimary(false);
        }
    }, [primaryNotifications]);
    const registeration = (event) => {
        event.preventDefault();
        if (history.location.pathname === "/register" && regStep != 1) {
            setRegModal(true);
        } else {
            history.push('/register');
            window.location.reload();
        }

    }
    const confirmNewReg = () => {
        dispatch(logout());
        setRegModal(false);
    }
    const closeNotificationsPopup = () => {
        setShowPrimary(false);
    }
    if (isLogin() == true) {
        return (
            <>
                <header className="ebw-header">
                    <Navbar className="ebw-header__inner ebw-header__inner--postlogin d-none d-xl-flex align-items-center py-0">
                        <NavbarBrand className="ebw-header__brandwrap" href="/dashboard">
                            <img className="ebw-header__brand img-fluid mx-auto d-block" src="/images/logo.svg" alt="Logo" />
                        </NavbarBrand >

                        <Nav className="ebw-header__lft d-none d-xl-flex align-items-center mr-auto h-100">

                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/dashboard" exact activeClassName="active" className="ebw-header__menu-link d-flex align-items-center" >
                                    {/* <span className="icon-home ebw-font__size-xl pr-1"></span> */}
                                    <div className="ebw-header__menu-svg path pr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><defs><style dangerouslySetInnerHTML={{ __html: ".a{fill:red;}.b{fill:none;}.c{clip-path:url(#a);}.d{clip-path:url(#b);}" }} /><clipPath id="a"><rect className="a" width={18} height={18} transform="translate(10561 -21839)" /></clipPath><clipPath id="b"><rect className="b" width="16.555" height="15.48" transform="translate(0 0)" /></clipPath></defs><g className="c" transform="translate(-10561 21839)"><g transform="translate(10561.723 -21838)"><g className="d"><path d="M11.141,15.479H5.652a2,2,0,0,1-2-2V8.624a2,2,0,0,1,2-2h5.489a2,2,0,0,1,2,2v4.855a2,2,0,0,1-2,2M5.652,8.124a.5.5,0,0,0-.5.5v4.855a.5.5,0,0,0,.5.5h5.489a.5.5,0,0,0,.5-.5V8.624a.5.5,0,0,0-.5-.5Z" /><path d="M15.805,9.338a.744.744,0,0,1-.53-.22l-7-7-7,7A.75.75,0,0,1,.22,8.058L8.278,0l8.057,8.058a.75.75,0,0,1-.53,1.28" /></g></g></g></svg>
                                    </div>
                                    <div className="py-2 pl-1">Dashboard</div>
                                </NavLink>
                            </NavItem>


                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/projects/all/1" className='ebw-header__menu-link d-flex align-items-center'>
                                    {/* <span className="icon-projects ebw-font__size-xl pr-1"></span> */}
                                    <div className="ebw-header__menu-svg path pr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10529 21839)"><g transform="translate(10530 -21838)"><g className="d"><path d="M6,16H1a1,1,0,0,1-1-1V10A1,1,0,0,1,1,9H6a1,1,0,0,1,1,1v5a1,1,0,0,1-1,1M1.5,14.5h4v-4h-4Zm4.5-4H6Z" /><path d="M15,16H10a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v5a1,1,0,0,1-1,1m-4.5-1.5h4v-4h-4Zm4.5-4h0Z" /><path d="M15,7H10A1,1,0,0,1,9,6V1a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1V6a1,1,0,0,1-1,1M10.5,5.5h4v-4h-4Zm4.5-4h0Z" /><path d="M6,7H1A1,1,0,0,1,0,6V1A1,1,0,0,1,1,0H6A1,1,0,0,1,7,1V6A1,1,0,0,1,6,7M1.5,5.5h4v-4h-4ZM6,1.5H6Z" /></g></g></g></svg>
                                    </div>
                                    <div className="py-2 pl-1">Available Projects</div>
                                </NavLink>
                            </NavItem>

                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/activities" className='ebw-header__menu-link d-flex align-items-center'>
                                    {/* <span className="icon-document ebw-font__size-xl pr-1"></span> */}
                                    <div className="ebw-header__menu-svg path pr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10499 21841)"><g transform="translate(10500 -21840)"><g className="d"><path d="M13,16H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H13a3,3,0,0,1,3,3V13a3,3,0,0,1-3,3M3,1.5A1.5,1.5,0,0,0,1.5,3V13A1.5,1.5,0,0,0,3,14.5H13A1.5,1.5,0,0,0,14.5,13V3A1.5,1.5,0,0,0,13,1.5Z" /><path d="M10.712,11.687a.752.752,0,0,1-.52-.21L8.23,9.588,6.268,11.477A.75.75,0,0,1,5,10.937v-9.6a.75.75,0,0,1,.75-.75h4.964a.75.75,0,0,1,.75.75v9.6a.75.75,0,0,1-.75.75M8.23,7.8a.751.751,0,0,1,.52.209L9.962,9.173V2.087H6.5V9.173L7.71,8.006A.751.751,0,0,1,8.23,7.8" /></g></g></g></svg>
                                    </div>
                                    <div className="py-2">Activities</div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-header__menu-item ">
                                <NavLink to="/messages" className='ebw-header__menu-link d-flex align-items-center'>
                                    {/* <span className="icon-my-projects ebw-font__size-xl pr-1"></span> */}
                                    <div className="ebw-header__menu-svg path pr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10447 21841)"><g transform="translate(10447.791 -21840)"><g className="d"><path d="M15.2,15.752a1.209,1.209,0,0,1-.871-.366l-2.652-2.65a.179.179,0,0,0-.128-.054H2.614A2.617,2.617,0,0,1,0,10.068V2.614A2.617,2.617,0,0,1,2.614,0h11.18a2.617,2.617,0,0,1,2.614,2.614l.008,11.919A1.21,1.21,0,0,1,15.2,15.752m-.285-1.217v0Zm.476-.208.005.005-.005-.005M2.614,1.5A1.115,1.115,0,0,0,1.5,2.614v7.454a1.115,1.115,0,0,0,1.114,1.114H11.55a1.674,1.674,0,0,1,1.189.493l2.177,2.177L14.908,2.614A1.116,1.116,0,0,0,13.794,1.5Zm9.322,6.523h-.005a1.685,1.685,0,1,1,.005,0m0-1.864a.182.182,0,1,0,0,.364v0a.182.182,0,1,0,0-.364M8.2,8.023A1.682,1.682,0,1,1,9.886,6.341,1.684,1.684,0,0,1,8.2,8.023m0-1.864a.182.182,0,0,0-.182.182c0,.2.364.2.364,0A.183.183,0,0,0,8.2,6.159M4.477,8.023A1.682,1.682,0,1,1,6.159,6.341,1.684,1.684,0,0,1,4.477,8.023m0-1.864a.182.182,0,0,0-.182.182c0,.2.363.2.364,0a.183.183,0,0,0-.182-.182" /></g></g></g></svg>
                                    </div>
                                    <div className="py-2">Messages</div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-header__menu-item ebw-header__menu-projects">
                                <NavLink to="/my-projects" className='ebw-header__menu-link d-flex align-items-center'>
                                    {/* <span className="icon-my-projects ebw-font__size-xl pr-1"></span> */}
                                    <div className="ebw-header__menu-svg path pr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10468 21841)"><g transform="translate(10468.691 -21839.223)"><g className="d"><path d="M13.617,9.616H10a3,3,0,0,1-3-3V3a3,3,0,0,1,3-3h3.616a3,3,0,0,1,3,3V6.616a3,3,0,0,1-3,3M10,1.5A1.5,1.5,0,0,0,8.5,3V6.616a1.5,1.5,0,0,0,1.5,1.5h3.616a1.5,1.5,0,0,0,1.5-1.5V3a1.5,1.5,0,0,0-1.5-1.5Z" /><path d="M10.995,6.818a.749.749,0,0,1-.53-.219L9.29,5.425a.75.75,0,1,1,1.06-1.062l.645.644,1.869-1.869A.75.75,0,1,1,13.925,4.2l-2.4,2.4a.744.744,0,0,1-.53.22" /><path d="M4.76,7.449A2.241,2.241,0,1,1,7,5.208,2.244,2.244,0,0,1,4.76,7.449m0-2.982a.741.741,0,1,0,.741.741.743.743,0,0,0-.741-.741" /><path d="M4.761,9.211A3.264,3.264,0,0,0,1.5,12.3a3.033,3.033,0,0,0,.949.384,9.515,9.515,0,0,0,2.307.262,9.515,9.515,0,0,0,2.307-.262,3.033,3.033,0,0,0,.949-.384A3.264,3.264,0,0,0,4.761,9.211m0-1.5a4.761,4.761,0,0,1,4.761,4.761C9.522,15.1,0,15.1,0,12.472A4.761,4.761,0,0,1,4.761,7.711" /></g></g></g></svg>
                                    </div>
                                    <div className="py-2">My Projects</div>
                                </NavLink>
                            </NavItem>

                        </Nav>

                        <div className="ebw-header__rgt d-none d-xl-flex align-items-center">
                            <NavItem className="ebw-header__menu-item">
                                <a role="button" ddNewService className="py-1 d-flex align-items-center" onClick={() => setAddNewService(true)}>
                                    <img className="ebw-header__menu-icon--video" src="/images/icons/services-notification.svg" />
                                </a>
                            </NavItem>
                            {video_url && video_url.length > 0 &&
                                <NavItem className="ebw-header__menu-item">
                                    <div className="py-1 d-flex align-items-center" onClick={() => setPlayVideo(true)}>
                                        <span className="ebw-icon ebw-icon--xl ebw-header__menu-icon">
                                            <img className="ebw-header__menu-icon--video" src="/images/icons/bulp.svg" />
                                        </span>
                                    </div>
                                    {/* </a> */}
                                </NavItem>
                            }

                            <NavItem className="ebw-header__menu-item">
                                <a target="_blank" href="https://easybids.com/service-provider" className='ebw-header__menu-link'>
                                    <div className="py-1 d-flex align-items-center">
                                        <span className="ebw-header__menu-dwdtxt d-none d-xl-block">Download the app</span>
                                        <span className="ebw-icon ebw-icon--xl icon-mobile ebw-header__menu-icon"></span>
                                    </div>
                                </a>
                            </NavItem>

                            <NavItem className="ebw-header__menu-item">
                                <NavLink to="/Support" className='ebw-header__menu-link'>
                                    <div className="py-1">
                                        <span className="ebw-icon ebw-icon--xl icon-support ebw-header__menu-icon"></span>
                                    </div>
                                </NavLink>
                            </NavItem>

                            <NavItem className="ebw-header__menu-item">
                                <div className="ebw-header__menu-link">
                                    <NotificationDropdown
                                        notificationData={firstFiveNotificationsData}
                                        notificationLoading={firstFiveNotificationsLoading}
                                        notificationCount={notificationCount}
                                    />
                                </div>
                            </NavItem>
                            {profile && !profile.is_pro_provider &&
                                <NavItem className="ebw-header__menu-item">
                                    <NavLink to="/pro-plus" className='ebw-header__menu-link d-flex flex-column align-items-center'>
                                        <span className="text-xs">Upgrade to</span>
                                        <img src="/images/icons/icon-pro.svg" className="img-fluid d-block w-100 h-100" alt="Pro +" />
                                    </NavLink>
                                </NavItem>}
                            <NavItem className="ebw-header__menu-item">
                                <div className=" ebw-header__profile">
                                    <HeaderDropdown
                                        profileData={profile}
                                        authData={auth.data}
                                    />
                                </div>
                            </NavItem>
                        </div>
                    </Navbar>
                    {/* Navbar Toggler on Left Side */}
                    <MobileHeader
                        notificationData={firstFiveNotificationsData}
                        notificationLoading={firstFiveNotificationsLoading}
                        notificationCount={notificationCount}
                        profileData={profile}
                        authData={auth.data}
                        video_url={video_url}
                    />
                    {/* Fixed Bottom Nav Tabs */}
                    <MobileNavTabs profileData={profile} video_url={video_url} />
                </header>
                <ProjectInConversation />
                <Modal isOpen={playVideo} size="lg" toggle={() => setPlayVideo(false)}>
                    <div className="">
                        {/* <ModalHeader toggle={() => redirectToReview()}></ModalHeader> */}
                        <ModalBody style={{ lineHeight: 0 }} className="p-0">
                            <iframe width="100%" height="500" src={video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </ModalBody>
                    </div>
                </Modal>
                <Modal isOpen={addNewService} toggle={() => setAddNewService(false)}>
                    <div className="px-5 py-5 ebw-modal__wrap">
                        <ModalHeader toggle={() => setAddNewService(false)}></ModalHeader>
                        <ModalBody className="pt-0">
                            <h3 className="text-4xl mb-6">New Services</h3>
                            <div>
                                <NewService />
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
                <Modal isOpen={showPrimary} toggle={() => setShowPrimary(false)}>
                    <div className="px-5 py-5 ebw-modal__wrap">
                        <ModalHeader toggle={() => setShowPrimary(false)}></ModalHeader>
                        <ModalBody className="pt-0">
                            <h3 className="text-4xl mb-6">Quick Actions</h3>
                            <div>
                                <DasboardNotification
                                    className="d-flex flex-column"
                                    notificationLoading={firstFiveNotificationsLoading}
                                    primaryNotifications={primaryNotifications}
                                    toWhere={"inHeader"}
                                    closeNotificationsPopup={closeNotificationsPopup}
                                />
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
                {bookmark.success &&
                    <div style={{ width: "400px", position: 'fixed', top: "90px", right: "10px", zIndex: 11 }}>
                        <ProjectBookmarked
                            additionalClass="es-project__fixed"
                            added={true}
                        />
                    </div>
                }
                {bookmark.deleteSuccess &&
                    <div style={{ width: "400px", position: 'fixed', top: "90px", right: "10px", zIndex: 11 }}>
                        <ProjectBookmarked
                            additionalClass="es-project__fixed"
                            added={false}
                        />
                    </div>
                }
            </>
        );
    } else {

        return (
            <>
                <header className="ebw-header">
                    <Navbar className="ebw-header__inner ebw-header__inner--prelogin d-flex align-items-center py-0">
                        <Nav className="ebw-header__lft d-inline-flex align-items-center">
                            <NavbarBrand className="ebw-header__brandwrap" href="/login">
                                <img className="ebw-header__brand img-fluid mx-auto d-block" src="/images/logo.svg" alt="Logo" />
                            </NavbarBrand>
                        </Nav>
                        <Nav className="ebw-header__rgt d-flex align-items-center ml-auto">
                            <NavItem className="ebw-header__menu-item d-none d-md-block">
                                <NavLink to="/Support" className="ebw-header__menu-link">
                                    <div className="d-flex align-items-center py-2">
                                        <span className="ebw-icon ebw-icon--xl icon-support ebw-header__menu-icon"></span>
                                        <span className="pl-1">Support</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="ebw-header__menu-item">
                                {/* <NavLink exact to="" className=" d-flex align-items-center" > */}
                                <Button color="success" type="submit" onClick={(e) => registeration(e)} text="Register" >Register</Button>
                                {/* </NavLink> */}
                            </NavItem>
                        </Nav>
                    </Navbar>
                    <Modal isOpen={regModal} toggle={toggle} className="ebw-global__modal">
                        <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                            <ModalBody className="ebw-global__modal-body text-center py-6">
                                <h3 className="text-lg mb-6">Are you sure you want to restart your registration?</h3>

                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="">
                                        <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={toggle} >No</Button>
                                    </div>
                                    <div className="col-4">
                                        <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => confirmNewReg()} >Yes</Button>
                                    </div>
                                </div>
                            </ModalBody>
                        </div>
                    </Modal>
                </header>
            </>
        );
    }
}

export default Header;