import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import Stepper from 'react-stepper-horizontal';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Main from '../../layout/main';
import RgisterPersonal from "../../components/register/register-personal";
import RegisterBusiness from "../../components/register/register-business";
import RegisterServices from "../../components/register/register-service";
import RegisterVerification from "../../components/register/register-verification";
import RegisterOtpVerification from "../../components/register/register-otp-verification";
import RgisterPersonalSocial from "../../components/register/socialRegister";
import { LoginRegsiterLeft, RegisterMobileSlider } from "../../components/login/login-left";
import { registerPersonal } from '../../redux/actions/registerpersonal';
import { registerOtp } from '../../redux/actions/register-otp';
import { storeBusiness } from '../../redux/actions/storeBusiness';
import { getServiceTypes } from "../../redux/actions/serviceList";
import { registerBussiness } from "../../redux/actions/registerBusiness";
import { registerAdditional } from "../../redux/actions/registerAdditional";
import { getRefarralList } from "../../redux/actions/refarralList";
import { socialSignup } from "../../redux/actions/socialSignup";
import GoogleLogin from "../../components/social-login/googlelogin";
import FacebookLogin from "../../components/social-login/facebookLogin";
import AppleSignup from "../../components/social-login/appleLogin";


const steps = ["Personal", "Verification", "Business", "Services"];

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepsCompleted: 0,
            personalData: {},
            phone: "",
            otpError: "",
            steps: [
                {
                    title: "Personal",
                    onClick: (e) => {
                        e.preventDefault();
                    }
                },
                {
                    title: "Verification",
                    onClick: (e) => {
                        e.preventDefault();
                    }
                },
                {
                    title: "Business",
                    onClick: (e) => {
                        e.preventDefault();
                    }
                },
                {
                    title: "Services",
                    onClick: (e) => {
                        e.preventDefault();
                    }
                },
                {
                    title: "Additional",
                    onClick: (e) => {
                        e.preventDefault();
                    }
                }
            ],
            // currentStep: 0,
        };
    }
    componentDidMount() {
        this.props.getServiceTypes();
        this.props.getRefarralList();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    handleRegisterPersonal = (data) => {
        const personalData = {
            name: data.name,
            email: data.email,
            mobile_number: data.phone,
            password: data.password,
            password_confirmation: data.confirmPassword,
        }
        const inputData = JSON.stringify({
            sign_up: {
                name: data.name,
                email: data.email,
                mobile_number: data.phone,
                password: data.password,
                password_confirmation: data.confirmPassword,
                user_type: "2"
            }
        });
        this.setState({ personalData })
        this.props.registerPersonal(inputData);
    }
    handeleOtpVerification = (data, confirmation_token) => {
        const { regPersonaldata } = this.props;
        if (data == "") {
            this.setState({ otpError: "Enter the OTP" })
        } else if (data.length < 6) {
            this.setState({ otpError: "Enter 6 digit OTP" })
        } else {
            this.setState({ otpError: "" })
        }
        if (this.state.otpError == "") {

            const inputData = JSON.stringify({
                confirmation_token: confirmation_token,
                otp: data
            });
            // otp Action here
            this.props.registerOtp(inputData);
        }

    }
    handleRegisterBusiness = (data) => {
        this.props.storeBusiness(data)
    }
    handleRegisterServices = (types, subTypes, storeBusiness) => {
        const locationAtt = storeBusiness.provider_locations_attributes;

        let selected_cities = []
        for (let index = 0; index < locationAtt.length; index++) {
            const location = locationAtt[index];
            selected_cities.push({
                location_name: location.cityName,
                city: location.cityName,
                state: location.state,
                state_short: location.state_short,
                country: location.country,
                radius: locationAtt[index].radius
            })
        }
        const data = JSON.stringify({
            provider: {
                company_name: storeBusiness.company_name,
                company_type: storeBusiness.company_type,
                zipcode: storeBusiness.zipcode,
                provider_locations_attributes: selected_cities,
                street_address: storeBusiness.street_address,
                years_of_business: storeBusiness.years_of_business,
                auth_token: Cookies.get('authentication_token'),
                project_type_ids: types,
                project_sub_type_ids: subTypes,
            }
        });
        // {
        //     auth_token: "",
        //     street_address: "",
        //     zipcode: "",
        //     company_name: "",
        //     company_type: "",
        //     project_type_ids: "",
        //     project_sub_type_ids: "",
        //     provider_locations_attributes: "",
        //     years_of_business: "",
        //     }
        // const data = { akhil: "test" }
        this.props.registerBussiness(data);

    }
    handleRegisterVerification = (data) => {
        const verifData = JSON.stringify({
            provider: {
                auth_token: data.auth_token,
                have_insurance: data.insuranceid != "",
                have_license: data.licenceid != "",
                insurance_number: data.insuranceid,
                license_number: data.licenceid,
                google_id: data.googleid,
                yelp_id: data.yelpid,
                referral_ids: data.referral_id
            }
        })
        this.props.registerAdditional(verifData)
    }
    handleRegisterPersonalSocial = (data) => {
        this.props.socialSignup(data);
    }
    render() {
        const { steps } = this.state;
        const { regPersonaldata, regOtpData, serviceTypesData, activeRegistrationStep, refarralList } = this.props;

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Easy Bids Pro Registration</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="register">
                    <section className="ebw-section--register">
                        <Container fluid={true} className="ebw__entry">
                            <Row className="ebw-entry__row h-100">

                                {/* <Col sm="12" md="5" lg="4" className="ebw-entry__col-lft order-2 order-md-1">
                                    <LoginRegsiterLeft
                                        title="Almost there"
                                        text="The go-to tool to find a variety of projects with the liberty to work with your choice of customers!"
                                        imgUrl="./images/login/register.png"
                                    />

                                    <RegisterMobileSlider />
                                </Col> */}

                                <Col sm="12" md="7" xl="5" className="ebw-entry__col h-100 mx-auto py-10 text-center order-1 order-md-2">
                                    <div className="ebw-entry__content-rgt position-relative">
                                        <h2 className="ebw-entry__register-title">Register</h2>

                                        <div className="ebw-entry__register-flow position-relative d-flex flex-column mx-auto justify-content-center">
                                            <ul className="ebw-entry__register-list position-relative d-flex justify-content-between align-items-center mb-6">

                                                <Stepper
                                                    steps={steps}
                                                    activeStep={activeRegistrationStep.activeRegistrationStep - 1}
                                                    completeBarColor="#1875FB"
                                                    defaultColor="#EAEDFF"
                                                    defaultTitleColor="#373737"
                                                />
                                            </ul>
                                            {activeRegistrationStep.activeRegistrationStep === 1 &&
                                                this.props.socialData ? <RgisterPersonalSocial socialData={this.props.socialData} handleRegisterPersonalSocial={this.handleRegisterPersonalSocial} /> : activeRegistrationStep.activeRegistrationStep === 1 && <RgisterPersonal handleRegisterPersonal={this.handleRegisterPersonal} />}

                                            {activeRegistrationStep.activeRegistrationStep === 2 &&
                                                <RegisterOtpVerification
                                                    otpError={this.state.otpError}
                                                    buttonName="Verify"
                                                    mobileNumber={this.state.personalData.mobile_number && this.state.personalData.mobile_number}
                                                    handeleOtpVerification={this.handeleOtpVerification}
                                                />}
                                            {activeRegistrationStep.activeRegistrationStep === 3 &&
                                                <RegisterBusiness handleRegisterBusiness={this.handleRegisterBusiness} />}
                                            {activeRegistrationStep.activeRegistrationStep === 4 &&
                                                <RegisterServices serviceTypesData={serviceTypesData} handleRegisterServices={this.handleRegisterServices} />}
                                            {activeRegistrationStep.activeRegistrationStep === 5 &&
                                                <RegisterVerification handleRegisterVerification={this.handleRegisterVerification} refarralList={refarralList.refarral_list} />}


                                            <div className="ebw-entry__other-options">
                                                <>
                                                    {activeRegistrationStep.activeRegistrationStep < 2 &&
                                                        <>
                                                            <p className="ebw-entry__other-text">
                                                                <span>or</span> <br />
                                                                sign in with
                                                            </p>

                                                            <div className="ebw-entry__social-btns d-md-flex justify-content-center">
                                                                <span>
                                                                    <GoogleLogin />
                                                                </span>
                                                                <span className="ml-5">
                                                                    <FacebookLogin />
                                                                </span>
                                                                <span className="ml-5 ">
                                                                    <AppleSignup />
                                                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                </>

                                                <div className="ebw-entry__other-register mt-6">
                                                    <span className="ebw-entry__other-account">Already have an account?</span>
                                                    <Link to="/login" className="ebw-entry__other-create ml-1">Login</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Main>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    regPersonaldata: state.registerPersonal,
    regOtpData: state.registerOtp,
    BussData: state.storeBusiness,
    serviceTypesData: state.serviceTypes,
    activeRegistrationStep: state.registrationStep,
    refarralList: state.refarralList,

});
export default connect(mapStateToProps, {
    registerPersonal, registerOtp, storeBusiness, getServiceTypes, registerBussiness, registerAdditional, getRefarralList, socialSignup
})(Register);