import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import history from '../../common/history';
import axios from 'axios';
import { setAuth } from '../../utils';

const apiUrl = API.baseURL + `/web_login_with_otp`;
async function postApi(data) {
    try {
        const response = axios.post(apiUrl, data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* login(action) {
    try {
        const auth = yield call(postApi, action.data);
        if (auth.data.confirmation_token || auth.data.signup_status || auth.data.verification_status) {
            setAuth(auth.data.data, auth.headers);
            // toastr.success('Login Success', 'You are successfully logged in');

            history.push('/dashboard');
            window.location.reload();
        } else {
            // toastr.error('Login failed', 'Sorry unable to login successfully');
        }
        // history.push('/');
        // yield put({ type: 'POST_LOGIN_SUCCESS', auth: auth });
        yield put({ type: 'STORE_SET_AUTH_SUCCESS', auth: auth.data });
        yield put({ type: 'POST_OTP_LOGIN_CONFIRM_SUCCESS', auth: auth });
    } catch (e) {
        // toastr.error('Sorry', 'Unable to login');
        yield put({ type: 'POST_OTP_LOGIN_CONFIRM_FAILED', message: e.response.data.errors[0] });
    }
}

function* loginOtpSecondStepSaga() {
    yield takeEvery('POST_OTP_LOGIN_CONFIRM_REQUESTED', login);
}

export default loginOtpSecondStepSaga;