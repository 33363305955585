import React, { useState, useEffect } from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Player } from 'video-react';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';
import SwiperCore, { Navigation, Thumbs } from 'swiper/core';
import options from '../../values/imageViewerOptions';
import 'swiper/swiper.scss';
import LighrBoxCmn from "../common/lightBox";


// Swiper's Thumbs component
SwiperCore.use([Navigation, Thumbs]);


const ProjectsSlider = (props) => {
    console.log(props.projectFiles, "props.projectFiles in the page image viewer")
    // const { files, videos } = props;
    let files = [];
    let allImages = [];
    props.files && props.files.forEach(file => {
        files.push(file);
        allImages.push(file.url);
    })
    props.projectFiles && props.projectFiles.forEach(file => {
        files.push(file);
        allImages.push(file.document.url);
    })


    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const videoFormats = ["mp4", "wmv", "avi", "flv", "mkv", "mov",]
    const [mainImg, setMainImg] = useState(files && files.length != 0 ? videoFormats.includes(files[0].document.url.split('.').pop()) ? "/images/projects/project-details.png" : files[0].document.url : "/images/projects/project-details.png");
    const [selectedImage, setSelectedImage] = useState(0);
    const selectImg = function (e, i) {
        setMainImg(e.document.url);
        setSelectedImage(i);
    }
    const closeViewer = () => {
        setIsopen(false);
    }
    const [videoPlayerOn, setVideoPlayerOn] = useState(false);
    const [currentVideo, setCurrentVideo] = useState("");
    const [isOpen, setIsopen] = useState(false);

    const getImage = (data) => {
        console.log(data, "data in the get image--------------------------");
        const extension = data.split(/[#?]/)[0].split('.').pop().trim();
        console.log(extension, "extension extension")
        if (videoFormats.includes(extension)) {
            return "/images/video-thumbnail.png"
        } else {
            return data
        }
    }
    const definePlayer = (data) => {
        console.log(data, "definePlayer data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
        const extension = data.split('.').pop();
        console.log(extension, "extension extension definePlayer")
        if (videoFormats.includes(extension)) {
            setVideoPlayerOn(true);
            setCurrentVideo(data);
        } else {
            setIsopen(true);
        }
        return;
    }
    const toggleModal = () => {
        setVideoPlayerOn(!videoPlayerOn);
    }

    useEffect(() => {
        setTimeout(() => {
            setMainImg(files && files.length != 0 ? videoFormats.includes(files[0].document.url.split('.').pop()) ? files[0].document.url : files[0].document.url : "/images/projects/project-details.png");
        }, 2000);
    }, [props.projectFiles]);
    const getTheUrl = (data, thumb) => {
        if (files && files.length != 0) {
            if (videoFormats.includes(thumb.document.url.split('.').pop())) {
                return "/images/video-thumbnail.png"
            } else {
                return thumb.document.url
            }
        } else {
            return "/images/projects/project-details.png"
        }
    }
    console.log(mainImg, "mainImg in the current")
    console.log(files && files.length != 0, "files length in the current")
    return (
        <>
            < div className=" ebw-project__main-slider--img" >
                < img onClick={() => definePlayer(mainImg)} src={files.length != 0 ? getImage(mainImg) : "/images/projects/project-details.png"} alt="" className="img-fluid d-block mx-auto" />
            </div >
            < Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={20}
                slidesPerView='auto'
                freeMode={true}
                watchSlidesVisibility
                watchSlidesProgress
                className="ebw-project__thumb-swiper"
            >

                {files && files.length != 0 && files.map((thumb, index) => (
                    <SwiperSlide key={index} className={`ebw-project__thumb-slider ${mainImg == thumb.document.url ? "img-active" : ""}`}>
                        <div className="ebw-project__thumb-slider--img">
                            <img onClick={() => selectImg(thumb, index)}
                                src={getTheUrl(files, thumb)}
                                alt="IMG" className="img-fluid d-block" />
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper >
            <Modal className="ebw-modal__videoplayer-wrap d-flex align-items-center" isOpen={videoPlayerOn} toggle={toggleModal}>
                <div className="ebw-modal__videoplayer-modal">
                    <div className="p-0">
                        <ModalHeader toggle={toggleModal} className="ebw-profile__modal-head pb-0" style={{ border: "none", background: "none", color: "var(${--color-white})" }}></ModalHeader>
                        <ModalBody className="ebw-modal__body p-0">
                            <div className="ebw-modal__videoplayer">
                                <Player>
                                    <source src={currentVideo} />
                                </Player>
                            </div>
                        </ModalBody>
                    </div>
                </div>
            </Modal>
            {
                isOpen && allImages.length != 0 &&
                <LighrBoxCmn closeViewer={() => closeViewer()} photoIndex={selectedImage} images={allImages} />
            }
        </>
    );
}
export default ProjectsSlider;