import * as type from './types';

const initialState = {
    xAvailable: [],
    pagination: {},
    files: [],

    loading: false,
    error: null,
    filesLoading: false,
    filesError: null,
}

export default function availableProjects(state = initialState, action) {
    switch (action.type) {
        //||||||||||||||||||||||  PROJECT DETAILS  ||||||||||||||||||||||||
        case type.GET_AVAILABLE_PROJECTS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_AVAILABLE_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                xAvailable: action.data.projects,
                pagination: action.data.pagination,
            }
        case type.GET_AVAILABLE_PROJECTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        //||||||||||||||||||||||  PROJECT FILES  ||||||||||||||||||||||||
        case type.GET_PROJECT_FILES_REQUESTED:
            return {
                ...state,
                filesLoading: true,
            }
        case type.GET_PROJECT_FILES_SUCCESS:
            return {
                ...state,
                files: action.files.media,
                filesLoading: false,
            }
        case type.GET_PROJECT_FILES_FAILED:
            return {
                ...state,
                filesLoading: false,
                filesError: action.message,
            }
        default:
            return state
    }
}