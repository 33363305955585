import Cookies from 'js-cookie';
const API = {
	// baseURL: process.env.REACT_APP_API_URL,
	// REACT_APP_STRIPE_KEY: "pk_test_5NR4ESvNO9SEVZWrHuzmdnKD",
	wsURL: `wss://api.easybids.com/cable`,
	stripeId: process.env.STRIPE_ID,
	baseURL: "https://api.easybids.com",
	reviewUrl: "http://easybids.com/provider-rating",
	// baseURL: "https://api.easybids.com",
	// wsURL: "wss://api.easybids.com/cable",
	options: {
		// headers: {
		// 	'Accept': 'application/json',
		// 	'Content-Type': 'application/json',
		// 	'access-token': localStorage.getItem('access_token'),
		// 	'uid': localStorage.getItem('uid'),
		// 	'client': localStorage.getItem('client'),
		// 	'is_provider': 'true',
		// }
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'access-token': Cookies.get('access_token'),
			'uid': Cookies.get('uid'),
			'client': Cookies.get('client'),
			'is_provider': 'true',
		}
	},
	fileOptions: {
		headers: {
			'Content-Type': 'multipart/form-data',
			'access-token': Cookies.get('access_token'),
			'uid': Cookies.get('uid'),
			'client': Cookies.get('client'),
			'is_provider': 'true',
		}
	},
	endPoints: {
		"dashboard": "/v2/providers/dashboard",
		"projects": "/v2/providers/projects",
		"addBookMark": "/v2/providers/projects/",
		"removeBookMark": "/v2/providers/projects/",
		"getBookMark": "/v2/providers/bookmark",
		"projectDetails": "/v2/providers/projects/",

		"activitiesAll": "/v2/providers/activities/all",
		"activitiesInBidding": "/v2/providers/activities/in_bidding",
		"activitiesInConversation": "/v2/providers/activities/in_conversation",
		"activitiesBookmark": "/v2/providers/activities/bookmark",

		"myProjectsAll": "/v2/providers/my_projects/all",
		"myProjectsInProcessing": "/v2/providers/my_projects/in_processing",
		"myProjectsCancelled": "/v2/providers/my_projects/cancelled",
		"myProjectsHistory": "/v2/providers/my_projects/history",

		"ignoreProject": "/v2/providers/projects/",
		"submitBid": "/v2/providers/projects/",
		"editBid": "/v2/providers/projects/",
		"deleteBid": "/v2/providers/projects/",
		"primaryNotifications": "/v2/providers/notifications"

	}
}
export default API;