export const ADD_BOOKMARK_REQUESTED = 'ADD_BOOKMARK_REQUESTED';
export const ADD_BOOKMARK_SUCCESS = 'ADD_BOOKMARK_SUCCESS';
export const ADD_BOOKMARK_FAILED = 'ADD_BOOKMARK_FAILED';

export const DELETE_BOOKMARK_REQUESTED = 'DELETE_BOOKMARK_REQUESTED';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_FAILED = 'DELETE_BOOKMARK_FAILED';

export const GET_BOOKMARKED_REQUESTED = 'GET_BOOKMARKED_REQUESTED';
export const GET_BOOKMARKED_SUCCESS = 'GET_BOOKMARKED_SUCCESS';
export const GET_BOOKMARKED_FAILED = 'GET_BOOKMARKED_FAILED';

export const CLEAR_POP_BOOKMARK_REQUESTED = 'CLEAR_POP_BOOKMARK_REQUESTED';
export const CLEAR_POP_BOOKMARK_SUCCESS = 'CLEAR_POP_BOOKMARK_SUCCESS';
export const CLEAR_POP_BOOKMARK_FAILED = 'CLEAR_POP_BOOKMARK_FAILED';