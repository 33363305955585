import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

function* addBookmark() {
    yield put({ type: 'CLEAR_POP_BOOKMARK_SUCCESS' });
}

function* clearPopBookmarkSaga() {
    yield takeEvery('CLEAR_POP_BOOKMARK_REQUESTED', addBookmark);
}

export default clearPopBookmarkSaga;