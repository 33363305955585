import React from "react";
import OtpInput from 'react-otp-input';

const OtpInputNumber = (props) => {
    return(
        <>
            <div className="ebw-entry__otp">
                <div className="ebw-entry__otp-title  text-left mb-3"> Enter 6 digit OTP</div>

                <OtpInput
                    value={ props.value}
                    onChange={props.changeEvent}
                    numInputs={6}
                    inputStyle="ebw-form__input-otp"
                />
                {props.otpError ? 
                    <p className="text-sm mt-2" style={{color:"var(--color-danger)"}}>{props.otpError}</p>
                    : ""
                }
            </div>
        </>
    )
}

export default OtpInputNumber;