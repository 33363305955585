import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../API';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';


async function getApi(data) {

    const apiUrl = API.baseURL + `/album/${data.id}`;
    try {
        const response = axios.put(apiUrl, data.data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* myWorksAlbumRename(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.data.code == 200 || response.data.code == 201) {
            // toastr.success('Success', 'Album renamed successfully');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to update the name', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'RENAME_MY_WORKS_ALBUM_SUCCESS', data: response.data.data });
    } catch (e) {
        yield put({ type: 'RENAME_MY_WORKS_ALBUM_FAILED', message: e.message });
    }
}

function* myWorksAlbumRenameSaga() {
    yield takeEvery('RENAME_MY_WORKS_ALBUM_REQUESTED', myWorksAlbumRename);
}

export default myWorksAlbumRenameSaga;