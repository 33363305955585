import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';

import Main from '../../layout/main'


const TermsConditions = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms and Conditions</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="terms-conditions pb-0">
                <section className="ebw-section ebw-section--terms">
                    <div className="ebw-terms__inner">
                        <Container>
                            <Row>
                                <Col sm="12">
                                    <h1 className="ebw-support__title ebw-support__title--terms pb-6">Terms & Conditions</h1>
                                    <div className="ebw-support__frequent mt-5">
                                    <div className="ebw-article">
                                        <div className="ebw-article__title">1. Introduction</div>
                                        <div className="ebw-article__subtitle">1.1. About the Company</div>
                                        <div className="ebw-article__content">
                                            <p>Easy Bids, Inc. (“Company”, “we”, “us”, or “our”) offers an online platform for connecting contractors (“Pros”) with consumers (“Consumers”) seeking contracting work. The Company provides Consumers with access to a network of Pros using a blind online auction process in which Consumers create and post projects on which Pros can bid (the “Services”). We may provide the Services through our website, mobile application(s), software, processes, and other means (collectively, the “Platform”). These Terms of Use govern your access to and use of the Platform and the Services offered by us.</p>
                                            <p>All references to “you” or “your,” means each person who accesses or visits the Platform and/or utilizes the Services as a registered Pro or Consumer (Pros and Consumers together are referred to as “Members”). If you use the Platform or the Services on behalf of an entity, you represent and warrant that you have the authority to bind that entity, your acceptance of the Terms will be deemed an acceptance by that entity, and “you” and “your” will refer to that entity. Use of the Services is available only to individuals who are at least 18 years old and can form legally binding contracts under applicable law. By using the Services, you represent and warrant that you are eligible.</p>
                                            <p>The Company is not a contractor, and does not offer contractor services. The Pros who access or utilize the Platform are not Company employees. The Company does not guarantee results of any work performed by Pros. At no time will the Company be liable for the acts or omissions of any of its Members, whether Pro or Consumer. Work that Consumers have performed by a Pro is done solely at the Consumer’s own risk.</p>
                                            <p>The Company does not endorse, vouch for, or sanction any of its Members. Reviews and/or ratings provided on the Platform are generated by third parties (such as Google or Yelp) or by other Platform Members. The Company makes no representations about the qualifications of any Member utilizing the Platform.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">1.2. Accessing the Platform and Account Security </div>
                                        <div className="ebw-article__content">
                                            <p>We reserve the right to withdraw or amend the Platform or the Services we provide on the Platform, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Platform, or the entire Platform, to users, including Members.</p>
                                            <p>You are responsible for:</p>
                                            <p>Making all arrangements necessary for you to have access to the Platform. Ensuring that all persons who access the Platform through your internet connection are aware of these Terms of Use and comply with them.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">1.3. Registration</div>
                                        <div className="ebw-article__content">
                                            <p>To access the Services offered through the Platform, you will be asked to provide certain registration details, including your name, email address, mailing address, phone number, payment method, and password. It is a condition of your use of the Platform that all the information you provide on the Platform is correct, current and complete. You agree that all information you provide to register with the Platform is governed by our Privacy Policy [INSERT LINK TO PRIVACY POLICY], and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
                                            <p>If you choose, or are provided with, a password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Platform or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
                                            <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">2. INTELLECTUAL PROPERTY RIGHTS</div>
                                        <div className="ebw-article__subtitle">2.1. General</div>
                                        <div className="ebw-article__content">
                                            <p>The Platform and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), except Member Contributions (defined in Section 4), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">2.2. Prohibitions</div>
                                        <p>You must not:</p>
                                        <div className="ebw-article__content">
                                            <p>Modify any materials from the Platform that are not your own Member Contribution. Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text. Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Platform. Access or use for any commercial purposes any materials available through the Platform other than in connection with posted projects. If you wish to make any use of material on the Platform other than that set out in this section, please address your request to: [support@easybids.com].</p>
                                            <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Platform in breach of the Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Platform or any content on the Platform is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Platform not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">2.3. Trademarks</div>
                                        <p>You must not:</p>
                                        <div className="ebw-article__content">
                                            <p>The Company name, the Company logo, and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use the Company name, the Company logo, or any related names, logos, product and service names, designs and slogans without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on the Platform are the trademarks of their respective owners.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">3. PROHIBITED USES</div>
                                        <div className="ebw-article__content">
                                            <p>You may use the Platform only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Platform:</p>
                                            <p>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</p>
                                            <p>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</p>
                                            <p>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards (Section 4.2) set out in these Terms of Use.</p>
                                            <p>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter" or "spam" or any other similar solicitation.</p>
                                            <p>To impersonate or attempt to impersonate the Company, a Company employee, another Member or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</p>
                                            <p>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Platform, or which, as determined by us, may harm the Company or Members of the Platform or expose them to liability.</p>
                                            <p>Additionally, you agree not to:</p>
                                            <p>Use the Platform in any manner that could disable, overburden, damage, or impair the Platform or interfere with any other party's use of the Platform, including their ability to engage in real time activities through the Platform. Use any robot, spider or other automatic device, process or means to access the Platform for any purpose, including monitoring or copying any of the material on the Platform. Use any manual process to monitor or copy any of the material on the Platform or for any other unauthorized purpose without our prior written consent.</p>
                                            <p>Use any device, software or routine that interferes with the proper working of the Platform.</p>
                                            <p>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful. Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Platform, the server on which the Platform is stored, or any server, computer or database connected to the Platform.</p>
                                            <p>Attack the Platform via a denial-of-service attack or a distributed denial-of-service attack. Circumvent or attempt to circumvent the Company’s blind auction process by communicating or collaborating with other Members regarding a project outside the Platform prior to a winning bid being awarded. Otherwise attempt to interfere with the proper working of the Platform.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">4. MEMBER CONTRIBUTIONS</div>
                                        <div className="ebw-article__content">
                                            <p>The Platform may contain message boards, chat tools, personal web pages or profiles, forums, bulletin boards and other interactive features (collectively, "Interactive Services") that allow Members to post, submit, publish, display or transmit to other Members ("post") photos, specifications, images and other content or materials about projects (collectively, "Member Contributions") on or through the Platform. All Member Contributions must comply with the Content Standards set out in these Terms of Use.</p>
                                            <p>Any Member Contribution you post to the Platform will be considered non-confidential and non-proprietary. By providing any Member Contribution on the Platform, you grant us and our licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>
                                            <div className="ebw-article__subtitle">You represent and warrant that:</div>
                                            <p>You own or control all rights in and to the Member Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</p>
                                            <p>All of your Member Contributions do and will comply with these Terms of Use. You understand and acknowledge that you are responsible for any Member Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.</p>
                                            <p>We are not responsible, or liable to any third party, for the content or accuracy of any Member Contributions posted by you or any other user of the Platform.</p>
                                            <div className="ebw-article__subtitle">4.1. Monitoring and Enforcement Termination We have the right to:</div>
                                            <p>Remove or refuse to post any Member Contributions for any or no reason in our sole discretion.</p>
                                            <p>Take any action with respect to any Member Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such Member Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Platform or the public or could create liability for the Company.</p>
                                            <p>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</p>
                                            <p>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Platform. Terminate or suspend your access to all or part of the Platform for any or no reason, including without limitation, any violation of these Terms of Use.</p>
                                            <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Platform. YOU WAIVE AND HOLD HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.</p>
                                            <p>However, we do not undertake to review material before it is posted on the Platform, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any Member, Platform visitor, or other third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
                                            <div className="ebw-article__subtitle">4.2. Content Standards</div>
                                            <p>These content standards apply to any and all Member Contributions and use of Interactive Services. Member Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, Member Contributions must not:</p>
                                            <p>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</p>
                                            <p>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</p>
                                            <p>Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</p>
                                            <p>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy [https://easybids.com/privacy].</p>
                                            <p>Be likely to deceive any person. Promote any illegal activity, or advocate, promote or assist any unlawful act. Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person. Impersonate any person, or misrepresent your identity or affiliation with any person or organization. Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising. Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">5. PAYMENTS</div>
                                        <div className="ebw-article__content">
                                            <p>The Company is not a party to any contracts for contracting work; such agreements are solely between Consumers and Pros. Contractor agreements are entered into between the Consumer and Pro after a winning bid is awarded through the Platform and the parties have been connected. Consumer is responsible for all payments for work done in connection with a project; the Company has no responsibility and is not liable to Pros for payment related to any project posted on the Platform.</p>
                                            <p>The Company charges a fee (a “Service Fee”) for each project that results in an awarded bid and a connection between Consumer and Pro, calculated as a percentage of the winning bid amount. Prior to the connection of the Pro and Consumer (and the identity of each party being disclosed to the other), the Company will charge [paying party] the Service Fee via the payment method provided at the time of registration as a Member. Upon completion of the charge, the Company will “connect” the parties and reveal each party’s identity to the other.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">6. RELIANCE ON INFORMATION POSTED</div>
                                        <div className="ebw-article__content">
                                            <p>The Platform includes content provided by third parties, including business review services (for example, Google and Yelp), satellite imagery provider(s), and our payments processor (Stripe). We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties. We do not warrant the accuracy, completeness or usefulness of information presented on or available through the Platform, including Member Contributions (such as dimensions, specifications, or requirements of projects) or information concerning Member profiles (such as ratings, reviews, qualifications, or licenses/permits). Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other Platform visitor or Member, or by anyone who may be informed of any of its contents. All statements and/or opinions expressed in third party materials, are solely the opinions and the responsibility of the person or entity providing those materials.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">7. CHANGES TO THE PLATFORM</div>
                                        <div className="ebw-article__content">
                                            <p>We may update the content on the Platform from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Platform may be out of date at any given time, and we are under no obligation to update such material.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">8. LINKS FROM THE PLATFORM</div>
                                        <div className="ebw-article__content">
                                            <p>If the Platform contains links to other websites and resources provided by third parties, these links are provided for your convenience only. This includes any links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those websites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to The Platform, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">9. GEOGRAPHIC RESTRICTIONS</div>
                                        <div className="ebw-article__content">
                                            <p>The owner of the Platform is based in the state of California in the United States. We provide the Platform for use only by persons located in the United States. We make no claims that the Platform or any of its content is accessible or appropriate outside of the United States. Access to the Platform may not be legal by certain persons or in certain countries. If you access the Platform from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">10. DISCLAIMER OF WARRANTIES</div>
                                        <div className="ebw-article__content">
                                            <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Platform will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Platform for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PLATFORM OR ANY OF OUR SERVICES , OR ON ANY WEBSITE LINKED TO IT.</p>
                                            <p>YOUR USE OF THE PLATFORM, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM IS AT YOUR OWN RISK. THE PLATFORM, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE PLATFORM. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE PLATFORM, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                                            <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
                                            <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">11. LIMITATION ON LIABILITY</div>
                                        <div className="ebw-article__content">
                                            <p>IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PLATFORM, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE PLATFORM OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>
                                            <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">12. INDEMNIFICATION</div>
                                        <div className="ebw-article__content">
                                            <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Platform, including, but not limited to, your Member Contributions, any use of our Services other than as expressly authorized in these Terms of Use or your use of any information obtained from the Platform.</p>
                                        </div>
                                    </div>

                                    <div className="ebw-article">
                                        <div className="ebw-article__title">13. MISCELLANEOUS</div>
                                        <div className="ebw-article__subtitle">13.1. Governing Law and Jurisdiction</div>
                                        <div className="ebw-article__content">
                                            <p>All matters relating to the Platform and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).</p>
                                            <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Platform shall be instituted exclusively in the federal courts of the United States or the courts of the State of California although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">13.2. Waiver and Severability</div>
                                        <div className="ebw-article__content">
                                            <p>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
                                            <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">13.3. Entire Agreement</div>
                                        <div className="ebw-article__content">
                                            <p>The Terms of Use, our Privacy Policy and End User License Agreement constitute the sole and entire agreement between you and Easy Bids, Inc. with respect to the Platform and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Platform.</p>
                                        </div>
                                        <div className="ebw-article__subtitle">13.4. Changes to the Terms of Use</div>
                                        <div className="ebw-article__content">
                                            <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Platform thereafter. However, any changes to the dispute resolution provisions in the Governing Law and Jurisdiction section below will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Platform.</p>
                                            <p>Your continued use of the Platform following the posting of revised Terms of Use means that you accept and agree to the changes.</p>
                                        </div>
                                    </div>




                                        {/* <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">General T&C</p>
                                            <p className="ebw-support__frequent-ans">
                                                Modification of these Conditions of Use, Jurisdiction, and applicable Law
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                These conditions of use may be modified by the publisher at any time. The conditions of use applicable to any use of the site are those available and published on the site at the time it is consulted by the user. The nullity or non-validity of a provision of these conditions of use shall not affect the validity of the remaining provisions of these conditions.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">Disclaimer</p>
                                            <p className="ebw-support__frequent-ans">
                                                Although all information on the site has been checked thoroughly, the user should not take the accuracy of the information for granted. In any case, the publisher cannot be held responsible for the accuracy of the information provided nor give a guarantee that the details are complete, accurate and current. All information provided is subject to modification, error or omission.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                The publisher also disclaims all responsibility for contents appearing on third parties web sites to which the site makes hyperlinks or from which it receives hyperlinks.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                Finally, the publisher cannot be held liable for and makes no warranty in the event of interruption or malfunction of the site. The publisher cannot be held responsible for direct or indirect damage incurred by the user as a result of access to the site and its use.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">Data Protection and Privacy Policy</p>
                                            <p className="ebw-support__frequent-ans">
                                                Modification of these Conditions of Use, Jurisdiction, and applicable Law
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                These conditions of use may be modified by the publisher at any time. The conditions of use applicable to any use of the site are those available and published on the site at the time it is consulted by the user. The nullity or non-validity of a provision of these conditions of use shall not affect the validity of the remaining provisions of these conditions.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn">Data Security</p>
                                            <p className="ebw-support__frequent-ans">
                                                The publisher uses Security Sockets Layer (SSL) encryption technology to encrypt sensitive personal information before it travels over the internet.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                The security of personal information is important to the publisher. The publisher follows generally accepted standards to protect the personal information submitted, both during transmission and once the publisher receives it. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, the publisher can never guarantee its absolute security.
                                            </p>
                                            <p className="ebw-support__frequent-ans">
                                                Because of the technical requirement to ensure reliability and security, the publisher may store personal information in locations outside of the publisher’s direct control (for instance, on servers located in data centers, at hosting providers, or on backup servers)
                                            </p>
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default TermsConditions;