import { all } from 'redux-saga/effects';
import { registerOtp } from '../actions/register-otp';
import loginSaga from '../saga/loginSaga';
import setAuthSaga from '../saga/setAuthSaga'
import registerOtpSaga from '../saga/registerOtpSaga';
import registerPersonalSaga from "../saga/registerpersonalSaga";
import userSaga from '../saga/userSaga';
import servedCities from '../saga/servedCitiesSaga';
import storeBusinessSaga from '../saga/storeBusinessSaga';
import serviceTypeList from "../saga/serviceListSaga";
import registerBusinessSaga from "../saga/registerBusinessSaga";
import registerAdditionalSaga from "../saga/registerAdditionalSaga";
import refarralListSaga from "../saga/refarralListSaga";
import dashboardDataSaga from "../reduxes/Dashboard/dashboardDataSaga";
import notificationListSaga from "../saga/notificationListSaga";
import allProjectsSaga from "../saga/allProjectsSaga";
import availableProjectsSaga from "../saga/projectsAvailableSaga";
import inConversationSaga from "../saga/inConversationSaga";
import getProfileDataSaga from "../saga/profileDataSage";
import getEditProfileDataSaga from "../saga/profileEditDataSaga";
import currentProjectSaga from "../reduxes/projectDetails/currentProjectSaga";
import commissionDetailsSaga from "../saga/commissionDetailsSaga";
import submitBidSaga from "../reduxes/bid/submitBidSaga";
import myWorksSaga from "../saga/myWorksSaga";
import myWorksFilesSaga from "../saga/myWorksFilesSaga";
import myWorksNewFolderSaga from "../saga/myWorksNewFolderSaga";
import buyProjectsSaga from "../saga/buyProjectSaga";
import markProjectComplet from "../saga/markCompleteProjectSaga";
import markProjectCancel from "../saga/markCancelProjectSaga";
import getChatSaga from "../saga/getChatSaga";
import deleteMessageSaga from "../saga/deleteMessageSaga";
import sendMessageSaga from "../saga/sendMessageSaga";
import updateEmailSaga from "../saga/updateEmailSaga";
import myWorksAlbumRenameSaga from "../saga/myWorksAlbumRenameSaga";
import myWorksAlbumDeleteSaga from "../saga/myWorksAlbumDeleteSaga";
import albumFIleUploadSaga from "../saga/albumFileUploadSaga";
import updateProfileImageSaga from "../saga/updateProfileImageSaga";
import notificationCountSaga from "../saga/notificationCountSaga";
import categoryFilterSaga from "../saga/categoryFilterSaga";
import selectedTypesSaga from "../saga/selectedTypesSaga";
import socialSignupSaga from "../saga/socialSignupSaga";
import updateProfileSaga from "../saga/updateProfileSaga";
import customerReviewSaga from "../saga/customerReviewsSaga";
import regResendOtpSaga from "../saga/registerResendOtpSaga";
import ignoreThisProjectSaga from "../saga/ignoreThisProjectSaga";
import deleteBidSaga from "../saga/deleteBidSaga";
import loginOtpFirstStepSaga from "../saga/loginWithOtpFirstStepSaga";
import loginOtpSecondStepSagafrom from "../saga/loginWithOtpSecondStepSaga";
import loginOtpResendSaga from "../saga/loginWithOtpResendSaga";
import regEditMobileSaga from "../saga/registrationEditMobileNumberSaga";
import projectVideosSaga from "../saga/projectViseosSaga";
import storeAvailableProjectsSaga from "../saga/availableProjectsSaga";
import firstFiveNotificationsSaga from "../saga/firstFiveNotificationsSaga";
import setAvailableCategoriesSaga from "../saga/setAvailableCategoriesSaga";
import myWorksAlbumFileDeleteSaga from "../saga/deleteMyWorksAlbumfileSaga";
import logoutSaga from "../saga/logoutSaga";
import selectedAllTypesSaga from "../saga/selectedAllServiceSaga";
import setSocialIncompleteSaga from "../saga/socialIncompleteRegSaga";
import setOtpLoginStepSaga from "../saga/loginOtpStepSaga";
import relatedProjectsSaga from "../saga/relatedProjectsSaga";
import newServices from "../saga/newServicesSaga";
import availableProjectsSagaX from "../reduxes/availableProjects/availableProjectSaga";
import projectFilesSagaX from "../reduxes/availableProjects/projectFilesSaga";
import primaryNotificationSaga from "../reduxes/Dashboard/pNotificationSaga";
import allActivitiesSaga from "../reduxes/activities/allSaga";
import bookmarkActivitiesSaga from "../reduxes/activities/bookmarkSaga";
import inBiddingActivitiesSaga from "../reduxes/activities/inBiddingSaga";
import inConversationActivitiesSaga from "../reduxes/activities/inConversationSaga";
import myProjectsAllSaga from "../reduxes/myProjects/allSaga";
import myProjectsCancelledSaga from "../reduxes/myProjects/cancelledSaga";
import myProjectsHistorySaga from "../reduxes/myProjects/historySaga";
import myProjectsInProcessSaga from "../reduxes/myProjects/inProcessSaga";
import commissionPaymentDataSaga from "../reduxes/payment/commissionDetailsSaga";
import addBookmarkSaga from "../reduxes/bookMark/addBookamrkSaga";
import clearPopBookmarkSaga from "../reduxes/bookMark/clearPopBookamrkSaga";
import deleteBookmarkSaga from "../reduxes/bookMark/deleteBookmarkSaga";
import getBookmarkedProjectsSaga from "../reduxes/bookMark/getBookmarkedSaga";
import messagesSaga from "../reduxes/messages/messagesSaga";

export default function* rootSaga() {
  yield all([
    userSaga(),
    loginSaga(),
    registerPersonalSaga(),
    registerOtpSaga(),
    servedCities(),
    storeBusinessSaga(),
    serviceTypeList(),
    registerBusinessSaga(),
    registerAdditionalSaga(),
    refarralListSaga(),
    setAuthSaga(),
    dashboardDataSaga(),
    notificationListSaga(),
    allProjectsSaga(),
    availableProjectsSaga(),
    inConversationSaga(),
    getProfileDataSaga(),
    getEditProfileDataSaga(),
    currentProjectSaga(),
    commissionDetailsSaga(),
    submitBidSaga(),
    myWorksSaga(),
    myWorksFilesSaga(),
    myWorksNewFolderSaga(),
    buyProjectsSaga(),
    markProjectComplet(),
    markProjectCancel(),
    getChatSaga(),
    deleteMessageSaga(),
    sendMessageSaga(),
    updateEmailSaga(),
    myWorksAlbumRenameSaga(),
    myWorksAlbumDeleteSaga(),
    albumFIleUploadSaga(),
    updateProfileImageSaga(),
    notificationCountSaga(),
    categoryFilterSaga(),
    selectedTypesSaga(),
    socialSignupSaga(),
    updateProfileSaga(),
    customerReviewSaga(),
    regResendOtpSaga(),
    ignoreThisProjectSaga(),
    deleteBidSaga(),
    loginOtpFirstStepSaga(),
    loginOtpSecondStepSagafrom(),
    loginOtpResendSaga(),
    regEditMobileSaga(),
    projectVideosSaga(),
    storeAvailableProjectsSaga(),
    firstFiveNotificationsSaga(),
    setAvailableCategoriesSaga(),
    myWorksAlbumFileDeleteSaga(),
    logoutSaga(),
    selectedAllTypesSaga(),
    setSocialIncompleteSaga(),
    setOtpLoginStepSaga(),
    relatedProjectsSaga(),
    newServices(),
    availableProjectsSagaX(),
    projectFilesSagaX(),
    primaryNotificationSaga(),
    allActivitiesSaga(),
    bookmarkActivitiesSaga(),
    inBiddingActivitiesSaga(),
    inConversationActivitiesSaga(),
    myProjectsAllSaga(),
    myProjectsCancelledSaga(),
    myProjectsHistorySaga(),
    myProjectsInProcessSaga(),
    commissionPaymentDataSaga(),
    addBookmarkSaga(),
    clearPopBookmarkSaga(),
    deleteBookmarkSaga(),
    getBookmarkedProjectsSaga(),
    messagesSaga(),
  ])
}
