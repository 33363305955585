import React, { useState } from 'react';
import { Dropdown, DropdownToggle, Collapse, Button, Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import { ignoreProject } from "../../redux/actions/ignoreThisProject";
import { useSelector, useDispatch } from 'react-redux';

const IgnoreProject = ({ project_status, id, bid_amount, consumer_mobile }) => {
    console.log(consumer_mobile, "consumer_mobile bid_amount", id)
    const dispatch = useDispatch();
    const [ingnoreProjectId, setIgnoreProjectId] = useState(null);
    const [ignoreProjectCfmModal, setIgnoreProjectCfmModal] = useState(false);
    const ignoreProjectLoading = useSelector(state => state.ignoreProject.loading);
    const ignoreThisProject = (project_id) => {
        setIgnoreProjectId(project_id);
        setIgnoreProjectCfmModal(true);
    };
    const confirmIgnoreProject = () => {
        console.log("comes inside ignore project", ingnoreProjectId)
        const data = { project_id: ingnoreProjectId };
        dispatch(ignoreProject(data));
    }
    return (
        <>
            {project_status == "2" && bid_amount == "$0.00" && consumer_mobile == null &&
                < div >
                    <Button
                        onClick={() => ignoreThisProject(id)}
                        // onClick={() => reirectPopUp()}
                        disabled={ignoreProjectLoading}
                        className="ebw-button ebw-button__border ebw-button__border--darkgrey py-1 px-3"
                    >
                        Ignore this Project
                        {/* <span className="ebw-prodet__btn-icon icon-arrow-right ebw-icon--lg position-absolute"></span> */}
                    </Button>
                </div>
            }
            <Modal isOpen={ignoreProjectCfmModal} toggle={() => setIgnoreProjectCfmModal(false)} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">Are you sure you want to ignore this project?</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setIgnoreProjectCfmModal(false)} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => confirmIgnoreProject()} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default IgnoreProject;