import React from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import InbiddingLoader from "../../components/dashboard/inbiddingSkeleton";
import {
    Col,
} from 'reactstrap';
import CardSecondary from '../../components/card/card-secondary';
const SuggestedProjects = (props) => {
    const { suggested_projects, suggested_lead_projects, loading, availableProjects } = props;
    return (
        <>
            <section className="ebw-section ebw-section--projects-suggested overflow-hidden">
                {
                    loading ?
                        <Col sm="12" className="text-center">
                            <InbiddingLoader
                            // width={500}
                            // height={100}
                            />
                        </Col>

                        :
                        <>{availableProjects && availableProjects.length != 0 &&
                            <div className=" ebw-projects__suggested container">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="ebw-title__primary">Available Projects</div>
                                    <Link to="/projects/all/1" className="ebw-button__plain ebw-button__plain--green d-flex align-items-center">
                                        <span className="ebw-button__plain-text">More</span>
                                        <i className="ebw-icon ebw-icon--lg icon-arrow-right ebw-slider__navigation-arrow--icon ebw-button__plain-icon"></i>
                                    </Link>
                                </div>
                                <Swiper
                                    loop={true}
                                    autoplay={{ delay: 3000 }}
                                    spaceBetween={15}
                                    slidesPerView="auto"
                                    navigation={{
                                        prevEl: '.prev4',
                                        nextEl: '.next4',
                                    }}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        640: {
                                            width: 640,
                                            slidesPerView: 2,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            width: 768,
                                            slidesPerView: 2,
                                        },
                                    }}
                                    className="ebw-swiper__container"
                                >
                                    {availableProjects && availableProjects.length != 0 && availableProjects.map((projectSuggested, index) => (
                                        <SwiperSlide key={index}>

                                            <CardSecondary
                                                projectName={projectSuggested.name}
                                                projectImage={projectSuggested.project_image_url ? projectSuggested.project_image_url : "./images/dashboard/project-img-1.png"}
                                                projectDate={projectSuggested.created_at}
                                                projectCategory={projectSuggested.project_type.title}
                                                // projectLocation={projectSuggested.city}
                                                projectLinks={`/project-detail/${projectSuggested.id}/projects`}
                                                project_post_type={1}
                                                NoArrow
                                                noLocation={true}
                                                bookmarked_by_current_provider={projectSuggested.bookmarked_by_current_provider}
                                                project_id={projectSuggested.id}
                                                provider_project_details={projectSuggested.provider_project_details}
                                                status={projectSuggested.status}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="ebw-slider__navigation">
                                    <div className="ebw-slider__navigation-arrow ebw-slider__navigation-arrow--prev prev4">
                                        <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon "></i>
                                    </div>
                                    <div className="ebw-slider__navigation-arrow next4 ebw-slider__navigation-arrow--active">
                                        <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon"></i>
                                    </div>
                                </div>
                            </div>
                        }</>

                }
            </section>
            {/* <section className="ebw-section ebw-section--projects-suggested overflow-hidden">
                {
                    loading ?
                        <Col sm="12" className="text-center">
                            <InbiddingLoader
                            // width={500}
                            // height={100}
                            />
                        </Col>

                        :
                        <>{suggested_lead_projects && suggested_lead_projects.length != 0 &&
                            <div className=" ebw-projects__suggested container">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="ebw-title__primary">Suggested Pro+ Lead Projects</div>
                                    <Link to="/buy-projects" className="ebw-button__plain ebw-button__plain--green d-flex align-items-center">
                                        <span className="ebw-button__plain-text">More</span>
                                        <i className="ebw-icon ebw-icon--lg icon-slide-right ebw-slider__navigation-arrow--icon ebw-button__plain-icon"></i>
                                    </Link>
                                </div>
                                <Swiper
                                    loop={true}
                                    autoplay={{ delay: 3000 }}
                                    spaceBetween={15}
                                    navigation
                                    slidesPerView="auto"
                                    navigation={{
                                        prevEl: '.prev2',
                                        nextEl: '.next2',
                                    }}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        640: {
                                            width: 640,
                                            slidesPerView: 2,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            width: 768,
                                            slidesPerView: 2,
                                        },
                                    }}
                                    className="ebw-swiper__container"
                                >
                                    {suggested_lead_projects && suggested_lead_projects.length != 0 && suggested_lead_projects.map((projectSuggested, index) => (
                                        <SwiperSlide key={index}>
                                            <CardSecondary
                                                projectName={projectSuggested.title}
                                                projectImage={projectSuggested.project_img_url ? projectSuggested.project_img_url : "./images/dashboard/project-img-1.png"}
                                                projectDate={projectSuggested.created_at}
                                                projectCategory={projectSuggested.project_type}
                                                projectLocation={projectSuggested.city}
                                                projectLinks={`/project-detail/${projectSuggested.project_id}/${projectSuggested.project_post_type == 1 ? "projects" : "lead_projects"}`}
                                                project_post_type={projectSuggested.project_post_type}
                                                NoArrow

                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="ebw-slider__navigation">
                                    <div className="ebw-slider__navigation-arrow  prev2">
                                        <i className="ebw-icon ebw-icon--xl icon-arrow-left ebw-slider__navigation-arrow--icon "></i>
                                    </div>
                                    <div className="ebw-slider__navigation-arrow next2 ebw-slider__navigation-arrow--active">
                                        <i className="ebw-icon ebw-icon--xl icon-arrow-right ebw-slider__navigation-arrow--icon"></i>
                                    </div>
                                </div>
                            </div>
                        }</>

                }
            </section> */}
        </>
    );
}

export default SuggestedProjects;