import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import { Link } from "react-router-dom";

SwiperCore.use([Autoplay, Pagination]);


export const LoginRegsiterLeft = (props) => {
    return (
        <div className="d-none d-md-block h-100">
            <div className="ebw-entry__content-wrap ">
                <div className="ebw-entry__content-top  d-flex flex-column align-items-start mt-auto">
                    <h1 className="ebw-entry__content-title">{props.title}</h1>
                    <p className="ebw-entry__content-para">
                        {props.text}
                    </p>
                </div>

                <div className="ebw-entry__content-bottom position-relative d-flex flex-column align-items-end mt-auto">
                    <div className="ebw-entry__content-img--screens">
                        <img className="img-fluid d-block mx-auto" src={props.imgUrl} alt={props.title} />
                    </div>

                    {/* <div className="ebw-entry__content-img--bottom">
                        <img className="img-fluid d-block w-100 " src="./images/login/login-mask.png" alt="Login Mask" />
                    </div> */}
                </div>
            </div>
        </div>
    );
}



//- LOGIN SLIDERS FOR MOBILE 
const lsliders = [
    { id: 1, imgUrl: './images/login/1.svg', title: 'A variety of projects to choose from', text: 'The go-to tool to find a variety of projects with the liberty to work with your choice of customers!', link: "https://easybids.com/service-provider" },
    { id: 2, imgUrl: './images/login/2.svg', title: '30% More Jobs!', text: 'Pro+ membership = Higher revenue! Becoming a Pro+ service provider earns you 30% more jobs.', link: "https://easybids.com/service-provider" },
    { id: 3, imgUrl: './images/login/3.svg', title: 'Unlimited project leads', text: 'Find reliable project leads for maximized business opportunities!', link: "https://easybids.com/service-provider" },
]
export const LoginMobileSlider = () => {
    const params = {
        slidesPerView: 1,
        direction: 'horizontal',
        loop: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
        },
    }

    return (
        <div className="ebw-entry__content-slider pt-10 pt-md-0">
            <Swiper
                {...params}
                pagination={{
                    clickable: true,
                    modifierClass: `ebw-entry__login-pagination`,
                    bulletClass: `swiper-pagination-bullet`,
                    renderBullet: (index, className) => {
                        return `<span class="${className} ebw-entry__pagination-bullet"></span>`;
                    }
                }}
                className="ebw-entry__slider-container"
            >
                {lsliders.map(slide => (
                    <SwiperSlide key={slide.id} className="ebw-entry__login-slide">
                        <div className="ebw-entry__content-wrap d-flex flex-column justify-content-start">
                            <div className="ebw-entry__content-img--screens mb-8">
                                <img className="img-fluid d-block mx-auto" src={slide.imgUrl} alt={slide.title} />
                            </div>
                            <div className="ebw-entry__content-top  d-flex flex-column align-items-start">
                                <h1 className="ebw-entry__content-title">{slide.title}</h1>
                                <p className="ebw-entry__content-para mb-4">
                                    {slide.text}
                                </p>
                                <a href={slide.link} target="_blank" className="d-flex align-items-center ebw-entry__content-btn">
                                    <span className="mr-2 ebw-entry__content-btn--text">Learn more</span>
                                    <span className="ebw-icon ebw-icon--l icon-arrow-straight ebw-header__menu-icon ebw-entry__content-btn--icon"></span>
                                </a>
                            </div>
                            {/* <div className="ebw-entry__content-bottom position-relative d-flex flex-column align-items-end mt-auto">
                                <div className="ebw-entry__content-img--screens">
                                    <img className="img-fluid d-block mx-auto" src={slide.imgUrl} alt={slide.title} />
                                </div>

                                <div className="ebw-entry__content-img--bottom ">
                                    <img className="img-fluid d-block w-100 " src="./images/login/login-mask.png" alt="Login Mask" />
                                </div>
                            </div> */}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}




//- REGISTER SLIDERS FOR MOBILE 
const rsliders = [
    { id: 1, imgUrl: './images/login/register.png', title: 'Almost there', text: 'The go-to tool to find a variety of projects with the liberty to work with your choice of customers!' },
    { id: 2, imgUrl: './images/login/register.png', title: 'Almost there', text: 'The go-to tool to find a variety of projects with the liberty to work with your choice of customers!' },
    { id: 3, imgUrl: './images/login/register.png', title: 'Almost there', text: 'The go-to tool to find a variety of projects with the liberty to work with your choice of customers!' },
]
export const RegisterMobileSlider = () => {
    const params = {
        slidesPerView: 1,
        direction: 'horizontal',
        loop: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
        },
    }

    return (
        <div className="d-block d-lg-none ebw-entry__content--login">
            <Swiper
                {...params}
                pagination={{
                    clickable: true,
                    modifierClass: `ebw-entry__register-pagination`,
                    bulletClass: `swiper-pagination-bullet`,
                    renderBullet: (index, className) => {
                        return `<span className="${className} ebw-entry__register-bullets"></span>`;
                    }
                }}
                className="ebw-entry__register-container"
            >
                {rsliders.map(slide => (
                    <SwiperSlide key={slide.id} className="ebw-entry__register-slide">
                        <div className="ebw-entry__content-wrap d-flex flex-column justify-content-start">
                            <div className="ebw-entry__content-top  d-flex flex-column align-items-start">
                                <h1 className="ebw-entry__content-title">{slide.title}</h1>
                                <p className="ebw-entry__content-para">
                                    {slide.text}
                                </p>
                            </div>

                            <div className="ebw-entry__content-bottom position-relative d-flex flex-column align-items-end mt-auto">
                                <div className="ebw-entry__content-img--screens">
                                    <img className="img-fluid d-block mx-auto" src={slide.imgUrl} alt={slide.title} />
                                </div>

                                <div className="ebw-entry__content-img--bottom ">
                                    <img className="img-fluid d-block w-100 " src="./images/login/login-mask.png" alt="Login Mask" />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}