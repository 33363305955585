import React from 'react';
// import { Button, Row, Col } from 'react-bootstrap';
import { Button } from 'reactstrap';

const ButtonsPrimary = (props) => {
    
    return (
        <Button
            type={props.type}
            className='ebw-button ebw-button__primary'
            onClick={props.clickEvent}
        >
            {props.text}
        </Button>
    );
}

export default ButtonsPrimary;