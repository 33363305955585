import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { toastr } from 'react-redux-toastr';
import { Link } from "react-router-dom";

import Main from "../../layout/main";
import { LoginRegsiterLeft, LoginMobileSlider } from "../../components/login/login-left";
import ForgortPasswordRequest from "../../components/forgot-password/forgot-password-request";
import ForgotPasswordOTP from "../../components/forgot-password/forgot-password-otp";
import ResetPassword from "../../components/forgot-password/reset-password";
class ForgotPasswordIn extends Component {
    state = {
        activeStep: 0,
        email: '',
    }
    completedForgotPassword = (step, email) => {
        this.setState({ activeStep: step, email: email })
        if (step === 3) {
            // toastr.success('Success', 'Password updated successfully');
        }
    }
    render() {
        return (
            <Main additionalClass_main="login">
                <section className="ebw__section--login h-100">
                    <Container className="ebw__entry ebw-entry__container--login d-md-flex align-items-center">
                        <Row className="ebw-entry__row ebw-entry__row--login">

                            <Col sm="12" md="7" lg="7" className="ebw-entry__col-lft h-100 mx-auto pt-10 pt-md-0 pb-10 pb-md-0">
                                <div className="ebw-entry__content-rgt position-relative">
                                    {this.state.activeStep == 0 && <ForgortPasswordRequest completedForgotPassword={this.completedForgotPassword} />}
                                    {this.state.activeStep == 1 && <ForgotPasswordOTP completedForgotPassword={this.completedForgotPassword} email={this.state.email} />}
                                    {this.state.activeStep == 2 && <ResetPassword completedForgotPassword={this.completedForgotPassword} email={this.state.email} />}
                                    <div className="ebw-entry__other-options text-center">
                                        <div className="ebw-entry__other-register mt-6">
                                            <span className="ebw-entry__other-account">Already have an account?</span>
                                            <Link to="/login" className="ebw-entry__other-create ml-1">Login</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="5" lg="5" className="ebw-entry__col-rgt">
                                <div className="ebw-entry__content-lft">
                                    <LoginMobileSlider />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Main>
            // <Main additionalClass_main="forgot-password">
            //     <section className="ebw__section--login">
            //         <Container fluid={true} className="ebw__entry">
            //             <Row className="ebw-entry__row h-100">
            //                 <Col sm="12" md="5" lg="4" className="ebw-entry__col-lft order-2 order-md-1">
            //                     <LoginRegsiterLeft
            //                         title="Get service providers easier than ever"
            //                         text="The go-to tool to find a variety of projects with the liberty to work with your choice of customers!"
            //                         imgUrl="./images/login/login@2x.png"
            //                     />

            //                     <LoginMobileSlider />
            //                 </Col>

            //                 <Col sm="12" md="7" lg={{ size: "4", offset: "1" }} className="ebw-entry__col-rgt h-100 mx-auto py-10 order-1 order-md-2">
            //                     <div className="ebw-entry__content-rgt position-relative">
            //                         {this.state.activeStep == 0 && <ForgortPasswordRequest completedForgotPassword={this.completedForgotPassword} />}
            //                         {this.state.activeStep == 1 && <ForgotPasswordOTP completedForgotPassword={this.completedForgotPassword} email={this.state.email} />}
            //                         {this.state.activeStep == 2 && <ResetPassword completedForgotPassword={this.completedForgotPassword} email={this.state.email} />}
            //                         <div className="ebw-entry__other-options text-center">
            //                             <div className="ebw-entry__other-register mt-6">
            //                                 <span className="ebw-entry__other-account">Already have an account?</span>
            //                                 <Link to="/login" className="ebw-entry__other-create ml-1">Login</Link>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </Col>
            //             </Row>
            //         </Container>
            //     </section>
            // </Main>
        );
    }
}

export default ForgotPasswordIn;