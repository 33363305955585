import React from "react"
import ContentLoader from "react-content-loader"

const InConversationLoader = (props) => (
    <ContentLoader
        speed={7}
        // width={476}
        // height={210}
        viewBox="0 0 476 210"
        backgroundColor="#E5E5E5"
        foregroundColor="#c9c9c9"
        {...props}
    >
        <rect x="8" y="11" rx="0" ry="0" width="445" height="3" />
        <rect x="9" y="60" rx="0" ry="0" width="445" height="3" />
        <rect x="15" y="20" rx="11" ry="11" width="35" height="35" />
        <rect x="72" y="28" rx="0" ry="0" width="118" height="10" />
        <rect x="73" y="45" rx="0" ry="0" width="215" height="4" />
        <rect x="11" y="141" rx="0" ry="0" width="445" height="3" />
        <rect x="17" y="86" rx="11" ry="11" width="35" height="35" />
        <rect x="74" y="94" rx="0" ry="0" width="118" height="10" />
        <rect x="75" y="110" rx="0" ry="0" width="215" height="4" />
    </ContentLoader>
)

export default InConversationLoader
