import * as type from '../actions/types';

const initialState = {
    loading: false,
    error: null,
}

export default function markcompleteProject(state = initialState, action) {
    switch (action.type) {
        case type.POST_PROJECT_COMPLETED_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.POST_PROJECT_COMPLETED_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case type.POST_PROJECT_COMPLETED_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}