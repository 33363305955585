import React from "react"
import ContentLoader from "react-content-loader"

const LeadPaymentLoader = (props) => (
    <ContentLoader
        speed={2}
        width={700}
        height={400}
        viewBox="0 0 700 400"
        backgroundColor="#9f9d9d"
        foregroundColor="#aaa7a7"
        {...props}
    >
        <rect x="12" y="19" rx="0" ry="0" width="77" height="14" />
        <rect x="101" y="19" rx="0" ry="0" width="65" height="14" />
        <rect x="8" y="96" rx="0" ry="0" width="126" height="27" />
        <circle cx="27" cy="164" r="18" />
        <rect x="51" y="151" rx="0" ry="0" width="90" height="9" />
        <rect x="51" y="165" rx="0" ry="0" width="44" height="10" />
        <rect x="6" y="208" rx="0" ry="0" width="214" height="1" />
        <rect x="7" y="292" rx="0" ry="0" width="214" height="1" />
        <rect x="8" y="220" rx="0" ry="0" width="82" height="10" />
        <rect x="6" y="249" rx="0" ry="0" width="110" height="9" />
        <rect x="7" y="269" rx="0" ry="0" width="147" height="13" />
        <rect x="192" y="267" rx="0" ry="0" width="25" height="15" />
        <rect x="189" y="246" rx="0" ry="0" width="28" height="9" />
        <rect x="185" y="217" rx="0" ry="0" width="34" height="10" />
        <rect x="7" y="305" rx="0" ry="0" width="216" height="8" />
        <rect x="7" y="324" rx="0" ry="0" width="216" height="8" />
        <rect x="7" y="341" rx="0" ry="0" width="110" height="9" />
        <rect x="323" y="93" rx="0" ry="0" width="288" height="3" />
        <rect x="325" y="346" rx="0" ry="0" width="283" height="2" />
        <rect x="331" y="114" rx="0" ry="0" width="132" height="18" />
        <rect x="520" y="111" rx="0" ry="0" width="87" height="18" />
        <rect x="331" y="144" rx="0" ry="0" width="216" height="8" />
        <rect x="331" y="161" rx="0" ry="0" width="110" height="9" />
        <rect x="330" y="184" rx="0" ry="0" width="147" height="13" />
        <rect x="331" y="205" rx="0" ry="0" width="110" height="9" />
        <rect x="329" y="230" rx="0" ry="0" width="275" height="27" />
        <rect x="331" y="268" rx="0" ry="0" width="275" height="27" />
        <rect x="329" y="311" rx="0" ry="0" width="275" height="27" />
    </ContentLoader>
)

export default LeadPaymentLoader
