import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import { BidTypeSelect } from "../inputs/select";
import validate from "./bidFieldsValidator";
import { submitBid } from "../../redux/reduxes/bid/submitBidAction"
import Buttons from "../buttons/button-primary";

const SubmitBid = (props) => {
    const { commissionDetails } = props;
    const dispatch = useDispatch();
    const bidTypes = [
        { label: "Hourly", value: "hourly" },
        { label: "Fixed", value: "fixed" },
    ]
    console.log(props.bid_amount, "props.bid_amount in bid page")
    const [state, setState] = useState({
        bid_amount: props.bid_amount.replace(/[^0-9]/g, "") / 100,
        bid_type: props.bid_type,
        hours: props.hours,
        bid_detail: props.bid_detail,
        amount_receive: 0,
        activeClass: "",

        hoursError: "",
        bidAmountError: "",

        selectedFile: [],
    });

    useEffect(() => {
        setState((prevProps) => ({
            ...prevProps,
            bid_amount: props.bid_amount.replace(/[^0-9]/g, "") / 100,
            bid_type: props.bid_type ? props.bid_type : "fixed",
            hours: props.hours,
            bid_detail: props.bid_detail,
        }));
        if (Number(props.bid_amount.replace(/[^0-9]/g, "") / 100) == 0) {
            setState((prevProps) => ({
                ...prevProps,
                bid_type: "hourly",
                bid_amount: "",
            }));
        }
        commissionCalc(props.bid_type, props.bid_amount.replace(/[^0-9]/g, "") / 100, props.hours);

    }, [])

    const commissionCalc = (bid_type, amount, bid_estimated_hours) => {
        const commission_calc = [...commissionDetails];
        if (amount > 0)
            setState((prevProps) => ({
                ...prevProps,
                activeClass: "active"
            }));
        else {
            setState((prevProps) => ({
                ...prevProps,
                activeClass: "",
                amount_receive: 0
            }));
            return
        }

        var singleCommission
        for (singleCommission of commission_calc) {
            if (amount >= singleCommission.from_bid && amount <= singleCommission.to_bid) {
                const commission = singleCommission.commission
                let bal
                if (bid_type === "hourly") {
                    bal = (amount * bid_estimated_hours) - ((amount * bid_estimated_hours) * (commission / 100))
                } else {
                    bal = amount - (amount * (commission / 100))
                }
                setState((prevProps) => ({
                    ...prevProps,
                    // bid_amount: amount,
                    activeClass: "",
                    amount_receive: bal
                }));
                // this.setState({ amount_receive: bal, bid_amount: amount })
                break;
            }
        }
    }

    const handleInputPriceChange = (event) => {
        let comVal = event.target.value;

        if (comVal < 0) {
            setState((prevProps) => ({
                ...prevProps,
                bid_amount: 0,
            }));
        } else {
            setState((prevProps) => ({
                ...prevProps,
                bid_amount: comVal,
            }));
        }
        setState((prevProps) => ({
            ...prevProps,
            hoursError: "",
            bidAmountError: "",
        }));
        commissionCalc(state.bid_type, event.target.value, state.hours);
    }
    const handleBidTypeChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            bid_type: event.target.value,
            hoursError: "",
            bidAmountError: "",
        }));
        commissionCalc(comVal, state.bid_amount, state.hours);
    }
    const handleInputHoursChange = (event) => {
        let comVal = event.target.value;
        if (comVal < 0) {
            setState((prevProps) => ({
                ...prevProps,
                hours: 0,
            }));
        } else {
            setState((prevProps) => ({
                ...prevProps,
                hours: comVal,
            }));
        }
        setState((prevProps) => ({
            ...prevProps,
            hoursError: "",
            bidAmountError: "",
        }));
        commissionCalc(state.bid_type, state.bid_amount, event.target.value);
    }
    const handleInputDiscriptionChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            bid_detail: event.target.value
        }));
    };
    const checkHourly = (type) => {
        let bidType = true;
        if (type === "hourly") {
            bidType = true
        } else {
            bidType = false
        }
        return bidType;
    }
    const handleSubmit = () => {
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = {
            bid_amount: state.bid_amount,
            bid_detail: state.bid_detail,
            bid_type: state.bid_type,
            bid_estimated_hours: state.hours,
            type: props.edit,
            selectedFile: state.selectedFile,
            project_id: props.project_id,
            providr_bid_id: props.providr_bid_id,
            project_status: props.project_status,
        };
        // {
        //     bid_amount
        //     bid_detail
        //     bid_type
        //     bid_estimated_hours
        //     selectedFile
        //     type
        //     project_id
        //     providr_bid_id
        //     }
        errors.valid && dispatch(submitBid(data));
    }

    const updateProposal = (event) => {
        event.preventDefault();
        const selectedFIles = [...state.selectedFile, ...event.target.files];
        setState((prevProps) => ({
            ...prevProps,
            selectedFile: selectedFIles
        }));
    };
    const removeThisFile = (index) => {
        const selectedRemoved = state.selectedFile.filter((file, i) => i != index)
        setState((prevProps) => ({
            ...prevProps,
            selectedFile: selectedRemoved
        }));
    };
    return (
        <>
            <Row>

                <Col xs="12">
                    <div className="ebw-prodet__subtitle ebw-card__subtitle">Bid amount</div>
                    <FormGroup className="d-flex justify-content-between mb-0">
                        <Col md="6" className="pl-0 mb-4">
                            <Input
                                type="number"
                                name="bid_price"
                                id="bid_price"
                                className="ebw-form__bid-input"
                                placeholder="$ Enter Amount"
                                value={state.bid_amount}
                                error={state.bidAmountError}
                                onChange={handleInputPriceChange}
                            />
                        </Col>

                        <Col md="6" className="pr-0 mb-4">
                            <BidTypeSelect
                                name="BidType"
                                id="register_address"
                                value={state.bid_type}
                                changeEvent={handleBidTypeChange}
                                data={bidTypes}
                            />
                        </Col>
                    </FormGroup>

                    {state.bid_type == "hourly" &&
                        <FormGroup>
                            <Col className="px-0 mb-4">
                                <Input
                                    type="number"
                                    name="hours"
                                    id="hours"
                                    className="ebw-form__bid-input"
                                    placeholder="Enter number of approx. hours"
                                    value={state.hours}
                                    error={state.hoursError}
                                    onChange={handleInputHoursChange}
                                />
                            </Col>
                        </FormGroup>
                    }
                    <Col className="px-0 mb-4">
                        {state.amount_receive != 0 && <div className="ebw-myprojects__inbidding-text">You will get $ {state.amount_receive} after you complete the job</div>}
                    </Col>
                    <FormGroup>
                        <Col className="px-0 mb-4">
                            <Input
                                type="textarea"
                                rows={5}
                                cols={5}
                                name="discription"
                                id="discription"
                                className="ebw-form__bid-input"
                                placeholder="Brief your bid details like cost breakdown, estimated time, human hours."
                                value={(state.bid_detail == "null") ? "" : state.bid_detail}
                                // error={state.hoursError}
                                onChange={handleInputDiscriptionChange}
                            />
                        </Col>
                    </FormGroup>
                    {state.selectedFile.length == 0 &&
                        <div>
                            <label for="attachment">
                                <div className="ebw-project__dropdown-attachment d-flex align-items-center cursor-pointer">
                                    <div className="icon-attachment ebw-icon__size-md ebw-icon__color--blue ebw-para--primary"></div>
                                    <div className="ml-2 ebw-para--primary">Attach Proposal (PDF Only)</div>
                                </div>
                            </label>
                            <input onChange={(e) => updateProposal(e)} className="d-none" accept="application/pdf,application/vnd.ms-excel" type="file" id="attachment" />
                        </div>
                    }
                    {state.selectedFile.length > 0 &&
                        state.selectedFile.map((file, index) => (
                            <div key={index} className="ebw-project__dropdown-file d-flex align-items-start position-relative mt-2">
                                <div className="icon-attachment ebw-para--primary ebw-icon__size-md ebw-icon__color--blue"></div>
                                <div className="ml-2 ebw-para--primary">{file.name}</div>
                                <div onClick={() => removeThisFile(index)} style={{ right: "10px" }} className="icon-close ebw-icon__size-xs ebw-icon__color--red  ebw-para--danger position-absolute"></div>
                            </div>
                        ))
                    }
                </Col>
                <Col xs="12">
                    {state.hoursError ?
                        <div className="ebw-error">{state.hoursError}</div> : ""}
                    {state.bidAmountError ?
                        <div className="ebw-error">{state.bidAmountError}</div> : ""}
                </Col>

                <Col xs="6" md="3" lg="6" xl="5" className="ml-auto mt-3">
                    <Buttons type="submit" text="Submit" clickEvent={handleSubmit} />
                </Col>
            </Row>

        </>
    );
}

export default SubmitBid;