import React, { useState } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlbumFile } from "../../redux/actions/removeAlbumFile";
import { getMyWorksFiles } from "../../redux/actions/myWorksFile";

const ImgDrpDwn = ({ workId, id }) => {
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const deleteFile = (event) => {
        event.preventDefault();
        dispatch(removeAlbumFile(id))
        setTimeout(() => {
            dispatch(getMyWorksFiles(workId));
        }, 2000);
    }
    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => toggle()} className="ebw-uploads__notification-dropdown">
            <DropdownToggle tag="a" role="button" className="ebw-uploads__dropdown-toggle--mywork d-flex justify-content-end p-0 m-0">
                <div className="ebw-uploads__option position-absolute"><span className="ebw-icon ebw-icon--xl icon-more-horizontal"></span></div>
            </DropdownToggle>
            <DropdownMenu right className="ebw-dropdown__menu p-0">
                <DropdownItem className="ebw-dropdown__item mt-0">
                    <div onClick={(e) => deleteFile(e)} className="d-flex align-items-center justify-content-between">
                        <span>Remove</span>
                        <span className="ebw-icon ebw-icon--lg icon-delete"></span>
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default ImgDrpDwn;