import * as type from './types';
const initialState = {
    paymentPageData: {},
    loading: false,
    error: null,
}

export default function payment(state = initialState, action) {
    switch (action.type) {
        case type.GET_COMMISSION_PAYMENT_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_COMMISSION_PAYMENT_DATA_SUCCESS:
            return {
                ...state,
                paymentPageData: action.paymentPageData,
                loading: false,
            }
        case type.GET_COMMISSION_PAYMENT_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}