import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import CardSecondary from '../card/card-secondary';
import { Link } from "react-router-dom";


const YourProjects = (props) => {
    console.log(props, "props in the available page ")
    return (
        <>
            {/* <section className="ebw-section ebw-section--projects-suggested ebw-section--white overflow-hidden"> */}
            {/* <div className=" ebw-projects-suggested container"> */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="ebw-title__primary">Your Projects</div>
                <Link to="/my-projects" className="ebw-button__plain ebw-button__plain--green d-flex align-items-center">
                    <span className="ebw-button__plain-text">Browse all</span>
                    <i className="ebw-icon ebw-icon--lg icon-arrow-right ebw-slider__navigation-arrow--icon ebw-button__plain-icon"></i>
                </Link>
            </div>
            <Swiper
                loop={props.data && props.data.length > 1 ? true : false}
                autoplay={{ delay: 3000 }}
                spaceBetween={15}
                slidesPerView="auto"
                navigation={{
                    prevEl: '.prev2',
                    nextEl: '.next2',
                }}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView: 2,
                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                }}
                className="ebw-swiper__container"
            >
                {props.data && props.data.map((yourProjects, index) => {
                    const { name, project_image_url, created_at, project_type, project_sub_type, city, id, project_id } = yourProjects;
                    return (
                        <SwiperSlide key={index}>
                            <CardSecondary
                                projectName={name}
                                projectImage={project_image_url ? project_image_url : "./images/dashboard/project-img-1.png"}
                                projectDate={created_at}
                                projectCategory={project_type.title}
                                projectLocation={city}
                                projectLinks={`/project-detail/${yourProjects.id}/my_projects`}
                                NoArrow
                                project_post_type={yourProjects.project_post_type}
                                noLocation={true}
                                bookmarked_by_current_provider={yourProjects.bookmarked_by_current_provider}
                                project_id={yourProjects.id}
                                provider_project_details={yourProjects.provider_project_details}
                                status={yourProjects.status}

                            />
                        </SwiperSlide>
                    )
                }
                )}
            </Swiper>
            <div className="ebw-slider__navigation">
                <div className="ebw-slider__navigation-arrow ebw-slider__navigation-arrow--prev prev2">
                    <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon "></i>
                </div>
                <div className="ebw-slider__navigation-arrow next2 ebw-slider__navigation-arrow--active">
                    <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon"></i>
                </div>
            </div>
            {/* </div> */}
            {/* </section> */}
        </>
    );
}

export default YourProjects;