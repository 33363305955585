import React from "react";

const ProjectBookmarked = (props) => {

    return (
        
        <div className={`ebw-card__project-bookmarked ebw-notification__slide ebw-notification__slide--no-anim ebw-notification__slide--hired  d-flex align-items-center ${props.additionalClass}`}>
            <div className="ebw-notification__slide-left">
                <div className="circle circle-no-anim"><img className="img-fluid" src={`/images/icons/bookmark-ico-a.svg`} /></div>
            </div>
            <div className="ebw-notification__slide-right">
                <p className="ebw-notification__slide-heading mb-1">
                    <bold className="ebw-notification__slide-project-name">{props.added ? "Project Bookmarked" : "Deleted Bookmark"}</bold>
                </p>
                <p className="ebw-notification__slide-project ebw-notification__slide-project--full-para"> {props.added ? 'Now you can find this project under the "Activities -> Bookmarks" section' : "Project has been removed from your favourites"} </p>
            </div>
        </div>
    )
}

export default ProjectBookmarked