import React, { useState } from 'react';

const ServiceFeeChart = (props) => {
    const [conditionFee, setConditionFee] = useState(props.open);
    const tabClickFee = () => {
        setConditionFee(!conditionFee);
    }
    return (
        // <Card>
        <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between cursor-pointer" onClick={() => tabClickFee()}>
                <div className="ebw-card__title ebw-card__title-medium d-flex align-items-center ">
                    Easy Bids Service fee
                    <div className="icon-info ml-1"></div>
                </div>
                <span style={{ right: "20px" }} className={`ebw-icon ebw-icon__size-lg position-absolute ${!conditionFee ? "icon-arrow-top" : "icon-arrow-down"} ml-1 ebw-icon__color--primary`}></span>
            </div>
            <div className={`ebw-enteramount__breakdown ebw-project__dropdown ${!conditionFee ? "toggled" : ""}`} >
                <div className="ebw-commission__table ebw-text--S">
                    <div className="ebw-commission__table-row no-border d-flex flex-wrap align-items-center">
                        <div className="ebw-commission__table-col ebw-commission__table-label w-50"><b>Bid cost</b></div>
                        <div className="ebw-commission__table-col ebw-commission__table-label w-50 text-right"><b>Commission</b></div>
                    </div>
                    {props.commission_calc && props.commission_calc.length != 0 && props.commission_calc.map((data, index) => {
                        return (
                            <div key={index} className="ebw-commission__table-row d-flex flex-wrap align-items-center">
                                {data.to_more ?
                                    <div className="ebw-commission__table-col ebw-commission__table-amount w-50">{`More than $${data.to_bid}`}</div>
                                    :
                                    <div className="ebw-commission__table-col ebw-commission__table-amount w-50">{`$${data.from_bid}`} to {`$${data.to_bid}`}</div>
                                }
                                <div className="ebw-commission__table-col ebw-commission__table-percentage w-50 text-right font-bold">{data.commission}%</div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
        // </Card>
    );
}

export default ServiceFeeChart;