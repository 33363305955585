import React from 'react';

const QuestionAnswers = (props) => {
    const { filethumbnail, filename, ProjectQuestion, ProjectAnswer, DimensionTitle, lengthDimension, widthDimension, heightDimension, unit, type, files, ProjectQuestionIndex } = props;
    return (

        type === "dimension" ?
            (
                <div className="">
                    <div className="ebw-prodet__dimension">{DimensionTitle}</div>
                    <div className="d-md-flex align-items-center mt-2">
                        <div className="ebw-prodet__dimension ebw-prodet__dimension-qa">Width :</div>
                        <div className="ebw-prodet__dimension-grey ebw-prodet__dimension-answer">{widthDimension}&nbsp;{unit}</div>
                    </div>
                    <div className="d-md-flex align-items-center mt-2">
                        <div className="ebw-prodet__dimension ebw-prodet__dimension-qa">Height :</div>
                        <div className="ebw-prodet__dimension-grey ebw-prodet__dimension-answer">{heightDimension}&nbsp;{unit}</div>
                    </div>
                    <div className="d-md-flex align-items-center mt-2">
                        <div className="ebw-prodet__dimension ebw-prodet__dimension-qa">Length :</div>
                        <div className="ebw-prodet__dimension-grey ebw-prodet__dimension-answer">{lengthDimension}&nbsp;{unit}</div>
                    </div>
                </div>
            ) :
            (
                <div className="d-md-flex ebw-prodet__qa-inwrap">
                    <div className="ebw-prodet__dimension ebw-prodet__dimension-qa"><span className="mr-2">{ProjectQuestionIndex})</span>{ProjectQuestion}</div>
                    <div className="ebw-prodet__dimension-grey ebw-prodet__dimension-answer pt-1">{ProjectAnswer}</div>
                </div>
            )


    );
}

export default QuestionAnswers;