import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Activities from "./activities";
import { allProjectsData } from "../../redux/actions/allProjects";
import { getProfileEditData } from "../../redux/actions/profileEditData";
import { getAllActivities } from "../../redux/reduxes/activities/allAction";
import { getInbiddingActivities } from "../../redux/reduxes/activities/inBiddingAction";
import { getAllinconversation } from "../../redux/reduxes/activities/inConversationAction";
import { getBookmarkActivities } from "../../redux/reduxes/activities/bookmarkAction";



const ActivitiesPage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state);

    useEffect(() => {
        dispatch(allProjectsData()); //-
        dispatch(getProfileEditData()); //-
        dispatch(getAllActivities());
        dispatch(getInbiddingActivities());
        dispatch(getAllinconversation());
        dispatch(getBookmarkActivities());
    }, [])

    const allActivities = useSelector(state => state.activities.all);
    const inbiddingActivities = useSelector(state => state.activities.inBidding);
    const inConversationActivities = useSelector(state => state.activities.inConversation);
    const bookmarkedActivities = useSelector(state => state.activities.bookmarked);
    const allLoading = useSelector(state => state.activities.allLoading);
    const inbiddingLoading = useSelector(state => state.activities.inbiddingLoading);
    const inConversationLoading = useSelector(state => state.activities.inConversationLoading);
    const bookmarkedLoading = useSelector(state => state.activities.bookmarkedLoading);
    const activities = useSelector(state => state.activities);



    console.log(activities, "activities allActicities allActicities");

    return (
        <>
            <Header />
            <Activities
                allActivities={allActivities}
                inbiddingActivities={inbiddingActivities}
                inConversationActivities={inConversationActivities}
                bookmarkedActivities={bookmarkedActivities}
                allLoading={allLoading}
                inbiddingLoading={inbiddingLoading}
                inConversationLoading={inConversationLoading}
                bookmarkedLoading={bookmarkedLoading}
            />
            <Footer />
        </>
    );
}

export default ActivitiesPage;