import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../API';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';


async function getApi(data) {

    const apiUrl = API.baseURL + `/album/${data.id}/upload_document`;
    try {
        const response = axios.post(apiUrl, data.data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* albumFileUpload(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.data.code == 200 || response.data.code == 201) {
            // toastr.success('Success', 'File uploaded to album');
        } else {
            // response.errors.length != 0 && toastr.error('Failed to upload the file to album', response.errors[0]);
        }
        // history.push('/');
        yield put({ type: 'POST_ALBUM_FILE_SUCCESS', data: response.data.data });
        location.reload()
        //GET_MY_WORKS_FILES_REQUESTED
        // yield put({ type: 'GET_MY_WORKS_FILES_REQUESTED', data: action.data.id });
    } catch (e) {
        yield put({ type: 'POST_ALBUM_FILE_FAILED', message: e.message });
    }
}

function* albumFIleUploadSaga() {
    yield takeEvery('POST_ALBUM_FILE_REQUESTED', albumFileUpload);
}

export default albumFIleUploadSaga;