import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import users from '../reducers/users';
import login from '../reducers/login';
import setAuth from "../reducers/setAuth";
import registerPersonal from '../reducers/registerpersonal';
import registerOtp from "../reducers/register-otp";
import servedCities from "../reducers/servedCities";
import storeBusiness from "../reducers/storeBusiness";
import serviceTypes from "../reducers/serviceList";
import registerBusiness from "../reducers/registerBusiness";
import registerAdditional from "../reducers/registerAdditional";
import getRefarralList from "../reducers/refarralList";
import dashoardData from "../reduxes/Dashboard/dashboardDataReducer";
import getProfileData from "../reducers/profileData";
import getNotificationList from "../reducers/notificationList";
import allProjectsData from "../reducers/allProjects";
import projectsAvailableData from "../reducers/projectsAvailable";
import inConversationData from "../reducers/inConversation";
import setRegStep from "../reducers/activeRegStep";
import getProfileEditData from "../reducers/profileEditData";
import currentProjectData from "../reduxes/projectDetails/currentProjectReducer";
import commissionDetailsData from "../reducers/commissionDetails";
import myWorks from "../reducers/myWorks";
import myWorksFiles from "../reducers/myWorksFiles";
import buyPtojects from "../reducers/buyProjects";
import activateChatBox from "../reducers/activateChatbox";
import currentChat from "../reducers/getChat";
import updateEmail from "../reducers/updateEmail";
import updateEmailOtp from "../reducers/updateEmailOtp";
import getNotificationCount from "../reducers/notificationCount";
import selectedCategoryData from "../reducers/categoryFilter";
import selectedTypes from "../reducers/selectedTypes";
import customerReviews from "../reducers/customerReviews";
import deleteProject from "../reducers/ignoreThisProject";
import deleteBid from "../reducers/deleteBid";
import markcompleteProject from "../reducers/markCompleteProject";
import markCancelProject from "../reducers/markCancelProject";
import loginOtpF from "../reducers/loginOtpFirstStep";
import loginOtpS from "../reducers/loginOtpSecondStep";
import loginOtpResend from "../reducers/loginOtpResendOtp";
import registerEditMobile from "../reducers/registrationEditMobileNumber";
import projectVideoData from "../reducers/projectVideos";
import availableProjectsData from "../reducers/availableProjects";
import firstFiveNotifications from "../reducers/firstFiveNotifications";
import setAvailableCategories from "../reducers/setAvailableCategories";
import logout from "../reducers/logout";
import selectedAllTypes from "../reducers/setSelectAllservice";
import relatedProjects from "../reducers/relatedProjects";
import sendMessage from "../reducers/sendMessage";
import submitBidMessage from "../reducers/submitBid";
import uploadAlbumFile from "../reducers/albumFileUpload";
import newService from "../reducers/getNewServices";
import availableProjects from "../reduxes/availableProjects/availableProjectsReducer";
import activities from "../reduxes/activities/activitiesReducer";
import myProjects from "../reduxes/myProjects/myProjectsReducer";
import payment from "../reduxes/payment/paymentReducer";
import bookmark from "../reduxes/bookMark/bookmarkReducer";
import messages from "../reduxes/messages/messagesReducer";
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'auth',
    "registerPersonal",
    "registerOtp",
    "servedCities",
    "storeBusiness",
    "serviceTypes",
    "registerBusiness",
    "registerAdditional",
    "registrationStep",
    "getProfileEditData",
  ],
};

const rootReducer = combineReducers({

  users: users,
  login: login,
  toastr: toastrReducer,
  registerPersonal: registerPersonal,
  registerOtp: registerOtp,
  servedCities: servedCities,
  storeBusiness: storeBusiness,
  serviceTypes: serviceTypes,
  registerBusiness: registerBusiness,
  registerAdditional: registerAdditional,
  refarralList: getRefarralList,
  auth: setAuth,
  dashoardData: dashoardData,
  profileData: getProfileData,
  notificationList: getNotificationList,
  allProjectsData: allProjectsData,
  projectAvailable: projectsAvailableData,
  conversations: inConversationData,
  registrationStep: setRegStep,
  getProfileEditData: getProfileEditData,
  currentProjectData: currentProjectData,
  commissionDetails: commissionDetailsData,
  myWorks: myWorks,
  myWorksFiles: myWorksFiles,
  buyPtojects: buyPtojects,
  activateChatBox: activateChatBox,
  chat: currentChat,
  updateEmail: updateEmail,
  updateEmailOtp: updateEmailOtp,
  notificationCount: getNotificationCount,
  categoryFilter: selectedCategoryData,
  selectedTypes: selectedTypes,
  customerReviews: customerReviews,
  ignoreProject: deleteProject,
  deleteBid: deleteBid,
  markcompleteProject: markcompleteProject,
  markCancelProject: markCancelProject,
  loginOtpFirstStep: loginOtpF,
  loginOtpSecondStep: loginOtpS,
  loginOtpResendOtp: loginOtpResend,
  registerEditMobile: registerEditMobile,
  projectVideos: projectVideoData,
  availableProjects: availableProjectsData,
  firstFiveNotifications: firstFiveNotifications,
  setAvailableCategories: setAvailableCategories,
  logout: logout,
  relatedProjects: relatedProjects,
  sendMessage: sendMessage,
  submitBidMessage: submitBidMessage,
  uploadAlbumFile: uploadAlbumFile,
  newService: newService,
  availableProjectsX: availableProjects,
  activities: activities,
  myProjects: myProjects,
  commissionPayment: payment,
  bookmark: bookmark,
  messages: messages,
});

export default persistReducer(persistConfig, rootReducer);