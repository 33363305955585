import React from 'react';
import { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';

const Select = (props) => {
    return (
        <FormGroup>
            <Input
                type="select"
                name={props.name}
                id={props.id}
                value={props.value}
                className={`ebw-form__input ebw-form__input-select  ${props.additionalClass}`}
                onChange={props.changeEvent}
                defaultValue={props.value}
                disabled={props.disabled}
            >
                {props.data.map((company, i) =>
                    <option key={i} value={company.value}>{company.label}</option>
                )}

            </Input>
            {props.error && <p className="ebw-input__error">{props.error}</p>}
        </FormGroup>
    );
}
export default Select


export const BidTypeSelect = (props) => {
    return (
        <>
            <Input
                type="select"
                name={props.name}
                id={props.id}
                value={props.value}
                className="ebw-form__bid-input"
                onChange={props.changeEvent}
                defaultValue={props.value}
            >
                {props.data.map((bidtype, i) =>
                    <option key={i} value={bidtype.value}>{bidtype.label}</option>
                )}

            </Input>
            {props.error && <p className="ebw-input__error">{props.error}</p>}
        </>
    );
}
