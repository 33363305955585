import React, { useState } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, NavItem, Label, Input, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMyWorksAlbum } from "../../redux/actions/myWorksAlbumDelete";
import { getMyworks } from "../../redux/actions/getMyworks";
import { getCategoryFilter } from "../../redux/actions/categoryFilter";
import { renameMyWorksFiles } from "../../redux/actions/myWorksAlbumRename";
import { logout } from "../../redux/actions/logout";

import history from "../../common/history";
import { logoutCookie } from "../../utils"
import { NavLink } from "react-router-dom";
import { Checkbox } from "../../components/inputs/checkbox";
import { CategorySearch } from '../inputs/search-select';
import API from '../../API'
import Loader from "../../common/loader"
import { InputText } from "../inputs/text-input"
import Buttons from "../buttons/button-primary";


//- Header Dropdown for Profile: Start


export const HeaderDropdown = (props) => {
  const dispatch = useDispatch();
  const { profileData } = props;
  const auth = useSelector(state => state.auth.auth);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const logoutFormApp = () => {
    logoutCookie();
    dispatch(logout());
  }
  const links = [
    { id: 1, icon: 'review', title: 'Reviews', url: '/review' },
    { id: 2, icon: 'edit', title: 'My Account', url: '/profile' },
    { id: 3, icon: 'works', title: 'My Works', url: '/my-works' },
    { id: 4, icon: 'faqs', title: "Faqs", url: '/faqs' },
    { id: 5, icon: 'conditions', title: 'Terms & Conditions', url: '/terms-conditions' },
    { id: 6, icon: 'privacy', title: 'Privacy Policy', url: '/privacy-policy' },
    { id: 7, icon: 'logout', title: 'Logout', url: '', onclick: () => logoutFormApp() }
  ]


  return (
    // <Dropdown className="ebw-header__dropdown" isOpen={dropdownOpen} toggle={toggle}>
    <Dropdown className="ebw-header__dropdown">
      <DropdownToggle tag="a" role="button" className="ebw-header__dropdown-toggle d-flex align-items-center">
        <div className="ebw-header__dropdown-profile d-flex align-items-center">
          <div style={{ backgroundImage: `url(${profileData && profileData.avatar_thumb && profileData.avatar_thumb})` }} className="ebw-header__profile-img">
            {/* <img src="../images/profile/profile.png" className="img-fluid d-block" alt="Profile" /> */}
          </div>
          {/*  */}
          <div className="ebw-header__profile-info px-2">
            <div className="ebw-header__profile-name">{profileData && profileData.name && profileData.name}</div>
            <div className="mt-1">
              {/* Provider */}
              {profileData.is_pro_provider &&
                <div className="ebw-header__profile-pro ebw-profile__pro">PRO +</div>}
            </div>
          </div>
        </div>
        <span className="ebw-icon ebw-icon--l icon-arrow-top ebw-header__dropdown-icon--arrow"></span>
      </DropdownToggle>

      <DropdownMenu right className="ebw-header__dropdown-menu">
        <h6 className="ebw-header__dropdown-item px-5 pb-3">Welcome!</h6>

        {links.map((link, index) => (
          <NavLink
            key={index}
            exact
            to={link.url}
            onClick={link.onclick && link.onclick}
            className="ebw-header__dropdown-item d-flex align-items-center"
          >
            <span className={"ebw-header__dropdown-icon ebw-icon ebw-icon--lg icon-" + link.icon}></span>
            <div className="pl-4">{link.title}</div>
          </NavLink>
        ))}
      </DropdownMenu>

    </Dropdown>
  );
}
//- Header Dropdown for Profile: End


//- Notification Dropdown in Header: Start
export const NotificationDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const getLink = (taskName, data) => {
    let link = "";
    if (taskName == "A Provider is interested in your project") {
      link = `/project-detail/${data.project_id}/projects`
    } else if (taskName == "New Project Available for Bidding") {
      link = `/project-detail/${data.project_id}/projects`
    } else if (taskName == "You have a New Lead for Purchase") {
      link = `/project-detail/${data.project_id}/activities`
    } else if (taskName == "Project Updates") {
      link = `/project-detail/${data.project_id}/activities`
    } else if (taskName == "You are Hired!") {
      link = `/project-detail/${data.project_id}/my_projects`
    } else if (taskName == "ProjectAwarded") {
      link = `/project-detail/${data.project_id}/my_projects`
    } else if (taskName == "Project Reviewed") {
      link = "/review"
    }
    return link;
  }
  const getIconSpesific = (taskName) => {
    let render = "";
    if (taskName === "RemainderToBid") {
      render = (
        <img className="" src="/images/notification/1@2x.png" alt="" />
      )
    } else if (taskName === "A Provider is interested in your project") {
      render = (
        <img className="" src="/images/notification/5@2x.png" alt="" />
      )
    } else if (taskName === "ProviderProjectAvailable") {
      render = (
        <img className="" src="/images/notification/2@2x.png" alt="" />
      )
    } else if (taskName == "New Project Available for Bidding") {
      render = (
        <img className="" src="/images/notification/1@2x.png" alt="" />
      )
    } else if (taskName === "UpgradeToPro") {
      render = (
        <img className="" src="/images/notification/4@2x.png" alt="" />
      )
    } else if (taskName === "ProjectReviewed") {
      render = (
        <img className="" src="/images/notification/10@2x.png" alt="" />
      )
    } else if (taskName === "ProjectContacted") {
      render = (
        <img className="" src="/images/notification/3@2x.png" alt="" />
      )
    } else if (taskName === "Pro+") {
      render = (
        <img className="" src="/images/notification/4@2x.png" alt="" />
      )
    } else if (taskName === "LeadProjectAvailable") {
      render = (
        <img className="" src="/images/notification/2@2x.png" alt="" />
      )
    } else if (taskName === "You have a New Lead for Purchase") {
      render = (
        <img className="" src="/images/notification/2@2x.png" alt="" />
      )
    }
    else if (taskName === "You are Hired!") {
      render = (
        <img className="" src="/images/notification/5@2x.png" alt="" />
      )
    } else if (taskName == "Project Updates") {
      render = (
        <img className="" src="/images/notification/8@2x.png" alt="" />
      )
    } else if (taskName == "Pro+ Upgrade") {
      render = (
        <img className="w-100" src="/images/notification/4@2x.png" alt="" />
      )
    } else {
      render = ""
    }
    return render;

  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ebw-header__notification-dropdown">
      <DropdownToggle tag="a" role="button" className="ebw-header__notification-toggle d-block">
        <div className="ebw-header__notification-toggle--icon d-flex align-items-center py-1">
          <span className="ebw-icon icon-notification ebw-header__notification-icon"></span>
          {props.notificationData && props.notificationCount != 0 ?
            <Badge pill className="ebw-header__notification-icon--badge">{props.notificationCount ? props.notificationCount : 0}</Badge>
            : ""
          }
        </div>
      </DropdownToggle>

      <DropdownMenu right className="ebw-header__notification-menu">
        <div className="ebw-header__notification-top d-flex justify-content-between align-items-center">
          <h6 className="ebw-header__notification-title">Notifications</h6>
          <NavLink exact to="/notifications" className="ebw-header__notification-link">View all</NavLink>
        </div>

        <div className="ebw-header__notification-list">
          {props.notificationData && props.notificationData.length != 0 && props.notificationData.map((notification, index) => {
            return (
              <NavItem to={getLink(notification.task_name, notification)} key={index} className="ebw-header__notification-item d-flex flex-row align-items-start flex-wrap">

                <div className="ebw-header__notification-item--img pl-2 pr-3">
                  {/* <span className={"ebw-icon ebw-icon--xl icon-buy" + notification.icon}><img src="/images/notification/hammer.png"></img></span> */}

                  {getIconSpesific(notification.task_name)}
                </div>

                <div className="ebw-header__notification-item--info">
                  <div className="ebw-header__notification-item--title">{notification.notification}</div>
                  <div className="ebw-header__notification-item--link">
                    <NavLink exact to="">{notification.tag}</NavLink>
                  </div>
                  <div className="ebw-header__notification-item--timestamp">{notification.created_at}</div>
                </div>
              </NavItem>
            )
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}
//- Notification Dropdown in Header: End


//- Dropdown In My Works Page: Start
const works = [
  { id: 1, icon: 'edit', title: "Rename folder" },
  // { id: 2, icon: 'draft', title: "Draft" },
  { id: 3, icon: 'delete', title: "Remove folder" }
]

export const MyWorksDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);

  const toggle = (e) => {
    setModal(!modal);
    setModalTitle(e.title)
  }
  const confirmDelete = (id) => {
    dispatch(deleteMyWorksAlbum(id));
    // setTimeout(dispatch(getMyworks()), 3000)
    setModal(false);
    setTimeout(() => {
      dispatch(getMyworks())
    }, 2000);
  };
  const [albumName, setAlbumName] = useState(props.name);
  const renameFolder = (event) => {
    event.preventDefault();
    const updata = {
      id: props.id,
      data: {
        album: {
          name: albumName
        }
      }
    }
    if (albumName != props.name) {
      dispatch(renameMyWorksFiles(updata));
      setModal(false);
      setTimeout(() => {
        dispatch(getMyworks())
      }, 2000);
    }
  }


  return (
    <div>
      {/* <div onClick={toggle}>delere</div> */}
      <Dropdown className="ebw-dropdown" isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle tag="a" role="button" className="ebw-dropdown__toggle ebw-dropdown__toggle--myworks d-flex justify-content-end p-0 m-0">
          <div className="ebw-uploads__option position-absolute"><span className="ebw-icon ebw-icon--xl icon-more-horizontal"></span></div>
        </DropdownToggle>

        <DropdownMenu right className="ebw-dropdown__menu p-2">
          {works.map((work, index) => (
            <DropdownItem
              type="button"
              key={index}
              className="ebw-dropdown__item mt-0"
            >
              <div onClick={() => toggle(work)} className="d-flex justify-content-between align-items-center">
                <div className="mr-1">{work.title}</div>
                <span className={"ebw-icon ebw-icon--lg icon-" + work.icon}></span>
              </div>
              {/*<img src={work.icon} alt={work.title} className="img-fluid d-block"></img>*/}
            </DropdownItem>
          ))}

        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={modal} toggle={toggle} className="ebw-global__modal">
        {modalTitle == "Remove folder" &&
          <ModalBody className="ebw-global__modal-body text-center py-6">
            <h3 className="text-lg mb-6">Are you sure want to delete?</h3>

            <div className="d-flex justify-content-center align-items-center">
              <div className="col-2">
                <Button color="danger" type="button" onClick={toggle} >No</Button>
              </div>
              <div className="col-2">
                <Button color="success" type="submit" onClick={() => confirmDelete(props.id)} >Yes</Button>
              </div>
            </div>
          </ModalBody>
        }

        {modalTitle == "Rename folder" &&
          <div className="px-5 ebw-modal__wrap">
            <ModalHeader toggle={toggle} className="ebw-global__modal-header">
              <h5 className="modal-title">Rename Folder</h5>
            </ModalHeader>

            <ModalBody className="ebw-global__modal-body ebw-modal__body">
              <Row>
                <Col sm="12">
                  <InputText
                    className="ebw-modal__input"
                    label="Album Name"
                    name="album"
                    id="album"
                    value={albumName}
                    error=""
                    changeEvent={(event) => setAlbumName(event.target.value)}
                  />
                </Col>

                <Col xs="5" md="4" className="ml-auto mt-3">
                  <Button color="success" type="submit" onClick={(e) => renameFolder(e)}  >Submit</Button>
                </Col>
              </Row>
            </ModalBody>
          </div>
        }
      </Modal>
    </div>
  );
}
//- Dropdown In My Works Page: End

//- Filter Dropdown in All the project pages: Start
export const CategoryDropdown = () => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const profileData = useSelector(state => state.getProfileEditData.profileEdit);
  const profileDataLoading = useSelector(state => state.getProfileEditData.loading);
  const profileDataError = useSelector(state => state.getProfileEditData.error);
  const categoriesFromStore = useSelector(state => state.categoryFilter.selectedCategories);
  const availableCategories = useSelector(state => state.setAvailableCategories.categories);
  const [search, setSearch] = useState("");
  const { project_sub_types, project_types } = profileData;
  const categories = project_sub_types && [...project_sub_types];
  const [updatedCategories, setUpdatedCategories] = useState([])
  const showApropriateSearch = (word) => {
    const newCategory = [];
    availableCategories && availableCategories.length > 0 && availableCategories.forEach((item) => {
      item.toLowerCase().includes(word) && newCategory.push(item)
      // item?
      //   item.name.toLowerCase().includes(word) && newCategory.push(item) :
      //   item.title ?
      //     item.title.toLowerCase().includes(word) && newCategory.push(item) : "";
      // item.name.toLowerCase().includes(word) && newCategory.push(item)
    })
    setUpdatedCategories(newCategory);
  }
  const onSearchChange = (event) => {
    setSearch(event.target.value);
    showApropriateSearch(event.target.value);
  }

  const realCategory = search == "" ? availableCategories : updatedCategories;
  const changeCheckboxStatus = (event) => {
    categoriesFromStore && categoriesFromStore.length != 0 && categoriesFromStore.includes(event.target.value) ?
      dispatch(getCategoryFilter(categoriesFromStore.filter(x => x != event.target.value))) :
      dispatch(getCategoryFilter([...categoriesFromStore, event.target.value]))
  }
  const resetButtonClick = () => {
    setSearch("")
    showApropriateSearch("")
    dispatch(getCategoryFilter([]))
  }


  return (
    <Dropdown className="ebw-tab__filter-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="a" role="button" className="ebw-tab__filter-dropdown--toggle d-flex align-items-center p-0 m-0 h-100" caret>
        <span className="ebw-icon icon-filter ebw-tab__filter-dropdown--icon"></span>
        <span className="pl-1 pr-3 d-none d-md-block">Category</span>
      </DropdownToggle>

      <DropdownMenu right className="ebw-tab__filter-dropdown--menu">
        <div className="ebw-tab__filter-dropdown--searchbar mb-6">
          <CategorySearch value={search} changeHandler={onSearchChange} label="Search by Category" />
          <Button onClick={resetButtonClick} color="link" className="ebw-tab__filter-dropdown--reset d-flex ml-auto px-0">Reset</Button>
        </div>

        {profileDataLoading ?
          <div className="my-auto" style={{ height: "200px" }}>
            <Loader loading={profileDataLoading} />
          </div>
          :
          <>
            {realCategory && realCategory.length != 0 && realCategory.map((category, index) => (
              <li key={index} className="mb-2 ebw-tab__filter-dropdown--item" >
                <span className="ebw-form__checkboxes position-relative d-flex align-items-center">
                  <Label check className="ebw-form__checkbox-label d-flex">
                    <span className="ebw-form__checkbox-text ebw-tab__filter-dropdown--text text-md">{category}</span>
                    <Input
                      type="checkbox"
                      className="ebw-form__checkbox ebw-form__checkboxes-check"
                      value={category}
                      onChange={changeCheckboxStatus}
                      checked={category ? categoriesFromStore && categoriesFromStore != 0 && categoriesFromStore.includes(category) : categoriesFromStore && categoriesFromStore != 0 && categoriesFromStore.includes(category)}
                    />
                    <span className="ebw-form__checkboxes-mark ebw-form__checkboxes-mark--category"></span>
                  </Label>
                </span>
              </li>
            ))}
          </>
        }
      </DropdownMenu>
    </Dropdown>
  );
}
//- Filter Dropdown in All the project pages: Start
