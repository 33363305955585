import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from "../../../API";

async function getApi(data) {
    const apiUrl = `${API.baseURL}${API.endPoints.myProjectsInProcessing}?page=${data.page}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* fetchMyProjectsInProcess(action) {
    try {
        const myProjects = yield call(getApi, action.payload);
        yield put({ type: 'GET_MYPROJECTS_IN_PROCESS_SUCCESS', inProcess: myProjects.data.data });
    } catch (e) {
        yield put({ type: 'GET_MYPROJECTS_IN_PROCESS_FAILED', message: e.message });
    }
}
function* myProjectsInProcessSaga() {
    yield takeEvery('GET_MYPROJECTS_IN_PROCESS_REQUESTED', fetchMyProjectsInProcess);
}
export default myProjectsInProcessSaga;