import React, { useEffect } from 'react';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import GetContact from "./getContact"

const GetContactContainer = (props) => {
    console.log(props.location.state, 'props in the get contact page');
    const { id } = props.match.params;
    return (
        <>
            <Header />
            <GetContact {...props.location.state} />
            <Footer />
        </>
    );
}

export default GetContactContainer;