import React, { useState } from 'react';

import { Container, Row, Col, FormGroup, Input, Modal, Form, ModalHeader, ModalBody, Button, Badge } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';


import BidAmount from "./bidAmount";
import ProjectBudget from "./projectBudget";
import BidAmountDetailed from "./bidAmountDetailedView";
import FileUpload from "../common/uploadFile";
import { markProjectCompleted } from "../../redux/actions/markCompleteProject";
import { markCancelProject } from "../../redux/actions/markCancelProject";
import Textarea from "../inputs/textarea";
import GotHired from "./gotHired";
import PaymentDetails from "./paymentDetails";
import { activateChatBox } from "../../redux/actions/chatboxActive"
import { getChat } from "../../redux/actions/getChat";

const CompletedAndHiredBidDetails = (props) => {
    const {
        bid_amount,
        bid_type,
        hours,
        bid_detail,
        commissionDetails,
        status,
        id,
        title,
        created_at,
        pending_amount,
        down_payment,
        project_id,
        project_status,
        project_post_type,
        budget,
        provider_project_id,
        is_commission_paid,
        amount_payable_to_easybids_after_discount,
        provider_project_status,
        total_amount,
        discount_applied_by_customer,
        easybids_commission,
        amount_receive_from_customer,
        unread_msg,
        provider_id,
        consumer_mobile,
    } = props;
    console.log(bid_amount, "bid_amount bid_amount bid_amount");
    const dispatch = useDispatch();
    const markcompleteProjectLoading = useSelector(state => state.markcompleteProject.loading);
    const markCancelProjectLoading = useSelector(state => state.markCancelProject.loading);
    const [modal, setModal] = useState(false);
    const [files, setFile] = useState([]);
    const [editItem, setEditItem] = useState("");
    const [reason, setReason] = useState([]);

    const toggle = (item) => {
        setModal(!modal); setModal
        setEditItem(item);
    }

    const handleChangeFile = (data) => {
        setFile([...files, ...data])

    }
    const removeFileFromList = (i) => {
        let newState = []
        files.length > 0 && files.forEach((item, index) => {
            index !== i && newState.push(item);
        })
        setFile([...newState])
    }

    const markComplete = (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append('project_status', project_status == 9 ? 7 : 4)

        for (let index = 0; index < files.length; index++) {
            if (files[index].blob !== undefined) {
                formData.append('documents[]', files[index].blob)
            }
        }
        const data = {
            data: formData,
            id: provider_project_id
        }

        dispatch(markProjectCompleted(data));
        setFile([]);
        setModal(!modal);
        // location.reload();
    };
    const cancelProject = (event) => {
        event.preventDefault();

        const data = JSON.stringify({
            cancellation_request: {
                description: reason,
                requested_user_type: "2",
                status: 1,
                project_id: id,
                provider_project_id: provider_project_id,
            }
        });
        dispatch(markCancelProject(data));
        setReason("");
        setModal(!modal);
        // location.reload();

    };
    const reasonInputHanler = (event) => {
        setReason(event.target.value);
    }
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    return (
        <>

            <div className="h-100 mt-4">
                <div className="pb-3 ebw-prodet__biddetail"><b>Bid details</b></div>

                <div className="ebw-prodet__content">
                    <div className="ebw-prodet__content-wrap">
                        {project_post_type === 1 &&
                            <>
                                <div className="ebw-prodet__subtitle ebw-card__subtitle">{bid_type}</div>
                                <BidAmount bid_type={bid_type} bid_amount={bid_amount} hours={hours} />
                                <div className="ebw-myproject__completed-text pt-4">{bid_detail}</div>
                                <BidAmountDetailed bid_type={bid_type} bid_amount={bid_amount} hours={hours} pending_amount={pending_amount} down_payment={down_payment} />
                            </>
                        }
                        {((project_status == 3 && is_commission_paid == false) || (project_status == 4 && is_commission_paid == false && amount_payable_to_easybids_after_discount != 0)) &&
                            <GotHired
                                project_id={id}
                                provider_project_id={provider_project_id}
                                project_status={project_status}
                                is_commission_paid={is_commission_paid}
                                amount_payable_to_easybids_after_discount={amount_payable_to_easybids_after_discount}
                                bid_amount={bid_amount}
                            />
                        }

                        {(project_status == 3 || project_status == 4) &&
                            <PaymentDetails
                                bid_type={bid_type}
                                project_status={project_status}
                                bid_amount={bid_amount}
                                hours={hours}
                                total_amount={total_amount}
                                discount_applied_by_customer={discount_applied_by_customer}
                                amount_payable_to_easybids_after_discount={amount_payable_to_easybids_after_discount}
                                is_commission_paid={is_commission_paid}
                                easybids_commission={easybids_commission}
                                amount_receive_from_customer={amount_receive_from_customer}
                            />}

                        {project_status !== 4 && project_status !== 5 && bid_amount != "$0.00" &&
                            <>
                                {(project_status == 3 && ((amount_payable_to_easybids_after_discount > 0 && is_commission_paid == true) || (amount_payable_to_easybids_after_discount == 0 && is_commission_paid == false))) &&
                                    <Button onClick={() => toggle("mark")} className="ebw-button__primary ebw-button__primary--no-border mt-8 mt-lg-14 w-100">
                                        <div className="d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3">
                                            <div className="ebw-myproject__completed-complete--text">Mark Complete</div>
                                            <i className="ebw-icon ebw-icon--lg icon-tick ebw-myproject__completed-complete--correct" aria-hidden="true"></i>
                                        </div>
                                    </Button>
                                }
                                {project_status == 3 &&
                                    <Button onClick={() => toggle("cancel")} className="ebw-button__border mt-2 w-100">
                                        <div className="d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3">
                                            <div className="">Cancel Project</div>
                                            <i className="ebw-icon ebw-icon--md icon-close ebw-myproject__completed-cancel--icon"></i>
                                        </div>
                                    </Button>
                                }
                            </>}
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle}>

                {editItem == "mark" ?
                    <div className="px-5 py-5 ebw-modal__wrap">
                        <ModalHeader toggle={toggle} className="ebw-profile__modal-head px-0 px-md-2 py-0" style={{ border: "none" }}>
                            <div className="ebw-modal__title text-center">Upload your work to highlight your service</div>
                        </ModalHeader>

                        <ModalBody className="ebw-modal__body">
                            <Form className="ebw-modal__form">
                                <FileUpload
                                    type="both"
                                    multiple={true}
                                    files={files}
                                    handleChangeFile={handleChangeFile}
                                    removeFileFromList={removeFileFromList}
                                />
                                <div className="text-right mt-4">
                                    <button onClick={(e) => markComplete(e)} className="ebw-modal__submit ebw-button__primary" type="submit">Update</button>
                                </div>
                            </Form>
                        </ModalBody>
                    </div>
                    :

                    <div className="px-5 py-5 ebw-modal__wrap">
                        <ModalHeader toggle={toggle} className="ebw-profile__modal-head px-0 px-md-2 py-0" style={{ border: "none" }}>
                            <div className="ebw-modal__title text-center">Reason for cancellation</div>
                        </ModalHeader>

                        <ModalBody className="ebw-modal__body px-0 px-md-2">
                            <Form className="ebw-modal__form">
                                <div className="ebw-modal__input-wrap mb-4">
                                    <Textarea
                                        changeEvent={(e) => reasonInputHanler(e)}
                                        name="reason"
                                        value={reason}
                                        id="uploadDP"
                                        className="ebw-modal__textarea py-2"
                                        label="Enter the reason for cancellation here."
                                    />

                                </div>

                                <div className="text-right">
                                    <button
                                        onClick={(e) => cancelProject(e)}
                                        className="ebw-modal__submit ebw-button__primary"
                                        type="submit"
                                    >Update</button>
                                </div>
                            </Form>
                        </ModalBody>
                    </div>
                }

                {/* <div>
                    <div className="ebw-profile__modal-close" onClick={toggle}>&times;</div>
                </div> */}
            </Modal>
        </>
    );
}

export default CompletedAndHiredBidDetails;