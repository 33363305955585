import React from "react";
import { Link } from 'react-router-dom';

const Alert = (props) => {
    return (
        <div className="ebw-alert ebw-alert--overflow d-md-flex align-items-center justify-content-between">
            <div className="ebw-alert__left d-flex flex-wrap align-items-center pr-2">
                <div className="ebw-alert__profile-image">
                    <img src={props.profileImage} alt="" />
                </div>
                <div className="ebw-alert__left-content ml-2 ml-md-5">
                    <div className="d-flex flex-wrap align-items-center ebw-alert__title mb-2">
                        <div className="mr-1 font-bold">{props.value}%</div> Profile Completed
                    </div>
                    <div className="ebw-alert__process-bar"><span style={{ width: props.value + "%" }} className="ebw-alert__process-bar--track"></span></div>
                    <div className="ebw-alert__process-level">Your profile completion is at {props.value}%</div>
                </div>
            </div>
            <div className="ebw-alert__right text-right">
                <Link to="/profile" className="ebw-button--plain ebw-button__plain--blue font-medium d-flex justify-content-end align-items-center">Update Profile
                    <i class="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon ml-2"></i>
                </Link>
            </div>
        </div>
    )
}
export default Alert;