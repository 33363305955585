import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../API';
import { toastr } from 'react-redux-toastr'
import axios from 'axios';


async function getApi(data) {
    const dat = {
        "project_id": data
    }


    const apiUrl = API.baseURL + `/providers/web_related_projects?project_id=${data}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* relatedProjectsData(action) {
    try {
        const response = yield call(getApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // toastr.success('Pegister personal Success', 'You are successfully completed the first step ');
        } else {
            // response.errors.length!=0&&toastr.error('Pegister personal  failed', response.errors[0]);
        }
        yield put({ type: 'GET_RELATED_PROJECTS_SUCCESS', data: response.data.data.related_projects });

    } catch (e) {
        yield put({ type: 'GET_RELATED_PROJECTS_FAILED', message: e.message });
    }
}

function* relatedProjectsSaga() {
    yield takeEvery('GET_RELATED_PROJECTS_REQUESTED', relatedProjectsData);
}

export default relatedProjectsSaga;