import * as type from '../actions/types';

const initialState = {
    data: {},
    firstStep: true,
    loading: false,
    error: null,
}

export default function loginOtpF(state = initialState, action) {
    switch (action.type) {
        case type.GET_OTP_LOGIN_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_OTP_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
                firstStep: false,
            }
        case type.GET_OTP_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case type.SET_OTP_LOGIN_STEP_SUCCESS:
            return {
                ...state,
                firstStep: action.data,
            }

        default:
            return state
    }
}