import React, { useState } from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Link } from "react-router-dom";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import SwiperCore, { Autoplay, Navigation, Scrollbar } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import options from "../../values/imageViewerOptions";
import 'swiper/swiper.scss';
import "swiper/components/scrollbar/scrollbar.min.css";

import UploadedImgs from '../myWorks/uploadedImgs';
import ImgDrpDwn from '../myWorks/imagedropdown';
import LighrBoxCmn from "../common/lightBox";

SwiperCore.use([Navigation, Autoplay, Scrollbar]);


export const MyWorkSlider = (props) => {
    const { myWorksFiles } = props;
    const [dataSet, setDataset] = useState([])
    const [selectedIndex, setselectedIndex] = useState(0)
    const [isOpen, setIsopen] = useState(false)
    const openViewer = (i) => {
        let data = [];
        myWorksFiles.forEach(x => {
            data.push(x.url)
        });
        setselectedIndex(i);
        setDataset(data)
        setIsopen(true);

    }
    const closeViewer = () => {
        setIsopen(false);
    }




    return (
        <>
            <SimpleReactLightbox >
                <SRLWrapper options={options}>
                    <Swiper
                        spaceBetween={15}
                        slidesPerView="auto"
                        className="ebw-swiper__container ebw-swiper__container--full-width py-2 ebw-uploads__container"
                        scrollbar={{
                            draggable: true,
                            hide: false
                        }}
                    >
                        {myWorksFiles && myWorksFiles.length > 0 && myWorksFiles.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="ebw-uploads__slider-wrap">
                                        <ImgDrpDwn workId={props.workId} id={image.id} />
                                        <div style={{ backgroundImage: `url(${image.url})` }} onClick={() => openViewer(index)} className="ebw-uploads__slider">
                                            {/* <img src={image.url} alt={props.alt} className="img-fluid mx-auto d-block ebw-uploads__slider-img" /> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </SRLWrapper>
            </SimpleReactLightbox>
            {isOpen &&
                <LighrBoxCmn closeViewer={() => closeViewer()} photoIndex={selectedIndex} images={dataSet} />}
        </>

    );
}