import React, { useState } from 'react';

const THeStatus = ({ project_status, consumer_mobile }) => {

    const getStatusOfSTatus = (status) => {
        console.log(status, "comes inside the statusus condi")
        if (status == 2) {
            if (consumer_mobile && consumer_mobile != "") {
                return 'Contacted'
            } else {
                return 'Available for Bid'
            }
        } else if (status == 3) {
            return 'Hired'
        } else if (status == 4) {
            return 'Completed'
        } else if (status == 9) {
            return 'Cancelled'
        } else if (status == 6) {
            return "Cancellation Requested "
        }
    }
    return (
        <div className="ebw-card__label text-center" >{getStatusOfSTatus(project_status)}</div>
    );
}

export default THeStatus;