import React, { useState } from 'react';
import { Form, Row, Col } from "reactstrap";
import axios from 'axios';

import InputEmail from "../inputs/email-input";
import Buttons from "../buttons/button-primary";
import API from '../../API';

//- Forgot Password Number OTP Request
const ForgotPasswordRequest = (props) => {
    const validate = (inputs) => {
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        let errors = {
            valid: true,
        };
        if (!validEmailRegex.test(inputs.email)) {
            errors.emailError = 'Invalid email address';
            errors.valid = false;
        } else if (!inputs.email) {
            errors.emailError = 'Enter Email';
            errors.valid = false;
        }
        return errors;
    };
    const [state, setState] = useState({
        email: "",
        emailError: "",
        valid: false
    });
    const onChangeEmail = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            email: event.target.value,
            emailError: "",
        }));
    }
    const handleEmailSubmit = (event) => {
        event.preventDefault();
        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = JSON.stringify({
            email: state.email,
            user_type: "2",
        })
        errors.valid && axios.post(API.baseURL + `/password_update_request`, data, API.options)
            .then(response => {
                const result = response.data;
                props.completedForgotPassword(1, state.email);
            }).catch(error => {
                setState((prevProps) => ({
                    ...prevProps,
                    emailError: error && error.response && error.response.data && error.response.data.data && error.response.data.data.errors && error.response.data.data.errors
                }));
            });

    }
    return (
        <>
            <div className="ebw-entry__forgot text-center mb-6">
                <h5 className="ebw-entry__forgot-title mb-0">Forgot your Password?</h5>
                <p className="ebw-entry__forgot-tag">Recover it now</p>
            </div>

            <Form className="ebw-entry__tab-form position-relative" onSubmit={handleEmailSubmit} >
                <Row form>
                    <Col sm="12" className="position-relative">
                        <InputEmail
                            label="Email address"
                            name="verify_request"
                            id="verify_request"
                            value={state.email}
                            changeEvent={(e) => onChangeEmail(e)}
                            error={state.emailError}
                        />
                    </Col>

                    <Col sm="12" className="text-center">
                        <p className="ebw-entry__forgot-tag">You will receive an OTP on your above email</p>
                    </Col>

                    <Col md="5" lg="7" xl="5" className="mt-4 mx-auto">
                        <Buttons type="submit" text="Request OTP" />
                    </Col>
                </Row>
            </Form>
        </>
    );
}
export default ForgotPasswordRequest;

