import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import PrivacyPolicy from "./privacy-policy";

const PrivacyPolicyPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <>
            <Header />
            <PrivacyPolicy />
            <Footer />
        </>
    );
}

export default PrivacyPolicyPage;