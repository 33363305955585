import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import CardSecondary from '../card/card-secondary';

const RelatedProjects = (props) => {
    const relatedProjects = useSelector(state => state.relatedProjects.relatedProjects);

    const projectsData = { loading: false }
    // const relatedProjects = [];
    return (
        <section className={`${relatedProjects && relatedProjects.length != 0 ? "ebw-section ebw-section--projects-suggested overflow-hidden pb-0 pt-2 pt-md-8" : ""}`}>
            {
                projectsData.loading ?
                    <Col sm="12" className="text-center">
                        <InbiddingLoader
                        // width={500}
                        // height={100}
                        />
                    </Col>
                    :
                    <>{relatedProjects && relatedProjects.length != 0 &&
                        <div className=" ebw-projects__suggested container">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="ebw-title__primary">Related Projects</div>
                            </div>
                            <Swiper
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                spaceBetween={15}
                                slidesPerView="auto"
                                navigation={{
                                    prevEl: '.prev2',
                                    nextEl: '.next2',
                                }}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        width: 640,
                                        slidesPerView: 2,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },
                                }}
                                className="ebw-swiper__container"
                            >
                                {relatedProjects && relatedProjects.length != 0 && relatedProjects.map((projectRelated, index) => (
                                    <SwiperSlide key={index}>
                                        <CardSecondary
                                            projectName={projectRelated.project_name}
                                            projectImage={projectRelated.project_img_url != "" ? projectRelated.project_img_url : "/images/dashboard/project-img-1.png"}
                                            projectDate={projectRelated.created_at}
                                            projectCategory={projectRelated.project_type}
                                            projectLocation={projectRelated.address_filterd}
                                            projectLinks={`/project-detail/${projectRelated.project_id}/projects`}
                                            project_post_type={projectRelated.project_post_type}
                                            NoArrow
                                            bookmarked_by_current_provider={projectRelated.bookmarked_by_current_provider}
                                            project_id={projectRelated.id}
                                            provider_project_details={projectRelated.provider_project_details}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="ebw-slider__navigation">
                                <div className="ebw-slider__navigation-arrow ebw-slider__navigation-arrow--prev prev2">
                                    <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon "></i>
                                </div>
                                <div className="ebw-slider__navigation-arrow next2 ebw-slider__navigation-arrow--active">
                                    <i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon"></i>
                                </div>
                            </div>
                        </div>
                    }
                    </>

            }
        </section>
    );
}

export default RelatedProjects;