import * as type from '../actions/types';

const initialState = {
  completedRegsteps:false,
  registration: [],
  loading: false,
  error: null,
}

export default function registerAdditional(state = initialState, action) {
  switch (action.type) {
    case type.POST_REGISTER_ADDITIONAL_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.POST_REGISTER_ADDITIONAL_SUCCESS:
      return {
        ...state,
        loading: false,
        registration: action.registerAdditional,
        completedRegsteps:true
        // error:action.errors
      }
    case type.POST_REGISTER_ADDITIONAL_FAILED:
      return {
        ...state,
        completedRegsteps:0,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}