import React, { useState } from 'react';
import { Col, Row, Form, Input, CustomInput, UncontrolledCollapse } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Buttons from "../buttons/button-primary";
import { setSeletedTypes } from "../../redux/actions/selectedTypes";
import { setSeletedAllTypes } from "../../redux/actions/selectAllServiceTypes";

const RegisterServices = (props) => {
    const { serviceTypesData } = props;
    const dispatch = useDispatch();
    const selectedSubTypes = useSelector(state => state.selectedTypes.selectedSubTypes);
    const selectedTypes = useSelector(state => state.selectedTypes.selectedTypes);
    const storeBusiness = useSelector(state => state.storeBusiness.business);
    const [error, setError] = useState("");
    const [selectAll, setSelectAll] = useState(false);

    const handleItemCheckbox = (event) => {
        dispatch(setSeletedTypes(parseInt(event.target.value)));
    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectAll(true)
            var all_sub_type = []
            serviceTypesData.services.forEach(category => {
                if (category.project_sub_types.length != 0)
                    category.project_sub_types.forEach(subType => {
                        all_sub_type.push(subType.id);
                    })
            })
            dispatch(setSeletedAllTypes(all_sub_type));
        } else {
            setSelectAll(false)
            dispatch(setSeletedAllTypes([]));
        }
    }
    const submitService = (event) => {
        event.preventDefault();
        if (selectedSubTypes.length == 0) {
            setError("Select any services")
        } else {
            props.handleRegisterServices(selectedTypes, selectedSubTypes, storeBusiness);
        }

    }
    return (
        <Form className="ebw-entry__register-form text-center" onSubmit={e => submitService(e)}>
            <Row form>
                <Col sm="12">
                    <div className="ebw-entry__services">
                        <div className="ebw-entry__services-row d-flex align-items-center justify-content-between mb-2">
                            <label for="selectAll" className="ebw-entry__label-services">Selected services {selectedSubTypes.length}</label>
                            <div className="ebw-form__checkboxes ebw-form__checkboxes-inline d-flex align-items-center ">
                                <label for="selectAll" className="ebw-form__checkbox-text pl-4 mb-0 mr-2">Select All</label>
                                <CustomInput
                                    type="checkbox"
                                    id="selectAll"
                                    onChange={(e) => handleSelectAll(e)}
                                    checked={selectAll ? true : false}
                                />
                            </div>
                        </div>
                        <div className="ebw-entry__services">

                            {selectedSubTypes && serviceTypesData && serviceTypesData.services.map((service, index) => {
                                return (
                                    <div key={index} className="ebw-entry__service">
                                        <div id={`toggler${index}`} className={`ebw-entry__service-main d-flex align-items-center justify-content-between mt-2 mb-2`}>
                                            <div className="ebw-entry__label-main">{service.title}</div>
                                            <div className={`ebw-entry__icon--add`}>
                                                <span className="ebw-icon ebw-icon--md icon-close"></span>
                                            </div>

                                        </div>
                                        <UncontrolledCollapse className="ebw-entry__service-subcategories" toggler={`#toggler${index}`}>
                                            <div className="px-6 py-4">
                                                {service.project_sub_types.length != 0 && service.project_sub_types.map((subCategory, subindex) => {
                                                    return (
                                                        <div key={subindex} className="ebw-entry__service-subcategory d-flex align-items-center justify-content-between">
                                                            <label for={`service_sub_item_${subCategory.id}_${service.id}`} className="ebw-entry__label-subcategory">{subCategory.name}</label>
                                                            <div className="ebw-form__checkboxes ebw-form__checkboxes-inline">
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    name={`service_sub_item_${subCategory.id}_${service.id}`}
                                                                    id={`service_sub_item_${subCategory.id}_${service.id}`}
                                                                    value={subCategory.id}
                                                                    onChange={e => handleItemCheckbox(e)}
                                                                    checked={selectedSubTypes.includes(subCategory.id) ? 'checked' : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </UncontrolledCollapse>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {error ?
                        <div className="ebw-entry__services-error mt-4">{error}</div> : ""}
                </Col>
                <Col sm="12" md="4" xl="3" className="mx-auto text-center mt-4">
                    <Buttons type="submit" text="Next" />
                </Col>
            </Row>
        </Form>
    );

}

export default RegisterServices;