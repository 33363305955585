import React from 'react';
import DashNotificationLoader from "../Skeletons/notificationDash"
import { IonSlide, IonSlides, IonModal, IonContent, } from "@ionic/react";
import NotificationSlideNotYetHired from "../common/notificationSlideNotYetHired";
import NotificationSlideHired from "../common/notificationSlideHired";
import NotificationSlideProposal from "../common/notificationSlideProposal";
import NotificationCardChat from "../common/notificationSlideMessage"
import { Swiper, SwiperSlide } from 'swiper/react';

const DasboardNotification = (props) => {
    const { primaryNotifications, notificationLoading, toWhere } = props;
    // const adSlideOpts = {
    //     initialSlide: 0,
    //     speed: 800,
    //     zoom: false,
    //     autoplay: {
    //         delay: 2000,
    //     },
    //     spaceBetween: 10,
    // };
    console.log("close notifications toWhere", toWhere);
    const closeNotifications = () => {
        props.closeNotificationsPopup()
    }
    return (
        <>

            {(primaryNotifications && primaryNotifications.length > 0 && toWhere == "inDashboard") ?
                <div className="ebw-notification__slider mt-5">
                    <div
                        // options={adSlideOpts}
                        className={`ebw-notification__slider-wrap d-flex flex-row `}
                        pager={true}
                    >
                        <Swiper
                            className="ebw-swiper__container pb-0 w-100 h-100 mt-4"
                            loop={true}
                            autoplay={{ delay: 3000 }}
                            spaceBetween={20}
                            slidesPerView="auto"
                            // pagination={{
                            //   el: 'swiper-pagination',
                            //   clickable: true
                            // }}
                            navigation={{
                                prevEl: '.prev',
                                nextEl: '.next',
                            }}
                            breakpoints={{
                                // when window width is >= 640px
                                640: {
                                    spaceBetween: '0'
                                },
                                // when window width is >= 768px
                                768: {
                                    spaceBetween: '20'
                                },
                            }}
                        >
                            {primaryNotifications.map((data, index) => {
                                let notis = [];
                                if (data.parameter.notification_type == "not_yet_hired") {
                                    notis.push(
                                        <SwiperSlide className='ebw-notification__slide--swiper'>
                                            <NotificationSlideNotYetHired key={index}  {...data} />
                                        </SwiperSlide>
                                    )
                                } else if (data.parameter.notification_type == "hired") {
                                    notis.push(
                                        <SwiperSlide className='ebw-notification__slide--swiper'>
                                            <NotificationSlideHired key={index}  {...data} />
                                        </SwiperSlide>
                                    )
                                } else if (data.parameter.notification_type == "Message") {
                                    notis.push(
                                        <SwiperSlide className='ebw-notification__slide--swiper'>
                                            <NotificationCardChat key={index}  {...data} />
                                        </SwiperSlide>
                                    )
                                } else if (data.parameter.notification_type == "bid_proposal") {
                                    notis.push(
                                        <SwiperSlide className='ebw-notification__slide--swiper'>
                                            <NotificationSlideProposal key={index}  {...data} />
                                        </SwiperSlide>
                                    )
                                }
                                return notis;
                            })}
                        </Swiper>

                    </div>
                </div> :
                <>
                    {primaryNotifications.map((data, index) => {
                        let notis = [];
                        if (data.parameter.notification_type == "not_yet_hired") {
                            notis.push(
                                <NotificationSlideNotYetHired key={index} closeNotifications={closeNotifications} {...data} />
                            )
                        } else if (data.parameter.notification_type == "hired") {
                            notis.push(
                                <NotificationSlideHired key={index} closeNotifications={closeNotifications} {...data} />
                            )
                        } else if (data.parameter.notification_type == "Message") {
                            notis.push(
                                <NotificationCardChat closeNotifications={closeNotifications} key={index}  {...data} />
                            )
                        } else if (data.parameter.notification_type == "bid_proposal") {
                            notis.push(
                                <NotificationSlideProposal key={index} closeNotifications={closeNotifications} {...data} />
                            )
                        }
                        return notis;
                    })}
                </>
                // "this is for popup"
            }
        </>
    );
}

export default DasboardNotification;