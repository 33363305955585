import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';

async function postApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/projects/${data.project_id}/ignore`;
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.post(apiUrl, {}, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* deleteProject(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            // setAuth(response.data.data, response.headers);
            yield put({ type: 'DELETE_IGNORE_THIS_PROJECT_SUCCESS', registerPersonal: response.data });
            // toastr.success('Removed', 'You are successfully ignored the project');
        } else {
            // response.errors.length != 0 && toastr.error('Deletion Failed', response.errors[0]);
        }
        // history.push('/projects');
        window.location.href = "/projects/all/1"

    } catch (e) {
        yield put({ type: 'DELETE_IGNORE_THIS_PROJECT_FAILED', message: e.message });
    }
}

function* ignoreThisProjectSaga() {
    yield takeEvery('DELETE_IGNORE_THIS_PROJECT_REQUESTED', deleteProject);
}

export default ignoreThisProjectSaga;