import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, UncontrolledCollapse } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import Main from '../../layout/main';
import RegisterServices from "../../components/register/register-service";
import EditServiceTypes from "../../components/profile/editServiceTypes";
import { connect } from 'react-redux';
import API from '../../API'
import EmailUpdate from "../../components/profile/emailUpdate";
import MobileNumberUpdate from "../../components/profile/mobileNumberUpdate";
import UploadProfilePic from "../../components/profile/profileImageUpdate";
import ResetPassword from "../../components/profile/resetPassword";
import Loader from "../../common/loader";
import Buttons from "../../components/buttons/button-primary";
import { useDispatch, useSelector } from 'react-redux';
import Select from "../../components/inputs/select";
import EditCityAndRadius from "../../components/profile/editCityAndRadius";
import validate from "../../components/profile/profileValidation";
import { updateProfile } from "../../redux/actions/updateProfile"
import { Link } from 'react-router-dom';
import ProfileLoader from "../../components/profile/profileSkeleton";
import GetFeedback from "../../components/profile/getFeedback";
import ProfileProcess from "../../components/profile/profile-process";

class Profile extends Component {
    constructor(props) {
        super(props);
        const editProfileItems = this.props.editProfile.profileEdit
        this.state = {
            editMode: true,
            fieldsUpdated: false,

            altranatePhone: "",
            street_address: "",
            zipcode: "",
            company_name: "",
            companyType: 2,
            google_id: "",
            yelp_id: "",
            insurance_number: "",
            license_number: "",
            years_of_business: null,
            cityRadius: [],

            companyNameError: "",
            altranatePhoneError: "",
            street_addressError: "",
            zipcodeError: "",
            companyTypeError: "",
            google_idError: "",
            yelp_idError: "",
            insurance_numberError: "",
            license_numberError: "",
            years_of_businessError: "",
            project_type_ids: [],
            project_sub_type_ids: [],
            provider_locations_attributes: [],
            cityRadiusError: "",
            serviceError: "",
            showmoreService: false,
            uploadProPic: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.editSave = this.editSave.bind(this);
    }
    componentDidMount() {
        const { street_address,
            phone_number2,
            zipcode,
            company_name,
            company_type,
            google_id,
            yelp_id,
            insurance_number,
            license_number,
            years_of_business,
            project_types,
            project_sub_types,
            provider_locations
        } = this.props.profileData;
        const proSubTypes = [];
        const proTypes = [];
        project_sub_types && project_types.forEach((types => {
            proTypes.push(types.id);
        }))
        project_sub_types && project_sub_types.forEach((stypes => {
            proSubTypes.push(stypes.id);
        }))
        this.setState({
            altranatePhone: phone_number2,
            street_address: street_address,
            zipcode: zipcode,
            company_name: company_name,
            companyType: company_type,
            google_id: google_id,
            yelp_id: yelp_id,
            insurance_number: insurance_number,
            license_number: license_number,
            years_of_business: years_of_business,
            project_type_ids: proTypes,
            project_sub_type_ids: proSubTypes,
            provider_locations_attributes: provider_locations,
        })
    }

    clearErrors = () => {
        this.setState({
            companyNameError: "",
            altranatePhoneError: "",
            street_addressError: "",
            zipcodeError: "",
            companyTypeError: "",
            google_idError: "",
            yelp_idError: "",
            insurance_numberError: "",
            license_numberError: "",
            years_of_businessError: "",
            serviceError: "",
            cityRadiusError: ""
        })
    }
    handleChange(event) {
        this.setState({ editMode: event.target.value });
        () => this.clearErrors();
    }
    editSave = (event) => {
        event.preventDefault();
        const errors = validate(this.state);
        this.setState({ ...errors })
        let data = JSON.stringify({
            provider: {
                phone_number2: this.state.altranatePhone,
                street_address: this.state.street_address,
                zipcode: this.state.zipcode,
                company_name: this.state.company_name,
                company_type: this.state.companyType,
                google_id: this.state.google_id,
                yelp_id: this.state.yelp_id,
                years_of_business: this.state.years_of_business,

                insurance_number: this.state.insurance_number,
                license_number: this.state.license_number,

                project_type_ids: this.state.project_type_ids,
                project_sub_type_ids: this.props.selectedTypes.selectedSubTypes,
                provider_locations_attributes: this.state.cityRadius
            }
        })
        errors.valid && this.setState({ editMode: true })
        errors.valid && this.props.updateProfile(data);
        errors.valid && this.props.getLatest();
    }
    // Started Modal Box Open Function 
    state = {
        isOpen: false,
        editItem: null,
        file: null,
        fileName: null,
        editStatus: "9898",
        error: "",
        value: "",
        altranatePhone: 8129,
    };

    openModal = (id) => this.setState({ isOpen: true, editItem: id });
    //closeModal = () => this.setState({ isOpen: false });
    toggleModal = () => this.setState({ isOpen: false });
    // Ended Modal Box Open Function 

    handleChangeFile(event) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            fileName: event.target.files[0]
        })
    }

    //   handleChange(e) {
    //       this.setState({
    //         editStatus: e.target.value
    //       })

    //   }

    handleRegisterServices = (types, subTypes, storeBusiness) => {
        const locationAtt = storeBusiness.provider_locations_attributes;

        let selected_cities = []
        for (let index = 0; index < locationAtt && locationAtt.length; index++) {
            const location = this.state.locationAtt[index];

            selected_cities.push({
                location_name: location.cityName,
                city: location.cityName,
                state: location.state,
                state_short: location.state_short,
                country: location.country,
                radius: locationAtt[index].radius
            })
        }

    }
    handleInputChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
            fieldsUpdated: true,
            companyNameError: "",
            altranatePhoneError: "",
            street_addressError: "",
            zipcodeError: "",
            companyTypeError: "",
            google_idError: "",
            yelp_idError: "",
            insurance_numberError: "",
            license_numberError: "",
            years_of_businessError: "",
            cityRadiusError: "",
            serviceError: "",
        })
    }
    handleTypeSubmit = (data) => {
        this.setState({ fieldsUpdated: true });
    };
    convertPRoviderLocation = (locations) => {
        let out = [];
        locations && locations.length != 0 && locations.forEach(x => {
            out.push({
                location_name: x.city,
                radius: x.radius,
                id: x.id,
                state_short: x.state_short,
                state: x.state
            })
        });
        return out;
    }
    handleChangeCityRadius = (data) => {
        let selected_cities = []
        // selected_cities.push({
        //     id: location.id,
        //     location_name: location.location_name,
        //     city: location.city,
        //     state: location.state,
        //     state_short: location.state_short,
        //     country: location.country,
        //     _destroy: location.destroy,
        //     radius: location.radius
        // })
        this.setState({ cityRadius: data })
    }
    setPropsToState = (editProfileItems) => {
        // this.setState({
        //     altranatePhone: editProfileItems.phone_number2,
        //     street_address: editProfileItems.street_address,
        //     zipcode: editProfileItems.zipcode,
        //     company_name: editProfileItems.company_name,
        //     companyType: editProfileItems.company_type,
        //     google_id: editProfileItems.google_id,
        //     yelp_id: editProfileItems.yelp_id,
        //     insurance_number: editProfileItems.insurance_number,
        //     license_number: editProfileItems.license_number,
        //     years_of_business: editProfileItems.years_of_business,
        // })
    }
    setValue = () => {
        this.setState({ editMode: !this.state.editMode })
    }
    capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    setChangedServices = (data) => {
        this.setState({ project_sub_type_ids: data })
    };
    showMoreservice = () => {
        this.setState({ showmoreService: !this.state.showmoreService })
    }
    shareWith = () => {

    }
    uploadProfilePic = () => {
        console.log("click happening")
        this.setState({ uploadProPic: !this.state.uploadProPic })
    }
    render() {
        const editProfileItems = this.props.editProfile.profileEdit
        this.props.editProfile && this.props.editProfile.profileEdit && this.setPropsToState(this.props.editProfile.profileEdit)
        const profileItems = this.props.profile.profile
        const { serviceTypesData, authData } = this.props;
        const companies = [
            { label: "Company Type", value: "0" },
            { label: "Sole Proprietor", value: "1" },
            { label: "LLC", value: "2" },
            { label: "Corp", value: "3" }
        ];
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>My Account</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="profile pb-0">
                    <section className="ebw-section__pages ebw-section__profile">
                        <Container className="ebw-profile__inwrap">
                            {this.props.ProfileDataLoading ?
                                // <div className="ebw-data__loader">
                                <ProfileLoader />
                                // </div>
                                :

                                <div className="ebw-profile__row d-flex flex-wrap">
                                    <div className="ebw-profile__col ebw-profile__col-left">
                                        <div className="ebw-profile__col-wrap">
                                            <div className="ebw-profile__title ebw-title__primary mb-7">Edit Profile</div>
                                            <div className="ebw-profile__content d-md-flex d-lg-block">
                                                <div style={{ backgroundImage: `url(${profileItems && profileItems.avatar && profileItems.avatar})` }} className="ebw-profile__img d-flex align-items-end justify-content-center">
                                                    <UploadProfilePic
                                                        uploadProPic={this.state.uploadProPic}
                                                        uploadProfilePic={this.uploadProfilePic}
                                                    />
                                                </div>
                                                <div className="ebw-profile__content-right">
                                                    <div className="ebw-profile__name-wrap d-flex align-items-center mt-7">
                                                        <div className="ebw-profile__name mr-4">{profileItems.name}</div>
                                                        {profileItems.is_pro_provider && <div className="ebw-profile__pro pt-1 pr-2 pb-1 pl-2">PRO +</div>}
                                                    </div>
                                                    <EmailUpdate email={profileItems.email} />
                                                    <MobileNumberUpdate mobile_number={profileItems.mobile_number} />
                                                    <ProfileProcess
                                                        uploadProfilePic={this.uploadProfilePic}
                                                        profile_percent={this.props.profile_percent}
                                                        profile_score={this.props.profile_score}
                                                        provider_id={this.props.provider_id}

                                                    />
                                                    <Link to="/review">
                                                        <div className="ebw-profile__rating d-flex align-items-center mt-4">
                                                            <div className="ebw-profile__rating-number pr-2 pl-1">{profileItems.average_ratings}<span className="ebw-icon ebw-icon--lg icon-review pl-1 ebw-profile__rating-star"></span></div>
                                                            <div className="ebw-profile__rating-review ml-1">{profileItems.total_reviews} Reviews</div>
                                                        </div>
                                                    </Link>

                                                    <GetFeedback provider_id={editProfileItems.id} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ebw-profile__col ebw-profile__col-right mt-10 mt-md-0">
                                        <div className="ebw-profile__col-two">
                                            <div className="ebw-profile__editprofile mt-1 mb-1">
                                                {/*<img className="ebw-profile__editprofile-icon" src="./images/profile/edit-icon.png"></img>*/}
                                                <div className="d-flex flex-wrap align-items-center">
                                                    {this.state.editMode ?
                                                        <div onClick={() => this.setValue()} className="ebw-profile__editprofile-btn">
                                                            <span className="ebw-icon ebw-icon--md icon-edit"></span>
                                                            <span className="ml-1 ebw-profile__editprofile-text text-md">Edit Profile</span>
                                                        </div> :
                                                        <div onClick={() => this.setValue()} className="ebw-profile__editprofile-btn">
                                                            <span className="ebw-icon ebw-icon--md icon-close ebw-bold"></span>
                                                            <span className="ml-1 ebw-profile__editprofile-text text-md">Cancel Edit</span>
                                                        </div>}
                                                </div>
                                            </div>
                                            <Form className="ebw-profile__details d-md-flex flex-wrap justify-content-between">
                                                <div className="row pb-6">

                                                    <div className="col-md-6">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__no-phone ebw-profile__name-gray">Alternate phone</Label>
                                                            <div className="ebw-profile__no position-relative">
                                                                {!this.state.editMode || this.state.altranatePhone == "" ?
                                                                    <Input
                                                                        type="number"
                                                                        className={`ebw-profile__no-num ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                        value={this.state.altranatePhone}
                                                                        name="altranatePhone"
                                                                        onChange={this.handleInputChange}
                                                                        disabled={this.state.editMode}
                                                                    /> : " "
                                                                }
                                                                {!this.state.editMode || this.state.altranatePhone == "" || this.state.altranatePhone == null &&
                                                                    <div>
                                                                        <p>Not provided yet</p>
                                                                    </div>
                                                                }
                                                                {this.state.altranatePhoneError != "" &&
                                                                    <p className="ebw-input__error">{this.state.altranatePhoneError}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__address-name ebw-profile__name-gray">Business Address</Label>
                                                            <div className="ebw-profile__address position-relative">
                                                                <Input
                                                                    type="text"
                                                                    className={`ebw-profile__address-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                    value={this.state.street_address}
                                                                    name="street_address"
                                                                    onChange={this.handleInputChange}
                                                                    disabled={this.state.editMode}
                                                                />
                                                                {this.state.street_addressError != "" &&
                                                                    <p className="ebw-input__error">{this.state.street_addressError}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__zip-name ebw-profile__name-gray">ZIP</Label>
                                                            <div className="ebw-profile__zip position-relative">
                                                                <Input
                                                                    type="number"
                                                                    className={`ebw-profile__zip-number ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                    value={this.state.zipcode}
                                                                    name="zipcode"
                                                                    onChange={this.handleInputChange}
                                                                    disabled={this.state.editMode}
                                                                />
                                                                {this.state.zipcodeError != "" &&
                                                                    <p className="ebw-input__error">{this.state.zipcodeError}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__company-name ebw-profile__name-gray">Company Name</Label>
                                                            <div className="ebw-profile__company position-relative">
                                                                <Input
                                                                    type="text"
                                                                    className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                    value={this.state.company_name}
                                                                    name="company_name"
                                                                    onChange={this.handleInputChange}
                                                                    disabled={this.state.editMode}
                                                                />
                                                                {this.state.companyNameError != "" &&
                                                                    <p className="ebw-input__error">{this.state.companyNameError}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__type-text ebw-profile__name-gray">Company Type</Label>
                                                            <div className="ebw-profile__type position-relative">
                                                                <Select
                                                                    // type="select"
                                                                    name="companyType"
                                                                    additionalClass={`ebw-profile__type-input ebw-profile__name-text ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                                    value={this.state.companyType}
                                                                    changeEvent={this.handleInputChange}
                                                                    data={companies}
                                                                    disabled={this.state.editMode}

                                                                />
                                                                {this.state.companyTypeError != "" &&
                                                                    <p className="ebw-input__error">{this.state.companyTypeError}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__type-text ebw-profile__name-gray">City Services</Label>
                                                            <div className="ebw-profile__type position-relative">
                                                                {this.state.editMode ?
                                                                    <div className="d-flex flex-wrap align-items-center">

                                                                        {editProfileItems && editProfileItems.provider_locations && editProfileItems.provider_locations.length != 0 && editProfileItems.provider_locations.map((cities, index) => (
                                                                            <div key={index} className="ebw-profile__service-city d-flex align-items-center mb-2">
                                                                                <div key={index} className="ebw-profile__type-input mr-2">{this.capitalize(cities.city)} - {cities.radius}mi</div>
                                                                            </div>
                                                                        ))}
                                                                    </div> : <>
                                                                        <EditCityAndRadius
                                                                            locations={this.convertPRoviderLocation(editProfileItems.provider_locations)}
                                                                            handleChangeCityRadius={this.handleChangeCityRadius} />
                                                                        {this.state.cityRadiusError != "" &&
                                                                            <p className="ebw-input__error">{this.state.cityRadiusError}</p>
                                                                        }
                                                                    </>

                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mt-4">
                                                            <Label className="ebw-profile__service-view ebw-profile__name-gray">Service list</Label>
                                                            <div className="ebw-profile__service position-relative">
                                                                <div className="mt-1">
                                                                    <div>
                                                                        {/* {showmoreService} */}
                                                                        <div>
                                                                            {!this.state.showmoreService && editProfileItems && editProfileItems.project_sub_types && editProfileItems.project_sub_types.slice(0, 15).map((serviceItem, index) =>
                                                                            (
                                                                                <>
                                                                                    <span key={index} className="mb-2">{serviceItem.name}{index + 1 != 15 ? "," : ""} </span>
                                                                                </>
                                                                            )
                                                                            )}
                                                                            {/* {this.state.showmoreService && editProfileItems.project_sub_types.map((serviceItem, index) => (
                                                                                <>
                                                                                    <span key={index} className="mb-2">{serviceItem.name} {index + 1 != editProfileItems.project_sub_types.length ? "," : ""}</span>
                                                                                </>
                                                                            )
                                                                            )} */}
                                                                        </div>
                                                                        {this.state.editMode &&
                                                                            <div className="ebw-profile__service-link " onClick={() => this.openModal('services')}>{this.state.showmoreService ? "show less" : "show more"}</div>
                                                                        }
                                                                    </div>
                                                                    {!this.state.editMode &&
                                                                        <div onClick={() => this.openModal('services')} className="ebw-profile__service-link">{this.state.editMode ? "View service list" : "Edit service list"}</div>
                                                                    }
                                                                </div>
                                                                {this.state.serviceError ? <p className="ebw-input__error ebw-input__error--service-list">{this.state.serviceError}</p> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>

                                            <Row className="ebw-profile__id mt-4">
                                                <Col md="12">
                                                    <div className=" card ebw-card ebw-profile__google">
                                                        <div style={{ backgroundImage: "url(/images/profile/google-yelp@2x.png)" }} className="card-body ebw-profile__google-inwrap">
                                                            <p className="ebw-profile__google-para mb-5">Add Google and Yelp star ratings (This gives customers a sense of your performance through a verified source)
                                                            </p>
                                                            <p className="ebw-profile__google-para ebw-profile__google-para--medium mb-1">Not sure of how to fetch your Google and Yelp ID ?</p>
                                                            <a className="ebw-profile__google-btn" target="_blank" href="https://blog.easybids.com/how-to-get-google-and-yelp-id-for-easybids-app">Learn here</a>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Col md="6" className="ebw-profile__google mt-4">
                                                    <p>
                                                        Add Google and Yelp star ratings (This gives customers a sense of your performance through a verified source)
                                                    </p>
                                                </Col>
                                                <Col md="6" className="ebw-profile__google mt-4">
                                                    <div className="ebw-profile__google-name ebw-profile__name-gray pb-2">
                                                        <a className="ebw-profile__yelpbanner" target="_blank" href="https://blog.easybids.com/how-to-get-google-and-yelp-id-for-easybids-app">
                                                            <img className="ebw-profile__yelpbanner__img" src="/images/register-google-yelp.png" />
                                                        </a>
                                                    </div>
                                                </Col> */}

                                                <Col md="6" className="ebw-profile__google mt-4">
                                                    <div className="ebw-profile__google-name ebw-profile__name-gray pb-2">Google ID</div>
                                                    <Input
                                                        type="text"
                                                        className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                        value={this.state.google_id}
                                                        name="google_id"
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.editMode}
                                                    />
                                                    {!this.state.editMode || this.state.google_id == "" &&
                                                        <div>
                                                            <p>Not provided yet</p>
                                                        </div>
                                                    }
                                                    {this.state.google_idError != "" &&
                                                        <p className="ebw-input__error">{this.state.google_idError}</p>
                                                    }
                                                    {/* <div className="ebw-profile__google-text ebw-profile__name-text">{editProfileItems.google_id == "" ? "--" : editProfileItems.google_id}</div> */}
                                                </Col>

                                                <Col md="6" className="ebw-profile__yelp mt-4">
                                                    <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">Yelp ID</div>
                                                    <Input
                                                        type="text"
                                                        className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                        value={this.state.yelp_id}
                                                        name="yelp_id"
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.editMode}
                                                    />
                                                    {!this.state.editMode || this.state.yelp_id == "" &&
                                                        <div>
                                                            <p>Not provided yet</p>
                                                        </div>
                                                    }
                                                    {this.state.yelp_idError != "" &&
                                                        <p className="ebw-input__error">{this.state.yelp_idError}</p>
                                                    }
                                                    {/* <div className="ebw-profile__yelp-text ebw-profile__name-text">{editProfileItems.google_id == "" ? "--" : editProfileItems.yelp_id}</div> */}
                                                </Col>

                                                <Col md="6" className="ebw-profile__yelp mt-4">
                                                    <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">Insurance ID</div>
                                                    <Input
                                                        type="text"
                                                        className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                        value={this.state.insurance_number}
                                                        name="insurance_number"
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.editMode}
                                                    />
                                                    {!this.state.editMode || this.state.insurance_number == "" &&
                                                        <div>
                                                            <p>Not provided yet</p>
                                                        </div>
                                                    }
                                                    {this.state.insurance_numberError != "" &&
                                                        <p className="ebw-input__error">{this.state.insurance_numberError}</p>
                                                    }
                                                    {/* <div className="ebw-profile__yelp-text ebw-profile__name-text">{editProfileItems.insurance_number == "" ? "--" : editProfileItems.insurance_number}</div> */}
                                                </Col>

                                                <Col md="6" className="ebw-profile__yelp mt-4">
                                                    <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">License ID</div>

                                                    <Input
                                                        type="text"
                                                        className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                        value={this.state.license_number}
                                                        name="license_number"
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.editMode}
                                                    />
                                                    {!this.state.editMode || this.state.license_number == "" &&
                                                        <div>
                                                            <p>Not provided yet</p>
                                                        </div>
                                                    }
                                                    {this.state.license_numberError != "" &&
                                                        <p className="ebw-input__error">{this.state.license_numberError}</p>
                                                    }
                                                    {/* <div className="ebw-profile__yelp-text ebw-profile__name-text">{editProfileItems.license_number == "" ? "--" : editProfileItems.license_number}</div> */}
                                                </Col>

                                                <Col md="6" className="ebw-profile__yelp mt-4">
                                                    <div className="ebw-profile__yelp-name ebw-profile__name-gray pb-2">Number of years in business</div>

                                                    <Input
                                                        type="number"
                                                        className={`ebw-profile__company-text ebw-profile__name-text ebw-form__input ebw-profile__input ${this.state.editMode ? "" : "edit-enable"}`}
                                                        value={this.state.years_of_business}
                                                        name="years_of_business"
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.editMode}
                                                    />

                                                    {!this.state.editMode || this.state.years_of_business == "" &&
                                                        <p className="ebw-input__error">{this.state.years_of_businessError}</p>
                                                    }
                                                </Col>

                                                <Col sm="12" className="ebw-profile__yelp mt-4">
                                                    <ResetPassword email={profileItems.email} />
                                                </Col>
                                                <Col sm="12" className="ebw-profile__yelp mt-4">
                                                    {!this.state.editMode &&
                                                        <a role="button" onClick={this.editSave} className="ml-1 ebw-button ebw-button__primary py-2 d-flex align-items-center justify-content-center">
                                                            <span>Save</span>
                                                        </a>}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            }

                        </Container>
                    </section>

                    {/* Started Modal Box */}
                    <div className="ebw-profile__modal">

                        <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
                            {this.state.editItem == "services" ?

                                <div className="px-5 py-5 ebw-modal__wrap">
                                    <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                        <div className="ebw-modal__title mb-1">Service List</div>
                                    </ModalHeader>
                                    <ModalBody className="ebw-modal__body">
                                        {this.state.editMode ?
                                            <>
                                                {editProfileItems.project_sub_types.map((serviceItem, index) => {
                                                    return (
                                                        <div key={index} className="mb-2">{serviceItem.name}</div>
                                                    )
                                                })}
                                            </> :

                                            <EditServiceTypes
                                                projectSubTypes={editProfileItems.project_sub_types}
                                                serviceTypesData={serviceTypesData}
                                                selectedSubTypesPrev={editProfileItems.project_sub_types}
                                                closeModal={this.toggleModal}
                                                handleRegisterServices={this.handleTypeSubmit}
                                                setChangedServices={this.setChangedServices}
                                            />
                                        }
                                    </ModalBody>
                                </div>
                                :
                                // this.state.editItem == "resetPassword" ?

                                // <div className="px-5 py-5">
                                //     <div className="ebw-profile__modal-head mb-4">
                                //         <div className="ebw-modal__title mb-1">Reset your Password</div>
                                //     </div>
                                //     <div className="ebw-modal__body">
                                //         <form className="ebw-modal__form">
                                //             <div className="ebw-modal__input-wrap mb-2">
                                //                 <Input className="ebw-modal__input" type="number" placeholder="Enter new password" required></Input>
                                //             </div>
                                //             <div className="ebw-modal__input-wrap mb-2">
                                //                 <Input className="ebw-modal__input" type="number" placeholder="Confirm password" required></Input>
                                //             </div>
                                //             <div className="text-right">
                                //                 <button className="ebw-modal__submit ebw-button__primary" type="submit">Submit</button>
                                //             </div>
                                //         </form>
                                //     </div>
                                // </div>

                                // :
                                this.state.editItem == "changeDP" ?

                                    <div className="px-5 py-5 ebw-modal__wrap">
                                        <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                            <div className="ebw-modal__title mb-1">Reset your Password</div>
                                        </ModalHeader>
                                        <ModalBody className="ebw-modal__body">
                                            <form className="ebw-modal__form">
                                                <div className="ebw-modal__input-wrap mb-4">
                                                    <Input id="uploadDP" className="ebw-modal__input d-none" type="file" onChange={this.handleChange} required></Input>
                                                    <label for="uploadDP" className="ebw-my__uploads-file m-0 w-100">
                                                        <div className="ebw-my__uploads-inwrap text-center">
                                                            <div className="ebw-form__input ebw-profile__input-file--custom d-flex justify-content-between align-items-center btn btn-secondary">
                                                                <span className="ebw-form__input ebw-profile__input-file--text">Choose File</span>
                                                                <span className="ebw-icon ebw-icon--xl icon-arrow-down"></span>
                                                            </div>
                                                            <p className="ebw-my__uploads-text pt-3">Or drop files here. Max file size 10MB</p>
                                                            {this.state.file ?
                                                                <div>
                                                                    <div style={{ backgroundImage: `url(${this.state.file})` }} className="ebw-my__uploads-img"></div>
                                                                    <div>{this.state.fileName.name}</div>
                                                                </div>
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="text-right mt-6">
                                                    <button className="ebw-modal__submit ebw-button__primary" type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </ModalBody>
                                    </div>

                                    :
                                    this.state.editItem == "changeDP" ?

                                        <div className="px-5 py-5 ebw-modal__wrap">
                                            <ModalHeader toggle={this.toggleModal} className="ebw-profile__modal-head" style={{ border: "none" }}>
                                                <div className="ebw-modal__title text-capitalize mb-1">Upload Profile Picture</div>
                                            </ModalHeader>
                                            <ModalBody className="ebw-modal__body">
                                                <form className="ebw-modal__form">
                                                    <div className="ebw-modal__input-wrap mb-4">
                                                        <Input id="uploadDP" className="ebw-modal__input d-none" type="file" onChange={this.handleChange} required></Input>
                                                        <label for="uploadDP" className="ebw-my__uploads-file m-0 w-100">
                                                            <div className="ebw-my__uploads-inwrap text-center">
                                                                <div className="ebw-form__input ebw-profile__input-file--custom d-flex justify-content-between align-items-center btn btn-secondary">
                                                                    <span className="ebw-form__input ebw-profile__input-file--text">Choose File</span>
                                                                    <span className="ebw-icon ebw-icon--xl icon-arrow-down"></span>
                                                                </div>
                                                                <p className="ebw-my__uploads-text pt-3">Or drop files here. Max file size 10MB</p>
                                                                {this.state.file ?
                                                                    <div>
                                                                        <div style={{ backgroundImage: `url(${this.state.file})` }} className="ebw-my__uploads-img"></div>
                                                                        <div>{this.state.fileName.name}</div>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="text-right">
                                                        <button className="ebw-modal__submit ebw-button__primary" type="submit">Update</button>
                                                    </div>
                                                </form>
                                            </ModalBody>
                                        </div>

                                        : ""
                            }
                        </Modal>
                    </div>
                    {/* Ended Modal Box */}
                </Main>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profileData,
    editProfile: state.getProfileEditData,
    serviceTypesData: state.serviceTypes,
    selectedTypes: state.selectedTypes,
    authData: state.auth,
    servedCities: state.servedCities.cities


});

export default connect(mapStateToProps, { updateProfile })(Profile);;
