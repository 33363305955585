import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from "../../../API";

async function getApi(data) {
    const apiUrl = API.baseURL + API.endPoints.activitiesInConversation;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* fetchInconversationsActivities(action) {
    try {
        const activities = yield call(getApi, action.payload);
        yield put({ type: 'GET_ACTIVITIES_INCONVERSATION_SUCCESS', inConversation: activities.data.data });
    } catch (e) {
        yield put({ type: 'GET_ACTIVITIES_INCONVERSATION_FAILED', message: e.message });
    }
}
function* inConversationActivitiesSaga() {
    yield takeEvery('GET_ACTIVITIES_INCONVERSATION_REQUESTED', fetchInconversationsActivities);
}
export default inConversationActivitiesSaga;