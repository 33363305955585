import * as type from './types';
const initialState = {
    bookmarked: [],
    loading: false,
    success: false,
    deleteSuccess: false,
    popData: {},
    error: null,
}

export default function bookmark(state = initialState, action) {

    switch (action.type) {
        // |||||||||||||||||||||||||| GET BOOKMARKED |||||||||||||||||||||||||\\
        case type.GET_BOOKMARKED_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_BOOKMARKED_SUCCESS:
            return {
                ...state,
                bookmarked: [],
                loading: false,
            }
        case type.GET_BOOKMARKED_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        // |||||||||||||||||||||||||| ADD BOOKMARK |||||||||||||||||||||||||\\
        case type.ADD_BOOKMARK_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_BOOKMARK_SUCCESS:
            return {
                ...state,
                bookmarked: [],
                success: true,
                popData: action.popData,
                loading: false,
            }
        case type.ADD_BOOKMARK_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        // |||||||||||||||||||||||||| DELETE BOOKMARK |||||||||||||||||||||||||\\
        case type.DELETE_BOOKMARK_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.DELETE_BOOKMARK_SUCCESS:
            return {
                ...state,
                bookmarked: [],
                deleteSuccess: true,
                popData: action.popData,
                loading: false,
            }
        case type.DELETE_BOOKMARK_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        // |||||||||||||||||||||||||| CLEAR POP |||||||||||||||||||||||||\\
        case type.CLEAR_POP_BOOKMARK_SUCCESS:
            return {
                ...state,
                deleteSuccess: false,
                success: false,
                popData: {},
            }
        default:
            return state
    }
}