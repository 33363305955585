import React, { useEffect } from 'react';
import GetFeedBack from "./getReviews";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

const GetReviess = (props) => {
    const { id } = props.match.params;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <>
            <Header />
            <GetFeedBack id={id} />
            <Footer />
        </>
    );
}

export default GetReviess;