import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ProPlus from "./pro-plus";
import { getProfileEditData } from "../../redux/actions/profileEditData"

const ProPlusPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProfileEditData());
    }, [])
    const ProfileData = useSelector(state => state.getProfileEditData.profileEdit);
    const ProfileDataLoading = useSelector(state => state.getProfileEditData.loading);
    const ProfileDataError = useSelector(state => state.getProfileEditData.error);

    return (
        <>
            <Header />
            <ProPlus ProfileData={ProfileData} ProfileDataLoading={ProfileDataLoading} />

            {/* <Footer/> */}
        </>
    );
}

export default ProPlusPage;