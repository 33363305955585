const validate = (inputs) => {
    let errors = {
        valid: true,
    };
    //company name errors
    if (inputs.bid_amount === "") {
        errors.bidAmountError = 'Enter bid amount'
        errors.valid = false;
    }
    //company type errors
    if (inputs.bid_type == "hourly" && inputs.hours == "") {
        errors.hoursError = 'Enter number of hours'
        errors.valid = false;
    }

    return errors;
}
export default validate;