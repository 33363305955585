import React, { Component } from 'react';
class OtpVerification extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                OTP page
            </div>
         );
    }
}
 
export default OtpVerification;