import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'reactstrap';

import Main from '../../layout/main'
import { InputText } from "../../components/inputs/text-input";

const Support = () => {
    // const faqs = [
    //     {
    //         question: "WHO PAYS THE FEE?",
    //         answer: `The customer pays the fee up-front to log in the job, however, it is subtracted from the total bid price.<br />
    //         <strong>NOTE: </strong>When inputting your bid price, the system will advise you of your net amount after the small Easy Bids fee.`
    //     }
    // ];
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="We strive to constantly to give minute to minute support with the excellent Support techinicians avaible round the clock" />
                <title>Support - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="support">
                <section className="ebw-section ebw-section--support">
                    <div className="ebw-support__inner">
                        <Container>
                            <h1 className="ebw-support__title mb-6">Support and Customer Service</h1>
                            <Row>
                                <Col sm="12">
                                    <div className="ebw-support__cards d-md-flex flex-wrap flex-row ">
                                        <Col md="12" lg="4" className="h-100 px-0 px-md-2">
                                            <div className="ebw-support__card-lft">
                                                <h5 className="ebw-support__card-support mb-1">Easybids support is available</h5>
                                                <h2 className="ebw-support__card-available">24/7</h2>
                                            </div>
                                        </Col>

                                        <Col md="6" lg="4" className="h-100 px-0 px-md-2">
                                            <div className="ebw-support__card">
                                                <div className="ebw-support__card-icon">
                                                    <span className="ebw-icon ebw-icon--7xl icon-support-headphone"></span>
                                                    {/*<img src="./images/icons/icon-headphones.svg" alt="Call Us at" className="img-fluid d-block"/>*/}
                                                </div>
                                                <div className="ebw-support__card-info">
                                                    <h6 className="ebw-support__card-title mb-1">Call us at</h6>
                                                    <a href="tel:+1 8291 20009" className="ebw-support__card-desc">+1 8291 20009</a>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md="6" lg="4" className="h-100 px-0 px-md-2">
                                            <div className="ebw-support__card">
                                                <div className="ebw-support__card-icon">
                                                    <span className="ebw-icon ebw-icon--7xl icon-mail"></span>
                                                    {/*<img src="./images/icons/icon-mail.svg" alt="Write us at" className="img-fluid d-block"/>*/}
                                                </div>
                                                <div className="ebw-support__card-info">
                                                    <h6 className="ebw-support__card-title mb-1">Write us at</h6>
                                                    <a href="mailto:support@easybids.com" className="ebw-support__card-desc">support@easybids.com</a>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>


                                <Col sm="12" className="my-6 my-md-12">
                                    <div className="ebw-support__frequent">
                                        <h4 className="ebw-support__title mb-6">Frequently asked questions.</h4>
                                        {/* <div className="ebw-support__search">
                                            <InputText
                                                label="e.g: What is the process?"
                                                id="support_search"
                                                name="support_search"
                                            />
                                        </div> */}

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">WHO PAYS THE FEE?</p>
                                            <p className="ebw-support__frequent-ans">
                                                The customer pays the fee up-front to log in the job, however, it is subtracted from the total bid price.<br />
                                                <strong>NOTE: </strong>When inputting your bid price, the system will advise you of your net amount after the small Easy Bids fee.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">WHAT IS THE FEE SCALE?</p>
                                            <p className="ebw-support__frequent-ans">
                                                <span>1000 or less = 5%</span><br />
                                                <span>2000 or less = 25%</span><br />
                                                <span>3000 or less = 35%</span><br />
                                                <span>4000 or less = 45%</span>
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">HOW DO WE GET PAID?</p>
                                            <p className="ebw-support__frequent-ans">
                                                Customer pays you directly. You will provide the customer with a final invoice, job description and schedule of values.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">HOW DO CHANGE ORDERS WORK?</p>
                                            <p className="ebw-support__frequent-ans">
                                                If the customer agrees upon the change order pricing, they will pay you directly.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">WHEN IS THE CONNECTION MADE FROM CONSUMER TO SERVICE PROVIDER?</p>
                                            <p className="ebw-support__frequent-ans">
                                                The customer and service provider will receive relevant contact information to schedule a site walk,once the customer hires the service provider.
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">WHAT IS THE PROCESS?</p>
                                            <p className="ebw-support__frequent-ans">
                                                Customer inputs job details, service providers bid, customer hires you and you begin…It’s that simple!
                                            </p>
                                        </div>

                                        <div className="ebw-support__frequent-inwrap">
                                            <p className="ebw-support__frequent-qstn text-uppercase">HOW DO WE GET MEASUREMENTS TO BID?</p>
                                            <p className="ebw-support__frequent-ans">
                                                Several ways. For outdoors, we use aerial footage to capture measurements of roofs, windows,solar, gutters, patios, fencing, decking and more. For indoors, we use augmented reality to measure anything from cabinets, floors, doors, and much more.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default Support;