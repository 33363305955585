import React, { useState } from 'react'
import { Card, CardTitle, CardText, CardImg, CardBody, CardSubtitle } from 'reactstrap';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addBookamark } from "../../redux/reduxes/bookMark/addBookamrkAction";
import { deleteBookmark } from "../../redux/reduxes/bookMark/deleteBookmarkAction";
import { clearPopBookamark } from "../../redux/reduxes/bookMark/clearPopBookamrkAction";

const CardSecondary = ({
    projectName,
    projectCategory,
    projectImage,
    projectDate,
    projectLocation,
    NoArrow,
    NoImage,
    Estimate,
    buyedAmount,
    projectBudjet,
    bidAmount,
    projectEstimationHour,
    paymentBending,
    projectLinks,
    paymentLink,
    projectStatusLabel,
    projectStatusLight,
    project_status,
    project_id,
    status,
    bidtype,
    project_post_type,
    mobile_number,
    bookmarked_by_current_provider,
    noLocation,
    project_type,
    provider_project_details,
}) => {
    var nf = new Intl.NumberFormat();
    const getStatusOfSTatus = (stat) => {
        if (stat == 2) {
            return 'Available for Bid'
        } else if (stat == 3) {
            return 'Hired'
        } else if (stat == 4) {
            return 'Completed'
        } else if (stat == 9) {
            return 'Cancelled'
        } else if (stat == 6) {
            return "Cancellation Requested"
        }
    }

    const dispatch = useDispatch();
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    const [bookmark, setBookmark] = useState(bookmarked_by_current_provider);
    const changeIcon = () => setBookmark(!bookmark)
    const bookmarkThis = (bookmarked, id) => {
        const data = {
            id: id,
            projectName: projectName,
            project_type: project_type,
            project_img_url: projectImage,
        }
        changeIcon();
        if (bookmarked) {
            dispatch(deleteBookmark(data));
            setTimeout(() => {
                dispatch(clearPopBookamark())
            }, 5000);
        } else {
            dispatch(addBookamark(data));
            setTimeout(() => {
                dispatch(clearPopBookamark())
            }, 5000);
        }
    }
    const bidAmountSection = (bidAmount, bidtype, projectEstimationHour) => {
        function calcTotal(a, b) {
            return Number(a) * Number(b);
        }
        return (
            bidtype === "hourly" ?
                <div className="mt-8">
                    <div className="ebw-card__bill-wrap">
                        <div className="d-flex justify-content-between pb-2">
                            <CardText className="ebw-card__text--md mb-0"><b>Bid Amount</b></CardText>
                            <CardText className="ebw-card__text--md mb-0">${bidAmount} /hr</CardText>
                        </div>
                        <div className="d-flex justify-content-between pb-2">
                            <CardText className="ebw-card__text--md mb-0"><b>Approx. hr</b></CardText>
                            <CardText className="ebw-card__text--md mb-0">{projectEstimationHour} hr</CardText>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <CardText className="ebw-card__text--green mb-0">Total</CardText>
                        <CardText className=""><b>${nf.format(calcTotal(bidAmount, projectEstimationHour))}</b></CardText>
                    </div>
                </div> :
                <div className="d-flex justify-content-between mt-2">
                    <CardText className="ebw-card__text--green mb-0">Total</CardText>
                    <CardText className=""><b>${nf.format(bidAmount)}(Fixed bid)</b></CardText>
                </div>
        )
    }
    const bidAmountHired = (bidAmount, bidtype, projectEstimationHour) => {
        return (
            bidtype === "hourly" ?
                <div className="mt-8">
                    <div className="d-flex justify-content-between pb-2">
                        <CardText className="ebw-card__text--md mb-0"><b>Bid Amount</b></CardText>
                        <CardText className="ebw-card__text--md mb-0">${bidAmount} /hr</CardText>
                    </div>
                </div> :
                <div className="mt-8">
                    <div className="d-flex justify-content-between pb-2">
                        <CardText className="ebw-card__text--md mb-0"><b>Bid Amount</b></CardText>
                        <CardText className="ebw-card__text--md mb-0">${bidAmount}(Fixed bid)</CardText>
                    </div>
                </div>

        )
    }
    return (
        <div className="ebw-card__wrap ebw-card__wrap--secondary">
            <Card className="ebw-card ebw-card__secondary" >
                <Link className="w-100" to={projectLinks}>
                    <div className="ebw-card__image-wrap">
                        <div style={{ backgroundImage: `url(${NoImage === true ? "" : projectImage})` }} className="ebw-card__image-inwrap">
                            {/* {NoImage === true ? "" : <CardImg className="ebw-card__image" src={projectImage} alt={projectName}></CardImg>} */}
                        </div>
                    </div>
                </Link>
                <CardBody className={` ${status ? "d-flex flex-column justify-content-between ebw-card__body-status" : "ebw-card__secondary-body"}`}>
                    <div>
                        <div className="d-flex flex-wrap justify-content-between">
                            <Link className="ebw-card__secondary-left" to={projectLinks}>
                                <div>
                                    <CardTitle className="ebw-card__secondary-title">{capitalize(projectName)}</CardTitle>
                                </div>
                            </Link>
                            <div className="text-right ebw-card__secondary-right">
                                <div className="ebw-card__bookmark" onClick={() => bookmarkThis(bookmarked_by_current_provider, project_id)}><img src={`/images/icons/${bookmark ? "bookmark-icon-filled" : "bookmark-icon-outline"}.svg`} alt="" /></div>

                                <CardText className="ebw-card__date">{projectDate}</CardText>
                            </div>
                            <CardSubtitle className="ebw-card__subtitle mb-4">{capitalize(projectCategory)}</CardSubtitle>
                        </div>
                        {<>
                            <div className="ebw-card__secondary-status ebw-card__label">{getStatusOfSTatus(status)}</div>
                        </>}
                        {status && status != 6 && status != 9 && provider_project_details && !provider_project_details.is_commission_paid && provider_project_details.amount_payable_to_easybids_after_discount != 0 &&
                            <div className={`ebw-card__status ebw-card__text ebw-card__status--red ebw-card__redtext`}>Pay the Service Fee now</div>
                        }
                        <div className="d-flex justify-content-between">
                            {!noLocation &&
                                <CardText className="d-flex align-items-center ebw-card__location mb-0">
                                    <span className="ebw-icon ebw-icon--xl icon-location">
                                        {/* <img src="./images/icons/icon-location.svg"></img> */}
                                    </span>
                                    <div className="ml-1">{capitalize(projectLocation)}</div>
                                </CardText>
                            }
                            {NoArrow === true || Estimate === true || buyedAmount === true ? "" : <Link className="ebw-card__link" to={projectLinks}><span className="ebw-icon ebw-icon--4xl icon-arrow-straight"></span></Link>}
                        </div>
                    </div>
                    <div>
                        <>
                            {Estimate === true &&
                                bidAmountSection(bidAmount, bidtype, projectEstimationHour)
                            }
                        </>
                        <>
                            {buyedAmount === true && project_post_type === 1 &&
                                bidAmountHired(bidAmount, bidtype, projectEstimationHour)
                            }
                        </>

                        <>
                            {Estimate === true || buyedAmount === true ?
                                <div className={`d-flex align-items-center ${mobile_number && buyedAmount === true ? "justify-content-between mt-4 mb-4" : "justify-content-end"}`}>
                                    {mobile_number && buyedAmount === true ?
                                        <div className="d-flex">
                                            <span className="ebw-card__phone-icon ebw-icon ebw-icon--lg icon-phone"></span>
                                            <div className="ebw-card__phone-label ml-2">{mobile_number}</div>
                                        </div>
                                        : ""
                                    }
                                    {/* <a className="ebw-card__link" href="">
                                            <span className="ebw-icon ebw-icon--4xl icon-slide-right"></span>
                                        </a> */}
                                </div>
                                : ""
                            }
                        </>
                    </div>
                </CardBody>

                {paymentBending ?
                    <div className="ebw-card__pending d-flex justify-content-between align-items-center mt-5 card-body">
                        <div className="ebw-card__pending-wrap">
                            <div className="ebw-card__pending-title">Pending Payment</div>
                            <div className="ebw-card__pending-subtitle">You have been hired by the customer</div>
                        </div>
                        <a href={paymentLink} className="ebw-button__primary ebw-button__primary--radius pt-1 pb-1 pr-5 pl-5">Pay</a>
                    </div>
                    : ""
                }
            </Card>
        </div>
    );
}

export default CardSecondary