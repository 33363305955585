import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Faqs from "./faqs";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

const FaqsPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <>
            <Header />
            <Faqs />
            <Footer />
        </>
    );
}

export default FaqsPage;