import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import InputPassword from '../inputs/password-input';
import Buttons from "../buttons/button-primary";
import API from '../../API';

const ResetPassword = (props) => {
    const { email } = props;
    const [isOpen, openModal] = useState(false);
    const [state, setState] = useState({
        password: "",
        passwordRe: "",

        successNote: "",

        passwordError: "",
        passwordReError: ""
    });

    const toggleModal = () => {
        openModal(!isOpen);
    }
    const validate = (inputs) => {
        let errors = {
            valid: true,
        };
        //Password Errors
        if (inputs.password.length < 6) {
            errors.passwordError = 'Check Password'
            errors.valid = false;
        }
        if (!inputs.password) {
            errors.passwordError = 'Enter Password'
            errors.valid = false;
        }
        //Confirm Password Errors
        if (inputs.passwordRe !== inputs.password) {
            errors.passwordReError = 'Enter Same Password'
            errors.valid = false;
        }
        if (inputs.passwordRe.length < 6) {
            errors.passwordReError = 'Check Password'
            errors.valid = false;
        }
        if (!inputs.passwordRe) {
            errors.passwordReError = 'Confirm Password'
            errors.valid = false;
        }
        return errors;
    }

    const handleChangePass = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            password: event.target.value,
            passwordError: "",
            passwordReError: ""
        }));
    }
    const handleChangePassRe = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            passwordRe: event.target.value,
            passwordError: "",
            passwordReError: ""
        }));
    }
    const sendUpdatedEmail = (event) => {
        event.preventDefault();
        const error = validate(state)
        setState((prevProps) => ({
            ...prevProps,
            ...error
        }));
        const data = JSON.stringify({
            password: state.password,
            email: email
        });
        error.valid && axios.post(API.baseURL + `/update_user_password`, data, API.options)
            .then(response => {
                const result = response.data;
                setState((prevProps) => ({
                    ...prevProps,
                    successMessage: result.data.message
                }));
                // toastr.success('Success', result.data.message);
                openModal(false);
            })
            .catch(error => {
                // toastr.error('Failed', "Failed to update the paddword")
            });
    }
    return (
        <>
            <div onClick={() => openModal(true)} className="ebw-profile__service-link ">Reset Password</div>
            <div className="ebw-profile__modal">
                <Modal isOpen={isOpen} toggle={toggleModal}>
                    <div className="px-5 py-5  ebw-modal__wrap">
                        <ModalHeader toggle={toggleModal} className="ebw-profile__modal-head px-0 px-md-4" style={{ border: "none" }}>
                            <div className="ebw-modal__title mb-1">Reset your Password</div>
                        </ModalHeader>

                        <ModalBody className="ebw-modal__body px-0 px-md-4">
                            <Row form className="ebw-modal__form">
                                <Col xs="12" className="ebw-modal__input-wrap mb-2 position-relative">
                                    <InputPassword className="ebw-modal__input"
                                        id="password"
                                        name="password"
                                        label="Enter new password"
                                        value={state.password}
                                        changeEvent={handleChangePass}
                                        error={state.passwordError}
                                    />
                                </Col>
                                <Col xs="12" className="ebw-modal__input-wrap mb-2 position-relative">
                                    <InputPassword className="ebw-modal__input"
                                        id="passwordRe"
                                        name="passwordRe"
                                        label="Re-enter the password"
                                        type="text"
                                        value={state.passwordRe}
                                        changeEvent={handleChangePassRe}
                                        error={state.passwordReError}
                                    />
                                </Col>
                                <Col md="4" className="ml-auto mt-3 d-flex justify-content-end">
                                    <div className="ebw-modal__submit ebw-button__plain ebw-button__plain--grey mr-2" onClick={toggleModal}>cancel</div>
                                    <Buttons clickEvent={sendUpdatedEmail} text="Submit" />
                                    {/* <button className="ebw-modal__submit ebw-button__primary" type="submit">Submit</button> */}
                                </Col>
                            </Row>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default ResetPassword;