import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationList } from "../../redux/actions/notificationList";
import Notification from "./notification";
import Header from '../../layout/header';
import Footer from '../../layout/footer';

const NotificationPAge = () => {
    const dispatch = useDispatch();
    // const auth = useSelector(state => state);
    // const data = {
    //     project_type_id: "0",
    //     project_sub_type_id: "0"
    // }
    useEffect(() => {
        dispatch(getNotificationList());
    }, [])
    const notificationData = useSelector(state => state.notificationList.notification_list);
    const notificationLoading = useSelector(state => state.notificationList.loading);
    const notificationError = useSelector(state => state.notificationList.error);
    return (
        <>
            <Header />
            {!notificationLoading &&
                < Notification
                    notificationData={notificationData}
                    notificationLoading={notificationLoading}
                // is_notification={notificationData && notificationData.is_notification}
                />}
            <Footer />
        </>
    );
}

export default NotificationPAge;