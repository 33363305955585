import React from "react"
import ContentLoader from "react-content-loader"
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Row, Col
} from 'reactstrap';

const AvailableProjectsLoader = (props) => (
    <>
        <Row>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col md="4">
                <ContentLoader
                    speed={7}
                    width="100%"
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
        </Row>
        {/* <Row>
            <Col>
                <ContentLoader
                    speed={7}
                    width={476}
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col>
                <ContentLoader
                    speed={7}
                    width={476}
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col>
                <ContentLoader
                    speed={7}
                    width={476}
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
        </Row>
        <Row>
            <Col>
                <ContentLoader
                    speed={7}
                    width={476}
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col>
                <ContentLoader
                    speed={7}
                    width={476}
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
            <Col>
                <ContentLoader
                    speed={7}
                    width={476}
                    height={210}
                    viewBox="0 0 476 210"
                    backgroundColor="#d5cdcd"
                    foregroundColor="#ababab"
                    {...props}
                >
                    <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
                    <rect x="27" y="17" rx="0" ry="0" width="328" height="88" />
                    <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
                    <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
                    <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
                    <circle cx="37" cy="162" r="9" />
                    <rect x="51" y="158" rx="0" ry="0" width="94" height="7" />
                    <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
                    <rect x="27" y="177" rx="0" ry="0" width="327" height="4" />
                </ContentLoader>
            </Col>
        </Row> */}

    </>
)

export default AvailableProjectsLoader