import React from "react"
import ContentLoader from "react-content-loader"
const InbiddingLoader = (props) => (
    <>
        <ContentLoader
            speed={3}
            width={476}
            height={210}
            viewBox="0 0 476 210"
            backgroundColor="#E5E5E5"
            foregroundColor="#c9c9c9"
            {...props}
        >
            <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
            <rect x="27" y="17" rx="10" ry="10" width="328" height="88" />
            <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
            <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
            <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
            <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
            <rect x="27" y="157" rx="0" ry="0" width="327" height="4" />
        </ContentLoader>
        <ContentLoader
            speed={3}
            width={476}
            height={210}
            viewBox="0 0 476 210"
            backgroundColor="#E5E5E5"
            foregroundColor="#c9c9c9"
            {...props}
        >
            <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
            <rect x="27" y="17" rx="10" ry="10" width="328" height="88" />
            <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
            <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
            <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
            <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
            <rect x="27" y="157" rx="0" ry="0" width="327" height="4" />
        </ContentLoader>
        <ContentLoader
            speed={3}
            width={476}
            height={210}
            viewBox="0 0 476 210"
            backgroundColor="#E5E5E5"
            foregroundColor="#c9c9c9"
            {...props}
        >
            <rect x="88" y="-35" rx="0" ry="0" width="280" height="9" />
            <rect x="27" y="17" rx="10" ry="10" width="328" height="88" />
            <rect x="27" y="113" rx="0" ry="0" width="81" height="17" />
            <rect x="274" y="112" rx="0" ry="0" width="81" height="17" />
            <rect x="27" y="137" rx="0" ry="0" width="108" height="8" />
            <rect x="247" y="135" rx="0" ry="0" width="108" height="8" />
            <rect x="27" y="157" rx="0" ry="0" width="327" height="4" />
        </ContentLoader>
    </>
)

export default InbiddingLoader