import React from 'react'

const Main = ({children, additionalClass_main}) => {
    return (
        <main className={`ebw-main ebw-main__${additionalClass_main}`}>
            {children}
        </main>
    )
} 

export default Main