import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, DropdownToggle, Collapse, Button, Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import SubmitBid from "../projectDetails/submitBid";
import { activateChatBox } from "../../redux/actions/chatboxActive"
import { getChat } from "../../redux/actions/getChat";
import { ignoreProject } from "../../redux/actions/ignoreThisProject";
import { deleteBid } from "../../redux/actions/deleteBid";
import EditBidShow from "./editBidShow";
import ServiceFeeChart from "./serviceFeeChart";

const AvailableAndInbiddingBideDetails = (props) => {
    const dispatch = useDispatch();
    const {
        bid_amount,
        bid_type,
        hours,
        bid_detail,
        commissionDetails,
        status,
        project_status,
        id,
        created_at,
        project_type,
        title,
        provider_id,
        project_id,
        message_count,
        unread_msg,

        providr_bid_id,
        bid_breakdown_document,
        amount_receive_from_customer,
        total_amount,
        easybids_commission,
        public_project_id,
        project_thumbnail,
        consumer_name,
        full_address,
        consumer_avatar_url,
        editMode,
        consumer_mobile,
        can_provider_contact_customer,
        already_hired,
    } = props;
    console.log(bid_detail, "bid_detail ksjhxdzgx", editMode, "project_status in the details right", project_status)
    const [isOpen, editBidToggler] = useState(!editMode);
    const slideChatIn = () => {
        dispatch(activateChatBox(true));
    }
    const activateChat = (provider_id, project_id) => {
        const activateData = {
            provider_id: provider_id,
            project_id: project_id,
            active: true
        };
        const data = {
            provider_id: provider_id,
            project_id: project_id
        };
        dispatch(activateChatBox(activateData));
        dispatch(getChat(data));
    };
    const ignoreProjectLoading = useSelector(state => state.ignoreProject.loading);
    const deleteBidLoading = useSelector(state => state.deleteBid.loading);
    const [ingnoreProjectId, setIgnoreProjectId] = useState(null);
    const [ignoreProjectCfmModal, setIgnoreProjectCfmModal] = useState(false);
    const [deletebidProjectId, setDeletebidProjectId] = useState(null);
    const [deletebidCfmModal, setDeletebidCfmModal] = useState(false);
    const submitBidMessage = useSelector(state => state.submitBidMessage.error);
    const [unavailable, setUnavailable] = useState(false);
    const [unavailableMessage, setUnavailableMessage] = useState("");
    const [showAnimation, setSowAnimation] = useState(true);
    useEffect(() => {
        if (submitBidMessage != "") {
            setUnavailable(true);
            setUnavailableMessage(submitBidMessage)
        }
        setTimeout(() => {
            setSowAnimation(false);
            // this.setState({ showAnimation: false })
        }, 6000)
    }, [submitBidMessage]);

    const toggle = () => {
        setIgnoreProjectCfmModal(!ignoreProjectCfmModal);
    }
    const toggleb = () => {
        setDeletebidCfmModal(!deletebidCfmModal);
    }
    const toggleC = () => {
        setUnavailable(!unavailable);
    }

    const ignoreThisProject = (project_id) => {
        setIgnoreProjectId(project_id);
        setIgnoreProjectCfmModal(true);
    };
    const confirmIgnoreProject = () => {
        console.log("comes inside confirm ignore", ingnoreProjectId)
        const data = { project_id: ingnoreProjectId };
        dispatch(ignoreProject(data));
    }
    const deleteThisBid = (project_id) => {
        setDeletebidProjectId(project_id)
        setDeletebidCfmModal(true)
    }
    const confirmDeleteThisBid = () => {
        const data = {
            project_id: deletebidProjectId,
            providr_bid_id: providr_bid_id
        };
        dispatch(deleteBid(data));
    }
    const reloadPage = () => {
        toggleC();
        location.reload();
    }
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    const [redirectPopupModal, setRedirectPopupModal] = useState(false);
    const reirectPopUp = () => {
        setRedirectPopupModal(true)
    }
    const redirectData = {
        pathname: '/get-contact',
        state: {
            project_id: project_id,
            public_project_id: public_project_id,
            project_thumbnail: project_thumbnail,
            title: title,
            consumer_name: consumer_name,
            full_address: full_address,
            customer_avathar: consumer_avatar_url,
            project_type: project_type,
        }
    };
    return (
        <div className={`${(already_hired && bid_amount == "$0.00" && showAnimation) ? "pulse" : ""}`}>
            <div className="ebw-prodet__content h-100">
                <div className="ebw-prodet__content-wrap">
                    {commissionDetails && commissionDetails.length > 0 &&
                        <ServiceFeeChart
                            open={false}
                            commission_calc={commissionDetails}
                            bid_amount={bid_amount}
                        />
                    }
                    <div className="position-relative">
                        <div
                            className={`ebw-prodet__bid mt-3 w-100 ${!isOpen ? "ebw-prodet__bid-arrow--down" : "ebw-prodet__bid-arrow--up"} `}
                            onClick={() => editBidToggler(!isOpen)}
                        // onClick={() => reirectPopUp()}
                        >
                            <span className="ebw-icon ebw-icon--md icon-bidding"></span>
                            <span className="ml-1">{bid_amount == "$0.00" ? "Bid Now" : "Edit Bid"}</span>
                        </div>
                    </div>
                </div>

                <Collapse isOpen={isOpen} className="ebw-prodet__bid-collapse mt-3">
                    {commissionDetails && commissionDetails.length > 0 &&
                        <SubmitBid
                            bid_amount={bid_amount}
                            bid_type={bid_type}
                            hours={hours}
                            bid_detail={bid_detail}
                            edit={editMode}
                            commissionDetails={commissionDetails}
                            status={status}
                            project_id={id}
                            providr_bid_id={providr_bid_id}
                            project_status={project_status}
                            already_hired={already_hired}
                        />
                    }
                </Collapse>
                {(editMode && !isOpen) &&
                    <EditBidShow
                        bid_id={providr_bid_id}
                        bid_amount={bid_amount}
                        hours={hours}
                        bid_detail={bid_detail}
                        bid_breakdown_document={bid_breakdown_document}
                        bid_type={bid_type}
                        commission_calc={commissionDetails}
                        project_id={project_id}
                        // refreshThePage={refreshThePage}
                        amount_receive_from_customer={amount_receive_from_customer}
                        easybids_commission={easybids_commission}
                        total_amount={total_amount}
                    />
                }
                {/* {project_status == "2" &&
                    <div className="mt-4">
                        <Button
                            onClick={() => ignoreThisProject(id)}
                            // onClick={() => reirectPopUp()}
                            disabled={ignoreProjectLoading}
                            className="ebw-button ebw-button__border ebw-button__border--darkgrey text-left position-relative"
                        >
                            Ignore Project
                            <span className="ebw-prodet__btn-icon icon-arrow-right ebw-icon--lg position-absolute"></span>
                        </Button>
                    </div>
                } */}
                {status === "In-Bidding" &&
                    <div className="mt-4">
                        <Button
                            onClick={() => deleteThisBid(id)}
                            // onClick={() => reirectPopUp()}
                            disabled={deleteBidLoading}
                            className="ebw-button ebw-button__border ebw-button__border--darkgrey text-left btn btn-secondary position-relative"
                        >
                            Delete Bid
                            <span className="ebw-prodet__btn-icon icon-arrow-right ebw-icon--lg position-absolute"></span>
                        </Button>
                    </div>
                }

                {/* <div className="mt-4">
                    <div className="" >
                        <div
                            className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--chat" : "ebw-button__border--blue"}  text-left position-relative`}
                            onClick={() => activateChat(provider_id, project_id)}
                        >
                            Chat
                            {unread_msg != "0" &&
                                <Badge pill className="ebw-prodet__btn-count">{unread_msg}</Badge>
                            }
                            <span className="ebw-prodet__btn-icon icon-chat ebw-icon--xl position-absolute"></span>
                        </div>
                    </div>
                </div> */}
                {/* {consumer_mobile ?
                    <div className="mt-4">
                        <a href={`tel:${consumer_mobile}`} >
                            <div
                                className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--chat" : "ebw-button__border--blue"}  text-left position-relative`}
                            >
                                {consumer_mobile}
                                <span className="ebw-prodet__btn-icon icon-chat ebw-icon--xl position-absolute"></span>
                            </div>
                        </a >
                    </div> : <>
                        {can_provider_contact_customer ?
                            <div className="mt-4">
                                <Link to={redirectData} className="w-100" >
                                    <div
                                        className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--chat" : "ebw-button__border--blue"}  text-left position-relative`}
                                    >
                                        Call Customer Now
                                        <span className="ebw-prodet__btn-icon icon-phone ebw-icon--xl position-absolute"></span>
                                    </div>
                                </Link >
                            </div> :
                            <div className="mt-4">
                                <Link to="" className="w-100" >
                                    <div
                                        className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--chat" : "ebw-button__border--blue"}  text-left position-relative`}
                                    >
                                        Call Customer Now
                                        <span className="ebw-prodet__btn-icon icon-phone ebw-icon--xl position-absolute"></span>
                                    </div>
                                </Link >
                            </div>
                        }
                    </>

                } */}


            </div>
            <Modal isOpen={ignoreProjectCfmModal} toggle={() => setIgnoreProjectCfmModal(false)} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">Are you sure you want to ignore this project?</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setIgnoreProjectCfmModal(false)} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => confirmIgnoreProject()} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={deletebidCfmModal} toggle={() => setDeletebidCfmModal(false)} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">Are you sure you want to delete this bid?</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={() => setDeletebidCfmModal(false)} >No</Button>
                            </div>
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => confirmDeleteThisBid()} >Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={unavailable} toggle={reloadPage} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--danger">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <h3 className="text-lg mb-6">{unavailableMessage}</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            {/* <div className="">
                                <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button" onClick={toggleb} >No</Button>
                            </div> */}
                            <div className="col-4">
                                <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" onClick={() => reloadPage()} >Okay</Button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={redirectPopupModal} toggle={() => setRedirectPopupModal(false)} className="ebw-global__modal">
                <div className="ebw-modal__wrap ebw-modal__wrap--blue ebw-modal__wrap--nopadding">
                    <ModalBody className="ebw-global__modal-body text-center py-6">
                        <div style={{ backgroundImage: `url("/images/usemob/upgrade-img-a.svg")` }} className="ebw-modal__themeimage"></div>
                        <h2 className="text-lg ebw-modal__title-sizeL mb-4">Stay tuned</h2>
                        <h3 className="text-md ebw-modal__para ebw-modal__para-sizeL  mb-6">We are currently upgrading our Web Portal.<br /> Please use mobile app to continue</h3>

                        <div className="d-flex justify-content-center align-items-center">
                            <a href="https://play.google.com/store/apps/details?id=easybids.customer.app" target="_blank" style={{ backgroundImage: `url("/images/usemob/play-btn@2x.png")` }} className="col-5 ebw-modal__buttonimage"  >
                                {/* <Button className="ebw-button ebw-button__primary--grey" color="grey" type="button"  >No</Button> */}
                            </a>
                            <a href="https://apps.apple.com/us/app/easybids-customer/id1511554907" target="_blank" style={{ backgroundImage: `url("/images/usemob/app-btn@2x.png")` }} className="col-5 ebw-modal__buttonimage" >
                                {/* <Button className="ebw-button ebw-button__primary--danger" color="danger" type="submit" >Yes</Button> */}
                            </a>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    );
}

export default AvailableAndInbiddingBideDetails;