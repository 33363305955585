import * as type from '../actions/types';

const initialState = {
  auth: {},
  loading: false,
  error: null,
}

export default function setAuth(state = initialState, action) {
  switch (action.type) {
    case type.STORE_SET_AUTH_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.STORE_SET_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        auth: action.auth,
      }
    case type.STORE_SET_AUTH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}