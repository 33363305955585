import React from 'react';
import { Card, CardTitle, CardText, CardImg, CardBody, CardSubtitle } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { activateChatBox } from "../../redux/actions/chatboxActive";
import { getChat } from "../../redux/actions/getChat";
const CardInConversation = (props) => {
    const { projectName, projectDate, projectLocation, project_id, provider_id, projectCategory, project_type, new_msg_count, showLocation, status, additionalClass, project_lost } = props;
    const dispatch = useDispatch();
    // const myWorksData = useSelector(state => state.myWorks.myWorks);
    // const myWorksLoading = useSelector(state => state.myWorks.loading);
    // const myWorksError = useSelector(state => state.myWorks.error);
    const data = {
        provider_id: provider_id,
        project_id: project_id
    };
    const activateData = {
        provider_id: provider_id,
        project_id: project_id,
        active: true
    };
    const activateChat = () => {
        if (additionalClass == "") {
            dispatch(activateChatBox(activateData));
            dispatch(getChat(data));
        }
    };
    const capitalize = (str) => {
        const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
        return str2
    }
    const getStatusOfSTatus = (projectStatus) => {
        if (projectStatus == 2) {
            return 'Available for Bid'
        } else if (projectStatus == 3 && !project_lost) {
            return 'Hired'
        } else if (projectStatus == 4 && !project_lost) {
            return 'Completed'
        } else if (status == 9 && !project_lost) {
            return 'Cancelled'
        } else if (projectStatus == 6 && !project_lost) {
            return "Cancellation Requested"
        } else if (project_lost) {
            return "Customer has Hired another provider"
        }
    }
    return (
        <div onClick={activateChat} className="ebw-card__wrap ebw-card__wrap-inconversation ebw-card__wrap--secondary">
            <Card className={`ebw-card ebw-card__secondary ${additionalClass}`} >
                <CardBody>
                    <div className="d-flex justify-content-between mb-md-3">
                        <div className="ebw-card__secondary-titles">
                            <CardTitle className="ebw-card__secondary-title mb-1 mb-md-2">{capitalize(projectName)}</CardTitle>
                            <CardSubtitle className="ebw-card__subtitle mb-2 mb-md-4">
                                {/* {projectCategory} */}
                                {capitalize(project_type)}
                            </CardSubtitle>
                        </div>
                        <CardText className="ebw-card__date">{projectDate}</CardText>
                    </div>
                    {/* <div className="d-flex justify-content-between mb-3"> */}
                    {/* </div> */}
                    <div className="d-flex justify-content-between mb-2 mb-md-4">
                        <CardText className="ebw-card__text d-flex align-items-center mb-0">
                            <span className="ebw-icon ebw-icon--lg ebw-font__color-secondary ebw-icon--lg icon-chat mr-1">
                                {/* <img className="img-fluid" src="/images/icons/message-ico.svg" alt="" /> */}
                            </span>
                            <span>Chat : {new_msg_count}</span>
                        </CardText>
                    </div>
                    <div className="d-flex justify-content-end justify-content-md-between  ebw-card__location">
                        {showLocation &&
                            <CardText className="d-flex align-items-center mb-0">
                                <span className="ebw-icon ebw-icon--xl icon-location">
                                    {/* <img src="./images/icons/icon-location.svg"></img> */}
                                </span>
                                <div className="ml-1">{capitalize(projectLocation)}</div>
                            </CardText>
                        }
                        {status &&
                            <div className="ebw-card__secondary-status ebw-card__label d-flex flex-column justify-content-md-center">{getStatusOfSTatus(status)}</div>
                        }
                        <span className="ebw-card__link d-none d-md-block"><span className="ebw-icon ebw-icon--4xl icon-arrow-straight"></span></span>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
export default CardInConversation;