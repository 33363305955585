import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Container,
} from 'reactstrap';
import ProjectsLoader from "../projects/projectsSkeleton";
import CardSecondary from '../card/card-secondary';

const InBiddingActivities = (props) => {
    const { inbiddingActivities, inbiddingLoading, categoriesFromStore } = props;
    console.log(categoriesFromStore, "All categoriesFromStore in the all activities component")
    return (
        <Container>
            <>
                <div className="d-md-flex justify-content-between">
                    <div className="ebw-title__primary">In-Bidding</div>
                    {/* {inbiddingActivities && inbiddingActivities.length > 0 &&
                        <Filter
                            otherFilterActive={otherFilterActive}
                            startDate={state.startDate}
                            endDate={state.endDate}
                            filterUpdated={filterUpdated}
                            currentData={currentinbiddingActivities}
                            wholeData={inbiddingActivities}
                            storeFilterdArray={storeFilterdArray}
                        />
                    } */}
                </div>
                {inbiddingLoading ?
                    <div className="ebw-data__loaderr">
                        <ProjectsLoader />
                    </div> :
                    <>
                        {!inbiddingActivities &&
                            <Row>
                                <Col sm="12 mt-6 mt-md-8" >
                                    <div className="ebw-section__empty position-relative">
                                        <div className="ebw-section__empty-imgs w-100">
                                            <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                            {/* <div className="position-absolute ebw-section__empty-img"><img src="/images/projects/project-empty-img.png" alt="" /></div> */}
                                        </div>
                                        <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                            <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Projects starts appearing here when you bid the Project</div>
                                            <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>}
                        {inbiddingActivities && inbiddingActivities.length == 0 &&
                            <Row>
                                <Col sm="12 mt-6 mt-md-8" >
                                    <div className="ebw-section__empty position-relative">
                                        <div className="ebw-section__empty-imgs w-100">
                                            <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                            {/* <div className="position-absolute ebw-section__empty-img"><img src="/images/projects/project-empty-img.png" alt="" /></div> */}
                                        </div>
                                        <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                            <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Projects starts appearing here when you bid the Project</div>
                                            <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>}
                        {/* {inbiddingActivities && inbiddingActivities.length === 0 &&
                            <Row>
                                <Col sm="12 mt-6 mt-md-8" >
                                    <div className="ebw-page__error-project ebw-page__error-404 d-flex flex-column align-items-center justify-content-center">
                                        <div className="ebw-page__error-wrap ">
                                            <div className="mb-4 position-relative">
                                                <img className="img-fluid" src="/images/projects/search-not-found.svg" alt="No results found" />
                                            </div>
                                            <p className="ebw-page__error-title text-center position-relative">No results found</p>
                                            <p>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>} */}
                        <Row>
                            {inbiddingActivities && inbiddingActivities.length > 0 && inbiddingActivities.map((all, index) => {
                                if (categoriesFromStore.length != 0 && categoriesFromStore.includes(all.project_type)) {
                                    return (
                                        <Col key={index} sm="6" lg="4" className="mt-8">
                                            <CardSecondary
                                                key={index}
                                                projectName={all.name}
                                                projectImage={all.project_image_url ? all.project_image_url : "./images/dashboard/project-img-1.png"}
                                                projectDate={all.created_at}
                                                projectCategory={all.project_type.title}
                                                projectLinks={`/project-detail/${all.id}/activities`}
                                                status={all.status}
                                                NoArrow
                                                noLocation={true}
                                                bookmarked_by_current_provider={all.bookmarked_by_current_provider}
                                                project_id={all.id}
                                                provider_project_details={all.provider_project_details}
                                            />
                                        </Col>
                                    )
                                } else if (categoriesFromStore.length == 0) {
                                    return (
                                        <Col sm="6" lg="4" className="mt-8">
                                            <CardSecondary
                                                key={index}
                                                projectName={all.name}
                                                projectImage={all.project_image_url ? all.project_image_url : "./images/dashboard/project-img-1.png"}
                                                projectDate={all.created_at}
                                                projectCategory={all.project_type.title}
                                                projectLinks={`/project-detail/${all.id}/activities`}
                                                status={all.status}
                                                NoArrow
                                                noLocation={true}
                                                bookmarked_by_current_provider={all.bookmarked_by_current_provider}
                                                project_id={all.id}
                                                provider_project_details={all.provider_project_details}
                                            />
                                        </Col>
                                    )
                                }

                            }
                            )}
                            <div className="w-100 text-right mt-6 mt-md-10">
                                {/* <PaginationCmn
                                    activePage={activePageInbidding}
                                    itemsPerPage={itemsPerPage}
                                    totalItems={currentinbiddingActivities && currentinbiddingActivities.length}
                                    handlePaginationChange={(data) => setInbiddingActivePage(data)}
                                /> */}
                            </div>
                        </Row>
                    </>
                }
            </>
        </Container>
    );
}

export default InBiddingActivities;