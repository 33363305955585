import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../API';
import { toastr } from 'react-redux-toastr'
import axios from 'axios';


async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/projects/${data}/documents`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* projectsData(action) {
    try {
        const response = yield call(getApi, action.data);
        console.log(response.data.data, "response in the available projects Saga files")
        yield put({ type: 'GET_PROJECT_FILES_SUCCESS', files: response.data.data });
    } catch (e) {
        yield put({ type: 'GET_PROJECT_FILES_FAILED', message: e.message });
    }
}
function* projectFilesSagaX() {
    yield takeEvery('GET_PROJECT_FILES_REQUESTED', projectsData);
}

export default projectFilesSagaX;