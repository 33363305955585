import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import InputEmail from "../inputs/email-input";
import OtpInput from 'react-otp-input';
import { Checkbox } from "../inputs/checkbox";
import Buttons from "../buttons/button-primary";
import validate from "./loginOtpValidationStepOne";
import { loginOtpF } from "../../redux/actions/loginOtpFirstStep";
import validateTwo from "./loginOtpValidationStepTwo";
import OtpInputNumber from "../inputs/otp-input";
import { loginOtpS } from "../../redux/actions/loginOtpSecondStep";
import { loginOtpResend } from "../../redux/actions/loginOtpResendOtp";
import { logout } from "../../redux/actions/logout";
import { loginOtpSetStep } from "../../redux/actions/loginOtpSetStep";


const LoginOTP = () => {
    const loginOtpFirstStep = useSelector(state => state.loginOtpFirstStep.data);
    const activeStep = useSelector(state => state.loginOtpFirstStep.firstStep);
    const loginOtpFirstStepLoading = useSelector(state => state.loginOtpFirstStep.loading);
    const loginOtpFirstStepError = useSelector(state => state.loginOtpFirstStep.error);

    const loginOtpSecondStep = useSelector(state => state.loginOtpSecondStep.data);
    const loginOtpSecondStepLoading = useSelector(state => state.loginOtpSecondStep.loading);
    const loginOtpSecondStepError = useSelector(state => state.loginOtpSecondStep.error);

    const loginOtpResendOtp = useSelector(state => state.loginOtpResendOtp.data);
    const loginOtpResendOtpLoading = useSelector(state => state.loginOtpResendOtp.loading);
    const loginOtpResendOtpError = useSelector(state => state.loginOtpResendOtp.error);

    const dispatch = useDispatch();
    const [state, setState] = useState({
        email: "",
        // firstStep: activeStep,
        emailError: "",
        otp: "",
        otpError: "",
    });
    // loginOtpFirstStep && loginOtpFirstStep.confirmation_token && setState((prevProps) => ({
    //     ...prevProps,
    //     firstStep: false
    // }));

    const onChangeEmail = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            email: event.target.value
        }));
    }

    const firstStepSubmit = (event) => {

        const errors = validate(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = JSON.stringify({
            email: state.email
        });
        errors.valid && dispatch(loginOtpF(data))
    }
    const secondStepSubmit = () => {
        const errors = validateTwo(state);
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));
        const data = JSON.stringify({
            email: state.email,
            otp: state.otp,

        });
        errors.valid && dispatch(loginOtpS(data));
    }
    const submit = (event) => {
        event.preventDefault();
        if (activeStep) {
            firstStepSubmit();
        } else {
            secondStepSubmit();
        }
    }
    const otpChangeHandler = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            otp: data
        }));
    }
    const resendOTP = (event) => {
        event.preventDefault();
        const data = JSON.stringify({
            email: state.email
        });
        dispatch(loginOtpResend(data))
    }
    const editEmailAddress = () => {
        // activeStep = 1
        dispatch(loginOtpSetStep());
    }

    return (
        <>
            <Form className="ebw-entry__tab-form" onSubmit={(e) => submit(e)}>

                    <InputEmail
                        changeEvent={(e) => onChangeEmail(e)}
                        label="Email"
                        name="login_email"
                        id="login_mail"
                        value={state.email}
                        error={state.emailError}
                        disabled={!activeStep}
                    />
                    {!activeStep &&
                        <div className="d-flex flex-row justify-content-between align-items-center  ml-2 ml-md-4 mr-2">

                            <div className="ebw-entry__tab-checkbox">
                                <span onClick={(e) => editEmailAddress(e)} className="mb-0 text-sm ebw-entry__tab-forgot--link" role="button" href="#">Edit email address</span>
                            </div>
                            < div className="ebw-entry__tab-forgot ml-auto">
                                <span onClick={(e) => resendOTP(e)} className="mb-0 text-sm ebw-entry__tab-forgot--link" role="button" href="#">Resend OTP</span>
                            </div>

                        </div>
                    }

                    {loginOtpFirstStepError ?
                        
                            <div className="text-left mt-2">
                               <div className="ebw-error mb-2"> {loginOtpFirstStepError}</div> 
                            </div>
                        : ""
                    }

                    {activeStep &&
                        <div className="mt-4">
                            <Buttons disabled={loginOtpFirstStepLoading} type="submit" text="Submit" />
                        </div>
                    }
                {!activeStep &&
                    <Row form>
                        <Col sm="12">
                            <div className="ebw-entry__otp">
                                {/* <div className="ebw-entry__otp-title  text-left  mb-4"> Enter 6 digit OTP</div> */}
                                <OtpInputNumber
                                    value={state.otp}
                                    changeEvent={otpChangeHandler}
                                    otpError={state.otpError}
                                />
                                <div>{loginOtpSecondStepError}</div>
                            </div>
                        </Col>

                        <Col md="3" lg="5" xl="3" className="mt-6 mx-auto">
                            <Buttons disabled={loginOtpSecondStepLoading} type="submit" text="Login" />
                        </Col>
                    </Row>
                }
            </Form>
        </>
    );
}

export default LoginOTP;