import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../redux/actions/users';
import Header from "../../layout/header";
import Register from "./register"
const RegisterPAge = (props) => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.users);
    const loading = useSelector(state => state.users.loading);
    const error = useSelector(state => state.users.error);
    const socialData = props.location.state && props.location.state.data && props.location.state.data;

    useEffect(() => {
        dispatch(getUsers());
    }, [])

    return (
        <>
            <Header />

            {users.length > 0 &&
                <Register key={users} user={users} socialData={socialData} />
            }
        </>
    );
}

export default RegisterPAge;