import React, { useState, useEffect } from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Container,
} from 'reactstrap';
import { Helmet } from "react-helmet";
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux';

import CardInConversation from '../../components/card/inConversation'
import Main from '../../layout/main'
import AvailableProjects from "../../components/projects/availableProjects"
import { CategoryDropdown } from "../../components/dropdown/dropdown"
import AvailableProjectsLoader from "../../components/projects/projectsSkeleton"
import Loader from "../../common/loader";
import { setAvailableCategories } from "../../redux/actions/setAvailableCategories";



const Projects = (props) => {
    console.log(props.availableProjects, "availableProjects availableProjects availableProjects")
    const dispatch = useDispatch();
    const categoriesFromStore = useSelector(state => state.categoryFilter.selectedCategories);
    const {
        availableData,
        availableLoading,
        conversations,
        conversationsLoading,
        availableProjects,
        pagination
    } = props;
    console.log(pagination, "pagination pagination")
    const [activeTab, setActiveTab] = useState(Number(props.tab))
    const [state, setState] = useState({
        dropdownOpen: false,
        startDate: "",
        filterdAvailableProjects: [],
        projectsData: [],
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const setCategories = () => {
        const availableCategories = [];
        if (activeTab == "2") {
            conversations.forEach(data => {
                !availableCategories.includes(data.project_type) && availableCategories.push(data.project_type);
            })
        } else if (activeTab == "1") {
            availableData.forEach(data => {
                !availableCategories.includes(data.project_type) && availableCategories.push(data.project_type);
            })
        }
        dispatch(setAvailableCategories(availableCategories));
    };
    setCategories();
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content=" Easy Bids Pro is the easiest way to bid on projects in your area. Whether it's plumbing, carpentry, landscaping or anything else you need done, find an easy project near you and get started today!" />
                <title>Bidding - Easy Bids Pro</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="projects">
                <Nav tabs className="ebw-projects__navtab">
                    <Container>
                        <Row>
                            <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === 1 })}
                                    onClick={() => setActiveTab(1)}
                                >
                                    Available Projects
                                </NavLink>
                            </NavItem>

                            {/* <NavItem className="ebw-tab__item">
                                <NavLink
                                    className={classnames({ active: activeTab === 2 })}
                                    onClick={() => setActiveTab(2)}
                                >
                                    In-Conversation
                                </NavLink>
                            </NavItem> */}

                            {/* <NavItem className="ebw-tab__item ml-auto">
                                <CategoryDropdown />
                            </NavItem> */}
                        </Row>
                    </Container>
                </Nav>
                <section className="ebw-section ebw-section__pages">
                    <TabContent activeTab={activeTab}>

                        <TabPane tabId={1}>
                            <Container>
                                {
                                    availableLoading && availableLoading ?
                                        <div className="ebw-data__loaderr">
                                            <AvailableProjectsLoader />
                                        </div>
                                        :
                                        <AvailableProjects
                                            availableLoading={availableLoading}
                                            activeTab={activeTab}
                                            projectAvailableData={availableData}
                                            startDate={state.startDate}
                                            availableProjectsX={availableProjects}
                                            pagination={pagination}
                                        />
                                }
                            </Container>
                        </TabPane>

                        {/* <TabPane tabId={2}>
                            <Container>
                                {availableLoading ?
                                    <Loader loading={availableLoading} />
                                    : <>
                                        <div className="ebw-title__primary">In-Conversation</div>
                                        {conversations && conversations.length == 0 &&
                                            <Row>
                                                <Col sm="12 mt-8" >
                                                    <div className="ebw-section__empty position-relative">
                                                        <div className="ebw-section__empty-imgs w-100">
                                                            <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                        </div>
                                                        <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                            <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Interested in a project? Talk to the customer. Gain better understanding. Place a winning bid</div>
                                                            <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>}
                                        <Row>
                                            <>
                                                {conversations && conversations.length != 0 && conversations.map((projectConversation, index) => {
                                                    if (categoriesFromStore.length != 0 && categoriesFromStore.includes(projectConversation.project_type)) {
                                                        return (
                                                            <Col key={index} sm="6" lg="4" className="mt-8">
                                                                <CardInConversation
                                                                    projectName={projectConversation.project_name}
                                                                    projectImage={projectConversation.projectImage}
                                                                    projectDate={projectConversation.created_at}
                                                                    projectCategory={projectConversation.projectCategory}
                                                                    projectLocation={projectConversation.address}
                                                                    chatActive={true}
                                                                    project_id={projectConversation.project_id}
                                                                    provider_id={projectConversation.provider_id}
                                                                    NoImage
                                                                    project_type={projectConversation.project_type}
                                                                    new_msg_count={projectConversation.new_msg_count}
                                                                    showLocation={true}

                                                                />
                                                            </Col>
                                                        )
                                                    } else if (categoriesFromStore.length == 0) {
                                                        return (
                                                            <Col key={index} sm="6" lg="4" className="mt-8">
                                                                <CardInConversation
                                                                    projectName={projectConversation.project_name}
                                                                    projectImage={projectConversation.projectImage}
                                                                    projectDate={projectConversation.created_at}
                                                                    projectCategory={projectConversation.projectCategory}
                                                                    projectLocation={projectConversation.address}
                                                                    chatActive={true}
                                                                    project_id={projectConversation.project_id}
                                                                    provider_id={projectConversation.provider_id}
                                                                    NoImage
                                                                    project_type={projectConversation.project_type}
                                                                    new_msg_count={projectConversation.new_msg_count}
                                                                    showLocation={true}

                                                                />
                                                            </Col>
                                                        )
                                                    }
                                                }
                                                )}
                                            </>
                                        </Row>
                                    </>
                                }
                            </Container>
                        </TabPane> */}
                    </TabContent>

                    {/* <ProjectInConversation /> */}
                </section>
            </Main>
        </div>
    );
}

export default Projects;
