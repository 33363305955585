import * as type from './types';
const initialState = {

    all: [],
    inBidding: [],
    inConversation: [],
    bookmarked: [],

    allLoading: false,
    allError: "",
    inbiddingLoading: false,
    inbiddingError: "",
    inConversationLoading: false,
    inConversationError: "",
    bookmarkedLoading: false,
    bookmarkedError: "",
}

export default function activities(state = initialState, action) {
    switch (action.type) {
        //|||||||||||||||||||||||||  -------------------- ALL
        case type.GET_ACTIVITIES_ALL_REQUESTED:
            return {
                ...state,
                allLoading: true,
            }
        case type.GET_ACTIVITIES_ALL_SUCCESS:
            return {
                ...state,
                allLoading: false,
                all: action.all,
            }
        case type.GET_ACTIVITIES_ALL_FAILED:
            return {
                ...state,
                allLoading: false,
                allError: action.message,
            }
        //|||||||||||||||||||||||||  -------------------- In Bidding
        case type.GET_ACTIVITIES_INBIDDING_REQUESTED:
            return {
                ...state,
                inbiddingLoading: true,
            }
        case type.GET_ACTIVITIES_INBIDDING_SUCCESS:
            return {
                ...state,
                inbiddingLoading: false,
                inBidding: action.inBidding,
            }
        case type.GET_ACTIVITIES_INBIDDING_FAILED:
            return {
                ...state,
                inbiddingLoading: false,
                inbiddingError: action.message,
            }
        //|||||||||||||||||||||||||  -------------------- In Conversation
        case type.GET_ACTIVITIES_INCONVERSATION_REQUESTED:
            return {
                ...state,
                inConversationLoading: true,
            }
        case type.GET_ACTIVITIES_INCONVERSATION_SUCCESS:
            return {
                ...state,
                inConversationLoading: false,
                inConversation: action.inConversation,
            }
        case type.GET_ACTIVITIES_INCONVERSATION_FAILED:
            return {
                ...state,
                inConversationLoading: false,
                inConversationError: action.message,
            }
        //|||||||||||||||||||||||||  -------------------- Bookmarked
        case type.GET_ACTIVITIES_BOOKMARK_REQUESTED:
            return {
                ...state,
                bookmarkedLoading: true,
            }
        case type.GET_ACTIVITIES_BOOKMARK_SUCCESS:
            return {
                ...state,
                bookmarkedLoading: false,
                bookmarked: action.bookmarked,
            }
        case type.GET_ACTIVITIES_BOOKMARK_FAILED:
            return {
                ...state,
                bookmarkedLoading: false,
                bookmarkedError: action.message,
            }
        default:
            return state
    }
}