import React, { useState, useEffect } from 'react';
import { getNewServices } from "../redux/actions/getNewServices";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, CustomInput, UncontrolledCollapse } from 'reactstrap';
import Buttons from "../components/buttons/button-primary";
import axios from 'axios';
import API from '../API';

const NewService = (props) => {
    const [selectAll, setSelectAll] = useState(false);
    const [error, setError] = useState("");
    const [selectedSubTypes, setSelectedSubtypes] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNewServices());
    }, [])
    const serviceList = useSelector(state => state.newService.services);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectAll(true)
            var all_sub_type = []
            serviceList.project_type.forEach(category => {
                if (category.project_sub_types.length != 0)
                    category.project_sub_types.forEach(subType => {
                        all_sub_type.push(String(subType.id));
                    })
            });
            setSelectedSubtypes([...all_sub_type])
        } else {
            setSelectAll(false)
            setSelectedSubtypes([])
        }
    };
    const handleItemCheckbox = (event) => {
        setSelectAll(false)
        let newselectedSubtype = [];

        if (selectedSubTypes.includes(event.target.value)) {
            newselectedSubtype = selectedSubTypes.filter(t => t != event.target.value)
        } else {
            newselectedSubtype = [...selectedSubTypes, event.target.value]
        }
        setSelectedSubtypes(newselectedSubtype);
    }
    const submitService = (event) => {
        event.preventDefault();
        let data = JSON.stringify({
            provider: {
                project_type_ids: [],
                project_sub_type_ids: selectedSubTypes
            }
        })
        axios.put(API.baseURL + `/providers/update_new_project_type`, data, API.options)
            .then(response => response.data)
            .then(res => {
                // this.setState({ loadingShow: false })
                window.location.href = '/';
            })
    }

    return (
        <>{(serviceList && serviceList.project_type && serviceList.project_type.length > 0) ?
            <Form className="ebw-entry__register-form text-center" onSubmit={e => submitService(e)}>
                <Row form>
                    <Col sm="12">
                        <div className="ebw-entry__services">
                            <div className="ebw-entry__services-row d-flex align-items-center justify-content-between mb-2">
                                <label for="selectAll" className="ebw-entry__label-services">Services</label>
                                <div className="ebw-form__checkboxes ebw-form__checkboxes-inline d-flex align-items-center ">
                                    <label for="selectAll" className="ebw-form__checkbox-text pl-4 mb-0 mr-2">Select All</label>
                                    <CustomInput
                                        type="checkbox"
                                        id="selectAll"
                                        onChange={(e) => handleSelectAll(e)}
                                        checked={selectAll ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="ebw-entry__services">

                                {serviceList && serviceList.project_type && serviceList.project_type.length > 0 && serviceList.project_type.map((service, index) => {
                                    return (
                                        <div key={index} className="ebw-entry__service">
                                            <div id={`toggler${index}`} className={`ebw-entry__service-main d-flex align-items-center justify-content-between mt-2 mb-2`}>
                                                <div className="ebw-entry__label-main">{service.title}</div>
                                                <div className={`ebw-entry__icon--add`}>
                                                    <span className="ebw-icon ebw-icon--md icon-close"></span>
                                                </div>

                                            </div>
                                            <UncontrolledCollapse className="ebw-entry__service-subcategories" toggler={`#toggler${index}`}>
                                                <div className="px-6 py-4">
                                                    {service.project_sub_types.length != 0 && service.project_sub_types.map((subCategory, subindex) => {
                                                        return (
                                                            <div key={subindex} className="ebw-entry__service-subcategory d-flex align-items-center justify-content-between">
                                                                <label for={`service_sub_item_${subCategory.id}_${service.id}`} className="ebw-entry__label-subcategory">{subCategory.name}</label>
                                                                <div className="ebw-form__checkboxes ebw-form__checkboxes-inline">
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        name={"all"}
                                                                        id={`service_sub_item_${subCategory.id}_${service.id}`}
                                                                        value={subCategory.id}
                                                                        onChange={e => handleItemCheckbox(e)}
                                                                        checked={selectedSubTypes.includes(String(subCategory.id)) ? "checked" : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                        {error ?
                            <div className="ebw-entry__services-error mt-4">{error}</div> : ""}
                    </Col>
                    <Col sm="12" md="4" xl="3" className="mx-auto text-center mt-4">
                        <Buttons type="submit" text="Next" />
                    </Col>
                </Row>
            </Form> :
            <div>
                <p className="text-center ebw-modal__para">Congratulations on being an expert in every home improvement service on EasyBids.</p>
                <p className="text-center ebw-modal__para">Stay tuned and get notified whenever we add new services on the platform!</p>
                <p className="text-center ebw-modal__para">Happy Bidding!</p>
            </div>
        }
        </>
    );
}

export default NewService;