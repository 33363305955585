import React, { useState } from 'react';

const PaymentDetails = ({
    project_status,
    total_amount,
    bid_amount,
    bid_type,
    hours,
    discount_applied_by_customer,
    amount_payable_to_easybids_after_discount,
    is_commission_paid,
    easybids_commission,
    amount_receive_from_customer,
}) => {
    return (
        <>
            {((project_status == 3 || project_status == 4) && bid_amount != "$0.00") &&
                // <Card>
                <>
                    <div className="ebw-card__title ebw-card__title-medium ebw-card__title-underline mb-2">Payment Details</div>
                    {bid_type == "fixed" ?
                        <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                            <div className="ebw-payment__bill-label">Bid Amount</div>
                            <div className="ebw-payment__bill-amount">${total_amount}</div>
                        </div>
                        : <>
                            <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                <div className="ebw-payment__bill-label">Bid Amount</div>
                                <div className="ebw-payment__bill-amount">{bid_amount}</div>
                            </div>
                            <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                <div className="ebw-payment__bill-label">Number of Hours</div>
                                <div className="ebw-payment__bill-amount">{hours}</div>
                            </div>
                            <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                <div className="ebw-payment__bill-label">Total Amount</div>
                                <div className="ebw-payment__bill-amount">${total_amount}</div>
                            </div>
                        </>
                    }

                    <div className="ebw-card__divider ebw-card__divider--dotted mb-1"></div>
                    <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                        <div className="ebw-payment__bill-label">Easy Bids Commission</div>
                        <div className="ebw-payment__bill-amount">${easybids_commission}</div>
                    </div>
                    {discount_applied_by_customer != null &&
                        <div>
                            <div className="ebw-payment__bill-row ebw-payment__bill-row--orange d-flex align-items-center justify-content-between">
                                <div className="ebw-payment__bill-label">Discount applied by the Customer</div>
                                <div className="ebw-payment__bill-amount ebw-payment__bill-amount--orange">-${discount_applied_by_customer}</div>
                            </div>
                            <div className="ebw-payment__bill-row ebw-payment__bill-row--lightgray d-flex align-items-center">
                                <div className="icon-info ebw-icon__size-sm"></div>
                                <div className="ml-1">Discount applied on Easy Bids commission</div>
                            </div>
                        </div>
                    }
                    <div className="ebw-card__divider ebw-card__divider--dotted mb-1"></div>
                    <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                        <div className="ebw-payment__bill-label">Amount payable to Easy Bids</div>
                        <div className="ebw-payment__bill-amount">${amount_payable_to_easybids_after_discount}</div>
                    </div>
                    {is_commission_paid &&
                        <>
                            <div className="ebw-card__divider ebw-card__divider--dotted mb-1"></div>
                            <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                <div className="ebw-payment__bill-label">Commission Paid</div>
                                <div className="ebw-payment__bill-amount">${easybids_commission}</div>
                            </div>
                        </>
                    }
                    <div className="ebw-card__divider ebw-card__divider--dotted mb-1"></div>
                    <div className="mt-2">
                        <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                            <div className="ebw-payment__bill-label ebw-payment__bill-label--bold">Amount to be received from Customer</div>
                            <div className="ebw-payment__bill-amount ebw-payment__bill-amount--lightgray">${amount_receive_from_customer}</div>
                        </div>
                    </div>
                    {/* </Card> */}
                </>
            }
        </>

    );
}

export default PaymentDetails;