import React, { useEffect } from 'react';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import PayCommission from "./commissionPayment";
import { useSelector, useDispatch } from 'react-redux';
import { getCommissionPaymentPageData } from "../../redux/reduxes/payment/commissionDetailsAction";
import LeadPaymentLoader from "../../components/commissionPayment/commissionPaymentSkeleton";
const PayCommissionContainer = (props) => {
    const dispatch = useDispatch();
    const { project_id, provider_project_id } = props.match.params;
    const data = {
        project_id: project_id,
        provider_project_id: provider_project_id,
    }
    useEffect(() => {
        dispatch(getCommissionPaymentPageData(data));
    }, []);
    const payment = useSelector(state => state.commissionPayment.paymentPageData);
    const loadingData = useSelector(state => state.commissionPayment.loading);
    console.log(payment, project_id, "payment in the page", provider_project_id)

    return (
        <>
            <Header />
            {loadingData ? <LeadPaymentLoader /> : <PayCommission {...payment} project_id={project_id} loadingData={loadingData} />}
            <Footer />
        </>
    );
}

export default PayCommissionContainer;