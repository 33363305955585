import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/notifications`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* fetchNotifications(action) {
    try {
        const notification = yield call(getApi, action.payload);
        console.log(notification.data.data.notification, "notification notification")

        const notifis = notification.data.data.notification
        const result = notifis.filter((notifi) => {
            return notifi.parameter.entity != "not_yet_hired";
        });

        yield put({ type: 'GET_PRIMARY_NOTIFICATION_SUCCESS', primaryNotification: result });
    } catch (e) {
        yield put({ type: 'GET_PRIMARY_NOTIFICATION_FAILED', message: e.message });
    }
}

function* primaryNotificationSaga() {
    yield takeEvery('GET_PRIMARY_NOTIFICATION_REQUESTED', fetchNotifications);
}

export default primaryNotificationSaga;