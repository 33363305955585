import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../../API';


async function postApi(data) {
    console.log(data, "data in the submit bid Saga")
    const formData = new FormData();
    formData.append('bid_amount', data.bid_amount);
    formData.append('bid_breakdown', data.bid_detail);
    formData.append('bid_type', data.bid_type == "hourly" ? 1 : 0);
    formData.append('hours', data.bid_estimated_hours);
    if (data.selectedFile.length > 0) {
        formData.append('bid[documents_attributes][][document]', data.selectedFile[0]);
    } else {
        formData.append('bid[documents_attributes][][document]', "");
    }

    if (!data.type) {
        try {
            const response = axios.post(API.baseURL + API.endPoints.submitBid + `${data.project_id}/bid`, formData, API.options)
            return await response;
        } catch (error) {
            throw error;
        }
    } else {
        try {
            const response = axios.put(API.baseURL + API.endPoints.submitBid + `${data.project_id}/bid/${data.providr_bid_id}`, formData, API.options)
            return await response;
        } catch (error) {
            throw error;
        }
    }


} 5

function* submitBid(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            if (action.data.project_status == 3) {
                window.location.href = `/commission-payment/${action.data.project_id}/${response.data.data.provider_project_id}`;
            } else {
                location.reload();
            }
        } else {
        }
        yield put({ type: 'POST_SUBMIT_BID_SUCCESS', data: response.data.data });
        yield put({ type: 'GET_CURRENT_PROJECT_DATA_REQUESTED', data: action.data.project_id });
    } catch (e) {
        yield put({ type: 'POST_SUBMIT_BID_FAILED', message: "You can not bid the project!" });
    }
    // location.reload();
}

function* submitBidSaga() {
    yield takeEvery('POST_SUBMIT_BID_REQUESTED', submitBid);
}

export default submitBidSaga;