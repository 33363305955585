import { call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import API from '../../API';



async function postApi(data) {
    const apiUrl = API.baseURL + `/providers/project_status_update/${data.id}.json`;
    // const requestOptions = {
    //     method: 'POST',
    //     headers: API.options.headers,
    //     body: data
    // };
    try {
        // const response = await fetch(apiUrl, requestOptions);
        const response = axios.post(apiUrl, data.data, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* projectComplete(action) {
    try {
        const response = yield call(postApi, action.data);
        if (response.status == 200 || response.status == 201) {
            yield put({ type: 'POST_PROJECT_COMPLETED_SUCCESS', registerPersonal: response.data.data });
            // toastr.success('Completed', 'Completed successfully');
            location.reload();
        } else {
            // response.errors.length != 0 && toastr.error('Completion Failed', response.errors[0]);
        }
        location.reload();
    } catch (e) {
        yield put({ type: 'POST_PROJECT_COMPLETED_FAILED', message: e.message });
    }
}

function* markProjectComplet() {
    yield takeEvery('POST_PROJECT_COMPLETED_REQUESTED', projectComplete);
}

export default markProjectComplet;