import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import Main from '../../layout/main';
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { InputText, InputTextNoBorder } from "../../components/inputs/text-input";
import WorksFolder from '../../components/myWorks/worksFolder'
import Buttons from "../../components/buttons/button-primary";
import Loader from "../../common/loader"
import ProjectsLoader from "../../components/projects/projectsSkeleton";
import CardInConversation from '../../components/card/inConversation'


const Messages = (props) => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    const dispatch = useDispatch();
    const { messages, messagesLoading } = props;
    const [sortedMessages, setSortedMessages] = useState([]);

    useEffect(() => {
        let changedNv = [];
        let changedPV = [];

        messages.forEach((message) => {
            if (message.project_lost || message.status == 9 || message.status == 4 || message.status == 6) {
                changedNv.push(message)
            } else {
                changedPV.push(message)
            }
        })
        setSortedMessages([...changedPV, ...changedNv]);

    }, [messages]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [newFolderName, setnewFolderName] = useState("");
    const categoriesFromStore = useSelector(state => state.categoryFilter.selectedCategories);

    const createNewFile = (event) => {
        event.preventDefault()
        let data = JSON.stringify({
            album: {
                name: newFolderName,
            }
        })
        if (newFolderName != "") {
            dispatch(myWorksNewFolder(data));
            setModal(false);
            setTimeout(() => {
                setnewFolderName("");
            }, 2000);
        }
    }

    console.log("projectConversation", messages)

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Works</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="my-works">
                <Container>
                    {messagesLoading ?
                        <div className="ebw-data__loaderr">
                            <ProjectsLoader />
                        </div>
                        : <>
                            <div className="ebw-title__primary mt-10">Messages</div>
                            {messages && messages.length == 0 &&
                                <Row>
                                    <Col sm="12 mt-8" >
                                        <div className="ebw-section__empty position-relative">
                                            <div className="ebw-section__empty-imgs w-100">
                                                <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                                {/* <div className="position-absolute ebw-section__empty-img"><img src="/images/projects/project-empty-img.png" alt="" /></div> */}
                                            </div>
                                            <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                                <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white ">Interested in a project? Talk to the customer. Gain better understanding. Place a winning bid</div>
                                                <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>}
                            <Row>
                                <>
                                    {sortedMessages && sortedMessages.length != 0 && sortedMessages.map((projectConversation, index) => {
                                        if (categoriesFromStore.length != 0 && categoriesFromStore.includes(projectConversation.project_type)) {
                                            return (
                                                <Col key={index} sm="6" lg="4" className="mt-3 mt-md-6">
                                                    <CardInConversation
                                                        projectName={projectConversation.title}
                                                        projectImage={projectConversation.project_sub_type_url}
                                                        projectDate={projectConversation.created_at}
                                                        projectCategory={projectConversation.project_type_name}
                                                        projectLocation={projectConversation.address}
                                                        // projectLinks={projectConversation.projectLinks}
                                                        chatActive={true}
                                                        project_id={projectConversation.project_id}
                                                        provider_id={projectConversation.provider_id}
                                                        NoImage
                                                        project_type={projectConversation.project_type_name}
                                                        new_msg_count={projectConversation.msg_count}
                                                        status={projectConversation.status}
                                                        showLocation={false}
                                                        additionalClass={(projectConversation.project_lost || projectConversation.status == 4 || projectConversation.status == 6 || projectConversation.status == 9) ? "ebw-card__hide" : ''}
                                                        project_lost={projectConversation.project_lost}
                                                    />
                                                </Col>
                                            )
                                        } else if (categoriesFromStore.length == 0) {
                                            return (
                                                <Col key={index} sm="6" lg="4" className="mt-3 mt-md-6">
                                                    <CardInConversation
                                                        projectName={projectConversation.title}
                                                        projectImage={projectConversation.project_sub_type_url}
                                                        projectDate={projectConversation.created_at}
                                                        projectCategory={projectConversation.project_type_name}
                                                        projectLocation={projectConversation.address}
                                                        // projectLinks={projectConversation.projectLinks}
                                                        chatActive={true}
                                                        project_id={projectConversation.project_id}
                                                        provider_id={projectConversation.provider_id}
                                                        NoImage
                                                        project_type={projectConversation.project_type_name}
                                                        new_msg_count={projectConversation.msg_count}
                                                        status={projectConversation.status}
                                                        showLocation={false}
                                                        additionalClass={(projectConversation.project_lost || projectConversation.status == 4 || projectConversation.status == 6 || projectConversation.status == 9) ? "ebw-card__secondary-hide" : ''}
                                                        project_lost={projectConversation.project_lost}
                                                    />
                                                </Col>
                                            )
                                        }
                                    }
                                    )}
                                </>
                                <div className="w-100 text-right mt-6 mt-md-10">
                                    {/* <PaginationCmn className=""
                                    fromPage="allActivities"
                                    paginationData={pagination}
                                /> */}
                                </div>
                            </Row>
                        </>
                    }
                </Container>
            </Main>
        </div>
    );
}
export default Messages;