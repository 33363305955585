import { call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../API';
// import {toastr} from 'react-redux-toastr'
import axios from 'axios';


async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/projects/${data}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* currentProjectData(action) {
    try {
        const response = yield call(getApi, action.data);
        console.log(response.data.data, "response in the current project details saga")
        // yield put({ type: 'GET_CURRENT_PROJECT_DATA_FAILED', notFound: false });
        yield put({ type: 'GET_CURRENT_PROJECT_DATA_SUCCESS', data: response.data.data });
    } catch (e) {
        // yield put({ type: 'GET_CURRENT_PROJECT_DATA_FAILED', message: e.message });
        yield put({ type: 'GET_CURRENT_PROJECT_DATA_FAILED', notFound: true });
    }
}

function* currentProjectSaga() {
    yield takeEvery('GET_CURRENT_PROJECT_DATA_REQUESTED', currentProjectData);
}

export default currentProjectSaga;