import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

async function getApi(data) {
    const apiUrl = API.baseURL + API.endPoints.getBookMark;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* getBookmarked(action) {
    try {
        const bookmark = yield call(getApi, action.payload);
        yield put({ type: 'GET_BOOKMARKED_SUCCESS', bookmark: bookmark.data });
    } catch (e) {
        yield put({ type: 'GET_BOOKMARKED_FAILED', message: e.message });
    }
}

function* getBookmarkedProjectsSaga() {
    yield takeEvery('GET_BOOKMARKED_REQUESTED', getBookmarked);
}

export default getBookmarkedProjectsSaga;