import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from "../inputs/checkbox";
import Buttons from "../buttons/button-primary";
import InputEmail from "../inputs/email-input";
import InputPassword from "../inputs/password-input";
import validate from "./loginEmailValidation";
import { login } from '../../redux/actions/login';

const LoginEmail = () => {
    const users = useSelector(state => state.login.auth);
    const loading = useSelector(state => state.login.loading);
    const loginError = useSelector(state => state.login.error);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     setState((prevProps) => ({
    //         ...prevProps,
    //         responseError: loginError
    //     }));
    // }, [])
    const [state, setState] = useState({
        email: "",
        password: "",
        emailError: "",
        passwordError: "",
        valid: false,
        responseError: "",
    });

    const handleInputChange = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value,
            emailError: "",
            passwordError: "",
            responseError: "",
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = validate(state)
        setState((prevProps) => ({
            ...prevProps,
            ...errors
        }));

        const data = {
            email: state.email,
            password: state.password,
            user_type: "2"
        };

        errors.valid && dispatch(login(data));
    };
    return (
        <Form className="ebw-entry__tab-form" onSubmit={handleSubmit}>
            {loginError ?
                <div className="text-left mb-3" style={{ color: "var(--danger", opacity: "0.9" }}>
                    <div className="ebw-error"> {loginError} </div>
                </div>
                : ""
            }

            <InputEmail
                label="Email"
                name="email"
                id="login_mail"
                error={state.emailError}
                value={state.email}
                changeEvent={handleInputChange}
            />

            <InputPassword
                label="Password"
                name="password"
                id="login_password"
                error={state.passwordError}
                value={state.password}
                changeEvent={handleInputChange}
            />


            <div className="d-flex flex-row justify-content-between align-items-center ml-3 ml-md-2 my-4">
                <div className="ebw-entry__tab-checkbox">
                    {/* <Checkbox text="Remember me" /> */}
                </div>
                <div className="ebw-entry__tab-forgot ml-auto">
                    <Link className="mb-0 text-sm ebw-entry__tab-forgot--link" role="button" to="/forgot-password">Forgot Password?</Link>
                </div>
            </div>

            <Buttons type="submit" text="Sign In" />

        </Form>
    );
}

export default LoginEmail;