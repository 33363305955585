import React from 'react';
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableProjects } from "../../redux/reduxes/availableProjects/availableProjectsAction";

import { getMyProjectsAll } from "../../redux/reduxes/myProjects/allAction";
import { getMyProjectsInProcess } from "../../redux/reduxes/myProjects/inProcessAction";
import { getMyProjectsCancelled } from "../../redux/reduxes/myProjects/cancelledAction";
import { getMyProjectsHistory } from "../../redux/reduxes/myProjects/historyAction";

const PaginationCmn = (props) => {
    const dispatch = useDispatch();
    // console.log(props.paginationData.current_page, "props in the pagination component");
    console.log(props, "props in the Pagination");
    const getCurrespondingPageData = (data) => {

        console.log(data, "data binding in pagination")
        if (props.fromPage == "availableProjects") {
            dispatch(getAvailableProjects({ page: data }));
        } else if (props.fromPage == "allMyProjects") {
            dispatch(getMyProjectsAll({ page: data }));
        } else if (props.fromPage == "inProcessMyProjects") {
            dispatch(getMyProjectsInProcess({ page: data }));
        } else if (props.fromPage == "cancelledMyProjects") {
            dispatch(getMyProjectsCancelled({ page: data }));
        } else if (props.fromPage == "historyMyProjects") {
            dispatch(getMyProjectsHistory({ page: data }));
        }
    }
    return (
        <Pagination
            activePage={props.paginationData.current_page}
            itemsCountPerPage={props.paginationData.per_page}
            totalItemsCount={props.paginationData.total_entries}
            innerClass="pagination ebw-pagination"
            itemClass="ebw-pagination__item"
            linkClass="d-flex align-items-center justify-content-center"
            activeClass="ebw-pagination__item-active"
            onChange={getCurrespondingPageData.bind(this)}
        />
    );
}

export default PaginationCmn;