import React from "react"
import ContentLoader from "react-content-loader"
const width = window.innerWidth;

export const ReviewsPageSkeleton = (props) => (

    <ContentLoader
        speed={2}
        //width={width}
        //height={500}
        //viewBox="0 0 800 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="-20" y="50" rx="3" ry="3" width="599" height="9" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
    </ContentLoader>
)
