import React, { Component } from 'react';
import Main from '../../layout/main';
import {
  Card,
  CardText,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import CardSecondary from '../../components/card/card-secondary';
import Stats from "../../components/dashboard/stats";
import YourActivities from "../../components/dashboard/activities";
import YourProjects from "../../components/dashboard/yourProjects";
import AvailableProjects from "../../components/dashboard/projectAvailable";
import SuggestedProjects from "../../components/dashboard/suggestedProjects";

import AvailableProjectsLoader from "../../components/dashboard/availableProjectsSkeleton";
import InbiddingLoader from "../../components/dashboard/inbiddingSkeleton";
import InConversationLoader from "../../components/dashboard/inConversationSkeleton";

import { ReviewsPageSkeleton } from "../../components/Skeletons/reviewsPageSkeleton";
import { activateChatBox } from "../../redux/actions/chatboxActive";
import { getChat } from "../../redux/actions/getChat";
import Alert from "../../components/dashboard/alert"

SwiperCore.use([Navigation, Autoplay]);

class DashBoard extends Component {
  state = {
    latestNotifications: [],
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  getFirstTowItems = (data) => {
    let newData = [];
    var i
    if (data.length > 2) {
      for (i = 0; i < 2; i++) {
        newData.push(data[i])
      };
    } else {
      newData = data;
    }
    return newData;
  }
  getFirstTenItem = (data) => {
    let newData = [];
    var i
    if (data.length > 10) {

      for (i = 0; i < 10; i++) {
        newData.push(data[i])
      };
    } else {
      newData = data;
    }
    return newData;
  }

  activateChat = (provider_id, project_id) => {
    const activateData = {
      provider_id: provider_id,
      project_id: project_id,
      active: true
    };
    const data = {
      provider_id: provider_id,
      project_id: project_id
    };
    this.props.activateChatBox(activateData);
    this.props.getChat(data);
  };
  capitalize = (str) => {
    const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
    return str2
  }
  render() {
    const { provider_stats, suggested_projects, suggested_lead_projects } = this.props.dashData;
    const {
      dashboardLoading,
      notifications,
      projectsData,
      dashData,
      projectAvailable,
      conversation,
      projectAvailableX,
      stats,
      primaryNotifications,
      availableProjects,
      activities,
      my_projects,
      profile_score,
      profileData,
      messages,
      messagesLoading,
      primaryNotificationsLoading,
    } = this.props;

    const twoNotification = notifications && notifications.activities && this.getFirstTowItems(notifications.activities);
    const firstTenInbidding = projectsData.inbidding.length != 0 && this.getFirstTenItem(projectsData.inbidding);
    const firstTenAvailable = projectAvailable && projectAvailable.available.length != 0 && this.getFirstTenItem(projectAvailable.available);
    const checkMessage = (mess) => {
      if (mess.project_lost || mess.status == 9 || mess.status == 4 || mess.status == 6) {
        return false;
      } else {
        return true;
      }
      return
    }
    const firstTwoCOnversation = messages && messages.length > 0 && (messages.filter(message => checkMessage(message))).slice(0, 2)
    //    {
    //   let changedNv = [];
    //   let changedPV = [];
    //   if (message.project_lost || message.status == 9 || message.status == 4 || message.status == 6) {
    //     changedNv.push(message)
    //   } else {
    //     changedPV.push(message)
    //   }
    //   return changedPV
    // })
    console.log(firstTwoCOnversation, "firstTwoCOnversation in the dash page", my_projects)

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Check out all your Data about EasyBids at a single place.The projects you bidded,avaible projects around locality  and the bids approved.Check everything at a single place" />
          <title>Dashboard - Easy Bids Pro</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        </Helmet>
        <Main additionalClass_main="dashboard pb-0">
          {profile_score && profile_score != 100 &&
            <section className="ebw-section pb-0">
              <div className="container d-flex justify-content-center">
                <Alert profileImage={profileData && profileData.avatar_thumb} value={profile_score} />
              </div>
            </section>
          }
          {/* START - ACHIVEMENTS */}
          <Stats
            provider_stats={provider_stats}
            {...stats}
            loading={primaryNotificationsLoading}
            primaryNotifications={primaryNotifications}
            profile_score={profile_score}
          />
          {/* END - ACHIVEMENTS */}


          {/* START - PROJECT AVAILABLE */}
          {/* {projectsData.loading ?
            <Col sm="12" className="text-center my-10">
              <AvailableProjectsLoader
              // width={500}
              // height={150}
              />
            </Col>
            :
            projectAvailable &&
            projectAvailable.available &&
            <AvailableProjects data={firstTenAvailable} loading={projectAvailable.loading} />} */}

          <section className="ebw-section ebw-section--projects-suggested ebw-section--white overflow-hidden">
            <div className="container">
              <Row>
                {activities && activities.length > 0 &&
                  <Col sm={my_projects.length === 0 ? "12" : "6"} className='mb-6'>
                    {/* START - ACTIVITIES */}
                    {dashboardLoading ?
                      <Col sm="12" className="text-center my-10">
                        <InbiddingLoader />
                      </Col> :
                      activities && activities.length > 0 && <YourActivities data={activities}
                      />}
                  </Col>
                }
                {my_projects && my_projects.length > 0 &&
                  <Col sm={activities.length === 0 ? "12" : "6"}>
                    {/* START - YOUR PROJECTS */}
                    {dashboardLoading ?
                      <Col sm="12" className="text-center my-10">
                        <InbiddingLoader />
                      </Col> :
                      my_projects && my_projects.length > 0 && <YourProjects data={my_projects}
                      />}
                  </Col>
                }
              </Row>
            </div>
          </section>


          {/* START - PROJECTS AVAILABLE */}
          {
            projectsData.loading ?
              <Col sm="12" className="text-center my-10">
                {/* <AvailableProjectsLoader /> */}
                <InbiddingLoader />
              </Col> :
              projectAvailable && projectAvailable.available && <SuggestedProjects availableProjects={availableProjects} loading={dashboardLoading} />
          }
          {/* START - IN CONVERSATION */}
          <section className="ebw-section ebw-section--projects-notification">
            {conversation.loading ?
              <Col sm="12" className="text-center my-10">
                <InConversationLoader
                // width={1080}
                // height={400}
                />
              </Col>
              :
              <>
                {firstTwoCOnversation && firstTwoCOnversation.length != 0 &&
                  <div className="ebw-notification__wrap container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="ebw-title__secondary ebw-title__secondary--gray">In-conversation</div>
                      <Link to="/messages" className="ebw-button__plain ebw-button__plain--green d-flex align-items-center">
                        <span className="ebw-button__plain-text">More</span>
                        <i className="ebw-icon ebw-icon--lg icon-arrow-right ebw-slider__navigation-arrow--icon ebw-button__plain-icon"></i>
                      </Link>
                    </div>

                    <div className="ebw-notifications">
                      <div className="ebw-notifications__card-wrap">
                        {conversation && conversation.loading ? <ReviewsPageSkeleton loading={conversation.loading} /> :

                          firstTwoCOnversation && firstTwoCOnversation.length != 0 && firstTwoCOnversation.map((conver, index) => {
                            return (
                              <Card onClick={() => this.activateChat(conver.provider_id, conver.project_id)} key={index} body className="ebw-card ebw-card__quaternary cursor-pointer">
                                <Row className="d-flex justify-content-between">
                                  <Col md="10" className="">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        style={{
                                          backgroundImage: `url(${conver.project_sub_type_url})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'cover',
                                          backgroundPosition: 'center'
                                        }}
                                        className="ebw-card__quaternary-icon mr-3"></div>
                                      <div className="ebw-card__quaternary-content">
                                        <CardTitle className="ebw-card__quaternary-title mb-0">{this.capitalize(conver.title)}</CardTitle>
                                        <CardText className="ebw-card__quaternary-text">{conver.project_type_name}</CardText>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="2" className="ebw-card__quaternary-date text-right ml-auto">
                                    {conver.created_at}
                                    <div><i className="ebw-icon ebw-icon--xl icon-arrow-straight ebw-slider__navigation-arrow--icon"></i></div>
                                  </Col>
                                </Row>
                              </Card>
                            )
                          })

                        }
                      </div>
                    </div>
                  </div>
                }
              </>
            }
          </section>

        </Main >
      </div >
    );
  }
}

const mapStateToProps = state => ({
  dashData: state.dashoardData,
  notifications: state.notificationList.notification_list,
  projectsData: state.allProjectsData,
  projectAvailable: state.projectAvailable,
  projectAvailableX: state.availableProjectsX.xAvailable,
  conversation: state.conversations,
  profileData: state.profileData.profile

});

export default connect(mapStateToProps, { activateChatBox, getChat })(DashBoard);;