
import Cookies from 'js-cookie';
// let TOKEN_KEY = 'authentication_token';


export const setAuth = (data, headers) => {
    Cookies.set('isLogin', true);
    Cookies.set("authentication_token", data.authentication_token);
    Cookies.set("access_token", headers["access-token"]);
    Cookies.set("uid", headers.uid);
    Cookies.set("client", headers.client);
}
export const setIncompleteRegAuth = (data, headers) => {
    Cookies.set("authentication_token", data.authentication_token);
    Cookies.set("access_token", headers["access-token"]);
    Cookies.set("uid", headers.uid);
    Cookies.set("client", headers.client);
    Cookies.set("mobileNumber", data.mobile_number);
}
export const setRegOneAuth = (data, headers) => {
    Cookies.set("authentication_token", data.authentication_token);
    Cookies.set("access_token", headers["access-token"]);
    Cookies.set("uid", headers.uid);
    Cookies.set("client", headers.client);
}

export const setRegtwoAuth = (val) => {
    Cookies.set('isLogin', true);
}

export const logoutCookie = () => {
    Cookies.remove('isLogin');
    Cookies.remove("authentication_token");
    Cookies.remove('access_token');
    Cookies.remove('uid');
    Cookies.remove('client');
    window.localStorage.clear();
}

export const isLogin = () => {
    if (Cookies.get('isLogin')) {
        return true;
    }
    return false;
}