import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import CardSecondary from '../../components/card/card-secondary';
import Filter from "../common/filter";
import PaginationCmn from "../../components/common/pagination";
import { setAvailableCategories } from "../../redux/actions/setAvailableCategories";
import { InputText } from "../inputs/text-input";
import AvailableProjectsLoader from "../../components/projects/projectsSkeleton"


const AvailableProjects = (props) => {
    const dispatch = useDispatch();
    const {
        startDate,
        activeTab,
        availableLoading,
        availableProjectsX,
        pagination
    } = props;
    console.log(availableLoading, "availableProjectsX in the itrating component");
    const [availableProjectsData, setAvailableProjects] = useState([...availableProjectsX])
    console.log(availableProjectsData, "availableProjectsData with new Data")
    const [otherFilterActive, setOtherFilterActive] = useState(false)
    const categoriesFromStore = useSelector(state => state.categoryFilter.selectedCategories);
    const [searchVal, setSearchVal] = useState("");
    const [activePage, setActivePage] = useState(1);
    const itemPerPage = 9;
    const availableLast = activePage * itemPerPage;
    const availableFirst = (activePage - 1) * itemPerPage;
    const availableProjectsDataPage = availableProjectsData && availableProjectsData.length != 0 && availableProjectsData.slice(
        availableFirst,
        availableLast
    )
    // availableProjectsData

    const [state, setState] = useState({
        startDate: "",
        endDate: "",
        location: "",
        budgetRangeStart: 10,
        budgetRangeEnd: 80,
    });

    const filterUpdated = (data) => {
        setState((prevProps) => ({
            ...prevProps,
            startDate: data.startDate,
            endDate: data.endDate,
            location: data.location,
            budgetRangeStart: data.budgetRangeStart,
            budgetRangeEnd: data.budgetRangeEnd,
        }));
        // () => setCategories();
    };
    const storeFilterdArray = (data) => {
        setAvailableProjects(data);
        setOtherFilterActive(true)
    };
    useEffect(() => {
        setAvailableProjects(availableProjectsX);
    }, [availableProjectsX]);

    const setCategories = () => {
        const availableCategories = [];
        if (activeTab === "1") {
            availableCategories && availableCategories.length > 0 && availableProjectsData.forEach(data => {
                !availableCategories.includes(data.project_type.title) && availableCategories.push(data.project_type.title);
            })
            dispatch(setAvailableCategories(availableCategories));
        }
    }

    const startedSearching = (event) => {
        event.preventDefault();
        setSearchVal(event.target.value)
        const searchAvailable = availableProjectsX && availableProjectsX.length > 0 && availableProjectsX.filter(availProjects => {
            return (availProjects.name.toLowerCase()).includes(event.target.value.toLowerCase());
        })
        if (event.target.value === "") {
            setAvailableProjects(availableProjectsX);
        } else {
            setAvailableProjects(searchAvailable);
        }
    }
    setCategories();
    return (
        <div>
            <div className="d-md-flex flex-wrap justify-content-between align-items-center">
                <div className="ebw-title__primary">Available Projects</div>
                {availableProjectsX && availableProjectsX.length != 0 &&
                    <>
                        <InputText
                            label="Search"
                            name="search_filter"
                            id="search_filter"
                            value={searchVal}
                            error=""
                            changeEvent={(event) => startedSearching(event)}
                            parentClass="ebw-form__input-search-wrap"
                            additionalClass="ebw-form__input-search ebw-form__input--projects"
                        />
                        {/* <Filter
                            otherFilterActive={otherFilterActive}
                            startDate={state.startDate}
                            endDate={state.endDate}
                            filterUpdated={filterUpdated}
                            currentData={availableProjectsData}
                            wholeData={availableProjectsX}
                            storeFilterdArray={storeFilterdArray}
                        /> */}
                    </>
                }

            </div>
            {availableLoading && availableLoading ?
                <div className="ebw-data__loaderr">
                    <AvailableProjectsLoader loading={availableLoading} />
                </div> : <>
                    {availableProjectsX && availableProjectsX.length == 0 &&
                        <Row>
                            <Col sm="12 mt-8" >
                                <div className="ebw-section__empty position-relative">
                                    <div className="ebw-section__empty-imgs w-100">
                                        <img className="w-100 ebw-section__empty-background" src="/images/projects/project-empty-background.svg" alt="" />
                                        {/* <div className="position-absolute ebw-section__empty-img"><img src="/images/projects/project-empty-img.png" alt="" /></div> */}
                                    </div>
                                    <div className="ebw-section__empty-content d-flex align-items-end position-absolute">
                                        <div className="ebw-section__empty-title h-100 d-flex align-items-center ebw-title__primary--white "> A variety of projects on plumbing, carpentry, landscaping & more to bid on</div>
                                        <div className="ebw-section__empty-img"><img className="w-100" src="/images/projects/project-empty-img.png" alt="" /></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>}
                    {availableProjectsX && availableProjectsX.length != 0 && !availableProjectsDataPage &&
                        <Row>
                            <Col sm="12 mt-8" >
                                {/* <div className=" ebw-page__error-title text-center position-relative py-5 mb-0">No results found</div> */}
                                <div className="ebw-page__error-project ebw-page__error-404 d-flex flex-column align-items-center justify-content-center">
                                    <div className="ebw-page__error-wrap ">
                                        <div className="mb-4 position-relative">
                                            <img className="img-fluid" src="/images/projects/search-not-found.svg" alt="No results found" />
                                        </div>
                                        <p className="ebw-page__error-title text-center position-relative">No results found</p>
                                        <p>
                                            {/* <Link className="ebw-button ebw-button__primary position-relative" to="/">Go to Dashboard</Link> */}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>}
                    <Row>
                        {availableProjectsData && availableProjectsData.length != 0 && availableProjectsData.map((projectAvailable, index) => {
                            if (categoriesFromStore.length != 0 && categoriesFromStore.includes(projectAvailable.project_type)) {
                                return (<Col key={index} sm="mt-8" md="6" lg="4" >
                                    <CardSecondary
                                        projectName={projectAvailable.name}
                                        projectImage={projectAvailable.project_image_url ? projectAvailable.project_image_url : "/images/dashboard/project-img-1.png"}
                                        projectDate={projectAvailable.created_at}
                                        status={projectAvailable.status}
                                        projectCategory={projectAvailable.project_type.title}
                                        projectId={projectAvailable.id}
                                        projectLinks={`/project-detail/${projectAvailable.id}/projects`}
                                        project_post_type={1}
                                        NoArrow
                                        noLocation={true}
                                        bookmarked_by_current_provider={projectAvailable.bookmarked_by_current_provider}
                                        project_id={projectAvailable.id}
                                        provider_project_details={projectAvailable.provider_project_details}
                                    />
                                </Col>)
                            } else if (categoriesFromStore.length == 0) {
                                return (
                                    <Col key={index} sm=" mt-8" md="6" lg="4">
                                        <CardSecondary
                                            projectName={projectAvailable.name}
                                            projectImage={projectAvailable.project_image_url ? projectAvailable.project_image_url : "/images/dashboard/project-img-1.png"}
                                            projectDate={projectAvailable.created_at}
                                            status={projectAvailable.status}
                                            projectCategory={projectAvailable.project_type.title}
                                            projectId={projectAvailable.id}
                                            projectLinks={`/project-detail/${projectAvailable.id}/projects`}
                                            project_post_type={1}
                                            NoArrow
                                            noLocation={true}
                                            bookmarked_by_current_provider={projectAvailable.bookmarked_by_current_provider}
                                            project_id={projectAvailable.id}
                                            provider_project_details={projectAvailable.provider_project_details}
                                        />
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                </>
            }
            <div className="text-right mt-6 mt-md-10">
                <PaginationCmn
                    activePage={pagination.current_page}
                    itemsPerPage={pagination.per_page}
                    totalItems={pagination.total_entries}
                    handlePaginationChange={(data) => setActivePage(data)}
                    fromPage="availableProjects"
                    paginationData={pagination}
                />
            </div>

        </div>
    );
}

export default AvailableProjects;