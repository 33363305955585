import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Review from "./review";
import { getCustomerReviews } from "../../redux/actions/customerReviews";
import ReviewsLoader from "../../components/reviews/reviewsSkeleton"

const ReviewPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerReviews());
    }, []);

    const customerReviews = useSelector(state => state.customerReviews.reviews);
    const customerReviewsLoading = useSelector(state => state.customerReviews.loading);
    const customerReviewsError = useSelector(state => state.customerReviews.error);

    return (
        <>
            <Header />
            {/* {customerReviewsLoading ?
                <ReviewsLoader /> : */}
            <Review
                customerReviews={customerReviews}
                customerReviewsLoading={customerReviewsLoading}
                customerReviewsError={customerReviewsError}
            />
            {/* } */}
            <Footer />
        </>
    );
}

export default ReviewPage;