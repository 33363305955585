import * as type from '../actions/types';

const initialState = {
    data: [],
    loading: false,
    error: null,
}

export default function firstFiveNotifications(state = initialState, action) {
    switch (action.type) {
        case type.GET_FIRST_FIVE_NOTIFICATIONS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_FIRST_FIVE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            }
        case type.GET_FIRST_FIVE_NOTIFICATIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}