import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Badge } from 'reactstrap';
import { activateChatBox } from "../../redux/actions/chatboxActive"
import { getChat } from "../../redux/actions/getChat";
import { useDispatch, useSelector } from 'react-redux';


const CustomerDetails = ({
    consumer_mobile,
    unread_msg,
    can_provider_contact_customer,
    provider_id,
    project_id,
    consumer_avatar_url,
    full_address,
    consumer_name,
    public_project_id,
    project_type,
    project_thumbnail,
    title,
}) => {
    console.log(can_provider_contact_customer, "can_provider_contact_customer in the page");
    const dispatch = useDispatch();
    const activateChat = (provider_id, project_id) => {
        const activateData = {
            provider_id: provider_id,
            project_id: project_id,
            active: true
        };
        const data = {
            provider_id: provider_id,
            project_id: project_id
        };
        dispatch(activateChatBox(activateData));
        dispatch(getChat(data));
    };
    const redirectData = {
        pathname: '/get-contact',
        state: {
            project_id: project_id,
            public_project_id: public_project_id,
            project_thumbnail: project_thumbnail,
            title: title,
            consumer_name: consumer_name,
            full_address: full_address,
            customer_avathar: consumer_avatar_url,
            project_type: project_type,
        }
    };
    return (
        <>
            <div className="ebw-prodet__title mb-2">Customer</div>
            <div className=" d-flex align-items-center ebw-prodet__profile py-4">
                <img src={consumer_avatar_url} className="ebw-prodet__img ebw-header__profile-img"></img>
                <div className="ebw-prodet__details pl-4">
                    <div className="ebw-prodet__name text-capitalize">{consumer_name}</div>
                    <div className="d-flex align-items-center pt-1">
                        <span className="ebw-icon ebw-icon--lg icon-location"></span>
                        <div>{full_address}</div>
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <div className="" >
                    <div className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--chat" : "ebw-button__border--blue"} d-flex align-items-center justify-content-center`}
                        onClick={() => activateChat(provider_id, project_id)}
                    >
                        <span className="ebw-prodet__btn-icon icon-chat ebw-icon--xl m-0 mr-2"></span>
                        Chat with Customer
                        {unread_msg != "0" &&
                            <Badge pill className="ebw-prodet__btn-count">{unread_msg}</Badge>
                        }

                    </div>
                </div>
            </div>

            {consumer_mobile ?
                <div className="mt-4">
                    <a href={`tel:${consumer_mobile}`} className="w-100">
                        {/* <DropdownTog    gle className="ebw-prodet__ignore-arrow d-flex align-items-center" className="ebw-prodet__ask" caret> */}
                        <div
                            className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--call" : "ebw-button__border--blue"}  d-flex align-items-center justify-content-center`}
                        >
                            <span className="ebw-prodet__btn-icon icon-phone ebw-icon--xl m-0 mr-2"></span>
                            {consumer_mobile}
                        </div>
                        {/* </DropdownToggle> */}
                        {/* <div className="text-right ebw-info mt-1">{message_count} Chat with customer</div> */}
                    </a >
                </div> : <>
                    {can_provider_contact_customer ?
                        <div className="mt-4">
                            <Link to={redirectData} className="w-100">
                                {/* <DropdownTog    gle className="ebw-prodet__ignore-arrow d-flex align-items-center" className="ebw-prodet__ask" caret> */}
                                <div
                                    className={`ebw-button ebw-button__border ${unread_msg != "0" ? "ebw-button__border--call" : "ebw-button__border--blue"}  d-flex align-items-center justify-content-center`}
                                >
                                    <span className="ebw-prodet__btn-icon icon-phone ebw-icon--xl m-0 mr-2"></span>
                                    Call the Customer
                                </div>
                                {/* </DropdownToggle> */}
                                {/* <div className="text-right ebw-info mt-1">{message_count} Chat with customer</div> */}
                            </Link >
                        </div> :
                        <div className="mt-4">
                            <div className='w-100'>
                                {/* <DropdownTog    gle className="ebw-prodet__ignore-arrow d-flex align-items-center" className="ebw-prodet__ask" caret> */}
                                <div
                                    className={`ebw-button ebw-button__border  d-flex align-items-center justify-content-center`}
                                >
                                    <span className="ebw-prodet__btn-icon icon-chat ebw-icon--xl m-0 mr-2"></span>
                                    Call the Customer
                                </div>
                                {/* </DropdownToggle> */}
                                {/* <div className="text-right ebw-info mt-1">{message_count} Chat with customer</div> */}
                            </div >
                        </div>
                    }
                </>
            }
        </>
    );
}

export default CustomerDetails;