import React, { useState } from 'react';
import { Row, Col, Navbar, Nav, NavbarToggler, Collapse, NavItem, Badge, NavbarBrand, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ButtonYellow from "../buttons/button-yellow";
import { NotificationDropdown } from '../dropdown/dropdown';
import { logoutCookie } from "../../utils"
import { logout } from "../../redux/actions/logout";
import NewService from "../../layout/newServices";

export const MobileHeader = (props) => {

    const { notificationData, notificationLoading, notificationCount, profileData, authData, video_url } = props;
    const dispatch = useDispatch();
    //- Navbar Toggler
    const [isOpen, setIsOpen] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [addNewService, setAddNewService] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const logoutFormApp = () => {
        logoutCookie();
        dispatch(logout());
    }

    const navs = [
        { id: 1, icon: 'review', title: 'Reviews', url: '/review' },
        { id: 2, icon: 'edit', title: 'My Account', url: '/profile' },
        { id: 3, icon: 'works', title: 'My Works', url: '/my-works' },
        { id: 4, icon: 'faqs', title: "Faqs", url: '/faqs' },
        { id: 5, icon: 'document', title: 'Terms & Conditions', url: '/terms-conditions' },
        { id: 6, icon: 'privacy', title: 'Privacy Policy', url: '/privacy-policy' },
        { id: 7, icon: 'support', title: 'Contact Support', url: '/support' },
        // { id: 9, icon: 'support', title: 'New Service', url: '', onClick: () => setAddNewService(true) },
        // { id: 10, icon: 'support', title: 'Tips', url: '', onClick: () => setPlayVideo(true) },
        { id: 8, icon: 'logout', title: 'Logout', url: '', onClick: () => logoutFormApp() }
    ]

    return (
        <>
            <Navbar className="ebw-header__inner ebw-header__inner--postlogin d-flex d-xl-none align-items-center py-0">
                <NavbarBrand className="ebw-header__brandwrap" href="/dashboard">
                    <img className="ebw-header__brand img-fluid mx-auto d-block" src="/images/logo.svg" alt="Logo" />
                </NavbarBrand >

                <Nav className="d-flex align-items-center ml-auto">
                    <NavItem className="ebw-header__menu-item mr-3">
                        <NavLink exact to="/profile" className='ebw-header__menu-link mt-1'>
                            <div className="ebw-header__profile-img ebw-header__profile-img--mobile">
                                <img src={`${profileData && profileData.avatar_thumb && profileData.avatar_thumb}`} className="img-fluid d-block" alt="Profile" />
                            </div>
                        </NavLink>
                    </NavItem>

                    <NavItem className="ebw-header__menu-item">
                        <div className="ebw-header__menu-link">
                            <NotificationDropdown
                                notificationData={notificationData}
                                notificationLoading={notificationLoading}
                                notificationCount={notificationCount}

                            />
                        </div>
                    </NavItem>
                </Nav>

                <NavbarToggler tag="a" role="button" onClick={toggle} className="ebw-header__menu-toggler px-2">
                    {/* <img src="./images/icons/icon-hamburger.svg"  className="img-fluid mx-auto d-block" /> */}
                    <span className="ebw-icon ebw-icon--4xl icon-hamburger"></span>
                </NavbarToggler>

                <Collapse isOpen={isOpen} navbar className="ebw-header__menu-collapse">
                    <Nav navbar className="ebw-header__menu-nav ml-auto">
                        <div className="d-flex ml-auto mb-4 mt-3">
                            <a className="ebw-header__menu-close" role="button" onClick={toggle}>
                                {/*<img src="./images/icons/icon-cancel.svg" className="img-fluid mx-auto d-block"/>*/}
                                <span className="ebw-icon ebw-icon--2xl icon-close"></span>
                            </a>
                        </div>
                        <NavItem className="ebw-header__menu-item">
                            <NavLink exact to="/profile">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="ebw-header__profile-img">
                                        <img src={`${profileData && profileData.avatar_thumb && profileData.avatar_thumb}`} className="img-fluid d-block" alt="Profile" />
                                    </div>

                                    <div className="ebw-header__profile-info px-2">
                                        <div className="ebw-header__profile-name">{profileData && profileData.name && profileData.name}</div>
                                        {/* <div className="ebw-header__profile-text">Provider</div> */}
                                        <div className="mt-1">
                                            {/* Provider */}
                                            <div className="ebw-header__profile-pro ebw-profile__pro">PRO +</div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </NavItem>
                        {!profileData.is_pro_provider &&
                            <NavItem className="ebw-header__menu-item">
                                <NavLink exact to="/pro-plus" className="text-center mb-2 w-100">
                                    <ButtonYellow text="Upgrade to Pro +" />
                                    <span className="text-xxs" style={{ color: "var(--color-grey-8)" }}>Our PRO+ Members gets <strong style={{ color: "#252525" }}>30%</strong> more jobs</span>
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem className="ebw-header__menu-item">
                            <div exact to="" onClick={() => setAddNewService(true)} className='ebw-header__menu-link' >
                                <div className="d-flex align-items-center">
                                    <span className="ebw-icon ebw-icon--lg icon-">  <img className="ebw-header__menu-icon--video" src="/images/icons/services-notification.svg" /></span>
                                    <span className="pl-3">New Service</span>
                                </div>
                            </div>
                        </NavItem>
                        <NavItem className="ebw-header__menu-item" >
                            <div exact to="" onClick={() => setPlayVideo(true)} className='ebw-header__menu-link' >
                                <div className="d-flex align-items-center">
                                    <span className="ebw-icon ebw-icon--lg">
                                        <img className="ebw-header__menu-icon--video" src="/images/icons/bulp.svg" />
                                    </span>
                                    <span className="pl-3">Tips</span>
                                </div>
                            </div>
                        </NavItem>

                        {navs.map((nav, index) => (
                            <NavItem key={index} className="ebw-header__menu-item">
                                <NavLink
                                    exact to={nav.url}
                                    onClick={nav.onClick && nav.onClick}
                                    className='ebw-header__menu-link' >
                                    <div className="d-flex align-items-center">
                                        <span className={"ebw-icon ebw-icon--lg icon-" + nav.icon}></span>
                                        <span className="pl-3">{nav.title}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        ))}

                    </Nav>
                </Collapse>
            </Navbar>
            <Modal isOpen={playVideo} size="lg" toggle={() => setPlayVideo(false)}>
                <div className="">
                    {/* <ModalHeader toggle={() => redirectToReview()}></ModalHeader> */}
                    <ModalBody style={{ lineHeight: 0 }} className="p-0">
                        <iframe width="100%" height="300" src={video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={addNewService} toggle={() => setAddNewService(false)}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={() => setAddNewService(false)}></ModalHeader>
                    <ModalBody className="pt-0">
                        <h3 className="text-4xl mb-6">New Services</h3>
                        <div>
                            <NewService />
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

const mnavs = [
    { id: 1, icon: 'home', title: 'Dashboard', url: '/dashboard' },
    { id: 2, icon: 'projects', title: 'Projects', url: '/projects/all/1' },
    { id: 3, icon: 'my-projects', title: 'My Projects', url: '/my-projects' },
    { id: 4, icon: 'my-projects', title: 'Activities', url: '/activities' },
    { id: 5, icon: 'my-projects', title: 'messages', url: '/messages' },
    // { id: 4, icon: 'buy', title: 'Buy Projects', url: '/buy-projects' }
]
export const MobileNavTabs = () => {
    return (
        <>
            <Navbar className="d-block d-xl-none py-0">
                <Nav className="ebw-header__menu-btm justify-content-between position-fixed">
                    {/* {mnavs.map((mnav, index) => ( */}
                        <NavItem className="ebw-header__menu-tabs">
                            <NavLink exact to="/dashboard" className="ebw-header__menu-tab">
                                <div className="ebw-header__menu-tab--img d-flex flex-column">
                                    {/*<img src={mnav.icon} alt={mnav.title} className="img-fluid d-block mx-auto" /> */}
                                    {/* <span className={"mx-auto ebw-icon ebw-icon--xl icon-" + mnav.icon}></span> */}
                                    <div className="ebw-header__menu-svg path text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><defs><style dangerouslySetInnerHTML={{__html: ".a{fill:red;}.b{fill:none;}.c{clip-path:url(#a);}.d{clip-path:url(#b);}" }} /><clipPath id="a"><rect className="a" width={18} height={18} transform="translate(10561 -21839)" /></clipPath><clipPath id="b"><rect className="b" width="16.555" height="15.48" transform="translate(0 0)" /></clipPath></defs><g className="c" transform="translate(-10561 21839)"><g transform="translate(10561.723 -21838)"><g className="d"><path d="M11.141,15.479H5.652a2,2,0,0,1-2-2V8.624a2,2,0,0,1,2-2h5.489a2,2,0,0,1,2,2v4.855a2,2,0,0,1-2,2M5.652,8.124a.5.5,0,0,0-.5.5v4.855a.5.5,0,0,0,.5.5h5.489a.5.5,0,0,0,.5-.5V8.624a.5.5,0,0,0-.5-.5Z" /><path d="M15.805,9.338a.744.744,0,0,1-.53-.22l-7-7-7,7A.75.75,0,0,1,.22,8.058L8.278,0l8.057,8.058a.75.75,0,0,1-.53,1.28" /></g></g></g></svg>
                                    </div>
                                    <div className="ebw-header__menu-tab--title text-sm pt-1">Dashboard</div>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className="ebw-header__menu-tabs">
                            <NavLink exact to="/projects/all/1" className="ebw-header__menu-tab">
                                <div className="ebw-header__menu-tab--img d-flex flex-column">
                                    {/*<img src={mnav.icon} alt={mnav.title} className="img-fluid d-block mx-auto" /> */}
                                    {/* <span className={"mx-auto ebw-icon ebw-icon--xl icon-" + mnav.icon}></span> */}
                                    <div className="ebw-header__menu-svg path text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10529 21839)"><g transform="translate(10530 -21838)"><g className="d"><path d="M6,16H1a1,1,0,0,1-1-1V10A1,1,0,0,1,1,9H6a1,1,0,0,1,1,1v5a1,1,0,0,1-1,1M1.5,14.5h4v-4h-4Zm4.5-4H6Z" /><path d="M15,16H10a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v5a1,1,0,0,1-1,1m-4.5-1.5h4v-4h-4Zm4.5-4h0Z" /><path d="M15,7H10A1,1,0,0,1,9,6V1a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1V6a1,1,0,0,1-1,1M10.5,5.5h4v-4h-4Zm4.5-4h0Z" /><path d="M6,7H1A1,1,0,0,1,0,6V1A1,1,0,0,1,1,0H6A1,1,0,0,1,7,1V6A1,1,0,0,1,6,7M1.5,5.5h4v-4h-4ZM6,1.5H6Z" /></g></g></g></svg>
                                    </div>
                                    <div className="ebw-header__menu-tab--title text-sm pt-1">Projects</div>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className="ebw-header__menu-tabs">
                            <NavLink exact to="/my-projects" className="ebw-header__menu-tab">
                                <div className="ebw-header__menu-tab--img d-flex flex-column">
                                    {/*<img src={mnav.icon} alt={mnav.title} className="img-fluid d-block mx-auto" /> */}
                                    {/* <span className={"mx-auto ebw-icon ebw-icon--xl icon-" + mnav.icon}></span> */}
                                    <div className="ebw-header__menu-svg path text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10468 21841)"><g transform="translate(10468.691 -21839.223)"><g className="d"><path d="M13.617,9.616H10a3,3,0,0,1-3-3V3a3,3,0,0,1,3-3h3.616a3,3,0,0,1,3,3V6.616a3,3,0,0,1-3,3M10,1.5A1.5,1.5,0,0,0,8.5,3V6.616a1.5,1.5,0,0,0,1.5,1.5h3.616a1.5,1.5,0,0,0,1.5-1.5V3a1.5,1.5,0,0,0-1.5-1.5Z" /><path d="M10.995,6.818a.749.749,0,0,1-.53-.219L9.29,5.425a.75.75,0,1,1,1.06-1.062l.645.644,1.869-1.869A.75.75,0,1,1,13.925,4.2l-2.4,2.4a.744.744,0,0,1-.53.22" /><path d="M4.76,7.449A2.241,2.241,0,1,1,7,5.208,2.244,2.244,0,0,1,4.76,7.449m0-2.982a.741.741,0,1,0,.741.741.743.743,0,0,0-.741-.741" /><path d="M4.761,9.211A3.264,3.264,0,0,0,1.5,12.3a3.033,3.033,0,0,0,.949.384,9.515,9.515,0,0,0,2.307.262,9.515,9.515,0,0,0,2.307-.262,3.033,3.033,0,0,0,.949-.384A3.264,3.264,0,0,0,4.761,9.211m0-1.5a4.761,4.761,0,0,1,4.761,4.761C9.522,15.1,0,15.1,0,12.472A4.761,4.761,0,0,1,4.761,7.711" /></g></g></g></svg>
                                    </div>
                                    <div className="ebw-header__menu-tab--title text-sm pt-1">My Projects</div>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className="ebw-header__menu-tabs">
                            <NavLink exact to="/activities" className="ebw-header__menu-tab">
                                <div className="ebw-header__menu-tab--img d-flex flex-column">
                                    {/*<img src={mnav.icon} alt={mnav.title} className="img-fluid d-block mx-auto" /> */}
                                    {/* <span className={"mx-auto ebw-icon ebw-icon--xl icon-" + mnav.icon}></span> */}
                                    <div className="ebw-header__menu-svg path text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10499 21841)"><g transform="translate(10500 -21840)"><g className="d"><path d="M13,16H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H13a3,3,0,0,1,3,3V13a3,3,0,0,1-3,3M3,1.5A1.5,1.5,0,0,0,1.5,3V13A1.5,1.5,0,0,0,3,14.5H13A1.5,1.5,0,0,0,14.5,13V3A1.5,1.5,0,0,0,13,1.5Z" /><path d="M10.712,11.687a.752.752,0,0,1-.52-.21L8.23,9.588,6.268,11.477A.75.75,0,0,1,5,10.937v-9.6a.75.75,0,0,1,.75-.75h4.964a.75.75,0,0,1,.75.75v9.6a.75.75,0,0,1-.75.75M8.23,7.8a.751.751,0,0,1,.52.209L9.962,9.173V2.087H6.5V9.173L7.71,8.006A.751.751,0,0,1,8.23,7.8" /></g></g></g></svg>
                                    </div>
                                    <div className="ebw-header__menu-tab--title text-sm pt-1">Activities</div>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem className="ebw-header__menu-tabs">
                            <NavLink exact to="/messages" className="ebw-header__menu-tab">
                                <div className="ebw-header__menu-tab--img d-flex flex-column">
                                    {/*<img src={mnav.icon} alt={mnav.title} className="img-fluid d-block mx-auto" /> */}
                                    {/* <span className={"mx-auto ebw-icon ebw-icon--xl icon-" + mnav.icon}></span> */}
                                    <div className="ebw-header__menu-svg path text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} viewBox="0 0 18 18"><g transform="translate(-10447 21841)"><g transform="translate(10447.791 -21840)"><g className="d"><path d="M15.2,15.752a1.209,1.209,0,0,1-.871-.366l-2.652-2.65a.179.179,0,0,0-.128-.054H2.614A2.617,2.617,0,0,1,0,10.068V2.614A2.617,2.617,0,0,1,2.614,0h11.18a2.617,2.617,0,0,1,2.614,2.614l.008,11.919A1.21,1.21,0,0,1,15.2,15.752m-.285-1.217v0Zm.476-.208.005.005-.005-.005M2.614,1.5A1.115,1.115,0,0,0,1.5,2.614v7.454a1.115,1.115,0,0,0,1.114,1.114H11.55a1.674,1.674,0,0,1,1.189.493l2.177,2.177L14.908,2.614A1.116,1.116,0,0,0,13.794,1.5Zm9.322,6.523h-.005a1.685,1.685,0,1,1,.005,0m0-1.864a.182.182,0,1,0,0,.364v0a.182.182,0,1,0,0-.364M8.2,8.023A1.682,1.682,0,1,1,9.886,6.341,1.684,1.684,0,0,1,8.2,8.023m0-1.864a.182.182,0,0,0-.182.182c0,.2.364.2.364,0A.183.183,0,0,0,8.2,6.159M4.477,8.023A1.682,1.682,0,1,1,6.159,6.341,1.684,1.684,0,0,1,4.477,8.023m0-1.864a.182.182,0,0,0-.182.182c0,.2.363.2.364,0a.183.183,0,0,0-.182-.182" /></g></g></g></svg>
                                    </div>
                                    <div className="ebw-header__menu-tab--title text-sm pt-1">Messages</div>
                                </div>
                            </NavLink>
                        </NavItem>
                    {/* ))} */}
                </Nav>
            </Navbar>
        </>
    );
}