import { call, put, takeEvery } from 'redux-saga/effects';
import API from "../../../API";
import axios from 'axios';

async function getApi(data) {
    const apiUrl = API.baseURL + `/v2/providers/messages`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}

function* fetchMessages(action) {
    try {
        const messages = yield call(getApi, action.payload);
        yield put({ type: 'GET_MESSAGES_SUCCESS', messages: messages.data.data.projects });
    } catch (e) {
        yield put({ type: 'GET_MESSAGES_FAILED', message: e.message });
    }
}

function* messagesSaga() {
    yield takeEvery('GET_MESSAGES_REQUESTED', fetchMessages);
}

export default messagesSaga;