import React, { useState } from 'react';
import CommissionCheckout from "./commissionCheckout";

const CommissionPaymentCard = (props) => {
    const [commissionAmnt, setCommissionAmnt] = useState(0)
    const { project_id, additionalClass } = props;
    return (
        <div className={`ebw-entry__pro-card ${additionalClass}`}>
            <div className="ebw-paymentn__row d-flex justify-content-between align-items-center mb-3">
                <div className="ebw-paymentn__title ebw-paymentn__title--M">Easy Bids commission</div>
                <div className="ebw-paymentn__amount ebw-paymentn__amount--M">$ {commissionAmnt}</div>
            </div>
            <div className="ebw-paymentn__row mb-4">
                <p className="ebw-paymentn__para ebw-paymentn__para--M">This amount goes to easybids, Please collect project closure amount from the customer.</p>
            </div>
            <div className="ebw-entry__pro-payment">
                <h3 className="ebw-title__secondary mb-2">Payment information</h3>
                <div className="ebw-entry__pro-form d-flex flex-wrap">
                    <CommissionCheckout setCommissionAmnt={setCommissionAmnt} project_id={project_id} />
                </div>
            </div>
        </div>
    );
}

export default CommissionPaymentCard;