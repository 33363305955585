import React, { useState } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Main from "../../layout/main";
// import axios from 'axios';
// import API from '../../API';
// // import Payment from "./checkout";
import CommissionPaymentCard from "../../components/commissionPayment/commissionPaymentCard";
import LeadPaymentLoader from "../../components/getContact/leadPaymentSkeleton";
import GotHired from "../../components/projectDetails/gotHired";

const PayCommission = ({
    project_id,
    title,
    total_amount,
    project_type,
    project_sub_type,
    project_public_id,
    hours,
    full_address,
    easybids_commission,
    amount_payable_to_easybids_after_discount,
    discount_amount,
    cutomer_name,
    consumer_avatar_url,
    bid_amount,
    amount_receive_from_customer,
    amount_payable_to_easybids,
    provider_project_id,
    is_commission_paid,
    bid_hired,
    address,
}) => {
    const [show, setShow] = useState(false);
    const showNotification = () => {
        setShow(true);
        setTimeout(function () {
            setShow(false);
        }, 3000);
    }
    const copyToClipBoard = (data) => {
        showNotification();
        navigator.clipboard.writeText(data);
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Have questions about EasyBids pro ? We strive to constantly listen to the questions we receive and build out helpful resources to quickly address such inquiries." />
                <title>Commission Payment</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <Main additionalClass_main="faqs pb-0">
                <section className="ebw-section ebw-section--getreview">
                    <div className="ebw-faqs__inner">
                        <Container>
                            {/* <div className="row"> */}

                            {/* </div> */}
                            <div className="row">
                                <div className="col-lg-6 mb-4 mb-5">
                                    {!bid_hired &&
                                        <div className="ebw-project__hired-wrap mb-4">
                                            <div className="row">
                                                <div className="ebw-project__hired-top d-flex align-items-center mt-2 w-100">
                                                    <div className="ebw-project__hired-icon">
                                                        <img src="/images/projects/congradulations.svg" alt="" />
                                                    </div>
                                                    <div className="ebw-project__hired-content">
                                                        <div className="ebw-project__hired-title">Congratulations!</div>
                                                        <div className="ebw-project__hired-subtitle">You have been hired by the customer.</div>
                                                    </div>
                                                </div>
                                                {/* <div className="row"> */}
                                                <div className="ebw-project__hired-bottom px-0 w-100">
                                                    <div className="ebw-project__hired-para mb-2 ml-5">Please pay the service fees to Easy Bids</div>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    }
                                    <div className="">
                                        <div className="d-flex flex-wrap align-items-center pb-2">
                                            <div className="ebw-card__image ebw-card__image-square" style={{ background: "url(https://picsum.photos/50/50)" }}></div>
                                            <div className="ebw-card__content">
                                                <div className="ebw-card__title ebw-prodet__name ebw-card__title-medium">{title}</div>
                                                <div className="ebw-prodet__subtitle ebw-card__subtitle">{project_type}</div>
                                            </div>
                                        </div>

                                        {/* <ProjectId /> */}
                                        <div className="ebw-project__id-wrap d-flex align-items-center mt-2 mb-4">
                                            <div className="d-flex align-items-center mr-2">
                                                <div className="icon-info ebw-icon__size-xl ebw-icon__color--lightgray"><img src="/images/icons/icon-information-circle-outline.svg" alt="" /></div>
                                                <div className="ebw-project__id-label ml-1">Project ID:</div>
                                            </div>
                                            <div className="ebw-prodet__id d-flex align-items-center w-60">
                                                <input className="ebw-prodet__id-input" type="text" value={project_public_id} id="projectId" disabled="disabled" readonly />
                                                <div onClick={() => copyToClipBoard(project_public_id)} className="icon-copy-url ebw-icon__size-sm ml-1"><img src="/images/icons/icon-copy.svg" /></div>
                                            </div>
                                            <div className={show ? "dblock ebw-project__id-notification" : "d-none"}>
                                                Copied
                                            </div>
                                        </div>

                                    </div>
                                    <div className="ebw-card__divider"></div>
                                    <div className="pt-4">
                                        <div className="d-flex align-items-center mb-3">

                                            <div className="ebw-payment__customer-image">
                                                <img src={(consumer_avatar_url && consumer_avatar_url.length > 0) ? consumer_avatar_url : "./assets/images/card/default-card-img-a.png"} alt="" />
                                            </div>

                                            <div className="ebw-payment__customer-content ml-4">
                                                <div className="ebw-payment__customer-name">{cutomer_name}</div>
                                                <div className="ebw-customer__location d-flex align-items-center pt-1">
                                                    <div className="icon-location ebw-icon__size-lg ebw-icon__color--primary"></div>
                                                    <div className="ebw-customer__location-label">{address && address.address_line1} - {address && address.zip_code} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ebw-card__title ebw-title__secondary mb-2">Commission amount</div>
                                        <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                            <div className="ebw-payment__bill-label">Bid amount</div>
                                            <div className="ebw-payment__bill-amount">${bid_amount}</div>
                                        </div>
                                        <div className="ebw-card__divider ebw-card__divider--dotted mb-1"></div>
                                        <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                            <div className="ebw-payment__bill-label">Payable to Easy Bids</div>
                                            <div className="ebw-payment__bill-amount">${easybids_commission}</div>
                                        </div>
                                        {discount_amount != null &&
                                            <div>
                                                <div className="ebw-payment__bill-row ebw-payment__bill-row--orange d-flex align-items-center justify-content-between">
                                                    <div className="ebw-payment__bill-label">Discount applied by the Customer</div>
                                                    <div className="ebw-payment__bill-amount ebw-payment__bill-amount--orange">-${discount_amount}</div>
                                                </div>
                                                <div className="ebw-payment__bill-row ebw-payment__bill-row--lightgray d-flex align-items-center">
                                                    <div className="icon-info ebw-icon__size-sm"></div>
                                                    <div className="ml-1">Discount applied on Easy Bids commission</div>
                                                </div>
                                            </div>
                                        }
                                        <div className="ebw-card__divider ebw-card__divider--dotted mb-1"></div>
                                        <div className="mt-2">
                                            <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                                <div className="ebw-payment__bill-label ebw-payment__bill-label--bold">Amount to be received from Customer</div>
                                                <div className="ebw-payment__bill-amount ebw-payment__bill-amount--lightgray">${amount_receive_from_customer}</div>
                                            </div>
                                            <div className="ebw-payment__bill-row d-flex align-items-center justify-content-between">
                                                <div className="ebw-payment__bill-label ebw-payment__bill-label--bold">Commission Payable to Easy Bids</div>
                                                <div className="ebw-payment__bill-amount ebw-payment__bill-amount--bold">${Math.round(amount_payable_to_easybids_after_discount * 100) / 100}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CommissionPaymentCard
                                        project_id={project_id}

                                    />
                                </div>
                            </div>
                        </Container>
                    </div>
                </section>
            </Main>
        </div>
    );
}

export default PayCommission;