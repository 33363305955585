import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from "../../../API";

async function getApi(data) {
    const apiUrl = `${API.baseURL}${API.endPoints.myProjectsHistory}?page=${data.page}`;
    try {
        const response = axios.get(apiUrl, API.options)
        return await response;
    } catch (error) {
        throw error;
    }
}
function* fetchMyProjectsHistory(action) {
    try {
        const myProjects = yield call(getApi, action.payload);
        yield put({ type: 'GET_MYPROJECTS_HISTORY_SUCCESS', history: myProjects.data.data });
    } catch (e) {
        yield put({ type: 'GET_MYPROJECTS_HISTORY_FAILED', message: e.message });
    }
}
function* myProjectsHistorySaga() {
    yield takeEvery('GET_MYPROJECTS_HISTORY_REQUESTED', fetchMyProjectsHistory);
}
export default myProjectsHistorySaga;