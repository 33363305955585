import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from "axios";
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import API from "../../API";
import InputNumber from "../inputs/number-input";
const ProCheckoutForm = (props) => {
    const [paymentErros, setPaymentErros] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [state, setState] = useState({
        serviceAmount: null,
        availableCards: [],
        addNewCard: false,
        provider_project_id: null,
        selectedCardId: null,
        client_payment_intent_key: null,
        preLoading: false,
        error: "",
        provider_name: "",
        loadingShow: false,
        provider_id: null,
        ssn_number: '',
        ssn_number_error: "",
        paymentSuccess: false,
    });
    const geStripecardDetails = (data) => {
        if (data) {
            return [...data]
        } else {
            return []
        }
    }
    const initiateProPayment = (res) => {
        setState((prevProps) => ({
            ...prevProps,
            serviceAmount: res.data.service_amount,
            // provider_id: res.data.provider_id,
            availableCards: geStripecardDetails(res.data.stripe_card_details),
            provider_name: res.data.provider_name,
            addNewCard: res.data.stripe_card_details.length == 0,
            // provider_project_id: res.data.provider_project_id,
            selectedCardId: res.data.stripe_card_details && res.data.stripe_card_details.length != 0 ? res.data.stripe_card_details[0].id : "",
        }));
        const data = JSON.stringify({
            provider_id: props.provider_id,
        });
        axios.post(API.baseURL + `/initiate-payment`, data, API.options)
            .then(response => response.data)
            .then(res => {
                setState((prevProps) => ({
                    ...prevProps,
                    client_payment_intent_key: JSON.parse(res.payment_response).client_secret,
                    preLoading: false
                }));

                if (res.errors) {
                    setState((prevProps) => ({
                        ...prevProps,
                        error: res.errors.message, preLoading: false
                    }));
                    return
                }
            })
            .catch(error => {
                // this.setState({ error: error.error.message, loadingShow: false });
            })

    }
    const getCardDetails = () => {
        axios
            .get(
                API.baseURL +
                `/providers/get_pro_plus_hire_customer_card_details?provider_user_id=${props.user_id}`,
                API.options
            ).then(response => response.data)
            .then(res => {
                if (res.data.paid == true) {
                    window.location = "/dashboard";
                } else {
                    initiateProPayment(res);
                }
            })
            .catch(err => {
                // if (err.response.status == 404)
                //     this.props.history.push('/project-not-found')
                // else if (err.response.status == 403)
                //     this.props.history.push('/project-ignored')
            });
    }
    useEffect(() => {
        getCardDetails();
    }, []);
    const confirmElapsedPayment = (result) => {
        if (result.error) {
            setState((prevProps) => ({
                ...prevProps, error: result.error.message, stripe: true, loadingShow: false
            }));
        } else {
            if (result.paymentIntent.status === "succeeded") {
                const data = JSON.stringify({
                    payment_method_id: state.addNewCard ? result.paymentIntent.payment_method : state.selectedCardId,
                    payment_intent_id: result.paymentIntent.id,
                    // payment_method_id: result.paymentIntent.payment_method,
                    // payment_intent_id: this.state.addNewCard ? result.paymentIntent.id : this.state.selectedCardId,
                    ssn_number: state.ssn_number,
                    provider_id: Number(props.user_id),
                    payment_type: "card",
                    // project_id: Number(project_id),
                    // provider_project_id: state.provider_project_id
                });
                axios
                    .post(API.baseURL + `/confirm-payment`, data, API.options)
                    .then(response => response.data)
                    .then(res => {
                        setState((prevProps) => ({
                            ...prevProps,
                            loadingShow: false,
                            paymentSuccess: true
                        }));
                        // window.location = "/dashboard";

                    })
                    .catch(error => {
                        setState((prevProps) => ({
                            ...prevProps,
                            // error: error.error.message, loadingShow: false
                        }));
                    })
            }
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.ssn_number == "") {
            setState((prevProps) => ({
                ...prevProps,
                ssn_number_error: "Enter SSN number",
            }));
        };

        if (!stripe || !elements) { return; }
        const cardElement = elements.getElement(CardElement);
        if (state.addNewCard) {
            if (elements.getElement(CardElement)._empty) {
                setState((prevProps) => ({
                    ...prevProps,
                    error: "Enter the card details",
                }));
                return
            };

            setState((prevProps) => ({
                ...prevProps,
                loadingShow: true, stripe: false
            }));
            if (state.ssn_number != "") {
                const result = await stripe.confirmCardPayment(
                    state.client_payment_intent_key,
                    {
                        payment_method: {
                            card: elements.getElement(CardElement),
                            billing_details: {
                                name: localStorage.getItem("username")
                            }
                        },
                        setup_future_usage: 'off_session'
                    }
                );
                confirmElapsedPayment(result);
            }
        } else {
            setState((prevProps) => ({
                ...prevProps,
                loadingShow: true, stripe: false
            }));
            if (state.selectedCardId != "" && state.ssn_number != "") {
                const result = await stripe.confirmCardPayment(
                    state.client_payment_intent_key,
                    {
                        payment_method: state.selectedCardId
                    }
                );
                confirmElapsedPayment(result);
            }

        }

    }
    const newCardAdd = () => {
        setState((prevProps) => ({
            ...prevProps,
            addNewCard: true,
            selectedCardId: "",
        }));
    }
    const checkRadio = (event) => {
        // event.preventDefault()
        setState((prevProps) => ({
            ...prevProps,
            selectedCardId: event.target.value,
            addNewCard: false,
            error: "",
        }));
    };
    const handleChangeSsn = (event) => {
        setState((prevProps) => ({
            ...prevProps,
            ssn_number: event.target.value,
            ssn_number_error: ""
        }));
    }
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };
    const redirectToReview = () => {
        window.location = "/dashboard";
    }
    return (
        <>
            <form className="ebw-paymentn__form w-100" onSubmit={handleSubmit}>
                <InputNumber
                    label="SSN Number"
                    name="ssn_number"
                    id="ssn_number"
                    value={state.ssn_number}
                    // error={state.ssn_number_error}
                    changeEvent={handleChangeSsn} />
                {state.ssn_number_error ? <div className="ebw-error mb-3">{state.ssn_number_error}</div> : ""}

                {state.availableCards && state.availableCards.length != 0 &&
                    <div className="ebw-paymentn__row">
                        <div className="ebw-paymentn__label mb-2">Saved Payment Methods</div>
                        <div className="">
                            {state.availableCards && state.availableCards.length != 0 && state.availableCards.map((cards, index) => {
                                return (
                                    <div key={index} className="ebw-paymentn__card mb-2">
                                        <input
                                            onChange={(e) => checkRadio(e)}
                                            value={cards.id}
                                            id={cards.id}
                                            className="ebw-paymentn__card-input d-none"
                                            type="radio"
                                            name="card"
                                            checked={state.selectedCardId == cards.id}
                                        />
                                        <label for={cards.id} className="ebw-paymentn__card-inwrap d-flex justify-content-between align-items-center mb-0">
                                            <div className="ebw-paymentn__card-left d-flex">
                                                <div className="ebw-paymentn__checkbox">
                                                </div>
                                                <div className="ebw-paymentn__card-type d-flex align-items-center">
                                                    <div className="ebw-paymentn__card-type--img mr-2">
                                                        <img src={cards.card_icon} alt=""
                                                        />
                                                    </div>
                                                    <div className="ebw-paymentn__card-type--num d-flex m-l">XX<div>{cards.last4_digit.substring(2)}</div></div>
                                                </div>
                                            </div>
                                            <div className="ebw-paymentn__card-right">
                                                <div className="ebw-paymentn__card-mmyy">{`${cards.exp_month}/${cards.exp_year}`}</div>
                                            </div>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                <span className="ebw-paymentn__label mb-3" onClick={newCardAdd}>
                    <div className="ebw-paymentn__add-card mb-2">
                        Add new card
                        <div className="ebw-button__measuring ebw-rform__measuring-btn"></div>
                    </div>
                </span>
                {state.addNewCard && <div className="ebw-paymentn__card-new mb-3"><CardElement options={CARD_ELEMENT_OPTIONS} /></div>}
                {state.error ? <div className="ebw-error mb-3">{paymentErros}{state.error}</div> : ""}
                <button className="ebw-button ebw-button__primary btn btn-secondary w-100 border-radius" type="submit" disabled={!stripe}>
                    Pay Now
                </button>
            </form>
            <Modal isOpen={state.paymentSuccess} toggle={() => redirectToReview()}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={() => redirectToReview()}></ModalHeader>
                    <ModalBody className="pt-0">
                        <h3 className="text-4xl mb-6">Payment Success</h3>
                        <div className="ebw-modal__para ebw-modal__para--bold">Thank you for opting PRO verification process. Happy Bidding!.</div>
                        <div className="text-right d-flex justify-content-end pt-3">
                            <Button
                                onClick={() => redirectToReview()}
                                className="ebw-button__primary border-none pt-2 pb-2 pl-4 pr-4">
                                <span>Summary</span>
                            </Button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default ProCheckoutForm;