import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from "../../layout/header";
import Login from "./login";

const LoginPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <>
            <Header />
            <Login />
        </>
    );
}

export default LoginPage;