const validate = (inputs) => {

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let errors = {
        valid: true,
    };

    //name errors
    if (!inputs.name) {
        errors.nameError = 'Enter Name';
        errors.valid = false;
    }
    //Email errors
    if (!validEmailRegex.test(inputs.email)) {
        errors.emailError = 'Invalid email address';
        errors.valid = false;
    } else if (!inputs.email) {
        errors.emailError = 'Enter Email';
        errors.valid = false;
    }
    //Phone errors
    if (inputs.phone.length != 10) {
        errors.phoneError = 'Enter valid phone number'
        errors.valid = false;
    }




    return errors;
}
export default validate;