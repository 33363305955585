import React, { useEffect } from 'react';
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { useSelector, useDispatch } from 'react-redux';
import LeadPayment from "./leadPayment";
import { getCurrentProject } from "../../redux/reduxes/projectDetails/currentProjectAction";

const leadPayment = (props) => {
    const { project_id } = props.match.params;
    const { id } = props.match.params;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCurrentProject(id));
    }, [])
    const projectData = useSelector(state => state.currentProjectData.projectData);
    const projectDataLoading = useSelector(state => state.currentProjectData.loading);
    const projectDataError = useSelector(state => state.currentProjectData.error);
    // const projectDataLoading = useSelector(state => state.currentProjectData.loading);
    // const projectDataError = useSelector(state => state.currentProjectData.error);

    return (
        <>
            <Header />
            <LeadPayment project_id={project_id} projectData={projectData} projectDataLoading={projectDataLoading} projectDataError={projectDataError} />
            <Footer />
        </>
    );
}

export default leadPayment;