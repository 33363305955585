import React from 'react';
// import { Button, Row, Col } from 'react-bootstrap';
import { Button } from 'reactstrap';

const ButtonsSecondary = (props) => {

    return (
        <Button
            type={props.type}
            className={`ebw-button ebw-button__secondary ${props.additionalClass}`}
            onClick={props.clickEvent}
            onKeyDown={props.onKeyDown}
        >
            {props.text}
        </Button>
    );
}

export default ButtonsSecondary;