import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from '../../redux/reduxes/Dashboard/dashboardDataAction';
import { firstFiveNotifications } from "../../redux/actions/firstFiveNotifications";
import { allProjectsData } from "../../redux/actions/allProjects";
import { projectsAvailableData } from "../../redux/actions/projectsAvailable";
import { inConversationData } from "../../redux/actions/inConversation"
import { getProfileData } from "../../redux/actions/profileData"
import { getProfileEditData } from "../../redux/actions/profileEditData"
import { getAvailableProjects } from "../../redux/reduxes/availableProjects/availableProjectsAction";
import { getPrimaryNotifications } from "../../redux/reduxes/Dashboard/pNotificationAction";
import { getMessages } from "../../redux/reduxes/messages/messagesAction";

import DashBoard from "./dashboard";
import Header from '../../layout/header';
import Footer from '../../layout/footer';

const RegisterPAge = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state);
    const data = {
        project_type_id: "0",
        project_sub_type_id: "0"
    }
    const availableData = { page: 1 }
    useEffect(() => {
        dispatch(getDashboardData());
        dispatch(firstFiveNotifications());
        dispatch(allProjectsData());
        dispatch(projectsAvailableData());
        dispatch(inConversationData());
        dispatch(getProfileData());
        dispatch(getProfileEditData());
        dispatch(getAvailableProjects(availableData))
        dispatch(getPrimaryNotifications())
        dispatch(getMessages());
    }, [])
    // const availableProjects = useSelector(state => state.availableProjectsX.xAvailable);
    const dashboardLoading = useSelector(state => state.dashoardData.loading);
    const activities = useSelector(state => state.dashoardData.activities);
    const available_projects = useSelector(state => state.dashoardData.available_projects);
    const my_projects = useSelector(state => state.dashoardData.my_projects);
    const stats = useSelector(state => state.dashoardData.stats);
    const video_url = useSelector(state => state.dashoardData.video_url);
    const provider_id = useSelector(state => state.dashoardData.provider_id);
    const profile_score = useSelector(state => state.dashoardData.profile_score);
    const primaryNotifications = useSelector(state => state.dashoardData.primaryNotifications);
    const primaryNotificationsLoading = useSelector(state => state.dashoardData.primaryNotificationsLoading);
    const newService = useSelector(state => state.dashoardData.newService);
    const provider_avatar = useSelector(state => state.dashoardData.provider_avatar);
    const messages = useSelector(state => state.messages.messages);
    const messagesLoading = useSelector(state => state.messages.loading);
    console.log(available_projects, "available_projects in dash")
    return (
        <>
            <Header
                primaryNotifications={primaryNotifications}
            />
            <DashBoard
                stats={stats}
                availableProjects={available_projects}
                activities={activities}
                my_projects={my_projects}
                primaryNotifications={primaryNotifications}
                primaryNotificationsLoading={primaryNotificationsLoading}
                dashboardLoading={dashboardLoading}
                profile_score={profile_score}
                messages={messages}
                messagesLoading={messagesLoading}
            />
            <Footer />
        </>
    );
}

export default RegisterPAge;